/*
 * File: AddConfigureRewards.tsx
 * Project: mint-portal
 * File Created: Thursday, 20th October 2022 7:05:04 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 1st December 2023 8:19:52 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './AddConfigureRewards.css';
import React, { FC, useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../shared/Buttons';
import {
  Toolbar,
  Button,
  Paper,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTextField from '../../../../shared/CustomTextField';
import { zeplinColor } from '../../../../../theme';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import {
  IAddPointCreditRewardPayload,
  RewardType,
  IAddGiftRewardPayload,
  IDetailRewardResponse,
  IRewardQueryParam,
  IRewardCategoryList,
} from '../../../../../model';
import { RewardService } from '../../../../../service/reward.service';
import CustomNumericInput from '../../../../shared/CustomNumericField';
import { useDropzone } from 'react-dropzone';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FileService } from '../../../../../service/file.service';
import { Constants } from '../../../../../utilities/constants';
import { handleKeyPressForEscapeSymbols } from '../../../../../utilities/validate';
import InfiniteDropDown from '../../../../shared/InfiniteDropDown/InfiniteDropDown';

type editProps = {
  edit?: boolean;
  rewardDetail?: IDetailRewardResponse;
  setPointCreditPayload?: React.Dispatch<
    React.SetStateAction<IAddPointCreditRewardPayload | undefined>
  >;
  updateLoader?: boolean;
  setEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateGift?: boolean;
  id?: string;
  handleGiftState?: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateGiftState?: React.Dispatch<React.SetStateAction<boolean>>;
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 2,
  },
};
const AddConfigureRewards: FC<editProps> = ({
  edit,
  rewardDetail,
  setPointCreditPayload,
  updateLoader,
  setEditBtn,
  handleUpdateGift,
  id,
  handleGiftState,
  handleUpdateGiftState,
}: editProps) => {
  const navigate = useNavigate();
  const [rewardName, setRewardName] = useState<string>(rewardDetail?.name || '');
  const [rewardPointValue, setRewardPointValue] = useState(rewardDetail?.pointsValue || 0);
  const [rewardReason, setRewardReason] = useState(rewardDetail?.pointsCreditReward?.reason || '');
  const [selectRewardType, setRewardType] = React.useState(rewardDetail?.rewardType || '');
  const [selectRewardCategory, setRewardCategory] = React.useState<any>(
    {
      id: rewardDetail?.giftReward?.categoryId,
      name: rewardDetail?.giftReward?.categoryName,
    } || '',
  );
  const [rewardDescription, setRewardDescription] = useState(
    rewardDetail?.giftReward?.description || '',
  );
  const [rewardInventoryCount, setRewardInventoryCount] = useState(
    rewardDetail?.giftReward?.inventoryCount || 0,
  );
  const [errorImg, setErrorImg] = useState('');
  const [profileImgObj, setProfileImgObj] = useState<any>([]);
  const [profile, setProfile] = useState<any[]>(rewardDetail?.giftReward?.images || []);
  const [resImage, setResImage] = useState<string[]>([]);
  const [disableAdd, setAddButtonActive] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const prevLink = [{ path: '/rewards', label: 'Rewards' }];
  const ref = useRef<any>();

  const handleImageErrors = (acceptedFiles: any) => {
    acceptedFiles.map((i: any, file: any) => {
      if (acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_ALL_IMAGES);
      }
      if (acceptedFiles?.length == 1 && acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_SIZE_EXCEED);
      }
    });
    if (acceptedFiles.length + profileImgObj.length > 3) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_FILES_3);
    }
    if (acceptedFiles?.length && profile.length === 3) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_EXCEED);
    }
    if (
      acceptedFiles.length > 3 ||
      acceptedFiles.length + profileImgObj.length > 3 ||
      acceptedFiles.length + profile.length > 3
    ) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_FILES_3);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      'image/jpeg': ['.png', '.jpg'],
    },
    onDrop: (acceptedFiles) => {
      handleImageErrors(acceptedFiles);
      if (
        acceptedFiles.filter((value) => value.size > 1048576).length === 0 &&
        acceptedFiles.length <= 3 - profile.length &&
        ref.current >= acceptedFiles.length
      ) {
        setErrorImg('');
        setProfile((prevFiles: any) => {
          return [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                location: URL.createObjectURL(file),
              }),
            ),
          ];
        });
        setProfileImgObj((prevFiles: any) => {
          return [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                location: URL.createObjectURL(file),
              }),
            ),
          ];
        });
      }
    },
  });

  useEffect(() => {
    ref.current = 3 - profile.length;
  }, [profile]);

  const [categoryList, setRewardCategoryList] = useState<IRewardCategoryList>({
    categories: [],
    total: 0,
  });
  const [searchCategoryList, setSearchRewardCategoryList] = useState<IRewardCategoryList>({
    categories: [],
    total: 0,
  });

  const [categorySearch, setCategorySearch] = useState('');
  const [rewardOptionsOpen, setRewardOptionsOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [searchCounter, setSearchCounter] = useState(0);
  const [typed, setTyped] = useState(false);

  const createPointCreditPayload: IAddPointCreditRewardPayload = {
    name: rewardName,
    pointsValue: rewardPointValue,
    reason: rewardReason,
  };

  useEffect(() => {
    if (selectRewardType === RewardType.Gift) {
      setSearchCounter(0 + 4);
      getRewardCategoryList(0);
      !categorySearch &&
        setSearchRewardCategoryList({
          categories: [],
          total: 0,
        });
    }
  }, [categorySearch, selectRewardType]);
  // For enabling/disabling add button
  useEffect(() => {
    if (
      (rewardName.trim() &&
        rewardReason.trim() &&
        rewardPointValue > 0 &&
        rewardPointValue <= Constants.REWARDS_VALIDATION.MAX_POINT_VALUE) ||
      (rewardName.trim() &&
        rewardDescription.trim() &&
        rewardPointValue > 0 &&
        rewardPointValue <= Constants.REWARDS_VALIDATION.MAX_POINT_VALUE &&
        selectRewardCategory !== '' &&
        selectRewardCategory !== null &&
        rewardInventoryCount >= 0 &&
        rewardInventoryCount <= Constants.REWARDS_VALIDATION.MAX_INVENTORY_COUNT &&
        profile.length > 0)
    ) {
      setAddButtonActive(false);
      setEditBtn && setEditBtn(false);
    } else {
      setAddButtonActive(true);
      setEditBtn && setEditBtn(true);
    }
    if (edit) {
      if (rewardDetail?.rewardType === RewardType.PointsCredit) {
        setPointCreditPayload && setPointCreditPayload(createPointCreditPayload);
      } else {
        if (handleUpdateGift) uploadFileOnS3AndAddGiftReward();
      }
    }
  }, [
    rewardName,
    rewardPointValue,
    rewardReason,
    rewardInventoryCount,
    rewardDescription,
    selectRewardCategory,
    profile,
    handleUpdateGift,
  ]);

  useEffect(() => {
    setProfileImgObj(profile);
  }, [profile]);

  const handleRewardOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRewardType((event.target as HTMLInputElement).value);
    resetFields();
  };

  const handleNameChange = (e: any) => {
    setRewardName(e.target.value);
  };
  const handlePointValueChange = (e: any) => {
    if (e.target.value >= 0) {
      setRewardPointValue(+e.target.value);
    }
  };
  const onPaste = (e: any) => {
    e.preventDefault();
    return false;
  };
  const handleRewardReasonChange = (e: any) => {
    setRewardReason(e.target.value);
  };
  const handleRewardDescriptionChange = (e: any) => {
    setRewardDescription(e.target.value);
  };
  const handleInventoryCountChange = (e: any) => {
    setRewardInventoryCount(+e.target.value);
  };

  const resetFields = () => {
    setRewardName('');
    setRewardPointValue(0);
    setRewardReason('');
    setRewardDescription('');
    setRewardInventoryCount(0);
    setRewardCategory('');
    setResImage([]);
    setProfile([]);
    setProfileImgObj([]);
  };

  const onSearchCategory = (searchVal: any) => {
    setTyped(true);
    setCategorySearch(searchVal.target.value);
  };
  const onSelectRewardCategory = (inputValue: any) => {
    setRewardOptionsOpen(!rewardOptionsOpen);
    setRewardCategory(inputValue);
    setCategorySearch('');
    setSearchRewardCategoryList({ categories: [], total: 0 });
    setCounter(0);
    setRewardCategoryList({ categories: [], total: 0 });
    setTyped(false);
  };
  const getRewardCategoryList = async (skipArg?: number) => {
    if (categorySearch && categorySearch.length < Constants.SEARCH_LIMIT) {
      setShowLoader(false);
    }
    try {
      setCounter(counter + 4);
      const queryParam: IRewardQueryParam = {
        limit: 4,
        skip: skipArg ? skipArg : 0,
        ...(categorySearch &&
          categorySearch.length > 2 && {
          searchString: categorySearch,
          skip: skipArg ? skipArg : 0,
        }),
      };
      const detail = await RewardService.getRewardCategory(queryParam);
      const prevVal = searchCategoryList?.categories?.filter((e: any) =>
        e?.name?.toLowerCase().match(categorySearch),
      );

      console.log(prevVal);
      categorySearch.length > 2
        ? setSearchRewardCategoryList({
          categories: prevVal?.length
            ? [
              ...prevVal,
              ...detail?.data?.categories?.filter(
                (e: any) => !prevVal.filter((ref: any) => ref.id == e.id).length,
              ),
            ]
            : detail.data.categories,
          total: detail.data.total,
        })
        : setRewardCategoryList((prev) => ({
          categories: prev?.categories?.length
            ? [
              ...prev?.categories,
              ...detail?.data?.categories?.filter(
                (e: any) => !prev?.categories.filter((ref: any) => ref.id == e.id).length,
              ),
            ]
            : detail.data.categories,
          total: detail.data.total,
        }));
      setShowLoader(false);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      setShowSnackbar(true);
    }
  };

  const addPointCreditReward = async () => {
    setShowLoader(true);
    const addPayload: IAddPointCreditRewardPayload = {
      name: rewardName,
      pointsValue: +rewardPointValue,
      reason: rewardReason,
    };
    try {
      const detail = await RewardService.addPointCreditReward(addPayload);
      setShowLoader(false);
      if (detail?.data) {
        sessionStorage.setItem('addedReward', addPayload.name);
      }
      navigate(prevLink[0].path);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      setShowSnackbar(true);
    }
  };

  const addGiftReward = async (image: string[]) => {
    setShowLoader(true);
    const addPayload: IAddGiftRewardPayload = {
      name: rewardName,
      pointsValue: +rewardPointValue,
      description: rewardDescription,
      inventoryCount: +rewardInventoryCount,
      categoryId: +selectRewardCategory?.id,
      imagesS3Keys:
        image && image.length && !profile
          ? image
          : !image.length && profile
            ? profile.map((i) => i.s3Key)
            : [...image, ...profile.map((i) => i.s3Key).filter((j) => j !== undefined)],
    };
    try {
      if (edit && id) {
        const detail = await RewardService.editGiftReward(+id, addPayload);
        if (detail?.data) {
          sessionStorage.setItem('updatedReward', addPayload.name);
        }
        handleGiftState && handleGiftState(false);
        handleUpdateGiftState && handleUpdateGiftState(false);
        navigate(`/rewards/detail/${id}`);
      } else {
        const detail = await RewardService.addGiftReward(addPayload);
        if (detail?.data) {
          sessionStorage.setItem('addedReward', addPayload.name);
        }
        navigate(prevLink[0].path);
      }
      setShowLoader(false);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      setShowSnackbar(true);
    }
  };
  const removeImg = (ind: number, type: string) => {
    setErrorImg('');
    if (type) {
      setResImage((resImage as any[])?.filter((d: any) => type !== d?.s3Key));
    } else {
      setProfileImgObj((profileImgObj as any[])?.filter((d: any, ref: number) => ref !== ind));
    }
    setProfile((profile as any[])?.filter((d: any, ref: number) => ref !== ind));
  };

  const uploadFileOnS3AndAddGiftReward = async () => {
    setShowLoader(true);
    if (profileImgObj && profileImgObj.length) {
      try {
        const imageArr =
          (await profileImgObj?.length) &&
          (await profileImgObj.map(async (file: any) => {
            return file?.s3Key
              ? file.s3Key
              : await FileService.getSignedUrlToUploadFile(
                Constants.FILE_UPLOAD_CATEGORY.REWARD_GIFT_IMAGES,
                file,
                { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
              );
          }));
        await Promise.all(imageArr)
          .then((val) => {
            const resolveArr = val
              .filter((d: any) => d?.signedUrl)
              .map((d: any) => d?.signedUrl?.data?.data?.s3Key);
            addGiftReward(resolveArr);
          })
          .catch((err) => {
            setShowSnackbar(true);
            setShowSnackbarMessage(
              typeof err === 'string' ? err : (err as any).response.data.error.message[0],
            );
            setShowLoader(false);
          });
        return true;
      } catch (err) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowLoader(false);
      }
    } else {
      addGiftReward([]);
    }
  };

  const handleRewardOptionsOpen = () => {
    setCategorySearch('');
    setRewardOptionsOpen(!rewardOptionsOpen);
    getRewardCategoryList(0);
    if (rewardOptionsOpen) {
      setTyped(false);
      setCounter(0);
      setRewardCategoryList({ categories: [], total: 0 });
    }
  };

  return (
    <>
      {(showLoader || updateLoader) && <Loader />}
      <div className='rewardAddContainer'>
        {edit ? (
          ''
        ) : (
          <div className='rewardAddHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                Add New Reward
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Add New Reward'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant='outlined'
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() => navigate(prevLink[0].path)}
                >
                  Cancel
                </Button>
                <Buttons
                  text='Add New Reward'
                  width='15em'
                  disable={disableAdd}
                  onClick={() =>
                    selectRewardType === RewardType.PointsCredit
                      ? addPointCreditReward()
                      : uploadFileOnS3AndAddGiftReward()
                  }
                />
              </div>
            </Toolbar>
          </div>
        )}
        <Paper className='rewardAddBox' sx={{ borderRadius: '16px' }}>
          <div className='rewardAdd'>
            <div className='rewardSection'>
              {!edit ? (
                <section>
                  <Typography variant='subtitle1'>Select a Reward Type*</Typography>
                  <FormControl
                    style={{ marginTop: '12px', marginLeft: '12px', position: 'inherit' }}
                  >
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={selectRewardType}
                      onChange={handleRewardOptionChange}
                    >
                      <FormControlLabel
                        value='gift'
                        control={
                          <Radio
                            disabled={showLoader ? true : false}
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Gift</Typography>}
                      />
                      <FormControlLabel
                        value='points_credit'
                        control={
                          <Radio
                            disabled={showLoader ? true : false}
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Points Credit</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </section>
              ) : (
                <section style={{ marginLeft: '8px' }}>
                  <Typography variant='subtitle1'> Reward Type*</Typography>
                  <Typography variant='body1'>
                    {selectRewardType === RewardType.PointsCredit ? 'Points Credit' : 'Gift'}
                  </Typography>
                </section>
              )}

              {selectRewardType && (
                <CustomTextField
                  name='rewardName'
                  label='Reward Name*'
                  showIcon={false}
                  value={rewardName}
                  onChange={handleNameChange}
                />
              )}
              {selectRewardType === RewardType.PointsCredit ? (
                <>
                  <CustomTextField
                    name='rewardReason'
                    label='Reason for Points Credit*'
                    showIcon={false}
                    value={rewardReason}
                    onChange={handleRewardReasonChange}
                    multiline={true}
                  />
                  <CustomNumericInput
                    name='point value'
                    label='Points Value*'
                    width='98%'
                    value={rewardPointValue}
                    error={
                      rewardPointValue > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE ? true : false
                    }
                    icon={false}
                    onChange={handlePointValueChange}
                    min={0}
                    marginTop='8px'
                    onKeyPress={handleKeyPressForEscapeSymbols}
                    onPaste={onPaste}
                  />
                  {rewardPointValue > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE && (
                    <Typography
                      className='reward-inline-err'
                      variant='body1'
                      style={{ color: zeplinColor.Error }}
                    >
                      {Constants.ERROR_MESSAGES.REWARDS.MAX_POINT_VALUE_EXCEED}
                    </Typography>
                  )}
                </>
              ) : selectRewardType === RewardType.Gift ? (
                <>
                  <div className='' style={{ marginLeft: 10 }}>
                    {/* <SearchDropdown
                      searchList={categoryList?.categories}
                      width={'100%'}
                      label={'Reward Category*'}
                      onSelectHandler={onSelectRewardCategory}
                      onSearchHandler={onSearchCategory}
                      multiple={false}
                      value={selectRewardCategory || undefined}
                      // fixed={selectRewardCategory ? true : false}
                      // paddingLeft={17}
                      placeholderPosition={true}
                    /> */}
                    <InfiniteDropDown
                      handleOpen={handleRewardOptionsOpen}
                      open={rewardOptionsOpen}
                      searchList={
                        categorySearch ? searchCategoryList?.categories : categoryList?.categories
                      }
                      name={'rewardCategory'}
                      padding
                      zIndex={'1'}
                      onSelectHandler={onSelectRewardCategory}
                      setSelected={setRewardCategory}
                      onSearchHandler={onSearchCategory}
                      search={categorySearch}
                      value={selectRewardCategory}
                      disable={false}
                      label={'Reward Category*'}
                      infinite={'rewardCategory'}
                      dataLength={
                        categorySearch.length
                          ? searchCategoryList?.categories?.length
                          : categoryList?.categories.length
                      }
                      hasMore={
                        categorySearch?.length > 2
                          ? searchCategoryList?.categories?.length < searchCategoryList?.total
                          : categoryList?.categories?.length < categoryList?.total
                      }
                      next={getRewardCategoryList}
                      counter={searchCategoryList ? searchCounter : counter}
                      setCounter={setSearchCounter}
                      clearSearch={typed ? true : false}
                      multiple={false}
                      width={'100%'}
                    />
                  </div>
                  <CustomTextField
                    name='description'
                    label='Brief Description of the Reward*'
                    showIcon={false}
                    value={rewardDescription}
                    onChange={handleRewardDescriptionChange}
                    multiline={true}
                  />
                  <CustomNumericInput
                    name='inventoryCount'
                    label='Inventory Count*'
                    width='98%'
                    value={rewardInventoryCount}
                    error={
                      rewardInventoryCount > Constants.REWARDS_VALIDATION.MAX_INVENTORY_COUNT
                        ? true
                        : false
                    }
                    icon={false}
                    onChange={handleInventoryCountChange}
                    min={0}
                    marginTop='8px'
                    onKeyPress={handleKeyPressForEscapeSymbols}
                    onPaste={onPaste}
                  />
                  {rewardInventoryCount > Constants.REWARDS_VALIDATION.MAX_INVENTORY_COUNT && (
                    <Typography
                      className='reward-inline-err'
                      variant='body1'
                      style={{ color: zeplinColor.Error }}
                    >
                      {Constants.ERROR_MESSAGES.REWARDS.MAX_INVENTORY_COUNT_EXCEED}
                    </Typography>
                  )}
                  <CustomNumericInput
                    name='point value'
                    label='Points Value*'
                    width='98%'
                    value={rewardPointValue}
                    error={
                      rewardPointValue > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE ? true : false
                    }
                    icon={false}
                    onChange={handlePointValueChange}
                    min={0}
                    marginTop='8px'
                    onKeyPress={handleKeyPressForEscapeSymbols}
                    onPaste={onPaste}
                  />
                  {rewardPointValue > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE && (
                    <Typography
                      className='reward-inline-err'
                      variant='body1'
                      style={{ color: zeplinColor.Error }}
                    >
                      {Constants.ERROR_MESSAGES.REWARDS.MAX_POINT_VALUE_EXCEED}
                    </Typography>
                  )}
                  <section style={{ width: '46vw' }}>
                    <Typography variant='h6' style={{ marginLeft: 10 }}>
                      Reward Photos*
                    </Typography>
                    <Typography
                      variant='body1'
                      style={{ marginLeft: 10, marginBottom: 7, color: zeplinColor.Background50 }}
                    >
                      You can upload upto 3 images
                    </Typography>
                    <div
                      style={{
                        borderStyle: 'dashed',
                        borderWidth: '0.2rem',
                        borderColor: zeplinColor.Primary,
                        backgroundColor: zeplinColor.Background97,
                        marginLeft: 10,
                      }}
                      {...getRootProps({ className: 'dropzone upload-btn' })}
                    >
                      <input {...getInputProps()} />
                      <SaveAltIcon />
                      <Typography variant='body1'>Drag & drop or click to upload</Typography>
                      <Typography variant='caption'>
                        JPG or PNG, 16:9 ratio and max size of 1 MB
                      </Typography>
                    </div>

                    {errorImg && (
                      <Typography
                        className='reward-imgDescription'
                        variant='body1'
                        style={{ color: zeplinColor.Error }}
                      >
                        {errorImg}
                      </Typography>
                    )}

                    {profile?.length ? (
                      <Carousel responsive={responsive} showDots containerClass='upload'>
                        {profile?.map((d, ind) => (
                          <div
                            style={{ position: 'relative', cursor: 'pointer', marginTop: 26 }}
                            key={ind}
                          >
                            <img
                              src={d?.location ? d?.location : (d as string)}
                              className='carousel-img'
                              key={ind}
                              alt='clinic-photos'
                            />

                            <img
                              src={'/close-selected.svg'}
                              className='reward-close-img'
                              onClick={() =>
                                d?.s3Key ? removeImg(ind, d?.s3Key) : removeImg(ind, '')
                              }
                              alt='close-button'
                            />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      ''
                    )}
                  </section>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Paper>
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
    </>
  );
};

export default AddConfigureRewards;
