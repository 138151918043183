import './Loader.css';

/*
 * File: Loader.tsx
 * Project: mint-portal
 * File Created: Wednesday, 20th July 2022 11:49:00 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 24th November 2022 7:17:56 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';

import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { zeplinColor } from '../../../theme';

type loaderProps = {
  margin?: boolean;
  small?: boolean;
  forTable?: boolean;
};
const Loader: FC<loaderProps> = ({ margin,small,forTable }: loaderProps) => {
  return (
    <>
      <div style={{ backgroundColor: `${zeplinColor.Surface}` }} className='loaderContainer'></div>
      <CircularProgress
        style={{ color: `${zeplinColor.Primary}`, top: forTable ? '18rem':'' }}
        className={small?`loadersmall ${margin ? 'margin' : ''}`:`loader ${margin ? 'margin' : ''}`}
      />
    </>
  );
};

export default Loader;
