/*
 * File: ListFamilyMembers.tsx
 * Project: mint-portal
 * File Created: Tuesday, 4th October 2022 4:04:41 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 11th October 2022 1:42:40 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { IFamilyMembersResponse, IListFamilyMembersProfilesResponse } from '../../../model';
import EmptyState from '../../shared/EmptyState/EmptyState';
import CustomToolTip from '../../shared/CustomToolTip';
import { Constants } from '../../../utilities/constants';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  familyMemberList: IListFamilyMembersProfilesResponse;
  onPageChangeHandler: any;
  currentPage: number;
}

interface FamilyMemberListColumn {
  id: 'family_member__name' | 'relationship' | 'patient_unique_code';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const familyMemberListColumns: FamilyMemberListColumn[] = [
  { id: 'family_member__name', label: 'Family Member’s Name', minWidth: 300 },
  { id: 'relationship', label: 'Relationship with Patient', minWidth: 150 },
  { id: 'patient_unique_code', label: 'Patient Unique Code', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListFamilyMembers: FC<IProps> = ({ familyMemberList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      {familyMemberList.total && familyMemberList.familyMembers.length > 0 ? (
        <div>
          <Paper
            className='containerdiv'
            sx={{ borderRadius: '16px', margin: '8px 0px 24px -16px' }}
          >
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {familyMemberListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {familyMemberList.total &&
                    familyMemberList.familyMembers.length &&
                    familyMemberList.familyMembers.map((familyMemObj: IFamilyMembersResponse) => {
                      return (
                        <TableRow
                          hover
                          classes={{ hover: classes.tableRow }}
                          key={familyMemObj.id}
                          onClick={() =>
                            navigate({
                              pathname: `/patient/${id}/family-member/${familyMemObj.id}`,
                            })
                          }
                        >
                          <TableCell>
                            <CustomToolTip
                              title={familyMemObj.firstName + ' ' + familyMemObj.lastName}
                              width={'250px'}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography>{familyMemObj.relationship}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{familyMemObj.code}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              familyMemberList.total <= Constants.LIMIT
                ? 1
                : Math.ceil(familyMemberList.total / Constants.LIMIT)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </div>
      ) : (
        familyMemberList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={''}
              text={'No family member to show'}
              subText={''}
              buttonText={''}
              showAddButton={false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListFamilyMembers;
