/*
 * File: ViewSocialLinks.tsx
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 8:20:22 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 11:45:33 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import React, { FC } from 'react';
import { ILinkDetailResponse } from '../../../../../../model/manage.model';
import { zeplinColor } from '../../../../../../theme';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Paper } from '@mui/material';
import './ListSocialLinks.css';
import { Constants } from '../../../../../../utilities/constants';
import { useNavigate } from 'react-router-dom';

interface IProps {
  socialList: ILinkDetailResponse[];
}

interface SocialListColumn {
  id: 'social' | 'url' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const socialListColumns: SocialListColumn[] = [
  { id: 'social', label: 'Social', minWidth: 350 },
  { id: 'url', label: 'URL', minWidth: 350 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
];

const ListSocialLinks: FC<IProps> = ({ socialList }) => {

  const navigate = useNavigate();

  return (
    <Paper className='containerLinkdiv' sx={{ borderRadius: '16px' }}>
      <TableContainer>
        <Table aria-label='caption table'>
          <TableHead>
            <TableRow>
              {socialListColumns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {socialList?.length &&
              socialList.map((obj: ILinkDetailResponse) => {
                return (
                  <TableRow key={obj.id}>
                    <TableCell key={obj.id}>
                      <Typography className='linkTitle' variant='subtitle1'>
                        <img
                          className='avatar'
                          src={obj?.imageDetails?.location || Constants.FALL_BACK_IMAGE}
                          onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
                        ></img>
                        {obj?.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{obj.url || '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Button className='actionNext' color='primary' onClick={() =>
                        navigate({
                          pathname: `/manage/social-link/${obj.id}`,
                        })
                      }>
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default ListSocialLinks;
