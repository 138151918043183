/*
 * File: Location.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 8:27:47 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 11th December 2023 8:32:54 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './Location.css';
import React, { useState, useEffect } from 'react';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { Box, Link, Typography } from '@material-ui/core';
import Field from '../../../../shared/CustomTextField';
import Buttons from '../../../../shared/Buttons';
import Geocode from 'react-geocode';
import { IClinicLocationPayload, IViewClinicDetailsData } from '../../../../../model';
import { ClinicService } from '../../../../../service/clinic.service';
import { Constants } from '../../../../../utilities/constants';
// import GoogleMapReact from 'google-map-react';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
// import pin from '../../../../../assests/map-pin.svg';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useJsApiLoader } from '@react-google-maps/api';
import InfiniteDropDown from '../../../../shared/InfiniteDropDown/InfiniteDropDown';

type locationProps = {
  updatedClinicDetails: undefined | IViewClinicDetailsData;
  handleCreateClinicBtn: (value: boolean) => void;
  showNavLoader?: boolean;
};
// let marker: any;
const Location = ({
  updatedClinicDetails,
  handleCreateClinicBtn,
  showNavLoader,
}: locationProps) => {
  const route = useLocation();
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  // console.log(googleApiKey)
  Geocode.setApiKey(googleApiKey || '');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyARCKPTzaUKFG3kSEavWc0yhNK5pYrHAF4',
  });
  console.log(isLoaded);
  const [clinicAddress, setClinicAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  // const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showLocationLoader, setShowLocationLoader] = useState(false);

  const [businessAccount, setBusinessAccount] = useState<any>();
  const [businessLocation, setBusinessLocation] = useState<any>();
  const [accounts, setAccounts] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);

  const [authExpiry, setAuthExpiry] = useState(0);
  const [googleAuth, setGoogleAuth] = useState('');
  const [prefilled, setPrefilled] = useState(true);
  const [locationNextPage, setLocationNextPage] = useState('');
  const [accountsNextPage, setAccountsNextPage] = useState('');

  const editUrl = process.env.REACT_APP_REDIRECT_EDIT_URL;
  const addUrl = process.env.REACT_APP_REDIRECT_ADD_URL;
  const viewUrl = process.env.REACT_APP_REDIRECT_VIEW_URL;
  const [redirectUrl, setRedirectUrl] = useState('');
  const storeAuth = sessionStorage.getItem('storeOauth');
  const exchangeCode = sessionStorage.getItem('exchangeCode');
  const [accountOpen, setAccountOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [locationSearch, setLocationSearch] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [clearAccountSearch, setClearAccountSearch] = useState(false);
  const [clearLocationSearch, setClearLocationSearch] = useState(false);
  const [filteredAccount, setFilteredAccount] = useState<any>([]);
  // console.log({ editUrl, addUrl, viewUrl, env: process.env });
  const verifyAuth = async () => {
    try {
      if (storeAuth) {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${storeAuth}`,
        );
        setAuthExpiry(res.data.expires_in);
      }
    } catch (err) {
      sessionStorage.removeItem('storeOauth');
      sessionStorage.removeItem('exchangeCode');
      window.location.reload();
    }
  };

  const exchangeAuth = async (val: string) => {
    try {
      if (val && redirectUrl) {
        const res = await axios.post(
          `https://oauth2.googleapis.com/token?client_id=32511426006-b4a6vimf80un61aag11fe627v1sis4pp.apps.googleusercontent.com&client_secret=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&code=${val}&grant_type=authorization_code&redirect_uri=${redirectUrl}`,
        );

        if (res?.data) {
          setGoogleAuth(res?.data?.access_token);
          setAuthExpiry(res?.data?.expires_in);
          sessionStorage.setItem('storeOauth', res?.data?.access_token);
          sessionStorage.setItem('exchangeCode', val);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(JSON.parse(JSON.stringify(err))?.code);
    }
  };
  useEffect(() => {
    if (route?.pathname?.includes('add') && addUrl) {
      setRedirectUrl(addUrl);
    } else if (route?.pathname?.includes('edit') && editUrl) {
      setRedirectUrl(editUrl);
    } else if (route?.pathname?.includes('view') && viewUrl) {
      setRedirectUrl(viewUrl);
    }
  }, [route?.pathname]);
  useEffect(() => {
    if (route?.search?.includes('code') && !exchangeCode && authExpiry <= 0) {
      const code = route?.search.split('code=')[1]?.split('scope=')[0] || exchangeCode;
      code && exchangeAuth(code);
    }
  }, [route.search, redirectUrl]);

  useEffect(() => {
    if (route?.search?.includes('code') || storeAuth) {
      verifyAuth();
    }
  }, [storeAuth, route.search]);

  useEffect(() => {
    const prefilled = () => {
      if (updatedClinicDetails?.lat && updatedClinicDetails?.long) {
        updatedClinicDetails?.accountId &&
          setBusinessAccount({
            name: `accounts/${updatedClinicDetails?.accountId}`,
            accountName: updatedClinicDetails?.accountName,
          });
        setLatitude(updatedClinicDetails?.lat.toString() || '');
        setLongitude(updatedClinicDetails?.long.toString() || '');
        updatedClinicDetails?.locationId &&
          setBusinessLocation({
            name: `locations/${updatedClinicDetails?.locationId}`,
            title: updatedClinicDetails?.locationTitle,
          });
        updatedClinicDetails?.locationId && setClinicAddress(updatedClinicDetails?.address || '');
      }
    };
    prefilled();
  }, [updatedClinicDetails?.id]);

  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (clinicAddress) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    }
  }, [clinicAddress]);

  const handleClinicAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClinicAddress(event.target.value);
  };

  const saveLocation = async () => {
    setShowLocationLoader(true);
    const placeId =
      businessLocation?.metadata?.placeId ||
      locations?.filter((val: any) => val.name === businessLocation.name)[0].metadata.placeId;
    const payload: IClinicLocationPayload = {
      address: clinicAddress,
      accountId: businessAccount?.name.split('/')[1],
      accountName: businessAccount?.accountName,
      locationId: businessLocation?.name.split('/')[1],
      locationTitle: businessLocation?.title,
      placeId,
      lat: +latitude,
      long: +longitude,
    };
    try {
      if (updatedClinicDetails?.id) {
        const locationResponse = await ClinicService.saveLocationDetails(
          payload,
          updatedClinicDetails?.id,
        );
        if (locationResponse.data && !locationResponse.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        setShowLocationLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Location Saved');
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLocationLoader(false);
    }
  };

  const onSelectAccount = (e: any) => {
    setBusinessAccount(e);
    setBusinessLocation('');
    setAccountSearch('');
    setLocationSearch('');
    handleAccountOpen();
  };
  const onSelectLocation = (e: any, select?: boolean) => {
    setBusinessLocation(e);
    select && handleLocationOpen();
  };
  const callAccount = async (nextPage?: string) => {
    try {
      if (storeAuth || googleAuth) {
        const res = await axios.get(
          nextPage
            ? `https://mybusinessaccountmanagement.googleapis.com/v1/accounts?pageToken=${nextPage}`
            : // : search && val
          //   ? `https://mybusinessaccountmanagement.googleapis.com/v1/accounts?filter=accountName=${val}`
            'https://mybusinessaccountmanagement.googleapis.com/v1/accounts',
          {
            headers: {
              Authorization: `Bearer ${storeAuth ? storeAuth : googleAuth}`,
            },
          },
        );
        if (res?.data) {
          setAccounts(res?.data?.accounts);
          setAccountsNextPage(res?.data?.nextPageToken);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(JSON.parse(JSON.stringify(err)).code);
      // sessionStorage.removeItem('storeOauth');
      // sessionStorage.removeItem('exchangeCode');
      // window.location.reload();
    }
  };

  const callLocation = async (val?: string, search = false, nextPage?: string) => {
    !search && !nextPage && !showNavLoader && setShowLocationLoader(true);
    try {
      if (googleAuth || storeAuth) {
        if (!prefilled && !search && !nextPage) {
          onSelectLocation(undefined, false);
          setClinicAddress('');
        }
        setPrefilled(false);
        const id = businessAccount.name.split('/')[1];
        const res = await axios.get(
          val
            ? `https://mybusinessaccountmanagement.googleapis.com/v1/accounts/${id}/locations?read_mask=name,title,metadata&filter=title="${val}"
`
            : nextPage
              ? `https://mybusinessaccountmanagement.googleapis.com/v1/accounts/${id}/locations?read_mask=name,title,metadata&pageToken=${nextPage}`
              : `https://mybusinessaccountmanagement.googleapis.com/v1/accounts/${id}/locations?read_mask=name,title,metadata`,
          {
            headers: {
              Authorization: `Bearer ${storeAuth ? storeAuth : googleAuth}`,
            },
          },
        );
        if (res?.data) {
          setLocations(
            res?.data?.locations
              ? nextPage
                ? locations.concat(res?.data?.locations)
                : res?.data?.locations
              : [],
          );
          setLocationNextPage(res?.data?.nextPageToken);
        } else {
          setLocations([]);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(JSON.parse(JSON.stringify(err))?.code);
      verifyAuth();
    } finally {
      setShowLocationLoader(false);
    }
  };
  const callAddress = async () => {
    setShowLocationLoader(true);
    try {
      const geocoder = new window.google.maps.Geocoder();
      const res = await geocoder.geocode({ placeId: businessLocation?.metadata?.placeId });
      if (res?.results?.length) {
        setLatitude(res.results[0].geometry.location.lat().toString());
        setLongitude(res.results[0].geometry.location.lng().toString());
        setClinicAddress(res.results[0].formatted_address);
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(JSON.parse(JSON.stringify(err))?.message);
      // setShowLoader(false);
    } finally {
      setShowLocationLoader(false);
    }
  };
  useEffect(() => {
    if (route.search.includes('code=') || (storeAuth && authExpiry > 0)) {
      callAccount();
    }
  }, [storeAuth, googleAuth, authExpiry, accountOpen]);
  useEffect(() => {
    if (businessAccount?.accountName) {
      callLocation();
    }
  }, [businessAccount]);

  useEffect(() => {
    if (businessAccount?.accountName && !locationOpen) {
      callLocation('', true);
    }
  }, [locationOpen]);

  const searchLocation = (e: any) => {
    setLocationSearch(e.target.value);
    e.target.value.length < 1 && setClearLocationSearch(true);
    callLocation(e.target.value, true);
  };
  const searchAccount = (e: any) => {
    e.target.value.length < 1 && setClearAccountSearch(true);
    setAccountSearch(e.target.value);
    // callAccount(e, true);
    setFilteredAccount(
      accounts.filter(
        (val: any) =>
          val?.accountName.match(e.target.value) && e.target.value.length <= val.accountName.length,
      ),
    );
  };
  useEffect(() => {
    if (businessLocation && locations?.length) {
      callAddress();
    }
  }, [businessLocation]);

  const handleAccountOpen = () => {
    setAccountOpen(!accountOpen);
  };
  const handleLocationOpen = () => {
    setLocationOpen(!locationOpen);
  };
  const handleOpenDropDown = () => {
    setClearAccountSearch(false);
    setClearLocationSearch(false);
    setFilteredAccount([]);
    if (accountOpen) {
      setAccountOpen(false);
      setAccountSearch('');
    }
    if (locationOpen) {
      setLocationOpen(false);
      setLocationSearch('');
    }
  };
  return (
    <div id='component' onClick={() => handleOpenDropDown()}>
      {!updatedClinicDetails?.id ? (
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '712px',
          }}
        >
          <EmptyState
            path={'/'}
            text={'Clinic Details Required'}
            subText={'Please complete Clinic Details\n first to get access to this section.'}
            buttonText={''}
            showAddButton={false}
            image={false}
          />
        </section>
      ) : (
        <div className='location-details-container'>
          {showLocationLoader && <Loader margin={true} small={true} />}
          <Typography variant='h6'>Location</Typography>
          <Box sx={{ margin: '32px auto' }}>
            <InfoTextBox
              content={
                authExpiry > 0
                  ? Constants.INFO_TEXTS.GOOGLE_BUSINESS_PROFILE
                  : Constants.INFO_TEXTS.GOOGLE_BUSINESS_AUTH
              }
              width={'100%'}
              authenticated={authExpiry > 0}
              show={true}
            />
          </Box>
          {authExpiry <= 0 ? (
            <section style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Link
                href={`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUrl}&prompt=consent&response_type=code&client_id=32511426006-b4a6vimf80un61aag11fe627v1sis4pp.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/business.manage&access_type=offline`}
                key='authenticate-btn'
              >
                <Buttons
                  text='AUTHENTICATE GOOGLE BUSINESS PROFILE'
                  bgWhite={true}
                  width='100%'
                  disable={false}
                  onClick={() => {
                    return;
                  }}
                />
              </Link>
            </section>
          ) : (
            <section
              style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: 28 }}
            >
              <section>
                <InfiniteDropDown
                  handleOpen={handleAccountOpen}
                  open={accountOpen}
                  searchList={accountSearch ? filteredAccount : accounts}
                  name={'accounts'}
                  zIndex={'10'}
                  onSelectHandler={onSelectAccount}
                  search={accountSearch}
                  onSearchHandler={searchAccount}
                  setFiltered={setFilteredAccount}
                  value={businessAccount ? businessAccount : null}
                  label={'Select Business Account'}
                  disable={route?.pathname?.includes('view')}
                  infinite={'account'}
                  dataLength={accounts?.length}
                  hasMore={accountsNextPage}
                  next={callAccount}
                  clearSearch={clearAccountSearch}
                />
              </section>
              <section>
                <InfiniteDropDown
                  handleOpen={handleLocationOpen}
                  open={locationOpen}
                  searchList={locations}
                  name={'locations'}
                  zIndex={'1'}
                  onSelectHandler={onSelectLocation}
                  onSearchHandler={searchLocation}
                  search={locationSearch}
                  value={businessLocation ? businessLocation : null}
                  disable={route?.pathname?.includes('view')}
                  label={'Select Location Title'}
                  infinite={'location'}
                  dataLength={locations?.length}
                  hasMore={locationNextPage}
                  next={callLocation}
                  clearSearch={clearLocationSearch}
                />
              </section>
              <section>
                <Field
                  id='address'
                  name='clinicAddress'
                  label='Clinic Address*'
                  placeholder='Clinic Address'
                  showIcon={false}
                  style={{ width: '100%', marginLeft: 0 }}
                  value={clinicAddress}
                  onChange={handleClinicAddressChange}
                  disable={route?.pathname?.includes('view')}
                />
              </section>

              {route?.pathname?.split('/')[2] === 'view' ? (
                <></>
              ) : (
                <section style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Buttons
                    text='SAVE DETAILS'
                    width='160px'
                    disable={
                      clinicAddress.trim() && longitude && latitude && businessLocation
                        ? false
                        : true
                    }
                    onClick={saveLocation}
                  />
                </section>
              )}
            </section>
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default Location;
