/*
 * File: ListRedeemHistoryRewards.tsx
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:01:48 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 8th November 2022 1:07:48 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { useState, FC } from 'react';
import './ListRedeemHistoryRewards.css';
import { zeplinColor } from '../../../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper, Chip, Button } from '@mui/material';
import { Constants } from '../../../../../utilities/constants';
import { useNavigate } from 'react-router-dom';
import getPermissionForModule from '../../../../../utilities/getPermission';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import {
  IListRedeemRewardHistoryPaginationResponse,
  IListRewardRedeemHistoryResponse,
  RewardType,
  FilterArray,
} from '../../../../../model';
import CustomToolTip from '../../../../shared/CustomToolTip';
import moment from 'moment';

interface IProps {
  redeemList: IListRedeemRewardHistoryPaginationResponse;
  onPageChangeHandler: any;
  currentPage: number;
  onFilterClear: any;
  onFilterOptionDel: any;
  selectedRedeemChipOption: FilterArray[] | null | undefined;
}
interface RedeemRewardListColumn {
  id: 'reward_name' | 'reward_type' | 'patient_name' | 'date';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const RedeemRewardListColumns: RedeemRewardListColumn[] = [
  { id: 'reward_name', label: 'Reward Name', minWidth: 300 },
  { id: 'reward_type', label: 'Reward Type', minWidth: 150 },
  { id: 'patient_name', label: 'Patient Name', minWidth: 150 },
  { id: 'date', label: 'Date', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListRedeemHistoryRewards: FC<IProps> = ({
  redeemList,
  onPageChangeHandler,
  currentPage,
  onFilterClear,
  onFilterOptionDel,
  selectedRedeemChipOption,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Rewards;
  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const [selectRedeemChips, setSelectedRedeemChips] = useState(selectedRedeemChipOption);
  const clearAllFilter = () => {
    setSelectedRedeemChips(null);
    onFilterClear({
      gift: false,
      pointCredits: false,
    });
  };

  const onFilterOptionChange = (chipToDelete: any) => {
    if (
      selectRedeemChips?.filter((chip: FilterArray) => chip.id !== chipToDelete.id).length === 0
    ) {
      clearAllFilter();
    } else {
      setSelectedRedeemChips((chips: any) =>
        chips?.filter((chip: any) => chip.id !== chipToDelete.id),
      );
      onFilterOptionDel(chipToDelete);
    }
  };
  return (
    <>
      <div style={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {!!selectRedeemChips &&
          selectRedeemChips?.map((data: FilterArray) => {
            return (
              <Chip
                style={{ textTransform: 'capitalize' }}
                key={data.id}
                label={
                  data.label === 'gift'
                    ? 'Gift'
                    : data.label === 'pointCredits'
                      ? 'Points Credit'
                      : (data.label === 'startDate' || data.label === 'endDate') &&
                      data.value !== null &&
                      data.value
                }
                onDelete={() => onFilterOptionChange(data)}
              />
            );
          })}
        {selectRedeemChips?.length && (
          <Button onClick={clearAllFilter} variant='text' style={{ color: zeplinColor.Primary }}>
            Clear all
          </Button>
        )}
      </div>
      {redeemList.total && redeemList.rewardRedeemHistory.length > 0 ? (
        <>
          <Paper className='configureRedeemContainer' sx={{ borderRadius: '16px' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {RedeemRewardListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {redeemList.total &&
                    redeemList.rewardRedeemHistory.length &&
                    redeemList.rewardRedeemHistory.map(
                      (redeemObj: IListRewardRedeemHistoryResponse) => {
                        return (
                          <TableRow
                            hover
                            classes={{ hover: classes.tableRow }}
                            key={redeemObj.id}
                            onClick={() =>
                              navigate({
                                pathname: `/rewards/redeem/detail/${redeemObj.id}`,
                              })
                            }
                          >
                            <TableCell>
                              <CustomToolTip title={redeemObj.rewardName} width={'275px'} />
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {redeemObj.type === RewardType.PointsCredit
                                  ? 'Points Credit'
                                  : 'Gift'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <CustomToolTip
                                title={
                                  redeemObj.patient?.firstName + ' ' + redeemObj.patient?.lastName
                                }
                                width={'250px'}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {moment(redeemObj.createdAt).format(
                                  Constants.MOMENT_FORMATS.DATE_FORMAT,
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              redeemList.total <= Constants.PAGINATION_OPTIONS.limit
                ? 1
                : Math.ceil(redeemList.total / Constants.PAGINATION_OPTIONS.limit)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </>
      ) : (
        redeemList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={permission.create ? '/rewards/redeem' : ''}
              text={'No rewards redeemed so far'}
              subText={'Start by redeeming one'}
              buttonText={permission.create ? 'REDEEM REWARD' : ''}
              showAddButton={permission.create ? true : false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListRedeemHistoryRewards;
