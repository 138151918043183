/*
 * File: Videos.tsx
 * Project: mint-portal
 * File Created: Thrusday, 25th May 2023 07:30:17 pm
 * Author: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 8th June 2023 7:47:08 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import './Videos.css';

import { Button, Toolbar, Typography } from '@material-ui/core';
import { IListVideosRO, IVideosDetailResponse } from '../../../../model';
import React, { useEffect, useState } from 'react';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { Constants } from '../../../../utilities/constants';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { Video } from '../../../../pages/Manage/ManageCardConstants';
import VideosList from './VideosList/VideosList';
import getPermissionForModule from '../../../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';

const Videos = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [videosList, setVideosList] = useState<IListVideosRO>({ data: [], message: '' });
  const deletedVideo = sessionStorage.getItem('deletedVideo');
  const addedVideo = sessionStorage.getItem('addedVideo');
  const [isReordering, setIsReordering] = useState(false);
  const [reorderedVideos, setReorderedVideos] = useState<IVideosDetailResponse[]>([]);

  const getVideosList = async () => {
    try {
      const response: IListVideosRO = await ManageService.getVideosList();
      setVideosList(response);
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    setShowLoader(true);
    if (addedVideo) {
      setShowSnackBar(true);
      setShowSnackbarMessage('New Video Added');
      sessionStorage.removeItem('addedVideo');
    }
    if (deletedVideo) {
      setShowSnackBar(true);
      setShowSnackbarMessage('Video Deleted');
      sessionStorage.removeItem('deletedVideo');
    }
    getVideosList();
  }, []);

  const onVideosReorder = (status: boolean, movedItems: IVideosDetailResponse[]) => {
    setIsReordering(status);
    setReorderedVideos(movedItems);
  };

  const onCancelReorder = () => {
    setShowLoader(true);
    getVideosList();
    setIsReordering(false);
  };

  const onSaveChange = async () => {
    try {
      setShowLoader(true);
      const videosIds = reorderedVideos.map((obj: IVideosDetailResponse) => obj.id);
      const res = await ManageService.updateVideosOrder({ mediaLinkIds: videosIds });
      setVideosList(res);
      setShowSnackBar(true);
      setShowSnackbarMessage('Order of Videos Updated');
      setIsReordering(false);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackBar(true);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {(isForbiddenAccess || !Video.roleAccess.includes(roleId)) && !showLoader && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {!isForbiddenAccess && Video.roleAccess.includes(roleId) && !showLoader && (
        <div className='videosContainer'>
          <div className='videosHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='videosTitle' color='inherit'>
                Videos
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Videos'} />
              </Typography>
              {Video.roleAccess.includes(roleId) && (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                  {!isReordering ? (
                    <Buttons
                      text='ADD NEW VIDEO'
                      width='15em'
                      disable={videosList?.data?.length >= Constants.VIDEOS_LIMIT ? true : false}
                      onClick={() => navigate('/manage/videos/add')}
                    />
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        style={{
                          width: '8em',
                          height: '34px',
                          backgroundColor: `${zeplinColor.Background97}`,
                          border: `1px solid ${zeplinColor.Background70}`,
                          color: `${zeplinColor.Background70}`,
                        }}
                        onClick={() => onCancelReorder()}
                      >
                        Cancel
                      </Button>
                      <Buttons
                        text='Save Changes'
                        width='11em'
                        disable={false}
                        onClick={() => onSaveChange()}
                      />
                    </>
                  )}
                </div>
              )}
            </Toolbar>
            <div style={{ margin: '33px 0px 0px 20px' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.VIDEOS_INFO_TEXT}
                show={true}
                width={'76.5vw'}
              />
            </div>
          </div>
          {videosList?.data.length === 0 && !showLoader ? (
            <div style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/manage/videos/add'}
                text={'No videos to show'}
                subText={'Get started by clicking on Add New Video'}
                buttonText={'ADD NEW VIDEO'}
                showAddButton={true}
                image={true}
              />
            </div>
          ) : (
            !showLoader && (
              <VideosList videoData={videosList?.data} onVideosReordering={onVideosReorder} />
            )
          )}
        </div>
      )}
      {showSnackBar && (
        <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
      )}
    </>
  );
};

export default Videos;
