/*
 * File: AddInsurance.tsx
 * Project: mint-portal
 * File Created: Monday, 22nd August 2022 5:49:06 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 31st March 2023 9:23:30 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './AddInsurance.css';

import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
} from '@mui/material';
import {
  IAddNewInsurancePayload,
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
} from '../../../../model';
import React, { FC, useEffect, useState } from 'react';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { ClinicService } from '../../../../service/clinic.service';
import CustomTextField from '../../../shared/CustomTextField';
import { InsuranceService } from '../../../../service/insurance.service';
import Loader from '../../../shared/Loader/Loader';
import SideDrawer from '../../../shared/SideDrawer/SideDrawer';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';

const AddInsurance: FC = () => {
  const navigate = useNavigate();
  const [insuranceName, setInsuranceName] = useState('');
  const [selectClinicOption, setSelectClinicOption] = React.useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [disableAdd, setAddButtonActive] = useState(true);
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [searchCustomClinic, setSearchCustomClinic] = useState('');
  const [selectCustomClinic, setSelectCustomClinic] = useState([]);
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [chipData, setChipData] = useState();

  const [moreClinics, setMoreClinics] = useState<any>(clinicList.clinics);
  const [counter, setCounter] = useState(0);
  const prevLink = [{ path: '/insurances', label: 'Insurances' }];
  // For enabling/disabling add button
  useEffect(() => {
    if (
      insuranceName.trim() &&
      ((selectClinicOption === 'custom' && selectCustomClinic.length >= 1) ||
        selectClinicOption === 'all')
    ) {
      setAddButtonActive(false);
    } else {
      setAddButtonActive(true);
    }
  }, [insuranceName.trim(), selectClinicOption, selectCustomClinic]);

  // For virtual list of clinics
  useEffect(() => {
    if (moreClinics.length > 0 && !searchCustomClinic) {
      getClinic();
    }
  }, [moreClinics.length]);

  // Inital load
  useEffect(() => {
    getClinic();
  }, []);

  // for search clinics
  useEffect(() => {
    getSearchedClinics();
    setCounter(0);
    if (searchCustomClinic.trim().length < 3 && moreClinics.length !== clinicList.total) {
      getClinic();
    }
  }, [searchCustomClinic.trim()]);

  const handleSelectClinicOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectClinicOption((event.target as HTMLInputElement).value);
  };

  const handleNameChange = (e: any) => {
    setInsuranceName(e.target.value);
  };

  // to search and select clinics for side drawer
  const onSearch = (inputValue: string) => {
    setSearchCustomClinic(inputValue);
  };

  const onSelectClinics = (inputValue: any) => {
    setSelectCustomClinic(inputValue);
  };

  const getClinic = async () => {
    if (clinicList.total > 0 && clinicList?.total !== moreClinics?.length) {
      setCounter((prev) => prev + 13);
    } else if (clinicList.total > 0 && clinicList?.total === moreClinics?.length) {
      return;
    }
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(clinicList.total > 0 &&
          clinicList?.total !== moreClinics?.length && {
          skip: counter,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      if (moreClinics.length === 0) {
        setMoreClinics(() => listClinic.data?.clinics);
      } else {
        const len = moreClinics.filter((o1: any) => {
          return listClinic.data.clinics.some((o2: any) => {
            return o1.id === o2.id; // return the ones with equal id
          });
        }).length;
        if (len === 0) {
          const newArray = moreClinics.map((a: any) => ({ ...a }));
          setMoreClinics(() => newArray.concat(listClinic.data?.clinics));
        } else {
          setMoreClinics(() => listClinic.data?.clinics);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getSearchedClinics = async () => {
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(searchCustomClinic &&
          searchCustomClinic.length >= 3 && {
          searchString: searchCustomClinic,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      setMoreClinics(() => listClinic.data?.clinics);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const addNewInsurance = async () => {
    setShowLoader(true);
    const insurancePayload: IAddNewInsurancePayload = {
      isAllClinicAccess: selectClinicOption === 'all' ? true : false,
      insuranceName: insuranceName,
      clinicIds: selectClinicOption !== 'all' ? selectCustomClinic : [],
    };
    try {
      const createInsurance = await InsuranceService.addInsurance(insurancePayload);
      setShowLoader(false);
      if (createInsurance?.data) {
        sessionStorage.setItem('addedInsurance', createInsurance.data.insuranceName);
      }
      navigate('/insurances');
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      setShowSnackbar(true);
    }
  };

  const handleDrawerOpen = () => {
    setSearchCustomClinic('');
    setSideDrawerOpen(true);
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='insuranceAddContainer'>
        <div className='insuranceAddHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              Add New Insurance
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Add New Insurance'} />
            </Typography>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                variant='outlined'
                style={{
                  width: '8em',
                  height: '34px',
                  backgroundColor: `${zeplinColor.Background97}`,
                  border: `1px solid ${zeplinColor.Background70}`,
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={() => navigate('/insurances')}
              >
                Cancel
              </Button>
              <Buttons
                text='Add New Insurance'
                width='15em'
                disable={disableAdd}
                onClick={() => addNewInsurance()}
              />
            </div>
          </Toolbar>
        </div>
        <Paper className='insuranceAddBox' sx={{ borderRadius: '16px' }}>
          <div className='insuranceAdd'>
            <div className='insuranceNameSection'>
              <CustomTextField
                name='insuranceName'
                label='Insurance Name*'
                showIcon={false}
                value={insuranceName}
                onChange={handleNameChange}
              />
            </div>
            <div className='clinicSection'>
              <Typography variant='subtitle1'>Select Clinics*</Typography>
              <FormControl style={{ marginTop: '12px', position: 'inherit' }}>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={selectClinicOption}
                  onChange={handleSelectClinicOption}
                >
                  <FormControlLabel
                    value='all'
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Select All</Typography>}
                  />
                  {selectClinicOption && selectClinicOption === 'all' && (
                    <Typography style={{ marginLeft: '32px' }} color='primary' variant='caption'>
                      {clinicList && clinicList.total} Clinics Selected
                    </Typography>
                  )}
                  <FormControlLabel
                    value='custom'
                    onClick={handleDrawerOpen}
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Select Custom</Typography>}
                  />
                  {selectClinicOption && selectClinicOption !== 'all' && (
                    <SideDrawer
                      setSideDrawerOpen={setSideDrawerOpen}
                      sideDrawerOpen={sideDrawerOpen}
                      clinicListData={clinicList}
                      onSearch={onSearch}
                      selectCustomClinic={selectCustomClinic}
                      onSelectClinics={onSelectClinics}
                      disableSave={false}
                      setChipData={setChipData}
                      moreClinics={moreClinics}
                      getClinics={getClinic}
                      getSearchedClinics={getSearchedClinics}
                      searchLength={searchCustomClinic.length}
                    />
                  )}
                  {chipData && selectClinicOption && selectClinicOption !== 'all' ? (
                    <Typography style={{ marginLeft: '32px' }} color='primary' variant='caption'>
                      {selectCustomClinic.length<10?'0':''}{chipData} Clinics Selected
                    </Typography>
                  ) : null}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Paper>
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
    </>
  );
};

export default AddInsurance;
