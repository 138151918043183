/*
 * File: ListDsoBlockout.tsx
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 9:22:03 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:30 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useState, useEffect } from 'react';
import './ListDsoBlockout.css';
import {
  IBlockoutListResponse,
  IListDSOBlockoutsQueryParam,
  IOngoingBlockoutDetails,
} from '../../../../../model';
import { ManageService } from '../../../../../service/manage.service';
import { Constants } from '../../../../../utilities/constants';
import Loader from '../../../../shared/Loader/Loader';
import Buttons from '../../../../shared/Buttons';
import getPermissionForModule from '../../../../../utilities/getPermission';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { Pagination, Paper } from '@mui/material';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { zeplinColor } from '../../../../../theme';
import { useNavigate } from 'react-router-dom';
import CustomToolTip from '../../../../shared/CustomToolTip';
interface DSOBlockoutListColumn {
  id: 'title' | 'start' | 'end';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const DSOBlockoutListColumns: DSOBlockoutListColumn[] = [
  { id: 'title', label: 'Title', minWidth: 300 },
  { id: 'start', label: 'Start', minWidth: 150 },
  { id: 'end', label: 'End', minWidth: 150 },
];
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListDsoBlockout = () => {
  const deletedDsoBlockout = sessionStorage.getItem('deletedDsoBlockout');
  const addedDsoBlockout = sessionStorage.getItem('addedDsoBlockout');
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Insurances; // TODO
  const [showLoader, setShowLoader] = useState(true);
  const [dsoBlockoutList, setDsoBlockoutList] = useState<IBlockoutListResponse>({
    blockouts: [],
    total: 0,
  });
  const [dataPage, setDataPage] = useState(1);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    if (deletedDsoBlockout) {
      setShowSnackbar(true);
      setSnackbarMessage(`${deletedDsoBlockout} is deleted`);
      sessionStorage.removeItem('deletedDsoBlockout');
    }
    if (addedDsoBlockout) {
      setShowSnackbar(true);
      setSnackbarMessage(`${addedDsoBlockout} is added`);
      sessionStorage.removeItem('addedDsoBlockout');
    }
    setShowLoader(true);
    listDsoBlockout();
  }, [dataPage]);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
  };

  const listDsoBlockout = async () => {
    const query: IListDSOBlockoutsQueryParam = {
      limit: Constants.PAGINATION_OPTIONS.limit,
      skip: (dataPage - 1) * Constants.PAGINATION_OPTIONS.limit,
      currentDateTime: moment().format(
        `${Constants.MOMENT_FORMATS.YYYY_MM_DD} ${Constants.MOMENT_FORMATS.HH_MM_SS}`,
      ),
    };
    try {
      const list = await ManageService.getDsoBlockouts(query);
      setDsoBlockoutList(list?.data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowSnackbar(true);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div>
          <div className='dsoListHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='dsoListTitle' color='inherit'>
                DSO Blockouts
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'DSO Blockouts'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                {permission.create && (
                  <Buttons
                    text='Add Blockout'
                    width='12em'
                    disable={false}
                    onClick={() => navigate('/manage/dso-blockouts/add')}
                  />
                )}
              </div>
            </Toolbar>
          </div>
          {permission.read && dsoBlockoutList.total === 0 && !showLoader ? (
            <div style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/manage/dso-blockouts/add'}
                text={'No DSO Blockouts to show'}
                subText={permission.create ? 'Get started by clicking on Add Blockout.' : ''}
                buttonText={permission.create ? 'ADD BLOCKOUT' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </div>
          ) : (
            permission.read &&
            !showLoader &&
            dsoBlockoutList && (
              <>
                <Paper className='dsoListTable' sx={{ borderRadius: '16px' }}>
                  <TableContainer>
                    <Table aria-label='caption table'>
                      <TableHead>
                        <TableRow>
                          {DSOBlockoutListColumns.map((column) => (
                            <TableCell key={column.id} align={column.align}>
                              <Typography
                                style={{ color: zeplinColor.Background50 }}
                                variant='subtitle1'
                              >
                                {column.label}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {dsoBlockoutList.total &&
                          dsoBlockoutList.blockouts.length &&
                          dsoBlockoutList.blockouts.map((blockoutObj: IOngoingBlockoutDetails) => {
                            return (
                              <TableRow
                                hover
                                classes={{ hover: classes.tableRow }}
                                key={blockoutObj.id}
                                onClick={() =>
                                  navigate({
                                    pathname: `/manage/dso-blockouts/detail/${blockoutObj.id}`,
                                  })
                                }
                              >
                                <TableCell>
                                  <CustomToolTip title={blockoutObj.title} width={'275px'} />
                                </TableCell>

                                <TableCell>
                                  <Typography>
                                    {!blockoutObj.allDay
                                      ? moment(blockoutObj.startDate).format(
                                        Constants.MOMENT_FORMATS.DATE,
                                      ) +
                                        ' ' +
                                        moment(blockoutObj.startTime, 'hh:mm:ss').format(
                                          Constants.MOMENT_FORMATS.TIME,
                                        )
                                      : moment(blockoutObj.startDate).format(
                                        Constants.MOMENT_FORMATS.DATE,
                                      ) + ' All Day'}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {!blockoutObj.allDay
                                      ? moment(blockoutObj.endDate).format(
                                        Constants.MOMENT_FORMATS.DATE,
                                      ) +
                                        ' ' +
                                        moment(blockoutObj.endTime, 'hh:mm:ss').format(
                                          Constants.MOMENT_FORMATS.TIME,
                                        )
                                      : '-'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <Pagination
                  count={
                    dsoBlockoutList.total <= Constants.PAGINATION_OPTIONS.limit
                      ? 1
                      : Math.ceil(dsoBlockoutList.total / Constants.PAGINATION_OPTIONS.limit)
                  }
                  onChange={onDataPageChange}
                  page={dataPage}
                  variant='outlined'
                  shape='rounded'
                  className='pagination'
                  classes={{ outlined: classes.ul }}
                />
              </>
            )
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ListDsoBlockout;
