/*
 * File: getPermission.ts
 * Project: mint-portal
 * File Created: Tuesday, 23rd August 2022 10:44:15 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 2nd November 2022 9:38:11 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { IRoles, IUserProfileResponse } from '../model';

import { Constants } from './constants';

const getPermissionForModule = () => {
  const loggedInUserData = sessionStorage.getItem('profileData');
  const loggedInUserRoles: IUserProfileResponse = loggedInUserData
    ? JSON.parse(loggedInUserData)
    : [];
  const role: IRoles = loggedInUserRoles?.roles?.[0];
  return { permissions: Constants.PERMISSIONS[role?.id], roleId: role?.id };
};

export default getPermissionForModule;
