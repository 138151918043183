/*
 * File: FilterStaff.tsx
 * Project: mint-portal
 * File Created: Monday, 26th September 2022 8:54:00 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 27th September 2022 12:07:35 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { zeplinColor } from '../../../../theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import { IClinics } from '../../../../model';
type UserRole = {
  id: number;
  name: string;
};

interface FilterProps {
  open: boolean;
  onClick: () => void;
  onFilterClick: any;
  roles: UserRole[];
  searchRole: (params: any) => void;
  clinicLists: IClinics[];
  searchClinic: (params: any) => void;
  selectedRoles: UserRole[];
  selectedClinics: IClinics[];
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: '600px' },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const FilterStaff: React.FC<FilterProps> = ({
  open,
  onFilterClick,
  onClick,
  roles,
  searchRole,
  clinicLists,
  searchClinic,
  selectedRoles,
  selectedClinics,
}) => {
  const classes = useStyles();
  const [roleSelected, setRoleSelected] = React.useState<any>(selectedRoles ? selectedRoles : []);
  const [clinicSelected, setClinicSelected] = React.useState<any>(
    selectedClinics ? selectedClinics : [],
  );

  const handleRoleSelection = (value: UserRole) => {
    setRoleSelected(value);
  };

  const handleClinicSelection = (value: IClinics) => {
    setClinicSelected(value);
  };

  const handleFilter = () => {
    onFilterClick(roleSelected, clinicSelected);
  };
  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'}>
            Filter Options
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.displayColumn}
            style={{ gap: '24px' }}
          >
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Clinics
                </Typography>
                <Typography component={'span'} variant='body2'>
                  Filter results by clinics you would like to view
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <SearchDropdown
                  searchList={clinicLists}
                  label={'Search Clinics'}
                  onSelectHandler={handleClinicSelection}
                  onSearchHandler={searchClinic}
                  value={clinicSelected}
                />
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Roles
                </Typography>
                <Typography component={'span'} variant='body2'>
                  Choose a role from the list to view the results
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <SearchDropdown
                  searchList={roles}
                  label={'Search Roles'}
                  onSelectHandler={handleRoleSelection}
                  onSearchHandler={searchRole}
                  value={roleSelected}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: `${zeplinColor.Primary}` }} onClick={onClick}>
            Cancel
          </Button>
          <Button onClick={handleFilter} style={{ color: `${zeplinColor.Primary}` }}>
            <Typography>Filter Results</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterStaff;
