/*
 * File: patient.service.tsx
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 9:09:22 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 22nd August 2023 1:44:52 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import axios from '../core/axios';
import {
  IPatientListQueryParams,
  IListClinicODPatientRO,
  IPatientListRO,
  IPatientAppointmentListRO,
  IPatientDetails,
  IListQueryParams,
  IFamilyMembersListRO,
  IPatientInsuranceUpdatePayload,
  IPatientInsuranceRO,
  IFamilyMemberDetailsRO,
  IPatientODUpdatePayload,
  IPatientODUpdateRO,
  IRemoveODRO,
  IEmptyRO,
  IAvailablePointRewardRO,
  IRewardRedeemHistoryListRO,
  IRewardRedeemHistoryQuery,
  IViewPatientInsuranceRO,
  IPatientDetailUpdate,
} from '../model';
import { ApiConstants } from './api-constants';

export class PatientService {
  static getPatientList = async (
    patientQueryParams: IPatientListQueryParams,
  ): Promise<IPatientListRO> => {
    return await (
      await axios.get(
        ApiConstants.PATIENT_API.LIST_PATIENTS.URL() +
          ApiConstants.PATIENT_API.LIST_PATIENTS.QUERY_PARAMS(patientQueryParams),
      )
    ).data;
  };
  static getPatientODConnectionClinicList = async (
    patientId: string,
    queryParams?: IListQueryParams,
  ): Promise<IListClinicODPatientRO> => {
    return await (
      await axios.get(
        ApiConstants.PATIENT_API.LIST_ODCONNECTION_CLINIC.URL(patientId) +
          ApiConstants.PATIENT_API.LIST_ODCONNECTION_CLINIC.QUERY_PARAMS(queryParams),
      )
    ).data;
  };
  static getPatient = async (id: string): Promise<IPatientDetails> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.PATIENT_DETAILS.URL(id))
    ).data;
  };

  static deletePatient = async (id: string): Promise<IEmptyRO> => {
    return await axios.delete(ApiConstants.PATIENT_API.DELETE_PATIENT.URL(id));
  };

  static getPatientAppointmentList = async (
    patientId: string,
    queryParams?: IListQueryParams,
  ): Promise<IPatientAppointmentListRO> => {
    return await (
      await axios.get(
        ApiConstants.PATIENT_API.LIST_PATIENT_APPOINTMENT.URL(patientId) +
          ApiConstants.PATIENT_API.LIST_PATIENT_APPOINTMENT.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getFamilyMembersList = async (
    patientId: string,
    queryParams?: IListQueryParams,
  ): Promise<IFamilyMembersListRO> => {
    return await (
      await axios.get(
        ApiConstants.PATIENT_API.LIST_FAMILY_MEMBERS.URL(patientId) +
          ApiConstants.PATIENT_API.LIST_FAMILY_MEMBERS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };
  static getFamilyMemberDetails = async (
    patientId: string,
    memberId: string,
  ): Promise<IFamilyMemberDetailsRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.FAMILY_DETAILS.URL(patientId, memberId))
    ).data;
  };
  static updatePrimaryInsurance = async (
    patientId: string,
    id: IPatientInsuranceUpdatePayload,
  ): Promise<IPatientInsuranceRO> => {
    return await (
      await axios.put(ApiConstants.PATIENT_API.UPDATE_PRIMARY_PATIENT_INSURANCE.URL(patientId), id)
    ).data;
  };

  static updateFamilyMemberInsurance = async (
    patientId: string,
    id: IPatientInsuranceUpdatePayload,
  ): Promise<IPatientInsuranceRO> => {
    return await (
      await axios.put(ApiConstants.PATIENT_API.UPDATE_FAMILY_MEMBER_INSURANCE.URL(patientId), id)
    ).data;
  };

  static updateOD = async (
    patientId: string,
    payload: IPatientODUpdatePayload,
  ): Promise<IPatientODUpdateRO> => {
    return await (
      await axios.put(ApiConstants.PATIENT_API.UPDATE_OD.URL(patientId), payload)
    ).data;
  };

  static removeOD = async (patientId: string, clinicId: string): Promise<IRemoveODRO> => {
    return await (
      await axios.delete(ApiConstants.PATIENT_API.REMOVE_OD.URL(patientId, clinicId))
    ).data;
  };

  static getAvailablePointsByPatientId = async (
    patientId: string,
  ): Promise<IAvailablePointRewardRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.GET_AVAILABLE_POINTS_BY_PATIENTID.URL(patientId))
    ).data;
  };

  static getPointsHistoryList = async (
    patientId: string,
    queryParams: IRewardRedeemHistoryQuery,
  ): Promise<IRewardRedeemHistoryListRO> => {
    return await (
      await axios.get(
        ApiConstants.PATIENT_API.LIST_POINTS_HISTORY.URL(patientId) +
          ApiConstants.PATIENT_API.LIST_POINTS_HISTORY.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getInsuranceDetail = async (patientId: string): Promise<IViewPatientInsuranceRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.PATIENT_INSURANCE_DETAILS.URL(patientId))
    ).data;
  };

  static getFamilyInsuranceDetail = async (familyId: string): Promise<IViewPatientInsuranceRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.FAMILY_INSURANCE_DETAILS.URL(familyId))
    ).data;
  };

  static deletePatientInsuranceDetail = async (patientId: string): Promise<IEmptyRO> => {
    return await axios.delete(
      ApiConstants.PATIENT_API.DELETE_PATIENT_INSURANCE_DETAILS.URL(patientId),
    );
  };

  static deleteFamilyInsuranceDetail = async (familyId: string): Promise<IEmptyRO> => {
    return await axios.delete(
      ApiConstants.PATIENT_API.DELETE_FAMILY_INSURANCE_DETAILS.URL(familyId),
    );
  };

  static updatePatientProfile = async (
    patientId: string,
    payload: IPatientDetailUpdate,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.PATIENT_API.UPDATE_PATIENT_PROFILE.URL(patientId), payload)
    ).data;
  };
  // static updateFamilyProfile = async (
  //   memberId: string,
  //   payload: IFamilyMemberPayload,
  // ): Promise<IEmptyRO> => {
  //   return await (
  //     await axios.put(ApiConstants.PATIENT_API.UPDATE_FAMILY_PROFILE.URL(memberId), payload)
  //   ).data;
  // };
}
