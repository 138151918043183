/*
 * File: LoginScreen.tsx
 * Project: mint-portal
 * File Created: Thursday, 16th June 2022 5:51:06 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 8th February 2023 8:59:40 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './LoginStyle.css';

import React, { FC, useEffect, useState } from 'react';

import Buttons from '../../components/shared/Buttons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomTextField from '../../components/shared/CustomTextField';
import LandingPage from '../../components/shared/Landing-page/LandingPage';
import Loader from '../../components/shared/Loader/Loader';
import Password from '../../components/shared/PasswordField';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import { Typography } from '@material-ui/core';
import { UserService } from '../../service/user.service';
import packageJson from '../../../package.json';
import { signIn } from '../../service/amplify.service';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../utilities/validate';

const LoginScreen: FC = () => {
  const navigate = useNavigate();
  const [isEmailValid, setEmailValid] = useState(true);
  const [isChangeIcon, setChangeIcon] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackMessage, setSnackMessage] = useState('');
  const [inputValue, setInputValue] = useState({ email: '', password: '' });
  const { email, password } = inputValue;
  const [showPasswordCreatedMessage, setPasswordCreatedMessage] = useState({
    isShowPasswordCreatedMessage: false,
    passwordCreatedMessage: '',
  });
  const { isShowPasswordCreatedMessage, passwordCreatedMessage } = showPasswordCreatedMessage;
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const email = sessionStorage.getItem('email');
    const newPassword = sessionStorage.getItem('new_password_created');
    if (email && newPassword) {
      setShowLoader(false);
      setShowSnackBar(true);
      setPasswordCreatedMessage({
        isShowPasswordCreatedMessage: true,
        passwordCreatedMessage: 'Password created successfully, Please login again',
      });
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('new_password_created');
    } else if (sessionStorage.getItem('reset-password')) {
      setShowLoader(false);
      setShowSnackBar(true);
      setPasswordCreatedMessage({
        isShowPasswordCreatedMessage: true,
        passwordCreatedMessage:
          'Your new password has been successfully created, please use it to login.',
      });
      sessionStorage.removeItem('reset-password');
    }
  }, []);

  const getSelfProfile = async () => {
    try {
      return await UserService.getSelfUserProfile();
    } catch (err) {
      setShowSnackBar(true);
      setSnackMessage(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  /**
   * This is called on click of login/sign-in button
   * It's repsonsible for dispatching action and based on response,
   * it will redirect to set new password page, dashboard page, or
   * show error if any.
   */
  const handleLogin = async () => {
    setShowLoader(true);
    try {
      const loggedInData = await signIn({ email: email.trim(), password: password.trim() });
      setShowSnackBar(false);
      if (loggedInData?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('password', password);
        setShowLoader(false);
        navigate('/set-password');
      } else {
        sessionStorage.setItem('token', loggedInData?.signInUserSession?.accessToken?.jwtToken);
        const profileData = await getSelfProfile();
        if (profileData && profileData.data) {
          sessionStorage.setItem('profileData', JSON.stringify(profileData.data));
          setShowLoader(false);
          navigate('/dashboard', { replace: true });
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setShowSnackBar(true);
        setSnackMessage(error.message);
        setShowLoader(false);
      }
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'email') {
      emailCheck(value);
    }
  };

  const emailCheck = (value: string) => {
    const isValid = validateEmail(value);
    setEmailValid(isValid);
    setChangeIcon(isValid);
  };

  const handleClearEmail = () => {
    setInputValue({
      ...inputValue,
      email: '',
    });
  };

  return (
    <>
      {showLoader && <Loader />}
      <LandingPage showFooter />
      <form className='login' data-testid='login-form'>
        <Card className='containers'>
          <CardContent className='cardtext'>
            <Typography color='textPrimary' variant='h3' className='welcome'>
              Welcome
            </Typography>
            <Typography color='textSecondary' variant='h5' className='welcometext'>
              Please enter your email and password to continue
            </Typography>
          </CardContent>

          <CustomTextField
            name='email'
            label='Email'
            width='558px'
            showIcon={true}
            // helperText={!isEmailValid ? Constants.INLINE_ERR_MESSAGE.INVALID_EMAIL : ''}
            error={email ? !isEmailValid : false}
            value={email}
            inputValue={inputValue}
            iconChanged={email ? !isChangeIcon : false}
            handleClearEmail={handleClearEmail}
            onChange={handleChange}
            onEnter={handleLogin}
          />
          <Password
            name='password'
            width='558px'
            error={false}
            label='Password'
            iconChanged=''
            value={password}
            onChange={handleChange}
            onEnter={handleLogin}
            inputValue={inputValue}
          />
          <Typography
            onClick={() => navigate('/forgot-password')}
            variant='subtitle2'
            color='textSecondary'
            className='forgotpassword'
          >
            FORGOT PASSWORD?
          </Typography>
          <Buttons
            classname='alignButtons'
            text='Login'
            disable={email && password && isEmailValid ? false : true}
            onClick={handleLogin}
          />
          <Typography variant='caption' className='version'>
            Build version: {packageJson.version}
          </Typography>
        </Card>
        {showSnackBar || isShowPasswordCreatedMessage ? (
          <SnackBar
            message={showSnackMessage || passwordCreatedMessage}
            show={showSnackBar}
            setShow={setShowSnackBar}
          />
        ) : (
          <></>
        )}
      </form>
    </>
  );
};
export default LoginScreen;
