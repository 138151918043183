/*
 * File: staff.service.tsx
 * Project: mint-portal
 * File Created: Monday, 27th June 2022 9:09:13 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 28th October 2022 1:12:42 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import axios from '../core/axios';
import { ApiConstants } from './api-constants';
import {
  IStaffListResponse,
  IAddStaffPayload,
  IStaffDetailRO,
  IStaffSwitchStatusRO,
  IStaffResendInviteRO,
  IUpdateStaffPayload,
  IEmptyRO,
  IStaffListQueryParams,
} from '../model';

export class StaffService {
  static getStaffList = async (queryParams: IStaffListQueryParams): Promise<IStaffListResponse> => {
    return await (
      await axios.get(
        ApiConstants.STAFF_API.LIST_STAFF.URL() +
          ApiConstants.STAFF_API.LIST_STAFF.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static addStaff = async (addStaffPayload: IAddStaffPayload): Promise<IStaffDetailRO> => {
    return await (
      await axios.post(ApiConstants.STAFF_API.ADD_STAFF.URL(), addStaffPayload)
    ).data;
  };

  static getStaffById = async (staffId: string): Promise<IStaffDetailRO> => {
    return await (
      await axios.get(ApiConstants.STAFF_API.DETAIL_STAFF_ID.URL(staffId))
    ).data;
  };

  static switchStaffStatus = async (
    staffId: string,
    status: boolean,
  ): Promise<IStaffSwitchStatusRO> => {
    return await (
      await axios.put(ApiConstants.STAFF_API.SWITCH_STAFF_STATUS.URL(staffId, status))
    ).data;
  };

  static resendStaffInvite = async (staffId: string): Promise<IStaffResendInviteRO> => {
    return await (
      await axios.post(ApiConstants.STAFF_API.RESEND_INVITE.URL(staffId))
    ).data;
  };

  static deleteStaff = async (staffId: string): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.STAFF_API.DELETE_STAFF.URL(staffId))
    ).data;
  };

  static updateStaffById = async (
    staffId: string,
    updateStaffPayload: IUpdateStaffPayload,
  ): Promise<IStaffDetailRO> => {
    return await (
      await axios.put(ApiConstants.STAFF_API.UPDATE_STAFF.URL(staffId), updateStaffPayload)
    ).data;
  };
}
