/*
 * File: amplify.service.tsx
 * Project: mint-portal
 * File Created: Wednesday, 8th June 2022 8:39:25 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 3rd November 2022 1:05:44 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { ILoginPayload, ISetNewPasswordPayload, IResetPasswordPayload } from '../model';
import { Auth } from 'aws-amplify';

const CLIENT_META_DATA = { firstTimeLogin: 'true' };

/**
 * Configure amplify based on set environment
 */
const configureAmplify = () => {
  const AuthConfig = {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    storage: sessionStorage,
  };
  localStorage.clear();
  Auth.configure(AuthConfig);
};

/**
 * SignIn using amplify
 * @param {ILoginPayload} { email, password }
 * @return {*}
 */
export const signIn = async ({ email, password }: ILoginPayload): Promise<any> => {
  configureAmplify();
  return await Auth.signIn(email, password);
};

/**
 * Set new password using amplify completeNewPassword function
 * @param {ISetNewPasswordPayload} { newPassword }
 */
export const setUserPassword = async ({
  email,
  oldPassword,
  newPassword,
}: ISetNewPasswordPayload): Promise<any> => {
  configureAmplify();
  return await Auth.signIn(email, oldPassword).then(async (data: any) => {
    await Auth.completeNewPassword(data, newPassword, null, CLIENT_META_DATA);
  });
};

/**
 * Get current user session from cognito
 * Get JWT token, refresh token, id token
 * @param {}
 */
export const getUserCurrentSession = async () => {
  configureAmplify();
  const currentUserSession = await Auth.currentSession();
  return {
    jwtToken: currentUserSession.getAccessToken(),
    refreshToken: currentUserSession.getRefreshToken(),
  };
};

/**
 * Signout the loggedin user
 * @param {}
 */
export const logoutUser = async () => {
  configureAmplify();
  return await Auth.signOut({ global: true });
};

/**
 * Forgot Password
 * Sends mail with the reset password code
 */
export const forgotPassword = async (email: string) => {
  configureAmplify();
  return await Auth.forgotPassword(email);
};

/**
 * Resets the Password
 * @param {IResetPasswordPayload} { email, code, password }
 */
export const resetPasswordSubmit = async ({ email, code, password }: IResetPasswordPayload) => {
  configureAmplify();
  await Auth.forgotPasswordSubmit(email, code, password);
  await Auth.signIn(email, password);
  return await Auth.signOut({ global: true });
};

export const updatePassword = async (oldPassword: string, newPassword: string) => {
  configureAmplify();
  const user = await Auth.currentAuthenticatedUser();
  if (user) {
    return Auth.changePassword(user, oldPassword, newPassword);
  }
};
