/*
 * File: ListNotifications.tsx
 * Project: mint-portal
 * File Created: Tuesday, 14th February 2023 1:47:34 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 21st February 2023 2:17:26 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import React, { FC } from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Paper } from '@mui/material';
import { zeplinColor } from '../../../../../theme';
import {
  IListNotificationCadenceRO,
  INotificationCadenceDetailResponse,
} from '../../../../../model';
import './ListNotifications.css';
import moment from 'moment';
import { Constants } from '../../../../../utilities/constants';
import DeletePopup from '../../../../shared/DeletePopup';

interface IProps {
  notificationsList: IListNotificationCadenceRO;
  handleDeleteNotification: () => Promise<void>;
  setOpenDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
  openDeletePopup: boolean;
  handleDeletePopupOpen: (obj: INotificationCadenceDetailResponse) => void;
  scheduleDelete: INotificationCadenceDetailResponse;
}
interface NotificationListColumn {
  id: 'notification_schedule' | 'action_edit' | 'action_delete';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const notificationListColumns: NotificationListColumn[] = [
  { id: 'notification_schedule', label: 'Notification Schedule' },
  { id: 'action_edit', label: 'Actions' },
  { id: 'action_delete', label: '' },
];

const ListNotifications: FC<IProps> = ({
  notificationsList,
  handleDeleteNotification,
  openDeletePopup,
  setOpenDeletePopup,
  handleDeletePopupOpen,
  scheduleDelete,
}) => {
  return (
    <>
      <Paper className='notificationContainer' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {notificationListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationsList?.data.length &&
                notificationsList?.data.map((obj: INotificationCadenceDetailResponse) => {
                  return (
                    <TableRow key={obj.id}>
                      <TableCell>
                        {obj.hours >= 24 ? (
                          <Typography>
                            {obj.hours / 24} day(s) before the appointment at{' '}
                            {moment(obj.scheduledTime, 'HHmmss').format(
                              Constants.MOMENT_FORMATS.TIME,
                            )}
                          </Typography>
                        ) : (
                          <Typography>{obj.hours} hour(s) before the appointment</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={`appointment-notifications/edit?id=${obj.id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant='subtitle1' className='actionText' color='primary'>
                            Edit
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography
                          onClick={() => handleDeletePopupOpen(obj)}
                          variant='subtitle1'
                          className='actionText'
                          color='error'
                        >
                          Delete
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {notificationsList && (
        <DeletePopup
          title={'Delete Schedule'}
          description={`‘${
            scheduleDelete.hours >= 24 ? scheduleDelete.hours / 24 : scheduleDelete.hours
          } ${scheduleDelete?.hours >= 24 ? 'day(s)' : 'hour(s)'} before the appointment ${
            scheduleDelete.hours >= 24
              ? `at ${moment(scheduleDelete.scheduledTime, 'HHmmss').format(
                Constants.MOMENT_FORMATS.TIME,
              )}`
              : ''
          }’`}
          subDescription={''}
          open={openDeletePopup}
          buttonText={`Delete`}
          setOpen={setOpenDeletePopup}
          onDeleteClick={handleDeleteNotification}
        />
      )}
    </>
  );
};

export default ListNotifications;
