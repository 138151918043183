/*
 * File: DetailBlockout.tsx
 * Project: mint-portal
 * File Created: Thursday, 15th September 2022 2:12:44 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 27th February 2023 2:56:33 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './DetailBlockout.css';

import {
  BlockOutType,
  IAddBlockoutPayload,
  IAddBlockoutRO,
  IClinicBlockoutDetailResponse,
  IClinicOpenDentalBlockoutDetailResponse,
  IDsoBlockoutDetailRO,
  IOngoingBlockoutDetails,
  IOpenDentalDetailBlockoutRO,
} from '../../../../model';
import { Button, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AddBlockout from '../AddBlockout/AddBlockout';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { ClinicService } from '../../../../service/clinic.service';
import { Constants } from '../../../../utilities/constants';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import DeletePopup from '../../../shared/DeletePopup';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import { Paper } from '@mui/material';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../../../utilities/getPermission';
import moment from 'moment';
import { zeplinColor } from '../../../../theme';

const DetailBlockout = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions?.Booking;
  const [blockoutDetail, SetBlockoutDetail] = useState<IClinicBlockoutDetailResponse>();
  const [openDentalblockoutDetail, SetOpenDentalBlockoutDetail] =
    useState<IClinicOpenDentalBlockoutDetailResponse>();
  const [dsoBlockoutDetail, SetDsoBlockoutDetail] = useState<IOngoingBlockoutDetails>();

  const search = useLocation().search;
  const clinicId = new URLSearchParams(search).get('id');
  const blockoutId = new URLSearchParams(search).get('blockout');
  const dsoBlockoutId = new URLSearchParams(search).get('dso-blockout');
  const openDentalBlockoutId = new URLSearchParams(search).get('od-blockout');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const prevLink = [
    { path: '/clinics', label: 'Clinic' },
    { path: `/clinics/detail/${clinicId}`, label: 'View Clinic' },
    { path: `/clinics/view/booking?id=${clinicId}`, label: 'Bookings & Blockouts' },
  ];
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<IAddBlockoutPayload>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);

  const onCancelEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    if (clinicId) {
      if (blockoutId) {
        getblockoutDetailsById();
      }
      if (dsoBlockoutId) {
        getDSOBlockoutById();
      }
      if (openDentalBlockoutId) {
        getOpenDentalBlockoutById();
      }
    }
  }, [clinicId, blockoutId, dsoBlockoutId, openDentalBlockoutId]);

  const getblockoutDetailsById = async () => {
    if (clinicId && blockoutId) {
      setShowLoader(true);
      try {
        const detail: IAddBlockoutRO = await ClinicService.getBlockoutDetailByClinicId(
          clinicId,
          blockoutId,
        );
        SetBlockoutDetail(detail?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  const getOpenDentalBlockoutById = async () => {
    if (clinicId && openDentalBlockoutId) {
      setShowLoader(true);
      try {
        const detail: IOpenDentalDetailBlockoutRO =
          await ClinicService.getOpenDentalBlockoutDetailByClinicId(clinicId, openDentalBlockoutId);
        SetOpenDentalBlockoutDetail(detail?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const getDSOBlockoutById = async () => {
    if (clinicId && dsoBlockoutId) {
      setShowLoader(true);
      try {
        const detail: IDsoBlockoutDetailRO = await ManageService.getDsoBlockoutDetail(
          dsoBlockoutId,
        );
        SetDsoBlockoutDetail(detail?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const updateBlockout = async () => {
    if (clinicId && blockoutId) {
      setUpdateLoader(true);
      try {
        const detail =
          updatePayload &&
          (await ClinicService.updateClinicBlockout(clinicId, blockoutId, updatePayload));
        getblockoutDetailsById();
        setUpdateLoader(false);
        if (detail?.data) {
          setShowSnackbar(true);
          setSnackbarMessage(`${detail.data.title} changes saved`);
          setEdit(false);
        }
      } catch (err) {
        setUpdateLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const handleDeleteBlockout = async () => {
    if (blockoutId && clinicId && blockoutDetail) {
      setShowLoader(true);
      try {
        await ClinicService.deleteBlockout(clinicId, blockoutId);
        sessionStorage.setItem('deletedBlockout', blockoutDetail?.title);
        setShowLoader(false);
        navigate(prevLink[2].path);
      } catch (err) {
        setShowLoader(false);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackbar(true);
      }
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='blockoutDetailContainer'>
        <div className='blockoutDetailHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              {!edit ? 'View Blockout' : 'Edit Blockout'}
              <br></br>
              <BreadCrumbs
                prevLink={prevLink}
                activeLink={!edit ? 'View Blockout' : 'Edit Blockout'}
              />
            </Typography>
            {!edit ? (
              <div style={{ display: 'flex', gap: '16px' }}>
                {permission?.delete && blockoutId && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteBox'
                    onClick={handleDeletePopupOpen}
                    disabled={false}
                  >
                    <DeleteOutlineSharpIcon
                      className='deleteIcon'
                      style={{ color: `${zeplinColor.Error}` }}
                    />
                  </Button>
                )}
                {permission?.update && blockoutId && (
                  <Buttons
                    text='Edit Details'
                    width='12em'
                    disable={editBtn}
                    onClick={() => setEdit(!edit)}
                  />
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                <Button
                  variant='outlined'
                  disabled={showLoader ? true : false}
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </Button>
                <Buttons
                  text='SAVE CHANGES'
                  width='100%'
                  disable={editBtn || showLoader ? true : false}
                  onClick={updateBlockout}
                />
              </div>
            )}
          </Toolbar>
        </div>
        {edit ? (
          <div>
            <AddBlockout
              edit
              blockoutDetail={blockoutDetail}
              setUpdatePayload={setUpdatePayload}
              updateLoader={updateLoader}
              setEditBtn={setEditBtn}
            />
          </div>
        ) : (
          <>
            {(blockoutDetail || openDentalblockoutDetail || dsoBlockoutDetail) && (
              <Paper className='blockoutDetailBox' sx={{ borderRadius: '16px' }}>
                <div
                  className='blockoutDetail'
                  style={{ display: 'flex', flexDirection: 'column', gap: 32 }}
                >
                  <div  style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography   variant='subtitle1'>Blockout Title</Typography>
                    <Typography variant='body1'>
                      {blockoutDetail?.title ||
                        openDentalblockoutDetail?.title ||
                        dsoBlockoutDetail?.title}
                    </Typography>
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography  variant='subtitle1' >Blockout For</Typography>
                    <Typography variant='body1'>
                      {(blockoutDetail?.type === BlockOutType.OPERATORY &&
                        blockoutDetail?.operatoryName) ||
                        openDentalblockoutDetail?.operatoryName ||
                        '-'}
                    </Typography>
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography   variant='subtitle1'>Duration</Typography>
                    <Typography variant='body1'>
                      {!blockoutDetail?.allDay ||
                      !openDentalblockoutDetail?.allDay ||
                      !dsoBlockoutDetail?.allDay
                        ? moment(
                          blockoutDetail?.startDate ||
                              openDentalblockoutDetail?.startDate ||
                              dsoBlockoutDetail?.startDate,
                        ).format(Constants.MOMENT_FORMATS.DATE) +
                          ' ' +
                          moment(
                            blockoutDetail?.startTime ||
                              openDentalblockoutDetail?.startTime ||
                              dsoBlockoutDetail?.startTime,
                            'hh:mm:ss',
                          ).format(Constants.MOMENT_FORMATS.TIME) +
                          ' TO ' +
                          moment(
                            blockoutDetail?.endDate ||
                              openDentalblockoutDetail?.endDate ||
                              dsoBlockoutDetail?.endDate,
                          ).format(Constants.MOMENT_FORMATS.DATE) +
                          ' ' +
                          moment(
                            blockoutDetail?.endTime ||
                              openDentalblockoutDetail?.endTime ||
                              dsoBlockoutDetail?.endTime,
                            'hh:mm:ss',
                          ).format(Constants.MOMENT_FORMATS.TIME)
                        : moment(blockoutDetail.startDate).format(Constants.MOMENT_FORMATS.DATE) +
                          ` All Day`}
                    </Typography>
                  </div>
                </div>
                {blockoutDetail && (
                  <DeletePopup
                    title={'Delete Blockout'}
                    description={`"${blockoutDetail?.title}"`}
                    subDescription={''}
                    open={openDeletePopup}
                    buttonText={`Delete`}
                    setOpen={setOpenDeletePopup}
                    onDeleteClick={handleDeleteBlockout}
                  />
                )}
              </Paper>
            )}
          </>
        )}
      </div>
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default DetailBlockout;
