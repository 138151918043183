/*
 * File: OpenDentalConfig.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:47 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 19th February 2023 11:05:48 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useState, useEffect } from 'react';
import './OpenDentalConfig.css';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { Typography, Button, Box } from '@material-ui/core';
import Field from '../../../../shared/CustomTextField';
import Buttons from '../../../../shared/Buttons';
import { ClinicService } from '../../../../../service/clinic.service';
import { IOpenDentalPayload, IViewClinicDetailsData } from '../../../../../model';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { useLocation } from 'react-router-dom';
import { Utilities } from '../../../../../utilities/utilities';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import getPermissionForModule from '../../../../../utilities/getPermission';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { zeplinColor } from '../../../../../theme';
import { Constants } from '../../../../../utilities/constants';

type dentalProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
  setUpdatedClinicDetails: React.Dispatch<React.SetStateAction<IViewClinicDetailsData | undefined>>;
};

const OpenDentalConfig = ({
  updatedClinicDetails,
  handleCreateClinicBtn,
  setUpdatedClinicDetails,
}: dentalProps) => {
  const route = useLocation();
  const [customerKey, setCustomerKey] = useState(
    updatedClinicDetails?.openDentalConfig?.customerKey || '',
  );
  const [developerKey, setDeveloperKey] = useState(
    updatedClinicDetails?.openDentalConfig?.developerKey || '',
  );
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');
  const [showRegeneratePopup, setShowRegeneratePopup] = useState<boolean>(false);
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Clinics;

  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (developerKey.trim() && customerKey.trim()) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    }
  }, [developerKey, customerKey]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (permission.readApikey) {
      getClinicApiKey();
    }
  }, [updatedClinicDetails?.id]);

  useEffect(() => {
    setCustomerKey(updatedClinicDetails?.openDentalConfig?.customerKey || '');
    setDeveloperKey(updatedClinicDetails?.openDentalConfig?.developerKey || '');
  }, [updatedClinicDetails?.id && updatedClinicDetails?.openDentalConfig?.id]);

  const handleCustomerKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerKey(event.target.value);
  };
  const handleDeveloperKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeveloperKey(event.target.value);
  };
  const handleShowApiKeyClick = () => {
    setShowApiKey(!showApiKey);
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    setShowSnackbar(true);
    setShowSnackbarMessage('Copied');
  };

  const saveClinicOpenDentalConfig = async () => {
    setShowLoader(true);
    const payload: IOpenDentalPayload = {
      customerKey: customerKey,
      developerKey: developerKey,
    };
    try {
      if (updatedClinicDetails?.id) {
        const saveResponse = await ClinicService.saveOpenDentalConfig(
          payload,
          updatedClinicDetails?.id,
        );
        if (saveResponse.data && !saveResponse.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        if (
          saveResponse?.data &&
          saveResponse?.data?.isDraft &&
          saveResponse?.data?.openDentalConfig?.id
        ) {
          /*
          It is required, because we need to show refresh button in next section, which is depended on open dental config set or not
           */
          setUpdatedClinicDetails({
            ...updatedClinicDetails,
            openDentalConfig: saveResponse?.data?.openDentalConfig,
          });
        }
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Open Dental Configuration Saved');
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
    }
  };

  const openRegeneratePopup = async () => {
    setShowRegeneratePopup(true);
  };

  const closeRegeneratePopup = async () => {
    setShowRegeneratePopup(false);
  };

  const regenerateClinicApiKey = async () => {
    setShowLoader(true);
    setShowRegeneratePopup(false);
    try {
      if (updatedClinicDetails?.id && permission.regenerateAPiKey) {
        const saveResponse = await ClinicService.regenerateClinicApiKey(updatedClinicDetails?.id);
        if (saveResponse.data && saveResponse.data.apiKey) {
          setApiKey(saveResponse.data.apiKey);
        }
        setShowSnackbar(true);
        setShowSnackbarMessage('Clinic API Key is regenerated');
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getClinicApiKey = async () => {
    if (updatedClinicDetails?.id) {
      try {
        const apiKeyResponse = await ClinicService.getClinicApiKey(updatedClinicDetails.id);
        setApiKey(apiKeyResponse?.data?.apiKey);
      } catch (err) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  return (
    <>
      {showLoader && <Loader margin={true} />}

      <div>
        {!updatedClinicDetails?.id ? (
          <section
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '712px',
            }}
          >
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.text}
              subText={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.subText}
              buttonText={''}
              showAddButton={false}
              image={false}
            />
          </section>
        ) : (
          <div className='open-dental-container'>
            <Typography variant='h6'>Open Dental Configuration</Typography>
            <Box sx={{ margin: '32px auto' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
                width={'100%'}
                show={true}
              />
            </Box>
            {permission.readApikey && (
              <div
                className='api-key-section'
                style={{
                  backgroundColor: zeplinColor.Background97,
                }}
              >
                <div className='api-key-view'>
                  <Typography variant='subtitle1'>Clinic API Key</Typography>
                  <div className='api-key'>
                    <Typography variant='body1'>
                      {showApiKey ? apiKey : new Array(apiKey.length).join('•')}
                    </Typography>
                    {showApiKey && (
                      <IconButton
                        style={{ color: zeplinColor.Primary }}
                        onClick={copyApiKey}
                        onMouseDown={handleMouseDown}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    )}
                    <IconButton
                      style={{ color: zeplinColor.Primary }}
                      onClick={handleShowApiKeyClick}
                      onMouseDown={handleMouseDown}
                    >
                      {showApiKey ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
                {permission.regenerateAPiKey && route?.pathname?.split('/')[2] !== 'view' && (
                  <div className='api-key-regenerate'>
                    <Buttons
                      text='regenerate'
                      disable={!showApiKey}
                      bgWhite={true}
                      startIcon={<RefreshIcon />}
                      disableBorder={!showApiKey} // no border when button is not active
                      onClick={openRegeneratePopup}
                    />
                  </div>
                )}
              </div>
            )}
            <section className='main-section'>
              <section>
                <Field
                  name='customerKey'
                  label='Customer Key*'
                  placeholder='Customer Key'
                  showIcon={false}
                  style={{ width: '100%', marginLeft: '0px' }}
                  value={customerKey}
                  onChange={handleCustomerKeyChange}
                  disable={route?.pathname?.split('/')[2] === 'view'}
                />
              </section>
              <section>
                <Field
                  name='developerKey'
                  label='Developer Key*'
                  placeholder='Developer Key'
                  showIcon={false}
                  style={{ width: '100%', marginLeft: '0px' }}
                  value={developerKey}
                  onChange={handleDeveloperKeyChange}
                  disable={route?.pathname?.split('/')[2] === 'view'}
                />
              </section>
              {route?.pathname?.split('/')[2] === 'view' ? (
                <></>
              ) : (
                <section style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Buttons
                    text='SAVE DETAILS'
                    width='160px'
                    disable={!(developerKey.trim() && customerKey.trim())}
                    onClick={saveClinicOpenDentalConfig}
                  />
                </section>
              )}
            </section>
          </div>
        )}
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
        {permission.regenerateAPiKey && (
          <Dialog
            open={showRegeneratePopup}
            onClose={closeRegeneratePopup}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle>
              <Typography variant='h5' component={'span'}>
                Regenerate Clinic API Key
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you regenerate the Clinic API Key? <br />
                <br />
                The existing key will become invalid and{' '}
                <text style={{ color: zeplinColor.Error, fontWeight: 'bold' }}>
                  the clinic sync to Open Dental will stop
                </text>
                . You need to restart the server agent with the new API key to start syncing again.{' '}
                <br />
                <br />
                You can’t undo this action.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={closeRegeneratePopup}
              >
                Cancel
              </Button>
              <Button
                style={{
                  color: `${zeplinColor.Primary}`,
                }}
                onClick={regenerateClinicApiKey}
                autoFocus={true}
              >
                GENERATE KEY
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default OpenDentalConfig;
