/*
 * File: ProtectedRoute.tsx
 * Project: mint-portal
 * File Created: Thursday, 23rd June 2022 2:57:17 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 27th June 2022 1:12:36 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Navigate } from 'react-router-dom';
import React, { ReactElement } from 'react';
import RoutePaths from './RoutePaths';

export const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const token = sessionStorage.getItem('token');
  let authState;
  if (token === null) {
    authState = false;
  } else {
    authState = true;
  }

  if (!authState) {
    return <Navigate to={RoutePaths.Login} />;
  } else {
    return children;
  }
};

export const ValidatePasswordRoute = ({ children }: { children: ReactElement }) => {
  const email = sessionStorage.getItem('email');
  if (email === null) {
    return <Navigate to={RoutePaths.Login} />;
  } else {
    return children;
  }
}
