/*
 * File: PasswordScreen.tsx
 * Project: mint-portal
 * File Created: Thursday, 16th June 2022 5:51:06 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 14th December 2022 1:34:43 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import './PasswordStyle.css';
import Password from '../../components/shared/PasswordField';
import Buttons from '../../components/shared/Buttons';
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import LandingPage from '../../components/shared/Landing-page/LandingPage';
import { setUserPassword } from '../../service/amplify.service';
import { useNavigate } from 'react-router-dom';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import Loader from '../../components/shared/Loader/Loader';
import { Constants } from '../../utilities/constants';

const PasswordScreen = () => {
  const navigate = useNavigate();
  const [isPasswordErrors, setPasswordErrors] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [inputValue, setInputValue] = useState({ newPassword: '', confirmPassword: '' });
  const [snackMessage, setSnackMessage] = useState('Both passwords did not match, Try again.');
  const { newPassword, confirmPassword } = inputValue;
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (
      (inputValue.newPassword && name === 'confirmPassword') ||
      (inputValue.confirmPassword && name === 'newPassword')
    ) {
      if (
        (name === 'newPassword' && inputValue.confirmPassword.includes(value)) ||
        (name === 'confirmPassword' && inputValue.newPassword.includes(value))
      ) {
        setPasswordErrors(false);
      } else {
        setPasswordErrors(true);
      }
    }
  };

  const handleSetPassword = async () => {
    setShowLoader(true);
    const isValid = await passwordValidation();
    const email = sessionStorage.getItem('email');
    const oldPassword = sessionStorage.getItem('password');
    if (isValid && email && oldPassword) {
      try {
        await setUserPassword({
          email: email.trim(),
          oldPassword: oldPassword,
          newPassword: confirmPassword,
        });
        sessionStorage.setItem('new_password_created', 'true');
        navigate('/login');
      } catch (error) {
        if (error instanceof Error) {
          setStateForInvalidPassword(error?.message);
        }
      } finally {
        setShowLoader(false);
      }
    }
  };

  const passwordValidation = async () => {
    if (newPassword !== confirmPassword) {
      setStateForInvalidPassword('Both passwords did not match, Try again.');
      return false;
    } else if (
      newPassword.length < Constants.PASSWORD_MIN_LIMIT &&
      confirmPassword.length < Constants.PASSWORD_MIN_LIMIT
    ) {
      setStateForInvalidPassword('Password should be minimum of length 8. Please try again');
      return false;
    } else {
      setShowSnackBar(false);
      setPasswordErrors(false);
      return true;
    }
  };

  const setStateForInvalidPassword = (errorMessage: string) => {
    setPasswordErrors(true);
    setShowSnackBar(true);
    setShowLoader(false);
    setSnackMessage(errorMessage);
  };

  return (
    <>
      {showLoader && <Loader />}
      <LandingPage showFooter={false} />
      <form className='set-password'>
        <Card className='container'>
          <CardContent>
            <Typography color='textPrimary' variant='h3' className='welcome'>
              Create New Password
            </Typography>
            <Typography color='textSecondary' variant='h6' className='welcometext'>
              We recommend that you create a new and strong password for security reasons. Use 08 or
              more characters with a mix of uppercase and lowercase letters, numbers and symbols.
            </Typography>
          </CardContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <Password
              name='newPassword'
              label='New Password'
              width='592px'
              error={
                (newPassword && confirmPassword && isPasswordErrors) ||
                (newPassword.length && newPassword?.length < Constants.PASSWORD_MIN_LIMIT)
                  ? true
                  : false
              }
              iconChanged={isPasswordErrors}
              value={newPassword}
              onChange={handleChange}
            />
            <Password
              name='confirmPassword'
              label='Re-enter New Password'
              width='592px'
              error={
                (newPassword && confirmPassword && isPasswordErrors) ||
                (confirmPassword.length && confirmPassword?.length < Constants.PASSWORD_MIN_LIMIT)
                  ? true
                  : false
              }
              iconChanged={isPasswordErrors}
              value={confirmPassword}
              onChange={handleChange}
            />
            <CardActions style={{ padding: '0px' }}>
              <Buttons
                text='Create'
                disable={
                  newPassword &&
                  confirmPassword &&
                  confirmPassword?.length >= Constants.PASSWORD_MIN_LIMIT &&
                  newPassword?.length >= Constants.PASSWORD_MIN_LIMIT
                    ? false
                    : true
                }
                onClick={handleSetPassword}
                classname='alignButton'
              />
            </CardActions>
          </div>
        </Card>
        {isPasswordErrors ? (
          <SnackBar message={snackMessage} show={showSnackBar} setShow={setShowSnackBar} />
        ) : (
          ''
        )}
      </form>
    </>
  );
};
export default PasswordScreen;
