/*
/*
 * File: DetailStaff.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 8:42:54 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 21st March 2023 6:31:49 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Button, FormControlLabel, Link, Switch, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './DetailStaff.css';
import { Paper } from '@mui/material';
import { zeplinColor } from '../../../../theme';
import {
  IStaffDetailRO,
  IStaffDetails,
  IUpdateProfilePayload,
  IUpdateStaffPayload,
} from '../../../../model';
import { StaffService } from '../../../../service/staff.service';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Constants } from '../../../../utilities/constants';
import Loader from '../../../shared/Loader/Loader';
import moment from 'moment';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import ListPopup from '../../../shared/ListPopup';
import DeletePopup from '../../../shared/DeletePopup';
import getPermissionForModule from '../../../../utilities/getPermission';
import { FileService } from '../../../../service/file.service';
import Buttons from '../../../shared/Buttons';
import AddStaff from '../AddStaff/AddStaff';
import { RoleIds } from '../../../../utilities/roles';
import { UserService } from '../../../../service/user.service';
import { useGlobalContext } from '../../../../utilities/sessionStorage';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import { Utilities } from '../../../../utilities/utilities';

const DetailStaff = () => {
  const { setUserData } = useGlobalContext();
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Staff;
  const loggedInUserRoleId = getPermission.roleId;
  const [activeText, setActiveText] = useState<boolean>();
  const [roleId, setRoleId] = useState<number>(0);
  const { id } = useParams();
  const [staffDetail, setStaffDetail] = useState<IStaffDetails>();
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  // For clinic list modal popup
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // For edit modal
  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<IUpdateStaffPayload>();
  const [updateProfile, setUpdateProfile] = useState<any>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const routes = useLocation();
  // For profile settings
  const [profileData, setProfileData] = useState<any>(); // TODO: type=any
  const updatePassword = sessionStorage.getItem('updatePassword');
  const [profilePayload, setProfilePayload] = useState<IUpdateProfilePayload>();
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const prevLink = [{ path: '/staff', label: 'Staff' }];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  useEffect(() => {
    setShowLoader(true);
    if (updatePassword) {
      setShowSnackBar(true);
      setShowSnackBarMessage('Password updated');
      sessionStorage.removeItem('updatePassword');
    }
    if (routes?.pathname?.includes('staff')) {
      getStaffUser();
      setProfileData(undefined);
    }
    if (routes?.pathname?.includes('settings')) {
      getSelfProfile();
      setStaffDetail(undefined);
    }
    setTimeout(() => setShowLoader(false), 2000);
  }, [id, routes?.pathname]);

  const getSelfProfile = async () => {
    const profileRes = await UserService.getSelfUserProfile();
    setProfileData(profileRes.data);
    sessionStorage.setItem('profileData', JSON.stringify(profileRes.data));
  };

  const handleStaffStatus = async (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    setShowLoader(true);
    try {
      await StaffService.switchStaffStatus(id, event.target.checked);
      setActiveText(() => !activeText);
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const resendInvite = async (id: string) => {
    setShowLoader(true);
    try {
      await StaffService.resendStaffInvite(id);
      setShowSnackBar(true);
      setShowSnackBarMessage(`Invitation has been successfully sent to ${staffDetail?.email}`);
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getStaffUser = async () => {
    try {
      if (id) {
        const response: IStaffDetailRO = await StaffService.getStaffById(id);
        const idRole = response.data.roles.map((i) => i.id)[0];
        setRoleId(idRole);
        setStaffDetail(() => response.data);
        setActiveText(response.data.isActive);
      }
    } catch (err) {
      setShowSnackBar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteStaff = async () => {
    setShowLoader(true);
    try {
      if (staffDetail) {
        await StaffService.deleteStaff(staffDetail?.id);
        sessionStorage.setItem(
          'deletedStaff',
          staffDetail?.firstName + ' ' + staffDetail?.lastName,
        );
        navigate('/staff');
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const onCancelEdit = () => {
    setEdit(false);
  };

  const updateStaff = async () => {
    setUpdateLoader(true);
    try {
      const imageUploadRes =
        updateProfile &&
        (await FileService.getSignedUrlToUploadFile(
          Constants.FILE_UPLOAD_CATEGORY.STAFF_PROFILE_IMAGE,
          updateProfile,
          { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
        ));

      const res =
        staffDetail &&
        updatePayload &&
        (await StaffService.updateStaffById(staffDetail.id, {
          ...updatePayload,
          profileImageS3Key: imageUploadRes
            ? imageUploadRes?.signedUrl?.data?.data?.s3Key
            : staffDetail?.profileImageDetails?.s3Key,
        }));
      getStaffUser();
      if (res?.data) {
        const fullname = res.data.firstName + ' ' + res.data.lastName;
        setShowSnackBar(true);
        setShowSnackBarMessage(`${fullname} changes saved`);
        setEdit(false);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
      setUpdateLoader(false);
    }
  };

  const updateProfileSettings = async () => {
    setUpdateLoader(true);
    try {
      const imageUploadRes =
        updateProfile &&
        (await FileService.getSignedUrlToUploadFile(
          Constants.FILE_UPLOAD_CATEGORY.STAFF_PROFILE_IMAGE,
          updateProfile,
          { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
        ));

      const res =
        profileData &&
        profilePayload &&
        (await UserService.updateProfile({
          ...profilePayload,
          phoneNumber: profilePayload?.phoneNumber,
          profileImageS3Key: imageUploadRes
            ? imageUploadRes?.signedUrl?.data?.data?.s3Key
            : profileData?.profileImageDetails?.s3Key,
        }));
      if (res?.data) {
        await getSelfProfile();
        setUserData(res.data);
        const fullname = res.data.firstName + ' ' + res.data.lastName;
        setShowSnackBar(true);
        setShowSnackBarMessage(`${fullname} changes saved`);
        setEdit(false);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
      setUpdateLoader(false);
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='staffDetailContainer'>
          <div className='staffDetailHeader'>
            <Toolbar style={{ color: 'primary', position: 'inherit' }}>
              {routes?.pathname.includes('staff') ? (
                <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                  Staff Details
                  <br></br>
                  <BreadCrumbs prevLink={prevLink} activeLink={'Staff Details'} />
                </Typography>
              ) : (
                <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                  Settings
                </Typography>
              )}
              {!edit ? (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-32px' }}>
                  {permission.delete &&
                  staffDetail &&
                  routes?.pathname.includes('staff') &&
                  loggedInUserRoleId <= staffDetail.roles[0].id ? (
                      <Button
                        variant='outlined'
                        color='secondary'
                        className='deleteButton'
                        onClick={handleDeletePopupOpen}
                      >
                        <DeleteOutlineSharpIcon style={{ width: '24px', height: '24px' }} />
                      </Button>
                    ) : (
                      routes?.pathname.includes('settings') && (
                        <Link
                          href={'/settings/update-password'}
                          className='addButton'
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant='subtitle1' className='buttonText' color='primary'>
                          UPDATE PASSWORD
                          </Typography>
                        </Link>
                      )
                    )}
                  {((permission.update &&
                    staffDetail &&
                    (loggedInUserRoleId < staffDetail.roles[0].id ||
                      loggedInUserRoleId === RoleIds.DSO_ADMIN)) ||
                    (profileData && routes?.pathname?.includes('settings'))) && (
                    <Button
                      variant='contained'
                      color='primary'
                      className='editDetailsButton'
                      onClick={() => setEdit(!edit)}
                    >
                      EDIT DETAILS
                    </Button>
                  )}
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                  <Button
                    variant='outlined'
                    disabled={showLoader ? true : false}
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={onCancelEdit}
                  >
                    Cancel
                  </Button>
                  <Buttons
                    text='SAVE CHANGES'
                    width='100%'
                    disable={editBtn || showLoader ? true : false}
                    onClick={(staffDetail && updateStaff) || (profileData && updateProfileSettings)}
                  />
                </div>
              )}
            </Toolbar>
          </div>
          {edit ? (
            <div>
              <AddStaff
                edit
                staffDetail={staffDetail || profileData}
                setUpdatePayload={setUpdatePayload}
                setProfilePayload={setProfilePayload}
                setUpdateProfile={setUpdateProfile}
                updateLoader={updateLoader}
                setEditBtn={setEditBtn}
              />
            </div>
          ) : (
            <div>
              {((staffDetail && routes?.pathname.includes('staff')) ||
                (profileData && routes?.pathname.includes('settings'))) && (
                <Paper className='staffDetailBox' sx={{ borderRadius: '16px' }}>
                  <div className='staffDetail'>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexGrow: 1 }}>
                        <img
                          className='profileImage'
                          src={
                            staffDetail?.profileImageDetails?.location ||
                            profileData?.profileImageDetails?.location ||
                            Constants.FALL_BACK_IMAGE
                          }
                          alt='profile'
                          onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
                        />
                      </div>
                      <div className='toggle'>
                        {permission.update &&
                          staffDetail &&
                          (loggedInUserRoleId < staffDetail.roles[0].id ||
                            loggedInUserRoleId === RoleIds.DSO_ADMIN) && (
                          <FormControlLabel
                            control={
                              <Switch
                                color='primary'
                                checked={activeText}
                                onChange={(e) => handleStaffStatus(e, staffDetail?.id)}
                              />
                            }
                            label={
                              activeText ? (
                                <Typography>User Active</Typography>
                              ) : (
                                <Typography>User Deactivated</Typography>
                              )
                            }
                            labelPlacement='start'
                            style={{
                              color: activeText ? zeplinColor.Primary : zeplinColor.Background50,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className='subHeading' style={{ gap: '20%' }}>
                      <div style={{ justifyContent: 'start' }}>
                        <Typography variant='subtitle1'>First Name</Typography>
                        <Typography>{staffDetail?.firstName || profileData?.firstName}</Typography>
                      </div>
                      <div style={{ justifyContent: 'right' }}>
                        <Typography variant='subtitle1'>Last Name</Typography>
                        <Typography>{staffDetail?.lastName || profileData?.lastName}</Typography>
                      </div>
                    </div>
                    <div className='subHeading'>
                      <div className='detailsHeading'>
                        <Typography variant='subtitle1'>Email Address</Typography>
                        <Typography>{staffDetail?.email || profileData?.email}</Typography>
                      </div>
                    </div>
                    <div className='subHeading'>
                      <div className='detailsHeading'>
                        <Typography variant='subtitle1'>Phone</Typography>
                        <Typography>
                          {(staffDetail && Utilities.usFormatMobile(staffDetail?.phoneNumber)) ||
                            Utilities.usFormatMobile(profileData?.phoneNumber)}
                        </Typography>
                      </div>
                    </div>
                    <div className='userRole'>
                      <div className='detailsHeading'>
                        <Typography variant='subtitle1'>User Role</Typography>
                        <>
                          {/* to get the role name   */}
                          {staffDetail?.roles && routes?.pathname.includes('staff')
                            ? staffDetail?.roles.map((role) => {
                              return (
                                <div key={role.id}>
                                  <Typography>{role.name}</Typography>
                                </div>
                              );
                            })
                            : profileData &&
                              profileData?.roles.map((role: any) => {
                                return (
                                  <>
                                    <div key={role.id}>
                                      <Typography>{role.name}</Typography>
                                    </div>
                                  </>
                                );
                              })}
                        </>
                      </div>
                      {staffDetail?.roles && routes?.pathname.includes('staff')
                        ? Constants.ROLE_DESCRIPTION[roleId].map((roleDesc, index) => {
                          return (
                            <div key={index} className='listItem'>
                              <CheckCircleOutlineIcon
                                sx={{ color: zeplinColor.Primary }}
                                style={{ height: '18px', width: '18px' }}
                              />
                              <Typography variant='caption'>{roleDesc}</Typography>
                            </div>
                          );
                        })
                        : profileData &&
                          Constants.ROLE_DESCRIPTION[loggedInUserRoleId].map((roleDesc, index) => {
                            return (
                              <div key={index} className='listItem'>
                                <CheckCircleOutlineIcon
                                  sx={{ color: zeplinColor.Primary }}
                                  style={{ height: '18px', width: '18px' }}
                                />
                                <Typography variant='caption'>{roleDesc}</Typography>
                              </div>
                            );
                          })}
                    </div>
                    <div className='assignedClinics'>
                      <div className='detailsHeading'>
                        <Typography variant='subtitle1'>Selected Clinics</Typography>
                      </div>
                      {staffDetail?.isAllClinicsAccess || profileData?.isAllClinicsAccess ? (
                        <Typography variant='body1'>All Clinics</Typography>
                      ) : staffDetail?.clinics.length === 0 || profileData?.clinics.length === 0 ? (
                        <Typography style={{ color: zeplinColor.Background50 }} variant='body1'>
                          No Clinics Assigned
                        </Typography>
                      ) : staffDetail && staffDetail?.clinics.length <= 5 ? (
                        staffDetail?.clinics?.map((user) => {
                          return (
                            <div key={user.id} className='listItem'>
                              <Typography>{user.name}</Typography>
                            </div>
                          );
                        })
                      ) : profileData?.clinics.length <= 5 ? (
                        profileData?.clinics?.map((user: any) => {
                          return (
                            <div key={user.id} className='listItem'>
                              <Typography>{user.name}</Typography>
                            </div>
                          );
                        })
                      ) : (
                        ((staffDetail && staffDetail?.clinics.length > 5) ||
                          profileData?.clinics.length > 5) && (
                          <div>
                            <Typography
                              variant='subtitle1'
                              color='primary'
                              onClick={handleClickOpen}
                              style={{ cursor: 'pointer' }}
                            >
                              {staffDetail?.clinics.length || profileData?.clinics.length} Clinics
                              Selected
                            </Typography>
                          </div>
                        )
                      )}
                      <ListPopup
                        title={'Selected Clinics'}
                        description={'Below is the list of clinics the staff will have access to'}
                        open={open}
                        assignedClinic={staffDetail?.clinics || profileData?.clinics}
                        setOpen={setOpen}
                      />
                    </div>
                    {staffDetail?.status === 'CONFIRMED' ||
                    (profileData && routes?.pathname?.includes('settings')) ? (
                        <div className='subHeading'>
                          <Typography
                            style={{ color: zeplinColor.Background50, marginBottom: '32px' }}
                          >
                          Created on{' '}
                            {moment(staffDetail?.createdAt || profileData?.createdAt).format(
                              'D MMM, YYYY',
                            )}
                          </Typography>
                        </div>
                      ) : (
                        staffDetail && (
                          <div className='invitaionFooter' style={{ gap: '27px' }}>
                            <div className='invitationPending'>
                              <Typography
                                style={{ color: zeplinColor.Background50, marginBottom: '112px' }}
                              >
                              Invitation Pending
                              </Typography>
                            </div>
                            <div>
                              {staffDetail && (
                                <Link
                                  onClick={() => resendInvite(staffDetail?.id)}
                                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                                >
                                  <Typography
                                    variant='subtitle1'
                                    color='primary'
                                    style={{ marginBottom: '112px' }}
                                  >
                                  RESEND INVITE
                                  </Typography>
                                </Link>
                              )}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  {staffDetail && (
                    <DeletePopup
                      title={'Delete Staff'}
                      description={`"${staffDetail?.firstName + ' ' + staffDetail?.lastName}"`}
                      subDescription={`Upon deletion, this staff will be removed from ${
                        staffDetail?.isAllClinicsAccess ? 'all' : staffDetail?.clinics?.length
                      } clinic(s).`}
                      open={openDeletePopup}
                      buttonText={`Delete Staff`}
                      setOpen={setOpenDeletePopup}
                      onDeleteClick={handleDeleteStaff}
                    />
                  )}
                </Paper>
              )}
            </div>
          )}
          {showSnackBar && (
            <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
          )}
        </div>
      )}
    </>
  );
};

export default DetailStaff;
