/*
 * File: ForgotPassword.tsx
 * Project: mint-portal
 * File Created: Wednesday, 3rd August 2022 9:52:33 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 12th October 2022 7:19:16 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState } from 'react';
import LandingPage from '../../components/shared/Landing-page/LandingPage';
import { Card, CardContent } from '@mui/material';
import { Typography } from '@material-ui/core';
import './ForgotPassword.css';
import CustomTextField from '../../components/shared/CustomTextField';
import Buttons from '../../components/shared/Buttons';
import { forgotPassword } from '../../service/amplify.service';
import { useNavigate } from 'react-router-dom';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import Loader from '../../components/shared/Loader/Loader';
import { validateEmail } from '../../utilities/validate';

const ForgotPassword: FC = () => {
  const [isEmailValid, setEmailValid] = useState(true);
  const [isChangeIcon, setChangeIcon] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [email, setEmail] = useState('');
  const [showSnackMessage, setSnackMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setShowLoader(true);
    try {
      await forgotPassword(email.trim());
      sessionStorage.setItem('email', email);
      setShowLoader(false);
      navigate('/reset-password');
    } catch (error) {
      if (error instanceof Error) {
        setShowSnackBar(true);
        setShowLoader(false);
        setSnackMessage(error.message);
      }
    }
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    emailCheck(e.target.value);
  };

  const handleClearEmail = () => {
    setEmail('');
  };

  const emailCheck = (value: string) => {
    const isValid = validateEmail(value);
    setEmailValid(isValid);
    setChangeIcon(isValid);
  };

  return (
    <>
      {showLoader && <Loader />}
      <LandingPage showFooter />
      <form className='forgotPassword'>
        <Card className='container'>
          <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <div>
              <Typography variant='h3'>Forgot Password?</Typography>
              <Typography color='textSecondary' style={{ paddingTop: '8px' }} variant='h5'>
                Please provide the email address registered to this account and we’ll send reset
                instructions.
              </Typography>
              <CustomTextField
                style={{ marginTop: '72px', marginLeft: '0px' }}
                name='email'
                label='Email'
                width='558px'
                showIcon={true}
                // helperText={!isEmailValid ? Constants.INLINE_ERR_MESSAGE.INVALID_EMAIL : ''}
                error={email ? !isEmailValid : false}
                value={email}
                iconChanged={email ? !isChangeIcon : false}
                handleClearEmail={handleClearEmail}
                onChange={handleChange}
              />
            </div>
            <div className='submitBtn'>
              <Buttons
                text='Submit'
                width='11.5em'
                disable={!isEmailValid || !email}
                onClick={handleForgotPassword}
                classname='alignButton'
              />
            </div>
          </CardContent>
        </Card>
        {showSnackBar && (
          <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </form>
    </>
  );
};

export default ForgotPassword;
