/*
 * File: user.service.tsx
 * Project: mint-portal
 * File Created: Thursday, 30th June 2022 3:29:26 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 2nd November 2022 9:41:43 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import axios from '../core/axios';
import { ApiConstants } from './api-constants';
import {
  IProfileDetailRO,
  IUpdateProfilePayload,
  IUserProfileRO,
} from '../model/userProfile.model';

export class UserService {
  static getSelfUserProfile = async (): Promise<IUserProfileRO> => {
    return await (
      await axios.get(ApiConstants.USER_API.PROFILE.URL())
    ).data;
  };
  static updateProfile = async (
    updateProfilePayload: IUpdateProfilePayload,
  ): Promise<IProfileDetailRO> => {
    return await (
      await axios.put(ApiConstants.USER_API.PROFILE.URL(), updateProfilePayload)
    ).data;
  };
}
