/*
 * File: AppointmentDetails.tsx
 * Project: mint-portal
 * File Created: Monday, 14th November 2022 12:30:58 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 30th June 2023 4:56:33 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { zeplinColor } from '../../../theme';
import { FC, useState } from 'react';
import { IAppointmentDetails } from '../../../model/analytics.model';
import { Constants } from '../../../utilities/constants';
import CustomToolTip from '../../shared/CustomToolTip';
import './ListAppointmentDetails.css';
import moment from 'moment';
import EmptyState from '../../shared/EmptyState/EmptyState';
import Loader from '../../shared/Loader/Loader';
import { makeStyles } from '@material-ui/core/styles';

interface IAppointmentListProps {
  appointmentList: any[];
  showLoader: boolean;
}

interface AppointmentDetailsListColumn {
  id:
    | 'patient_name'
    | 'clinic'
    | 'service'
    | 'appointment_id'
    | 'status'
    | 'created_at'
    | 'appointment_at'
    | 'operatory_name'
    | 'insurance'
    | 'appointment_note';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const appointmentDetailsListColumns: AppointmentDetailsListColumn[] = [
  { id: 'patient_name', label: 'Patient Name', minWidth: 300 },
  { id: 'clinic', label: 'Clinic', minWidth: 150 },
  { id: 'service', label: 'Service', minWidth: 150 },
  { id: 'appointment_id', label: 'Appointment ID', minWidth: 150 },
  { id: 'status', label: 'Status', minWidth: 150 },
  { id: 'created_at', label: 'Created at', minWidth: 150 },
  { id: 'appointment_at', label: 'Appointment at', minWidth: 150 },
  { id: 'operatory_name', label: 'Operatory Name', minWidth: 150 },
  { id: 'insurance', label: 'Insurance', minWidth: 150 },
  { id: 'appointment_note', label: 'Appointment Note', minWidth: 150 },
];
const useStyles = makeStyles(() => ({
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
  },
}));

const ListAppointmentDetails: FC<IAppointmentListProps> = ({ appointmentList, showLoader }) => {
  const [openViewNote, setOpenViewNote] = React.useState(false);
  const [viewNote, setViewNote] = useState<IAppointmentDetails>();
  const classes = useStyles();
  const handleClickOpen = (viewObj: IAppointmentDetails) => {
    setViewNote(viewObj);
    setOpenViewNote(true);
  };

  const handleClose = () => {
    setOpenViewNote(false);
  };

  return (
    <>
      {showLoader && <Loader small forTable />}
      {appointmentList.length === 0 && !showLoader ? (
        <div style={{ marginTop: '15%' }}>
          <EmptyState
            path={''}
            text={'No appointment details to show'}
            subText={''}
            buttonText={''}
            showAddButton={false}
            image={true}
          />
        </div>
      ) : (
        !showLoader && (
          <>
            <Paper sx={{ borderRadius: '16px' }}>
              <TableContainer className='containerAppointmentDiv'>
                <Table aria-label='caption table'>
                  <TableHead>
                    <TableRow>
                      {appointmentDetailsListColumns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <Typography
                            style={{ color: zeplinColor.Background50 }}
                            variant='subtitle1'
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointmentList.length &&
                      appointmentList.map((obj: IAppointmentDetails, index) => {
                        return (
                          <TableRow key={index} className={classes.tableRow}>
                            <TableCell>
                              <CustomToolTip
                                title={obj?.patient?.firstName + ' ' + obj?.patient?.lastName}
                                width={'180px'}
                              />
                            </TableCell>
                            <TableCell>
                              <CustomToolTip title={obj.clinic.name} width={'180px'} />
                            </TableCell>
                            <TableCell>
                              <CustomToolTip title={obj.service.name} width={'180px'} />
                            </TableCell>
                            <TableCell>
                              <Typography style={{ width: '150px' }}>
                                {obj.appointmentNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {obj.status === 1 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Blue,
                                    backgroundColor: zeplinColor.Blue01,
                                  }}
                                >
                                  Scheduled
                                </Typography>
                              ) : obj.status === 2 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Primary,
                                    backgroundColor: zeplinColor.Primary50,
                                  }}
                                >
                                  Complete
                                </Typography>
                              ) : obj.status === 3 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Blue,
                                    backgroundColor: zeplinColor.Blue01,
                                  }}
                                >
                                  Unscheduled List
                                </Typography>
                              ) : obj.status === 4 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Blue,
                                    backgroundColor: zeplinColor.Blue01,
                                  }}
                                >
                                  ASAP
                                </Typography>
                              ) : obj.status === 5 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Error,
                                    backgroundColor: zeplinColor.Error50,
                                  }}
                                >
                                  Broken
                                </Typography>
                              ) : obj.status === 6 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Blue,
                                    backgroundColor: zeplinColor.Blue01,
                                  }}
                                >
                                  Planned
                                </Typography>
                              ) : obj.status === 7 ? (
                                <Typography
                                  className='status'
                                  style={{
                                    color: zeplinColor.Blue,
                                    backgroundColor: zeplinColor.Blue01,
                                  }}
                                >
                                  PtNote
                                </Typography>
                              ) : (
                                obj.status === 8 && (
                                  <Typography
                                    className='status'
                                    style={{
                                      color: zeplinColor.Blue,
                                      backgroundColor: zeplinColor.Blue01,
                                    }}
                                  >
                                    PtNote Completed
                                  </Typography>
                                )
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography style={{ width: '180px' }}>
                                {moment(obj.createdAt).format(
                                  Constants.MOMENT_FORMATS.DATE +
                                    ' ' +
                                    Constants.MOMENT_FORMATS.TIME_LOWERCASE,
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography style={{ width: '180px' }}>
                                {moment(obj.appointmentDateTime).format(
                                  Constants.MOMENT_FORMATS.DATE +
                                    ' ' +
                                    Constants.MOMENT_FORMATS.TIME_LOWERCASE,
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <CustomToolTip title={obj.operatory.operatoryName} width={'180px'} />
                            </TableCell>
                            <TableCell>
                              <Typography style={{ width: '120px' }}>
                                {obj.isInsuranceUsed ? 'Yes' : 'No'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {obj?.note ? (
                                <Typography
                                  style={{ width: '140px' }}
                                  variant='subtitle1'
                                  className='buttonText'
                                  color='primary'
                                  onClick={() => handleClickOpen(obj)}
                                >
                                  View Note
                                </Typography>
                              ) : (
                                <Typography style={{ width: '140px' }}>-</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Dialog open={openViewNote} onClose={handleClose}>
              <DialogTitle>
                <Typography variant='h5' component={'span'}>
                  Appointment Note
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography>{viewNote?.note}</Typography>
              </DialogContent>
              <DialogActions
                style={{ paddingRight: '24px', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button color='primary' onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      )}
    </>
  );
};

export default ListAppointmentDetails;
