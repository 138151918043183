/*
 * File: ElectiveProcedure.tsx
 * Project: mint-portal
 * File Created: Monday, 12th June 2023 1:51:33 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 16th June 2023 12:17:58 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Button, FormControlLabel, Typography } from '@material-ui/core';
import { Box, Paper, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import './AddElectiveProcedure.css';
import { zeplinColor } from '../../../../theme';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import CustomTextField from '../../../shared/CustomTextField';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import CustomNumericInput from '../../../shared/CustomNumericField';
import { handleKeyPressForEscapeSymbols } from '../../../../utilities/validate';
import Checkbox from '@mui/material/Checkbox';
import { RewardService } from '../../../../service/reward.service';
import Loader from '../../../shared/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const AddElectiveProcedure = () => {
  const prevLink = [{ path: '/rewards', label: 'Auto-assign Points' }];
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackMessage, setSnackMessage] = useState('');
  const [canEarn, setCanEarn] = useState<string[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    title: '',
    description: '',
    familyPoints: 0,
    primaryPoints: 0,
    eligibleCodes: '',
  });
  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    borderColor: zeplinColor.Primary,
    width: '98%',
    height: '0rem',
  };
  const patient = [
    { name: 'Primary Patient', value: 'primary' },
    { name: 'Family Member', value: 'family' },
  ];
  const navToList = () => {
    navigate('/rewards');
  };
  const handleChange = (e: any) => {
    const duplicate = canEarn.filter((val: any) => val === e.target?.value);
    duplicate?.length
      ? setCanEarn([...canEarn.filter((val: any) => val !== e.target?.value)])
      : setCanEarn([...canEarn, e.target.value]);
  };
  const handleInput = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const addProcedure = async () => {
    setShowLoader(true);
    try {
      await RewardService.addElectiveProcedure({
        ...(canEarn.includes('primary') && { primaryPatientPointsValue: +input.primaryPoints }),
        ...(canEarn.includes('family') && { familyPatientPointsValue: +input.familyPoints }),
        procedureCodes: input.eligibleCodes.split(','),
        name: input.title,
        description: input.description,
      });
      sessionStorage.setItem('addedElectiveProcedure', input.title);
      navToList();
    } catch (error) {
      setShowSnackBar(true);
      setSnackMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div>
      {showLoader && <Loader />}
      <div className='procedureAddContainer'>
        <div className='procedureAddHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='procedureTitle' color='inherit'>
              Add Procedures
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Add Procedures '} />
            </Typography>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                variant='outlined'
                style={{
                  width: '8em',
                  height: '34px',
                  backgroundColor: `${zeplinColor.Background97}`,
                  border: `1px solid ${zeplinColor.Background70}`,
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={navToList}
              >
                Cancel
              </Button>
              <Buttons
                text='Add Procedures'
                width='100%'
                disable={
                  Object.values(input).some((e: any) => typeof e === 'string' && !e) ||
                  canEarn.length === 0 ||
                  (canEarn.includes('primary') && input.primaryPoints <= 0) ||
                  input.primaryPoints > 1000000 ||
                  (canEarn.includes('family') && input.familyPoints <= 0) ||
                  input.familyPoints > 1000000
                }
                onClick={addProcedure}
              />
            </div>
          </Toolbar>
        </div>
        <Paper className='procedureAddBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 32, width: '100%' }}>
              <CustomTextField
                width='60%'
                name='title'
                value={input.title}
                onChange={handleInput}
                label='Title*'
              ></CustomTextField>

              <CustomTextField
                width='60%'
                name='description'
                value={input?.description}
                onChange={handleInput}
                label='Description*'
              ></CustomTextField>
              <Box sx={{ ...commonStyles, borderTop: 1, color: zeplinColor.Background95 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 32,
                width: '100%',
                marginTop: 40,
              }}
            >
              <Typography variant='subtitle1' color='inherit'>
                Who can earn these points?*
              </Typography>
              <div style={{ display: 'flex', gap: 35, paddingLeft: 20 }}>
                <FormControlLabel
                  style={{ alignItems: 'normal' }}
                  control={
                    <Checkbox
                      value={patient[0].value}
                      sx={{
                        '&.Mui-checked': {
                          color: `${zeplinColor.Primary}`,
                        },
                      }}
                      style={{
                        height: '30px',
                        width: '20px',
                        paddingRight: '20px',
                        paddingTop: '5px',
                      }}
                      checked={canEarn.includes(patient[0].value) ? true : false}
                      onChange={handleChange}
                    />
                  }
                  label={patient[0].name}
                />
                <FormControlLabel
                  style={{ alignItems: 'normal' }}
                  control={
                    <Checkbox
                      value={patient[1].value}
                      sx={{
                        '&.Mui-checked': {
                          color: `${zeplinColor.Primary}`,
                        },
                      }}
                      style={{
                        height: '30px',
                        width: '20px',
                        paddingRight: '20px',
                        paddingTop: '5px',
                      }}
                      checked={canEarn.includes(patient[1].value) ? true : false}
                      onChange={handleChange}
                    />
                  }
                  label={patient[1].name}
                />
              </div>
              <CustomNumericInput
                name='primaryPoints'
                width={'60%'}
                label='Points earned by Primary Patient*'
                placeholder='Points earned by Primary Patient*'
                value={input?.primaryPoints}
                error={false}
                disabled={canEarn.includes(patient[0].value) ? false : true}
                onKeyPress={handleKeyPressForEscapeSymbols}
                onChange={handleInput}
                icon={false}
              />
              <CustomNumericInput
                name='familyPoints'
                width={'60%'}
                label='Points earned by Family Member*'
                placeholder='Points earned by Family Member*'
                value={input?.familyPoints}
                error={false}
                disabled={canEarn.includes(patient[1].value) ? false : true}
                onKeyPress={handleKeyPressForEscapeSymbols}
                onChange={handleInput}
                icon={false}
              />
              <Box sx={{ ...commonStyles, borderTop: 1, color: zeplinColor.Background95 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 32,
                width: '100%',
                marginTop: 40,
              }}
            >
              <Typography variant='subtitle1' color='inherit'>
                Eligible Procedures
              </Typography>
              <CustomTextField
                name='eligibleCodes'
                width={'60%'}
                label='List of Procedures Codes*'
                showIcon={false}
                value={input?.eligibleCodes}
                disable={false}
                onChange={handleInput}
              />
            </div>
          </div>
        </Paper>
        {showSnackBar && (
          <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </div>
  );
};

export default AddElectiveProcedure;
