/*
 * File: RedeemReward.tsx
 * Project: mint-portal
 * File Created: Thursday, 27th October 2022 9:47:49 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 6th October 2023 7:54:30 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './RedeemReward.css';
import React, { FC, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../shared/Buttons';
import {
  Toolbar,
  Button,
  Paper,
  Grid,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../../theme';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { RewardService } from '../../../../../service/reward.service';
import {
  RewardType,
  IPatientListQueryParams,
  IPatientListRO,
  IPatientListAPIResponse,
  IPatientList,
  IRewardQueryParam,
  IListRewardRO,
  IListRewardPaginationResponse,
  IListRewardResponse,
  IAvailablePointRewardRO,
} from '../../../../../model';
import { Constants } from '../../../../../utilities/constants';
import { PatientService } from '../../../../../service/patient.service';
import CloseIcon from '@mui/icons-material/Close';
import InfiniteDropDown from '../../../../shared/InfiniteDropDown/InfiniteDropDown';

const RedeemReward: FC = () => {
  const navigate = useNavigate();
  const prevLink = [{ path: '/rewards', label: 'Rewards' }];
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  // const [disableRedeemBtn, setRedeemBtn] = useState(true);
  const [patientSearchValue, setPatientSearchValue] = useState('');
  const [selectRewardType, setRewardType] = React.useState('');
  const [selectedPatient, setSelectedPatient] = useState<IPatientList>();
  const [patientList, setPatientList] = useState<IPatientListAPIResponse>({
    patients: [],
    total: 0,
  });
  const [searchPatientList, setSearchPatientList] = useState<IPatientListAPIResponse>({
    patients: [],
    total: 0,
  });
  const [rewardSearchValue, setRewardSearchValue] = useState('');
  const [rewardList, setRewardList] = useState<IListRewardPaginationResponse>({
    total: 0,
    rewards: [],
  });
  const [searchRewardList, setSearchRewardList] = useState<IListRewardPaginationResponse>({
    total: 0,
    rewards: [],
  });
  const defaultReward = {
    id: 0,
    rewardType: '',
    pointsValue: 0,
    inventoryCount: 0,
    name: '',
  };
  const [selectedReward, setSelectedReward] = useState<IListRewardResponse>(defaultReward);
  const [availablePoints, setAvailablePoints] = useState<number>();
  const [typed, setTyped] = useState(false);
  const [counter, setCounter] = useState(0);
  const [searchCounter, setSearchCounter] = useState(0);
  const [patientOptionsOpen, setPatientOptionsOpen] = useState(false);
  const [rewardOptionsOpen, setRewardOptionsOpen] = useState(false);

  useEffect(() => {
    if (patientSearchValue?.length > 2) {
      getPatientList();
    }
  }, [patientSearchValue]);

  useEffect(() => {
    if (rewardSearchValue?.length > 2) {
      getRewardList(0);
    }
  }, [selectRewardType, rewardSearchValue]);

  useEffect(() => {
    if (selectedPatient?.id) getRewardPoints();
  }, [selectedPatient]);

  useEffect(() => {
    // window.history.pushState({ prevUrl: window.location.href }, prevLink[0].path);
    if (availablePoints === 0) {
      setRewardType(RewardType.PointsCredit);
      // getRewardList();
    } else {
      setRewardType('');
      setRewardList({
        total: 0,
        rewards: [],
      });
    }
  }, [availablePoints]);

  const onSearchPatient = (searchVal: any) => {
    setTyped(true);
    setPatientSearchValue(searchVal?.target?.value);
  };
  const onSelectPatient = (inputValue: any) => {
    setSelectedPatient(inputValue);
    setTyped(false);
    setCounter(0);
    handlePatientOptionsOpen();
  };
  const onSearchReward = (searchVal: any) => {
    setTyped(true);
    setRewardSearchValue(searchVal.target.value);
  };
  const onSelectReward = (inputValue: any) => {
    setSelectedReward(inputValue);
    setCounter(0);
    handleRewardOptionsOpen();
  };
  const handleRewardOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRewardType((event.target as HTMLInputElement).value);
    setSelectedReward(defaultReward);
  };

  const removePatient = () => {
    setSelectedPatient(undefined);
    resetFields();
  };

  const getPatientList = async (skipArg?: number) => {
    if (patientSearchValue && patientSearchValue.length < Constants.SEARCH_LIMIT) {
      setShowLoader(false);
    }
    try {
      setCounter(counter + 4);
      console.log(skipArg);
      const listPatientQuery: IPatientListQueryParams = {
        limit: Constants.SEARCH_LIMIT,
        skip: skipArg ? skipArg : 0,
        ...(patientSearchValue &&
          patientSearchValue.length > 2 && {
          searchString: patientSearchValue,
          skip: skipArg ? skipArg : 0,
        }),
      };
      const response: IPatientListRO = await PatientService.getPatientList(listPatientQuery);
      const prevVal = searchPatientList?.patients?.filter(
        (e: any) =>
          e?.firstName?.toLowerCase().match(patientSearchValue) ||
          e?.lastName?.toLowerCase().match(patientSearchValue),
      );

      console.log(prevVal);
      patientSearchValue.length > 2
        ? setSearchPatientList({
          patients: prevVal?.length
            ? [
              ...prevVal,
              ...response?.data?.patients?.filter(
                (e: any) => !prevVal.filter((ref: any) => ref.id == e.id).length,
              ),
            ]
            : response.data.patients,
          total: response.data.total,
        })
        : setPatientList((prev) => ({
          patients: prev?.patients?.length
            ? [
              ...prev?.patients,
              ...response?.data?.patients?.filter(
                (e: any) => !prev?.patients.filter((ref: any) => ref.id == e.id).length,
              ),
            ]
            : response?.data?.patients,
          total: response?.data?.total,
        }));
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  const getRewardPoints = async () => {
    if (selectedPatient) {
      setShowLoader(true);
      try {
        const response: IAvailablePointRewardRO =
          await PatientService.getAvailablePointsByPatientId(selectedPatient?.id);
        setAvailablePoints(() => response?.data?.availablePoints);
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof error === 'string' ? error : (error as any).response.data.error.message[0],
        );
      }
    }
  };

  const getRewardList = async (skipArg?: number) => {
    if (rewardSearchValue && rewardSearchValue.length < Constants.SEARCH_LIMIT) {
      setShowLoader(false);
    }
    if (selectedPatient && selectedPatient.id && selectRewardType) {
      try {
        setCounter(counter + 4);
        const rewardListQueryParams: IRewardQueryParam = {
          limit: Constants.SEARCH_LIMIT,
          skip: skipArg ? skipArg : 0,
          patientId: selectedPatient?.id,
          rewardType: [selectRewardType],
          ...(rewardSearchValue &&
            rewardSearchValue.length >= 3 && {
            searchString: rewardSearchValue,
            skip: skipArg ? skipArg : 0,
          }),
          ...(selectRewardType === RewardType.Gift && {
            hasInventoryStock: true,
          }),
        };
        const response: IListRewardRO = await RewardService.getConfigRewardList(
          rewardListQueryParams,
        );
        const prevVal = searchRewardList?.rewards?.filter(
          (e: any) =>
            e?.firstName?.toLowerCase().match(patientSearchValue) ||
            e?.lastName?.toLowerCase().match(patientSearchValue),
        );

        console.log(prevVal, response);
        rewardSearchValue.length > 2
          ? setSearchRewardList({
            rewards: prevVal?.length
              ? [
                ...prevVal,
                ...response?.data?.rewards?.filter(
                  (e: any) => !prevVal.filter((ref: any) => ref.id == e.id).length,
                ),
              ]
              : response?.data?.rewards,
            total: response?.data?.total,
          })
          : setRewardList((prev) => ({
            rewards: prev?.rewards?.length
              ? [
                ...prev?.rewards,
                ...response?.data?.rewards?.filter(
                  (e: any) => !prev?.rewards.filter((ref: any) => ref.id == e.id).length,
                ),
              ]
              : response?.data?.rewards,
            total: response?.data?.total,
          }));
        setShowLoader(false);
      } catch (err) {
        console.log(err);
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const onCancel = () => {
    navigate(prevLink[0].path);
    sessionStorage.setItem('redeem-cancel', 'cancel');
  };

  const redeemReward = async () => {
    if (selectedPatient && selectedReward) {
      setShowLoader(true);
      const payload = {
        patientId: selectedPatient.id,
        rewardType: selectRewardType,
        rewardId: selectedReward.id,
      };
      try {
        await RewardService.redeemReward(payload);
        sessionStorage.setItem('redeemReward', selectedReward?.name);
        navigate(prevLink[0].path);
      } catch (error) {
        setShowSnackbarMessage(
          typeof error === 'string' ? error : (error as any).response.data.error.message[0],
        );
        setShowLoader(false);
        setShowSnackbar(true);
      }
    }
  };

  const resetFields = () => {
    setSelectedReward(defaultReward);
    setAvailablePoints(undefined);
  };

  const handlePatientOptionsOpen = () => {
    setPatientSearchValue('');
    setPatientOptionsOpen(!patientOptionsOpen);
    getPatientList(0);
    if (patientOptionsOpen) {
      setTyped(false);
      setCounter(0);
      setPatientList({ patients: [], total: 0 });
    }
  };
  const handleRewardOptionsOpen = () => {
    setRewardSearchValue('');
    setRewardOptionsOpen(!rewardOptionsOpen);
    getRewardList(0);
    if (rewardOptionsOpen) {
      setTyped(false);
      setCounter(0);
      setRewardList({ rewards: [], total: 0 });
    }
  };
  console.log(counter);
  return (
    <>
      {showLoader && <Loader />}
      <div className='redeemAddContainer'>
        <div className='redeemAddHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              Redeem Rewards
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Redeem Rewards'} />
            </Typography>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                variant='outlined'
                style={{
                  width: '8em',
                  height: '34px',
                  backgroundColor: `${zeplinColor.Background97}`,
                  border: `1px solid ${zeplinColor.Background70}`,
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Buttons
                text='Redeem'
                width='9em'
                disable={
                  selectedPatient?.id &&
                  selectRewardType &&
                  selectedReward &&
                  selectedReward.id !== 0
                    ? false
                    : true
                }
                onClick={redeemReward}
              />
            </div>
          </Toolbar>
        </div>
        <Paper className='redeemAddBox' sx={{ borderRadius: '16px' }}>
          <div className='redeemAdd'>
            <div className='redeemSection'>
              <section style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <Typography variant='subtitle1'> Select a Primary Patient*</Typography>
                <Typography variant='body2'>Only one patient can be selected at a time</Typography>
                <section style={{ marginTop: 8 }}>
                  {patientList && !selectedPatient && (
                    // <SearchDropdown
                    //   searchList={patientList?.patients}
                    //   width={'100%'}
                    //   label={'Search by Patient Name or Unique Code'}
                    //   onSelectHandler={onSelectPatient}
                    //   onSearchHandler={onSearchPatient}
                    //   multiple={false}
                    //   value={selectedPatient || null}
                    //   patient={true}
                    //   // fixed={selectedPatient ? true : false}
                    // />
                    <InfiniteDropDown
                      handleOpen={handlePatientOptionsOpen}
                      open={patientOptionsOpen}
                      searchList={
                        patientSearchValue ? searchPatientList?.patients : patientList?.patients
                      }
                      name={'patientList'}
                      zIndex={'1'}
                      onSelectHandler={onSelectPatient}
                      setSelected={setSelectedPatient}
                      onSearchHandler={onSearchPatient}
                      search={patientSearchValue}
                      value={selectedPatient}
                      disable={false}
                      label={'Search by Patient Name or Unique Code'}
                      infinite={'patientList'}
                      dataLength={
                        patientSearchValue
                          ? searchPatientList?.patients?.length
                          : patientList?.patients?.length
                      }
                      hasMore={
                        patientSearchValue?.length > 2
                          ? searchPatientList?.patients?.length < searchPatientList?.total
                          : patientList?.patients?.length < patientList?.total
                      }
                      next={getPatientList}
                      counter={patientSearchValue ? searchCounter : counter}
                      setCounter={setSearchCounter}
                      clearSearch={typed ? true : false}
                      multiple={false}
                      width={'100%'}
                    />
                  )}
                  {selectedPatient && (
                    <>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          backgroundColor: zeplinColor.Background90,
                        }}
                        className='selected-patient'
                      >
                        <Grid item md={5}>
                          <Typography variant='body1' style={{ marginLeft: 2 }}>
                            {selectedPatient?.firstName + ' ' + selectedPatient?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography variant='body1'>{selectedPatient?.code}</Typography>
                        </Grid>
                        <Grid item md={1}>
                          <IconButton
                            style={{ cursor: 'pointer' }}
                            disableRipple
                            onClick={removePatient}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Typography
                        variant='h6'
                        style={{ marginTop: 16, marginLeft: 4 }}
                        color='primary'
                      >
                        <>Available Points: {availablePoints}</>
                      </Typography>
                    </>
                  )}
                </section>
              </section>
              {selectedPatient && (
                <section>
                  <Typography variant='subtitle1'>Select a Reward Type*</Typography>
                  <FormControl
                    style={{ marginTop: '12px', marginLeft: '12px', position: 'inherit' }}
                  >
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={selectRewardType}
                      onChange={handleRewardOptionChange}
                    >
                      <FormControlLabel
                        value={RewardType.Gift}
                        control={
                          <Radio
                            disabled={showLoader || availablePoints === 0 ? true : false}
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Gift</Typography>}
                      />
                      <FormControlLabel
                        value={RewardType.PointsCredit}
                        control={
                          <Radio
                            disabled={showLoader ? true : false}
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Points Credit</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </section>
              )}
              {rewardList && selectedPatient && (
                <section>
                  {/* <SearchDropdown
                    searchList={rewardList?.rewards}
                    width={'100%'}
                    label={'Select Reward*'}
                    onSelectHandler={onSelectReward}
                    onSearchHandler={onSearchReward}
                    multiple={false}
                    value={selectedReward}
                    // fixed={selectedReward ? true : false}
                  /> */}
                  <InfiniteDropDown
                    handleOpen={handleRewardOptionsOpen}
                    open={rewardOptionsOpen}
                    searchList={rewardSearchValue ? searchRewardList?.rewards : rewardList?.rewards}
                    name={'reward'}
                    zIndex={'1'}
                    onSelectHandler={onSelectReward}
                    setSelected={setSelectedReward}
                    onSearchHandler={onSearchReward}
                    search={rewardSearchValue}
                    value={selectedReward}
                    disable={false}
                    label={'Select Reward*'}
                    infinite={''}
                    dataLength={
                      rewardSearchValue?.length
                        ? searchRewardList?.rewards?.length
                        : rewardList?.rewards?.length
                    }
                    hasMore={
                      rewardSearchValue?.length > 2
                        ? searchRewardList?.rewards?.length < searchRewardList?.total
                        : rewardList?.rewards?.length < rewardList?.total
                    }
                    next={getRewardList}
                    counter={rewardSearchValue ? searchCounter : counter}
                    setCounter={setSearchCounter}
                    clearSearch={typed ? true : false}
                    multiple={false}
                    width={'100%'}
                  />
                </section>
              )}
              {rewardList?.total === 0 ||
                (!RewardType.Gift && (
                  <Typography
                    className='reward-inline-err'
                    variant='body1'
                    style={{ color: zeplinColor.Error }}
                  >
                    Sorry, no gifts available to redeem
                  </Typography>
                ))}
            </div>
          </div>
        </Paper>
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
    </>
  );
};

export default RedeemReward;
