/*
 * File: FilterServicePopup.tsx
 * Project: mint-portal
 * File Created: Wednesday, 10th August 2022 5:23:48 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 9th September 2022 12:49:28 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Dispatch, useState } from 'react';
import { zeplinColor } from '../../../../theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { FilterParams } from '../../../../model/service.model';

type filterModalProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  onFilterClick: Dispatch<boolean>;
  selectedChips: object;
};

const useStyles = makeStyles(() => ({
  paper: { minWidth: '600px' },
  displayColumn: {
    display: 'flex', flexDirection: 'column'
  },
  displayRow: {
    display: 'flex', flexDirection: 'row'
  }
}));

const ToggleButton = styled(MuiToggleButton)({
  height: '34px',
  color: zeplinColor.Background50,
  borderRadius: '4px',
  border: `1px solid ${zeplinColor.Background50}`,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: `${zeplinColor.Primary}`,
    border: `1px solid ${zeplinColor.Primary}`,
    backgroundColor: zeplinColor.Surface
  }
});


const FilterServicePopup = ({ open, setOpen, onFilterClick, selectedChips }: filterModalProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<FilterParams | any>(selectedChips ||
    { newPatient: false, existing: false, adult: false, child: false });
  const { newPatient, existing, adult, child } = selected;

  React.useEffect(() => {
    if (selectedChips) {
      setSelected(selectedChips);
    }
  }, [selectedChips])


  const handleFilterOptions = (e: any) => {
    if(e.target.name !== undefined){
      const name = e.target.name;
      setSelected((prev: FilterParams) => ({
        ...prev,
        [name]: !selected[name],
      }));
    }
  };

  const handleClose = () => {
    if(selectedChips){
      setSelected(selectedChips);
    } else {
      setSelected({ newPatient: false, existing: false, adult: false, child: false })
    }
    setOpen(false);
  };

  const onFilter = () => {
    onFilterClick(selected);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'} >
            Filter Options
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className={classes.displayColumn} style={{ gap: '16px' }}>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Age type
                </Typography>
                <Typography component={'span'} variant='body2'>Filter services based on age type</Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <ToggleButton disableRipple selected={adult} name='adult' onChange={handleFilterOptions} value='checked'
                  style={{ width: '93px' }}>{adult && (<CheckIcon />)}Adult</ToggleButton>
                <ToggleButton disableRipple selected={child} name='child' onChange={handleFilterOptions} value='checked'
                  style={{ width: '91px' }}>{child && (<CheckIcon />)}Child</ToggleButton>
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Patient type
                </Typography>
                <Typography component={'span'} variant='body2'>Filter services based on patient type</Typography>
              </div>
              <div className={classes.displayRow} style={{ gap: '16px' }}>
                <ToggleButton disableRipple selected={existing} onChange={handleFilterOptions} value='checked' name='existing'
                  style={{ width: '115px' }}>{existing && (<CheckIcon />)} Existing</ToggleButton>
                <ToggleButton disableRipple selected={newPatient} name='newPatient' value='checked' onChange={handleFilterOptions}
                  style={{ width: '80px' }}>{newPatient && (<CheckIcon />)} New</ToggleButton>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{
            color: `${zeplinColor.Primary}`
          }} onClick={handleClose}>Cancel</Button>

          <Button onClick={onFilter}
            style={{
              color: `${zeplinColor.Primary}`
            }}>
            <Typography>
            Filter Results
            </Typography>
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
export default FilterServicePopup;