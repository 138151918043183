/*
 * File: AccordionTable.tsx
 * Project: mint-portal
 * File Created: Thursday, 8th June 2023 9:28:00 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 13th June 2023 11:57:46 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import { Accordion, AccordionDetails, AccordionSummary, Pagination, Paper } from '@mui/material';
import {
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../theme';
import getPermissionForModule from '../../../utilities/getPermission';

type accordionTableProps = {
  title: string;
  isButton: boolean;
  buttonText?: string;
  onClick?: (e: any) => void;
  columnHeaders: Array<any>;
  tableContents: Array<any>;
  isPagination: boolean;
  onPageChangeHandler?: any;
  currentPage: number;
  count?: number;
  handleStatus?: (id: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  isViewDetail: boolean;
  pathName?: string;
};

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const AccordionTable = ({
  title,
  isButton,
  buttonText,
  onClick,
  columnHeaders,
  tableContents,
  handleStatus,
  isPagination,
  onPageChangeHandler,
  currentPage,
  count,
  isViewDetail,
  pathName,
}: accordionTableProps) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();
  const navigate = useNavigate();
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.AutoAssignPoints;

  const onDataPageChange = (event: any, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };

  return (
    <>
      <Paper
        style={{
          borderRadius: '16px',
          overflow: 'hidden',
          width: '76.5vw',
          margin: '8px 0px 24px -8px',
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon style={{ color: zeplinColor.Primary }} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ height: '72px' }}
          >
            <Typography variant='subtitle1'>{title}</Typography>
            {isButton && (
              <Button
                style={{
                  position: 'absolute',
                  right: '52px',
                  borderRadius: '8px',
                  border: `${zeplinColor.Primary} 1px solid`,
                  color: `${zeplinColor.Primary}`,
                }}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {columnHeaders.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableContents.map((obj: any) => (
                    <TableRow
                      key={obj.id}
                      hover={isViewDetail}
                      classes={{ hover: classes.tableRow }}
                      onClick={() => {
                        isViewDetail && navigate({ pathname: `${pathName}/${obj.id}` });
                      }}
                    >
                      <TableCell key={obj.id}>
                        <Typography>{obj.name}</Typography>
                      </TableCell>

                      <TableCell>
                        <Typography>
                          {obj.primaryPatientPointsValue
                            ? obj.primaryPatientPointsValue + ' ' + 'Points'
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {obj.familyPatientPointsValue
                            ? obj.familyPatientPointsValue + ' ' + 'Points'
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: '250px' }}>
                        <FormControlLabel
                          control={
                            permission.update ? (
                              <Switch
                                color='primary'
                                checked={obj.status}
                                onChange={(e) => handleStatus && handleStatus(obj.id, e)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            ) : (
                              <span style={{ width: '20px' }}></span>
                            )
                          }
                          label={
                            obj.status ? (
                              <Typography variant='subtitle1'>Active</Typography>
                            ) : (
                              <Typography variant='subtitle1'>Inactive</Typography>
                            )
                          }
                          labelPlacement='end'
                          style={{
                            color: obj.status ? zeplinColor.Primary : zeplinColor.Error,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {isPagination && (
                    <TableRow style={{ height: '55px' }}>
                      <Pagination
                        count={count}
                        onChange={onDataPageChange}
                        page={dataPage}
                        variant='outlined'
                        shape='rounded'
                        className='pagination'
                        style={{ padding: '20px 0px 20px 0px' }}
                        classes={{ outlined: classes.ul }}
                      />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
};

export default AccordionTable;
