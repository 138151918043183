/*
 * File: Routes.tsx
 * Project: mint-portal
 * File Created: Wednesday, 1st June 2022 1:43:59 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 13th June 2023 5:04:53 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { ProtectedRoute, ValidatePasswordRoute } from './ProtectedRoute';
import React, { ReactElement, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import AddBlockout from '../components/feature/booking/AddBlockout/AddBlockout';
import AddClinic from '../components/feature/clinic/AddClinic/AddClinic';
import AddConfigureRewards from '../components/feature/reward/ConfigureRewards/AddConfigureRewards/AddConfigureRewards';
import AddDsoBlockout from '../components/feature/manage/DsoBlockout/AddDsoBlockout/AddDsoBlockout';
import AddFaq from '../components/feature/manage/Faq/AddFaq/AddFaq';
import AddInsurance from '../components/feature/insurance/AddInsurance/AddInsurance';
import AddNotifications from '../components/feature/manage/AppointmentNotifications/AddNotifications';
import AddOtherLinks from '../components/feature/manage/Links/OtherLinks/AddOtherLinks/AddOtherLinks';
import AddPatientInsurance from '../components/feature/patient/AddInsurance/AddPatientInsurance';
import AddService from '../components/feature/service/AddService/AddService';
import AddStaff from '../components/feature/staff/AddStaff/AddStaff';
import AddVideo from '../components/feature/manage/Videos/AddVideo/AddVideo';
import AppointmentNotifications from '../components/feature/manage/AppointmentNotifications/AppointmentNotifications';
import Booking from '../pages/Bookings/Booking';
import Clinics from '../pages/Clinics/Clinics';
import DashBoard from '../pages/Dashboard/DashBoard';
import DetailAutoRewards from '../components/feature/reward/AutoRewards/DetailAutoReward/DetailAutoRewards';
import DetailBlockout from '../components/feature/booking/DetailBlockout/DetailBlockout';
import DetailConfigureReward from '../components/feature/reward/ConfigureRewards/DetailConfigureRewards/DetailConfigureReward';
import DetailDsoBlockout from '../components/feature/manage/DsoBlockout/DetailDsoBlockout/DetailDsoBlockout';
import DetailRedeemReward from '../components/feature/reward/RedeemRewards/DetailRedeemReward/DetailRedeemReward';
import DetailService from '../components/feature/service/DetailService/DetailService';
import DetailStaff from '../components/feature/staff/DetailStaff/DetailStaff';
import Details from '../components/feature/patient/Details/Details';
import Faq from '../components/feature/manage/Faq/Faq';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import InsuranceDetail from '../components/feature/insurance/IsuranceDetail/InsuranceDetail';
import InsuranceDetails from '../components/feature/patient/InsuranceDetail/InsuranceDetails';
import Insurances from '../pages/Insurances/Insurances';
import LandingCarousel from '../components/feature/manage/LandingCarousel/LandingCarousel';
import LegalDocuments from '../components/feature/manage/LegalDocuments/LegalDocuments';
import Links from '../components/feature/manage/Links/Links';
import ListDsoBlockout from '../components/feature/manage/DsoBlockout/ListDsoBlockout/ListDsoBlockout';
import LoginScreen from '../pages/LoginScreen/LoginScreen';
import MainLayout from '../layout/mainLayout';
import Manage from '../pages/Manage/Manage';
import { MyGlobalContext } from '../utilities/sessionStorage';
import PageNotFound from '../pages/PageNotFound';
import PasswordScreen from '../pages/PasswordScreen/PasswordScreen';
import Patient from '../pages/Patient';
import RedeemReward from '../components/feature/reward/RedeemRewards/RedeemReward/RedeemReward';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import RewardCategories from '../components/feature/manage/RewardCategories/RewardCategories';
import RewardDetails from '../components/feature/manage/RewardCategories/RewardDetails';
import Rewards from '../pages/Rewards/Rewards';
import RoutePaths from './RoutePaths';
import Services from '../pages/Services/Services';
import ServicesIconLibrary from '../components/feature/manage/ServicesIconLibrary/ServicesIconLibrary';
import Staff from '../pages/Staff/Staff';
import UpdatePassword from '../components/feature/settings/UpdatePassword/UpdatePassword';
import UpdateSocialLinks from '../components/feature/manage/Links/SocialLinks/UpdateSocialLinks/UpdateSocialLinks';
import Videos from '../components/feature/manage/Videos/Videos';
import { ViewClinic } from '../components/feature/clinic/ViewClinic/ViewClinic';
import ViewEditDeleteVideoLink from '../components/feature/manage/Videos/ViewEditDeleteVideo';
import ViewFaq from '../components/feature/manage/Faq/ViewEditDeleteFaq/ViewEditDeleteFaq';
import ViewLinkDetail from '../components/feature/manage/Links/OtherLinks/ViewLinkDetail/ViewLinkDetail';
import ViewNppwrk from '../components/feature/manage/Nppwrk/ViewNppwrk/ViewNppwrk';
import ViewReferFriend from '../components/feature/manage/ReferFriend/ViewReferFriend/ViewReferFriend';
import AddElectiveProcedure from '../components/feature/reward/ElectiveProcedure/AddElectiveProcedure';

// eslint-disable-next-line react/display-name
export default (): ReactElement => {
  const [userData, setUserData] = useState({
    createdAt: '',
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isActive: false,
    isEditable: false,
    status: '',
    roles: [],
    isAllClinicsAccess: false,
    clinics: [],
    profileImageDetails: { expiresInSeconds: 0, isSignedURL: false, location: '', s3Key: '' },
  });
  return (
    <Routes>
      <Route path={RoutePaths.Login} element={<LoginScreen />} />
      <Route path={RoutePaths.ForgotPassword} element={<ForgotPassword />} />
      <Route
        element={
          <ValidatePasswordRoute>
            <PasswordScreen />
          </ValidatePasswordRoute>
        }
      >
        <Route path='/set-password' element={<PasswordScreen />} />
      </Route>

      <Route
        element={
          <ValidatePasswordRoute>
            <ResetPassword />
          </ValidatePasswordRoute>
        }
      >
        <Route path='/reset-password' element={<ResetPassword />} />
      </Route>
      <Route
        element={
          <MyGlobalContext.Provider value={{ userData, setUserData }}>
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          </MyGlobalContext.Provider>
        }
      >
        <Route path='/dashboard' element={<DashBoard />} />
        <Route path='/staff' element={<Staff />}></Route>
        <Route path='/staff/add' element={<AddStaff />} />
        <Route path='/staff/:id' element={<DetailStaff />} />
        <Route path='/settings' element={<DetailStaff />} />
        <Route path='/clinics' element={<Clinics />} />
        <Route path='/clinics/add/:stage' element={<AddClinic />} />
        <Route path='/clinics/edit/:stage' element={<AddClinic />} />
        <Route path='/clinics/view/:stage' element={<AddClinic />} />
        <Route path='/clinics/add/' element={<AddClinic />} />
        <Route path='/clinics/detail/:id' element={<ViewClinic />} />
        <Route path='/clinics/add/booking' element={<AddBlockout />} />
        <Route path='/clinics/view/booking' element={<Booking />} />
        <Route path='/clinics/view/booking/blockout/' element={<DetailBlockout />} />
        <Route path='/services' element={<Services />} />
        <Route path='/services/add' element={<AddService />} />
        <Route path='/services/detail/:id' element={<DetailService />} />
        <Route path='/patient' element={<Patient />} />
        <Route path='/patient/:id' element={<Details />} />
        <Route path='/patient/:id/insurance-details' element={<InsuranceDetails />} />
        <Route
          path='/patient/:id/family-member/:fid/insurance-details'
          element={<InsuranceDetails />}
        />
        <Route
          path='/patient/:id/add-insurance/:firstName/:lastName'
          element={<AddPatientInsurance />}
        />
        <Route
          path='/patient/:id/family-member/:fid/add-insurance/:firstName/:lastName'
          element={<AddPatientInsurance />}
        />
        <Route
          path='/patient/:id/edit-insurance/:firstName/:lastName'
          element={<AddPatientInsurance />}
        />
        <Route
          path='/patient/:id/family-member/:fid/edit-insurance/:firstName/:lastName'
          element={<AddPatientInsurance />}
        />
        <Route path='/patient/:id/family-member/:fid' element={<Details />} />
        <Route path='/rewards' element={<Rewards />} />
        <Route path='/rewards/detail/:id' element={<DetailConfigureReward />} />
        <Route path='/rewards/redeem/detail/:id' element={<DetailRedeemReward />} />
        <Route path='/rewards/add' element={<AddConfigureRewards />} />
        <Route path='/rewards/elective-procedure/details/:id' element={<DetailAutoRewards />} />
        <Route path='/rewards/elective-procedure/add' element={<AddElectiveProcedure />} />
        <Route path='/rewards/redeem' element={<RedeemReward />} />
        <Route path='/insurances' element={<Insurances />} />
        <Route path='/insurances/detail/:id' element={<InsuranceDetail />} />
        <Route path='/insurances/add' element={<AddInsurance />} />
        <Route path='/manage' element={<Manage />} />
        <Route path='/manage/dso-blockouts' element={<ListDsoBlockout />} />
        <Route path='/manage/dso-blockouts/detail/:id' element={<DetailDsoBlockout />} />
        <Route path='manage/dso-blockouts/add' element={<AddDsoBlockout />} />
        <Route path='/manage/dso-nppwrk' element={<ViewNppwrk />} />
        <Route path='/manage/landing-carousel' element={<LandingCarousel />} />
        <Route path='/manage/videos' element={<Videos />} />
        <Route path='/manage/videos/:id' element={<ViewEditDeleteVideoLink />} />
        <Route path='/manage/videos/add' element={<AddVideo />} />
        <Route path='/manage/links' element={<Links />} />
        <Route path='manage/social-link/:id' element={<UpdateSocialLinks />} />
        <Route path='/manage/other-link/:id' element={<ViewLinkDetail />} />
        <Route path='/manage/other-link/add' element={<AddOtherLinks />} />
        <Route path='/manage/legal-documents' element={<LegalDocuments />} />
        <Route path='/manage/refer-friend' element={<ViewReferFriend />} />
        <Route path='/manage/reward-categories' element={<RewardCategories />} />
        <Route path='/manage/faq' element={<Faq />} />
        <Route path='/manage/faq/:id' element={<ViewFaq />} />
        <Route path='/manage/faq/add' element={<AddFaq />} />
        <Route path='/manage/appointment-notifications' element={<AppointmentNotifications />} />
        <Route path='/manage/appointment-notifications/add' element={<AddNotifications />} />
        <Route path='/manage/appointment-notifications/edit' element={<AddNotifications />} />
        <Route
          path='/manage/reward-categories/reward-category-details'
          element={<RewardDetails />}
        />
        <Route path='/manage/services-icon-library' element={<ServicesIconLibrary />} />
        <Route path='/settings/update-password' element={<UpdatePassword />} />
      </Route>
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/' element={<LoginScreen />} />
      <Route path='/*' element={<PageNotFound />} />
    </Routes>
  );
};
