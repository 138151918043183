/*
 * File: interceptor.tsx
 * Project: mint-portal
 * File Created: Monday, 27th June 2022 8:58:47 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 31st October 2022 6:06:09 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Constants } from '../utilities/constants';
import axios from './axios';
// import React from 'react';
// import { getUserCurrentSession } from '../service/amplify.service';

axios.interceptors.request.use(
  async (config: any) => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('Something went wrong, please refresh.');
    }
    if (
      config.headers['Content-Type'] !== Constants.HEADERS_CONTENT_TYPE.imageType &&
      config.headers['Content-Type'] !== Constants.HEADERS_CONTENT_TYPE.pdfType
    ) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// const generateNewToken = async () => {
//   return await getUserCurrentSession();
// };

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const token = sessionStorage.getItem('token');
    if (error.response === undefined) {
      return Promise.reject(error);
    }
    const {
      response: { status },
    } = error;
    if (error.code === Constants.API_STATUS_CODE.ERR_NETWORK) {
      return Promise.reject('Network Error');
    }
    // const originalRequest = config;
    if (status === 401 && token !== undefined) {
      window.location.replace(window.location.origin + '/login');
      // return generateNewToken()
      //   .then((response: any) => {
      //     console.log((response))
      //     if (response?.jwtToken) {
      //       alert('login again' + response)

      //       sessionStorage.setItem('token', response.jwtToken);
      //       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      //       return axios(originalRequest);
      //     }
      //   })
      //   .catch(() => {
      //     return Promise.reject(error);
      //   });
    }
    return Promise.reject(error);
  },
);
