/*
 * File: CustomNumericField.tsx
 * Project: mint-portal
 * File Created: Wednesday, 10th August 2022 3:21:52 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th June 2023 8:00:10 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { styled, TextField } from '@material-ui/core';
import React from 'react';
import { zeplinColor } from '../../theme';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, InputAdornment } from '@mui/material';

interface FieldProps {
  value?: number;
  name?: string;
  label?: string;
  width?: any;
  placeholder?: any;
  type?: any;
  error?: boolean;
  disabled?: boolean;
  onChange?: (props: any) => any;
  onIncrement?: () => any;
  onDecrement?: () => any;
  handleChange?: () => void;
  icon?: boolean;
  min?: number;
  marginTop?: string;
  onKeyPress?: (params: any) => any;
  onPaste?: (e: any) => void;
  left?: number | string;
  dynamicValue?: boolean;
}

const CustomNumericField = styled(TextField)({
  '& .MuiFormLabel-root': {
    padding: '0px 0px 0px 10px',
  },

  '& .MuiTextField-root': {
    '& label': {
      color: zeplinColor.Primary,
    },
  },
  '& .MuiInputBase-input': {
    padding: '0px 0px 23px 8px',
  },
  backgroundColor: zeplinColor.Background,
});

const CustomNumericInput: React.FC<FieldProps> = ({
  name,
  placeholder,
  label,
  width,
  value,
  error,
  disabled,
  onChange,
  onIncrement,
  onDecrement,
  icon = true,
  min = 15,
  marginTop = '32px',
  onKeyPress,
  onPaste,
  left,
  dynamicValue,
}) => {
  React.useEffect(() => {
    const handleWheel = (e: any) => {
      e.preventDefault();
      e.currentTarget.querySelector('input')?.blur();
    };
    document.getElementById('numeric')?.addEventListener('wheel', handleWheel);
  });

  const handleScroll = (e: any) => {
    window.addEventListener(
      'wheel',
      () => {
        e.preventDefault();
      },
      { passive: false },
    );
  };

  return icon ? (
    <CustomNumericField
      id='numeric'
      style={{
        margin: 1,
        width: width,
        left: left ? left : '9px',
        marginTop: marginTop,
        backgroundColor: zeplinColor.Background,
      }}
      label={label}
      value={value}
      defaultValue={value ? value : ''}
      error={error}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      type={'number'}
      variant='standard'
      onKeyDown={onKeyPress}
      onPaste={onPaste}
      onWheel={handleScroll}
      InputProps={{
        inputProps: { min: min || min === 0 ? min : '' },
        endAdornment: icon ? (
          <InputAdornment
            position='end'
            sx={{ display: 'flex', flexDirection: 'column', height: 'auto', gap: '1px' }}
          >
            <IconButton
              style={{
                backgroundColor: zeplinColor.Background90,
                borderRadius: 5,
                width: 15,
                height: 15,
              }}
              //   onClick={ handleChange}
              onClick={onIncrement}
            >
              <KeyboardArrowUpIcon sx={{ width: 15, height: 15 }} />
            </IconButton>
            <IconButton
              style={{
                backgroundColor: zeplinColor.Background90,
                borderRadius: 5,
                width: 15,
                height: 15,
              }}
              onClick={onDecrement}
              disabled={value !== undefined && value <= 15 ? true : false}
            >
              <KeyboardArrowDownIcon sx={{ width: 15, height: 15 }} />
            </IconButton>
          </InputAdornment>
        ) : (
          ''
        ),
      }}
    />
  ) : dynamicValue ? (
    <CustomNumericField
      id='numeric'
      style={{
        margin: 1,
        width: width,
        left: '9px',
        marginTop: marginTop,
        backgroundColor: zeplinColor.Background,
      }}
      label={label}
      value={value ? value : ''}
      defaultValue={value ? value : ''}
      error={error}
      disabled={disabled}
      name={name}
      placeholder={dynamicValue ? '' : placeholder}
      onChange={onChange}
      type={'number'}
      variant='standard'
      onKeyDown={onKeyPress}
      onPaste={onPaste}
      onWheel={handleScroll}
      InputProps={{
        inputProps: { min: min || min === 0 ? min : '' },
      }}
    />
  ) : (
    <CustomNumericField
      id='numeric'
      style={{
        margin: 1,
        width: width,
        left: '9px',
        marginTop: marginTop,
        backgroundColor: zeplinColor.Background,
      }}
      label={label}
      // value={value}
      defaultValue={value ? value : ''}
      error={error}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      type={'number'}
      variant='standard'
      onKeyDown={onKeyPress}
      onPaste={onPaste}
      onWheel={handleScroll}
      InputProps={{
        inputProps: { min: min || min === 0 ? min : '' },
      }}
    />
  );
};

export default CustomNumericInput;
