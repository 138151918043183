/*
 * File: NavCard.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 12:17:57 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 22nd August 2022 1:10:54 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './NavCard.css';

import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@material-ui/core';
import { zeplinColor } from '../../../../../theme';

type CardProps = {
  header: string;
  content: string;
  required?: boolean;
  currentPath: string;
  path: string;
};

export default function NavCard({ header, content, required, currentPath, path }: CardProps) {

  return (
    <Card
      sx={{
        borderColor: path === currentPath ? zeplinColor.Primary : zeplinColor.Background90,
        backgroundColor: zeplinColor.Surface,
      }}
      className={`Card ${path === currentPath && 'selected'}`}
      elevation={0}
    >
      <CardContent className={`Card-header `} id={`${header}`}>
        <Typography variant='h6' id={`${header}`}>
          {header}
          <span style={{ color: zeplinColor.Error }}>{required ? '*' : ''}</span>
        </Typography>
      </CardContent>
      <CardContent className='CardContent' id={`${header}`}>
        <Typography variant='body1' style={{ color: zeplinColor.Background50 }} id={`${header}`}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
