/*
 * File: analytics.service.tsx
 * Project: mint-portal
 * File Created: Monday, 14th November 2022 7:56:11 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 17th November 2022 8:17:47 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import axios from '../core/axios';
import {
  IAppointmentAnalyticsInsuranceRO,
  IAppointmentAnalyticsRO,
  IAppointmentDetailsListRO,
  IAppointmentDetailsQueryParams,
  IAppointmentInsuranceQueryParams,
  IAppointmentPatientGenderAnalyticsRO,
  IDashboardQueryParams,
  IDashBoardServiceRO,
} from '../model/analytics.model';

import { ApiConstants } from './api-constants';

export class AnalyticsService {
  static getAppintmentDetailsList = async (
    queryParams: IAppointmentDetailsQueryParams,
  ): Promise<IAppointmentDetailsListRO> => {
    return await (
      await axios.get(
        ApiConstants.ANALYTICS_API.LIST_APPOINTMENT_DETAIL.URL() +
          ApiConstants.ANALYTICS_API.LIST_APPOINTMENT_DETAIL.QUERY_PARAMS(queryParams),
      )
    ).data;
  };
  static getInsuranceAnalytics = async (
    queryParams: IAppointmentInsuranceQueryParams,
  ): Promise<IAppointmentAnalyticsInsuranceRO> => {
    return await (
      await axios.get(
        ApiConstants.ANALYTICS_API.GET_INSURANCE_ANALYTICS.URL() +
          ApiConstants.ANALYTICS_API.GET_INSURANCE_ANALYTICS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };
  static getServiceBreakDown = async (
    params: IDashboardQueryParams,
  ): Promise<IDashBoardServiceRO> => {
    return await (
      await axios.get(
        ApiConstants.ANALYTICS_API.SERVICE_BREAKDOWN.URL() +
          ApiConstants.ANALYTICS_API.SERVICE_BREAKDOWN.QUERY_PARAMS(params),
      )
    ).data;
  };
  static getAppointmentPatientGenderAnalytics = async (
    queryParams: IDashboardQueryParams,
  ): Promise<IAppointmentPatientGenderAnalyticsRO> => {
    return await (
      await axios.get(
        ApiConstants.ANALYTICS_API.GET_APPOINTMENT_PATIENT_GENDER_ANALYTICS.URL() +
          ApiConstants.ANALYTICS_API.GET_APPOINTMENT_PATIENT_GENDER_ANALYTICS.QUERY_PARAMS(
            queryParams,
          ),
      )
    ).data;
  };
  static getAppointmentAnalytics = async (
    queryParams: IDashboardQueryParams,
  ): Promise<IAppointmentAnalyticsRO> => {
    return await (
      await axios.get(
        ApiConstants.ANALYTICS_API.GET_APPOINTMENT_ANALYTICS.URL() +
          ApiConstants.ANALYTICS_API.GET_APPOINTMENT_ANALYTICS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };
}
