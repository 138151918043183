/*
 * File: ClinicListPopup.tsx
 * Project: mint-portal
 * File Created: Monday, 1st August 2022 12:38:28 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 29th September 2022 12:05:31 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import * as React from 'react';

import { Box, Typography } from '@material-ui/core';

import CloseIcon from '@mui/icons-material/Close';
import { Constants } from '../../utilities/constants';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch } from 'react';
import IconButton from '@mui/material/IconButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from './Loader/Loader';
import { styled } from '@mui/material/styles';
import { zeplinColor } from '../../theme';

type modalProps = {
  title: string;
  description: string;
  open: boolean;
  assignedClinic: any;
  setOpen: Dispatch<boolean>;
  staff?:boolean;
  adminTotal?:number;
  getAdminStaff?: () => Promise<void>;
  showLoader?:boolean;
  setHeight?:React.Dispatch<React.SetStateAction<number>>;
  height?:number;
};

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3, 1, 3),
    maxHeight: '26.5rem',
    minWidth: '35rem',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogBoxTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1 0 1 0' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: zeplinColor.Background0,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ListPopup({
  title,
  description,
  open,
  assignedClinic,
  setOpen,
  staff,
  adminTotal,
  getAdminStaff,
  showLoader=false,
  // setHeight,
  // height
}: modalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  const [profileSuccess, setProfileSuccess] = React.useState(false);
  // React.useEffect(()=>{
  //   document.getElementById('scroll')?.addEventListener('scroll',()=>{
  //     const pos=document.getElementById('scroll')?.scrollTop;
  //     const diff=height && pos ? height-pos:0
  //     setHeight && diff<50 && setHeight(height ? height*2:220)
  //     console.log({adminTotal,diff,getAdminStaff,pos,height})
     
  //     if((diff<190)  && getAdminStaff){
  //       getAdminStaff()
  //     }
  //     // console.log(pos,height,diff,(diff<140),adminTotal , adminTotal>assignedClinic?.length  )
      
  //     console.log(adminTotal)
  //   })
   
  // },[])

  return (
    <div>
      <DialogBox onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogBoxTitle id='customized-dialog-title' onClose={handleClose}>
          <Typography variant='h5'>{title}</Typography>
        </DialogBoxTitle>
        <Typography style={{ padding: '8px 24px 8px 24px' }}>{description}</Typography>
       
        <DialogContent id='scroll' >
          <InfiniteScroll
            dataLength={assignedClinic?.length}
            next={getAdminStaff ? getAdminStaff:()=>{return}}
            hasMore={adminTotal ? adminTotal>assignedClinic?.length:false}
            loader={<h4 style={{display:'none'}}></h4>}
            height={220}
            style={{display:'flex',flexDirection:'column',gap:10}}
          >
            {showLoader ? <Loader margin={false}/>: assignedClinic?.map((clinicList:any) => (
              <div key={clinicList.id} >
                <Box style={{display:'flex',justifyContent:'space-between',
                // height: '36px',
                  width: '100%',
                  padding: '10px 12px',
                  borderRadius: '8px',
                  backgroundColor: zeplinColor.Background97,alignItems:'center'}}>
                  <Box style={{display:'flex',alignItems:'center',gap:10}}>
                    <img
                      src={profileSuccess ? clinicList.profileImageDetails?.location: Constants.FALL_BACK_IMAGE}
                      // width='25px'
                      // height='25px'
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
            
                      }}
                      onError={() => setProfileSuccess(false)}
                    />
                    <Typography
                      gutterBottom
                    >
                      {staff ? `${clinicList?.firstName+' '+clinicList?.lastName}`:clinicList.name}
                    </Typography>
                  </Box>
                  {staff ?<Typography
                    gutterBottom
                  // style={{
                  //   height: '36px',
                  //   width: '100%',
                  //   padding: '8px 0px 0px 8px',
                  //   borderRadius: '8px',
                  //   backgroundColor: zeplinColor.Background97,
                  // }}
                  >
                    {staff ? `${clinicList?.roles[0].name}`:''}
                  </Typography>:''}
                </Box>
              </div>
            ))}
          </InfiniteScroll>
        </DialogContent>
        
      </DialogBox>
    </div>
  );
}
