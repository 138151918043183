/*
 * File: SearchBar
 * Project: mint-portal
 * File Created: Monday, 18th July 2022 9:24:55 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 31st January 2023 9:44:02 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { zeplinColor } from '../../theme';
import { FC, useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { Constants } from '../../utilities/constants';

interface IProps {
  onSearchHandler: any;
  searchValue?: string;
  placeholderText?: string;
  clear?: (reset: boolean, clear: boolean) => void;
  setTableCounter?: React.Dispatch<React.SetStateAction<number>>;
}

const Search = styled('div')(() => ({
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: `${zeplinColor.Primary50}`,
  // '&:hover': {
  //   backgroundColor: `${zeplinColor.Primary}`
  // },
  width: '260px',
  height: '36px',
  color: `${zeplinColor.Background0}`,
}));

const SearchIconWrapper = styled('div')(() => ({
  width: '24px',
  height: '24px',
  flexGrow: 0,
  color: `${zeplinColor.Primary}`,
  margin: '6px 12px',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    height: '24px',
    flexGrow: '0',
    opacity: '0.42',
    fontSize: '16px',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    textAlign: 'left',
    paddingLeft: '40px',
    width: '196px',
    color: `${zeplinColor.Background0}`,
  },
}));

const SearchBar: FC<IProps> = ({
  onSearchHandler,
  searchValue,
  placeholderText,
  clear,
  setTableCounter,
}) => {
  const [searchVal, setSearchVal] = useState(false);
  const [crossIcon, setCrossIcon] = useState(false);
  const onSearch = (inputVal: string) => {
    inputVal ? setCrossIcon(true) : setCrossIcon(false);
    setSearchVal(false);
    onSearchHandler(inputVal.trimStart());
  };
  const handleClearSearch = () => {
    setCrossIcon(false);
    setSearchVal(true);
    onSearchHandler('');
    clear && clear(false, true);
    setTableCounter && setTableCounter(Constants.DASHBOARD_LIMIT);
  };

  useEffect(() => {
    if (searchValue === '') {
      setCrossIcon(false);
    }
  }, [searchValue]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeholderText ? placeholderText : 'Search…'}
          inputProps={{ 'aria-label': 'search' }}
          value={searchVal ? '' : searchValue}
          endAdornment={
            <InputAdornment position='start'>
              {crossIcon && (
                <ClearIcon
                  onClick={handleClearSearch}
                  sx={{
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                    color: zeplinColor.Background60,
                  }}
                />
              )}
            </InputAdornment>
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
        />
      </Search>
    </Box>
  );
};

export default SearchBar;
