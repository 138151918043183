/*
 * File: CustomCard.tsx
 * Project: mint-portal
 * File Created: Monday, 12th September 2022 10:41:44 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 29th September 2022 5:58:11 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
// @flow
import * as React from 'react';
import { zeplinColor } from '../../../theme';
import './CustomCard.css';
type Props = {
  children?: React.ReactNode;
};
export const CustomCard = ({ children }: Props) => {
  return (
    <div
      className='view-clinic-card'
      style={{ backgroundColor: zeplinColor.Surface, border: zeplinColor.Background90 }}
    >
      {children}
    </div>
  );
};
