/*
 * File: getTime.ts
 * Project: mint-portal
 * File Created: Wednesday, 7th September 2022 10:29:08 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 1st March 2023 5:10:21 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import moment from 'moment';

// dropdown list in 12 hour format with AM/PM
export const getTime = () => {
  const x = 15; // minutes interval
  const times = []; // time array
  let tt = 0; // start time
  const ap = [' AM', ' PM']; // AM-PM

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ('' + (hh == 12 ? 12 : '0' + (hh % 12))).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  return times;
};

// Formats API response for time recieved
// in 24 hour format ("13:45") to 12 hour format
// ("01:45 PM")
export const formatTime = (time: string) => {
  const militaryTime = ['00:00', '00:15', '00:30', '00:45'];
  if (militaryTime.includes(time)) {
    // to fix '12:00' to show as '00:00 AM'
    return time + ' AM';
  }
  return moment(time, ['HH:mm']).format('hh:mm A');
};
