/*
 * File: ReferFriend.tsx
 * Project: mint-portal
 * File Created: Monday, 31st October 2022 8:59:34 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:01 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import './ViewReferFriend.css';
import { Typography, Toolbar, Button } from '@material-ui/core';
import { Paper } from '@mui/material';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../shared/Buttons';
import { ManageService } from '../../../../../service/manage.service';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../shared/Loader/Loader';
import { IReferralDetailResponse, IReferralDetailRO } from '../../../../../model';
import getPermissionForModule from '../../../../../utilities/getPermission';
import { zeplinColor } from '../../../../../theme';
import EditReferFriend from '../EditReferFriend/EditReferFriend';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { Constants } from '../../../../../utilities/constants';
import { ReferFriend } from '../../../../../pages/Manage/ManageCardConstants';

const ViewReferFriend = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [referralDetails, setReferralDetails] = useState<IReferralDetailResponse>({
    referredByPoints: 0,
    referredToPoints: 0,
  });
  const [showLoader, setShowLoader] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<IReferralDetailResponse>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const onCancelEdit = () => {
    setEdit(false);
    setEditBtn(false);
  };
  useEffect(() => {
    setShowLoader(true);
    getReferFriend();
  }, []);

  const getReferFriend = async () => {
    try {
      const response: IReferralDetailRO = await ManageService.getReferralDetails();
      setReferralDetails(response.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const updateReferFriendPoints = async () => {
    if (updatePayload) {
      setUpdateLoader(true);
      try {
        const referralRes = await ManageService.updateReferralDetails(updatePayload);
        setReferralDetails(referralRes.data);
        if (referralRes?.data) {
          setShowSnackbar(true);
          setSnackbarMessage(`Points updated`);
          setEdit(false);
        }
      } catch (err) {
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      } finally {
        setShowLoader(false);
        setUpdateLoader(false);
      }
    }
  };

  return (
    <>
      {isForbiddenAccess ||
        (!ReferFriend.roleAccess.includes(roleId) && (
          <EmptyState
            path={``}
            text={'Sorry, you don’t have access to this section'}
            subText={''}
            buttonText={''}
            showAddButton={false}
            image={true}
            removeImage={false}
            forbiddenAccess={true}
          />
        ))}
      {showLoader && <Loader />}
      {!isForbiddenAccess && ReferFriend.roleAccess.includes(roleId) && (
        <div>
          <div className='referrerHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='referrerTitle' color='inherit'>
                Refer a Friend
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Refer a Friend'} />
              </Typography>
              {ReferFriend.roleAccess.includes(roleId) && !showLoader && referralDetails && (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                  {!edit ? (
                    <Buttons
                      text='EDIT DETAILS'
                      width='100%'
                      disable={false}
                      onClick={() => setEdit(!edit)}
                    />
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        disabled={showLoader ? true : false}
                        style={{
                          width: '8em',
                          height: '34px',
                          backgroundColor: `${zeplinColor.Background97}`,
                          border: `1px solid ${zeplinColor.Background70}`,
                          color: `${zeplinColor.Background70}`,
                        }}
                        onClick={onCancelEdit}
                      >
                        Cancel
                      </Button>
                      <Buttons
                        text='SAVE CHANGES'
                        width='100%'
                        disable={editBtn || showLoader ? true : false}
                        onClick={updateReferFriendPoints}
                      />
                    </>
                  )}
                </div>
              )}
            </Toolbar>
          </div>
          {edit ? (
            <div>
              <EditReferFriend
                edit
                referralDetails={referralDetails}
                setUpdatePayload={setUpdatePayload}
                updateLoader={updateLoader}
                setEditBtn={setEditBtn}
              />
            </div>
          ) : (
            <>
              {!showLoader && !edit && (
                <Paper className='referrerDetailBox' sx={{ borderRadius: '16px' }}>
                  <div
                    className='referrerDetail'
                    style={{ display: 'flex', flexDirection: 'column', gap: 32 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        marginTop: '32px',
                      }}
                    >
                      <Typography variant='subtitle1'>Points earned by the Referrer</Typography>
                      <Typography>{referralDetails?.referredByPoints || '0'}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                      <Typography variant='subtitle1'>Points earned by the Referee</Typography>
                      <Typography>{referralDetails?.referredToPoints || '0'}</Typography>
                    </div>
                  </div>
                </Paper>
              )}
            </>
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ViewReferFriend;
