/*
 * File: UpdateSocialLinks.tsx
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 8:20:45 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Friday, 16th June 2023 5:53:12 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import { ManageService } from '../../../../../../service/manage.service';
import { useNavigate, useParams } from 'react-router-dom';
import { ILinkAddPayload } from '../../../../../../model';
import { Constants } from '../../../../../../utilities/constants';
import { zeplinColor } from '../../../../../../theme';
import { Box, Button, Typography } from '@material-ui/core';
import CustomTextField from '../../../../../shared/CustomTextField';
import { Avatar, Paper } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { FileService } from '../../../../../../service/file.service';
import EmptyState from '../../../../../shared/EmptyState/EmptyState';
import { Links } from '../../../../../../pages/Manage/ManageCardConstants';
import Loader from '../../../../../shared/Loader/Loader';
import Buttons from '../../../../../shared/Buttons';
import BreadCrumbs from '../../../../../shared/BreadCrumbs/BreadCrumbs';
import getPermissionForModule from '../../../../../../utilities/getPermission';
import SnackBar from '../../../../../shared/Snack-Bar/snackBar';
import './UpdateSocialLinks.css';

const UpdateSocialLinks = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [payLoad, setPayload] = useState({ title: '', url: '' });
  const [s3Key, setS3Key] = useState('');
  const [imageUrl, setImageUrl] = useState<string>();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: `/manage/links`, label: 'Links' },
    { path: `/manage/links`, label: 'Social Media' },
  ];

  const handleText = (e: { target: { name: string; value: string } }) => {
    setPayload({ ...payLoad, [e.target.name]: e.target.value });
  };

  const handleIconUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      setShowLoader(true);
    }
    const imageUploadRes = await FileService.getSignedUrlToUploadFile(
      Constants.FILE_UPLOAD_CATEGORY.LINK_ICON_IMAGES,
      e.target.files[0],
      { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
    );
    setS3Key(imageUploadRes?.signedUrl?.data?.data?.s3Key);
    setShowLoader(false);
  };

  const getSocialLinkDetailsById = async () => {
    setShowLoader(true);
    try {
      if (id) {
        const res = await ManageService.getLinkById(+id);
        setPayload({ title: res?.data?.title, url: res?.data?.url });
        setS3Key(res?.data?.imageDetails?.s3Key);
        setImageUrl(res?.data?.imageDetails?.location);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const updateSocialLink = async () => {
    setShowLoader(true);
    const updatePayLoad: ILinkAddPayload = {
      title: payLoad.title,
      url: payLoad.url,
      s3Key: s3Key,
    };
    try {
      if (id) {
        await ManageService.updateLink(updatePayLoad, +id);
        await getSocialLinkDetailsById();
        sessionStorage.setItem('updatedSocialLink', 'updatedLink');
        navigate('/manage/links');
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getSocialLinkDetailsById();
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      {!Links.roleAccess.includes(roleId) && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {Links.roleAccess.includes(roleId) && (
        <Box sx={{ width: '76.5vw', margin: '32px 0px 32px 24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            <Box>
              <Typography variant='h5'>Update Link</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Update Link'} />
            </Box>
            <Box>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant='outlined'
                  disabled={showLoader ? true : false}
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() =>
                    navigate({
                      pathname: `/manage/links`,
                    })
                  }
                >
                  Cancel
                </Button>
                <Buttons
                  text='SAVE CHANGES'
                  width='162px'
                  disable={
                    Object.values(payLoad).some((d) => !d) || !s3Key || showLoader ? true : false
                  }
                  onClick={updateSocialLink}
                />
              </div>
            </Box>
          </Box>
          <Paper className='updateSocialLinkCard' sx={{ borderRadius: '16px' }}>
            <div className='updateSocialLinkDiv'>
              <div className='updateSocialLinkProfile'>
                <input
                  type='file'
                  name='image-upload'
                  id='input'
                  accept={Constants.IMAGE_TYPE}
                  onChange={handleIconUpload}
                />
                <label
                  htmlFor='input'
                  style={{ backgroundColor: zeplinColor.Surface, cursor: 'pointer' }}
                >
                  <Avatar
                    className='updateSocialLinkImageClass'
                    src={imageUrl as string}
                    style={{ backgroundColor: zeplinColor.Background90 }}
                  >
                    <ImageOutlinedIcon
                      style={{ color: zeplinColor.Background60, width: 45, height: 45 }}
                    />
                  </Avatar>
                  <div
                    className='updateSocialLinkIconClass'
                    style={{ backgroundColor: zeplinColor.Surface }}
                  >
                    {' '}
                    <CameraAltOutlinedIcon className='updateSocialLinkCameraIcon' />
                  </div>
                </label>

                <div className='imageText'>
                  <Typography variant='caption' color='textSecondary'>
                    Upload a 64 x 64 PNG or JPG icon here
                  </Typography>
                </div>
              </div>
              <CustomTextField
                name='title'
                label='Title*'
                multiline={true}
                value={payLoad.title}
                width={'584px'}
                onChange={handleText}
              />
              <CustomTextField
                name='url'
                label='URL*'
                multiline={true}
                value={payLoad.url}
                width={'584px'}
                onChange={handleText}
              />
            </div>
          </Paper>
          {showSnackBar && (
            <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
          )}
        </Box>
      )}
    </>
  );
};
export default UpdateSocialLinks;
