/*
 * File: Insurance.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:45:54 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:12:07 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)

 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useEffect, useState } from 'react';
import { InsuranceListRO, InsuranceListQueryParams, InsuranceListAPIResponse } from '../../model';
import { Constants } from '../../utilities/constants';
import { Typography, Toolbar } from '@material-ui/core';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import { useNavigate } from 'react-router-dom';
import './Insurances.css';
import Loader from '../../components/shared/Loader/Loader';
import Buttons from '../../components/shared/Buttons';
import { InsuranceService } from '../../service/insurance.service';
import ListInsurance from '../../components/feature/insurance/ListInsurance/ListInsurance';
import SearchBar from '../../components/shared/SearchBar';
import InfoTextBox from '../../components/shared/InfoText/InfoText';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../utilities/getPermission';

const Insurance: FC = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Insurances;
  const deletedInsurance = sessionStorage.getItem('deletedInsurance');
  const createdInsurance = sessionStorage.getItem('addedInsurance');
  const navigate = useNavigate();
  const [insuranceList, setInsuranceList] = useState<InsuranceListRO>({ total: 0, insurances: [] });
  const [dataPage, setDataPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarmsg, setShowSnackbarMsg] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  /**
   * Triggered from ListStaff component on page change
   * sets the page number accordingly
   * @param {number} page
   */
  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
    setDataPage(1);
  };

  useEffect(() => {
    if (deletedInsurance) {
      setShowSnackbar(true);
      setShowSnackbarMsg(`${deletedInsurance} is deleted`);
      sessionStorage.removeItem('deletedInsurance');
    }
    if (createdInsurance) {
      setShowSnackbar(true);
      setShowSnackbarMsg(`${createdInsurance} is added`);
      sessionStorage.removeItem('addedInsurance');
    }
    setShowLoader(true);
    getInsuranceList();
  }, [dataPage, searchValue]);

  const getInsuranceList = async () => {
    if (searchValue && searchValue.length < 3) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const listInsuranceQuery: InsuranceListQueryParams = {
        limit,
        skip,
        ...(searchValue &&
          searchValue.length >= 3 && {
          searchString: searchValue,
          skip: dataPage !== 1 ? skip : 0,
        }),
      };
      const response: InsuranceListAPIResponse = await InsuranceService.getInsuranceList(
        listInsuranceQuery,
      );
      setInsuranceList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMsg(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && <Loader />}

      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {!isForbiddenAccess && (
        <div className='insuranceContainer'>
          <div className='insuranceHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='insuranceTitle' color='inherit'>
                Insurances
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <SearchBar
                  onSearchHandler={onSearch}
                  placeholderText={'Search by Insurance Name'}
                />
                {permission.create && (
                  <Buttons
                    text='Add new insurance'
                    width='15em'
                    disable={false}
                    onClick={() => navigate('/insurances/add')}
                  />
                )}
              </div>
            </Toolbar>
            <div style={{ margin: '33px 0px 0px 20px' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.INSURANCE_INFO_TEXT}
                show={true}
                width={'76.5vw'}
              />
            </div>
          </div>

          {permission.read && insuranceList.total === 0 && !showLoader ? (
            <div style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/insurances/add'}
                text={'No insurances to show'}
                subText={permission.create ? 'Get started by clicking on Add New Insurance.' : ''}
                buttonText={permission.create ? 'ADD NEW INSURANCE' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </div>
          ) : (
            permission.read &&
            !showLoader && (
              <ListInsurance
                insuranceList={insuranceList}
                onPageChangeHandler={onDataPageChange}
                currentPage={dataPage}
              />
            )
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarmsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default Insurance;
