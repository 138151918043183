/*
 * File: PageNotFound.tsx
 * Project: mint-portal
 * File Created: Wednesday, 1st June 2022 3:01:43 pm
 * Author: priyagupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 1st June 2022 3:02:51 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      <h1>PAGE NOT FOUND...!!</h1>
    </div>
  );
};

export default PageNotFound;
