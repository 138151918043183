/*
 * File: FilterClinicPopup.tsx
 * Project: mint-portal
 * File Created: Monday, 22nd August 2022 7:15:04 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 19th September 2022 12:05:28 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';

import { Dispatch, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FilterParams } from '../../../../model/clinic.model';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { zeplinColor } from '../../../../theme';

type filterModalProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  onFilterClick: Dispatch<boolean>;
  selectedChips: object;
};

const useStyles = makeStyles(() => ({
  paper: { minWidth: '600px' },
  displayColumn: {
    display: 'flex', flexDirection: 'column'
  },
  displayRow: {
    display: 'flex', flexDirection: 'row'
  }
}));

const ToggleButton = styled(MuiToggleButton)({
  height: '34px',
  color: zeplinColor.Background50,
  borderRadius: '4px',
  border: `1px solid ${zeplinColor.Background50}`,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: `${zeplinColor.Primary}`,
    border: `1px solid ${zeplinColor.Primary}`,
    backgroundColor: zeplinColor.Surface
  }
});


const FilterClinicPopup = ({ open, setOpen, onFilterClick, selectedChips }: filterModalProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<FilterParams | any>(selectedChips ||
    { active1: false, offline: false, accepting: false, notAccepting: false, connected: false, disconnected: false  });
  const { active1, offline, accepting, notAccepting, connected, disconnected } = selected;

  React.useEffect(() => {
    if (selectedChips) {
      
      setSelected(selectedChips);
    }
  }, [selectedChips])

  const handleFilterOptions = (e: any) => {
    if(e.target.name !== undefined){
      const name = e.target.name;
      setSelected((prev: FilterParams) => ({
        ...prev,
        [name]: !selected[name],
      }));
    }
  };

  const handleClose = () => {
    if(selectedChips){
      setSelected(selectedChips);
    } else {
      setSelected({ active1: false, offline: false, accepting: false, notAccepting: false, connected: false, disconnected: false  })
    }
    setOpen(false);
  };

  const onFilter = () => {
    onFilterClick(selected);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'} >
            Filter Options
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className={classes.displayColumn} style={{ gap: '16px' }}>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  App Listings
                </Typography>
                <Typography component={'span'} variant='body2'>Filter clinics based on listing status</Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <ToggleButton disableRipple selected={active1} name='active1' onChange={handleFilterOptions} value='checked'
                  style={{ width: '115px' }}>{active1 && (<CheckIcon />)}Active</ToggleButton>
                <ToggleButton disableRipple selected={offline} name='offline' onChange={handleFilterOptions} value='checked'
                  style={{ width: '115px' }}>{offline && (<CheckIcon />)}Offline</ToggleButton>
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                    Bookings
                </Typography>
                <Typography component={'span'} variant='body2'>Filter clinics based on booking status</Typography>
              </div>
              <div className={classes.displayRow} style={{ gap: '16px' }}>
                <ToggleButton disableRipple selected={accepting} onChange={handleFilterOptions} value='checked' name='accepting'
                  style={{ width: '145px' }}>{accepting && (<CheckIcon />)} Accepting</ToggleButton>
                <ToggleButton disableRipple selected={notAccepting} name='notAccepting' value='checked' onChange={handleFilterOptions}
                  style={{ width: '181px' }}>{notAccepting && (<CheckIcon />)} Not Accepting</ToggleButton>
              </div>
            </div>
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                    Open Dental Status
                </Typography>
                <Typography component={'span'} variant='body2'>Filter clinics based on Open Dental connection</Typography>
              </div>
              <div className={classes.displayRow} style={{ gap: '16px' }}>
                <ToggleButton disableRipple selected={connected} onChange={handleFilterOptions} value='checked' name='connected'
                  style={{ width: '154px' }}>{connected && (<CheckIcon />)} Connnected</ToggleButton>
                <ToggleButton disableRipple selected={disconnected} name='disconnected' value='checked' onChange={handleFilterOptions}
                  style={{ width: '181px' }}>{disconnected && (<CheckIcon />)} Disconnected</ToggleButton>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{
            color: `${zeplinColor.Primary}`
          }} onClick={handleClose}>Cancel</Button>

          <Button onClick={onFilter}
            style={{
              color: `${zeplinColor.Primary}`
            }}>
            <Typography>
            Filter Results
            </Typography>
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
export default FilterClinicPopup;