/*
 * File: BarChart.tsx
 * Project: mint-portal
 * File Created: Tuesday, 15th November 2022 7:09:46 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 30th June 2023 2:47:42 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './BarChart.css';
import { Bar } from 'react-chartjs-2';
import zeplinColor, { dashboardColorTheme } from '../../../../theme/zeplinColor';
import CustomLegend from '../CustomLegend/CustomLegend';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
const BarChart = ({
  name,
  value,
  percentage = [0],
}: {
  name: string[];
  value: number[];
  percentage?: number[];
}) => {
  const options = {
    scales: {
      y: {
        suggestedMax: Math.max(...value) + 2,
        grid: {
          // borderDash: [8, 6],
          color: zeplinColor.Background90,
          drawTicks: false,
          drawBorder: false,
        },
        categoryPercentage: 0.5,
        ticks: {
          beginAtZero: true,
          // autoSkip: false,
          // maxTicksLimit: 5,
          // stepSize: 1,
          padding: 20,
          font: {
            size: 12,
            family: 'HelveticaNeue',
            weight: 'bold',
          },
          color: zeplinColor.Background50,
        },
      },

      x: {
        gridLines: {
          offsetGridLines: true,
        },
        grid: {
          borderDash: [6, 4],
          color: zeplinColor.Background90,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          color: '#f7aa54',
          display: false,
          // maxTicksLimit: 6,
        },
      },
    },
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end' as const,
        align: 'top' as const,
        formatter: Math.round,
        color: zeplinColor?.Background50,
        font: {
          size: 12,
        },
      },
      tooltip: {
        padding: 16,
        bodyFont: {
          family: 'HelveticaNeue',
          size: 14,
          weight: 'bold',
        },
        boxPadding: 10,
        callbacks: {
          title: function () {
            return '';
          },
          label: function (tooltipItem: any) {
            return tooltipItem?.label + '   ' + percentage[tooltipItem?.dataIndex] + '%';
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: '',
      },
    },
  };

  const [data, setData] = useState({
    labels: name,
    datasets: [
      {
        label: '1',
        data: value,
        backgroundColor: dashboardColorTheme.map((val) => val?.color),
        borderColor: dashboardColorTheme.map((val) => val?.color),
        barThickness: 24,
        legend: name,
        borderRadius: 5,
      },
    ],
  });

  useEffect(() => {
    setData({
      labels: name,
      datasets: [
        {
          label: '1',
          data: value,
          backgroundColor: dashboardColorTheme.map((val) => val?.color),
          borderColor: dashboardColorTheme.map((val) => val?.color),
          barThickness: 24,
          // barThickness:
          //   name.length > 15
          //     ? 35
          //     : name.length > 9
          //       ? 26 * 2
          //       : name.length > 8
          //         ? 24 * 3
          //         : name.length > 5
          //           ? 24 * 5
          //           : 24 * 6,
          legend: name,
          borderRadius: 5,
        },
      ],
    });
  }, [value, name]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          height: 290,
          // width:
          //   name.length > 9 ? '78vw' : name.length > 8 ? '77vw' : name.length > 5 ? '75vw' : '74vw',
          // position: 'relative',
          // left:
          //   name.length > 9 ? '-6%' : name.length > 8 ? '-4.5%' : name.length > 5 ? '-1.8%' : '-1%',
        }}
      >
        <Bar options={options} data={data} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 32,
        }}
        style={{ gap: 32 }}
      >
        <Typography variant='subtitle1'>Most Booked Services</Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4,1fr)',
            gridColumnGap: '48px',
            gridRowGap: '24px',
          }}
        >
          {value.map((val: number, index: number) =>
            index <= 9 ? (
              <Box key={index}>
                <CustomLegend
                  color={dashboardColorTheme.filter((val: any) => val?.id === index + 1)[0]?.color}
                  label={name[index]}
                  percentage={percentage[index]}
                />
              </Box>
            ) : (
              ''
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BarChart;
