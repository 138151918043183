/*
 * File: api-constants.tsx
 * Project: mint-portal
 * File Created: Tuesday, 28th June 2022 12:07:34 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 16th October 2023 5:30:27 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import {
  BookingStatus,
  IClinicQueryParamOptions,
  IListQueryParams,
  IPatientListQueryParams,
  IProviderByClinicIdQueryParamOptions,
  IQueryParam,
  IRewardQueryParam,
  IRewardRedeemHistoryQuery,
  IServiceOperatoriesQueryParamOptions,
  IStaffListQueryParams,
  InsuranceListQueryParams,
  InsurancesByClinicIdQueryParamOptions,
} from '../model';
import {
  IAppointmentDetailsQueryParams,
  IAppointmentInsuranceQueryParams,
  IDashboardQueryParams,
} from '../model/analytics.model';
import { IServiceClinicOnlyQuery, IServiceListQueryParams } from '../model/service.model';

import queryString from 'query-string';

export class ApiConstants {
  public static readonly STAFF_API = {
    LIST_STAFF: {
      URL: () => `staff/list?`,
      QUERY_PARAMS: (queryParams: IStaffListQueryParams): string =>
        queryString.stringify(queryParams),
    },
    UPDATE_STAFF: {
      URL: (id: string) => `staff?id=${id}`,
    },
    DETAIL_STAFF_ID: {
      URL: (id: string) => `staff?id=${id}`,
    },
    ADD_STAFF: {
      URL: () => `staff`,
    },
    SWITCH_STAFF_STATUS: {
      URL: (id: string, status: boolean) => `staff/switch/active-status?id=${id}&status=${status}`,
    },
    RESEND_INVITE: {
      URL: (id: string) => `staff/resend-invite?id=${id}`,
    },
    DELETE_STAFF: {
      URL: (id: string) => `staff?id=${id}`,
    },
  };
  public static readonly USER_API = {
    PROFILE: {
      URL: () => `staff/me`,
    },
  };
  public static readonly FILE_API = {
    UPLOAD_FILE: {
      URL: () => `file/upload`,
    },
    GET_SIGNED_URL_TO_UPLOAD_FILE: {
      URL: (category: number) => `file/signed-url/put-object?category=${category}`,
    },
  };
  public static readonly CLINIC_API = {
    LIST_CLINIC: {
      URL: () => `clinic/list?`,
      QUERY_PARAMS: (queryParams: IClinicQueryParamOptions): string =>
        queryString.stringify(queryParams),
    },
    LIST_BLOCKOUTS_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/blockout-list/${id}?`,
      QUERY_PARAMS: (queryParams?: IProviderByClinicIdQueryParamOptions): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    LIST_OPEN_DENTAL_BLOCKOUTS_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/open-dental-blockouts/${id}?`,
      QUERY_PARAMS: (queryParams?: IProviderByClinicIdQueryParamOptions): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_PROVIDERS_BY_CLINIC_ID: {
      URL: (clinicId: string) => `clinic/providers/${clinicId}?`,
      QUERY_PARAMS: (queryParams?: IProviderByClinicIdQueryParamOptions): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_SERVICE_MAP_OPERATORIES_BY_CLINIC_ID: {
      URL: () => `clinic/service/operatories?`,
      QUERY_PARAMS: (queryParams?: IServiceOperatoriesQueryParamOptions): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_ONGOING_BLOCKOUTS_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/blockout-ongoing/${id}`,
    },
    GET_OPERATORIES_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/operatories/${id}/list?`,
      QUERY_PARAMS: (queryParams:IProviderByClinicIdQueryParamOptions): string =>
        queryString.stringify(queryParams),
    },
    GET_BLOCKOUT_DETAILS_BY_ID: {
      URL: (clinicId: string, blockoutId: string) => `/clinic/blockout/${clinicId}/${blockoutId}`,
    },
    GET_OPEN_DENTAL_BLOCKOUT_DETAILS_BY_ID: {
      URL: (clinicId: string, blockoutId: string) =>
        `/clinic/open-dental-blockouts/${clinicId}/${blockoutId}`,
    },
    GET_NPPWK_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/nppw/${id}`,
    },
    VIEW_CLINIC: {
      URL: (id: string) => `clinic/view/${id}`,
    },
    SAVE_CLINIC: {
      URL: () => `clinic`,
    },
    SAVE_OPEN_DENTAL_CONFIG: {
      URL: (id: string) => `clinic/${id}/open-dental-configuration`,
    },
    GET_CLINIC_API_KEY: {
      URL: (clinicId: string) => `clinic/api-key/${clinicId}`,
    },
    REGENERATE_CLINIC_API_KEY: {
      URL: (clinicId: string) => `clinic/regenerate/api-key/${clinicId}`,
    },
    GET_OPEN_DENTAL_SYNC_STATUS: {
      URL: (id: string) => `clinic/${id}/open-dental-sync/status`,
    },
    SAVE_CLINIC_LOCATION: {
      URL: (id: string) => `clinic/${id}/business-location`,
    },
    SAVE_CLINIC_OPENING_HOURS: {
      URL: (id: string) => `clinic/${id}/availability`,
    },
    SAVE_CLINIC_PROVIDERS: {
      URL: (id: string) => `clinic/${id}/providers`,
    },
    SAVE_CLINIC_MAP_OPERATORIES: {
      URL: (id: string) => `clinic/${id}/services/map-operatories`,
    },
    SAVE_CLINIC_INSURANCES: {
      URL: () => `clinic/insurance/update`,
    },
    ADD_BLOCKOUT: {
      URL: (id: string) => `clinic/blockout/${id}`,
    },
    EDIT_CLINIC: {
      URL: (id: string) => `clinic/${id}`,
    },
    UPDATE_BLOCKOUT_DETAILS_BY_ID: {
      URL: (clinicId: string, blockoutId: string) => `/clinic/blockout/${clinicId}/${blockoutId}`,
    },
    UPDATE_CLINIC_DRAFT_STATUS: {
      URL: (id: string) => `clinic/${id}/change-draft-status`,
    },
    UPDATE_BOOKING_STATUS: {
      URL: (id: string, updateBookingStatus: BookingStatus) =>
        `clinic/${id}/booking-status?bookingStatus=${updateBookingStatus}`,
    },
    UPDATE_NPPW_BY_CLINIC_ID: {
      URL: (id: string) => `clinic/nppw/${id}`,
    },
    SWITCH_APP_LISTING: {
      URL: (id: string, switchStatus: boolean) =>
        `clinic/${id}/switch/app-listing?appListing=${switchStatus}`,
    },
    DELETE_BLOCKOUT: {
      URL: (clinicId: string, blockoutId: string) => `/clinic/blockout/${clinicId}/${blockoutId}`,
    },
    EDIT_CLINIC_SERVICES: {
      URL: () => `clinic/service/update`,
    },
    EDIT_CLINIC_STAFF: {
      URL: () => `clinic/staff/update`,
    },
  };
  public static readonly INSURANCE_API = {
    LIST_INSURANCE: {
      URL: () => `insurance/list?`,
      QUERY_PARAMS: (queryParams: InsuranceListQueryParams): string =>
        queryString.stringify(queryParams),
    },
    GET_INSURANCE_BY_ID: {
      URL: (id: string) => `insurance/${id}`,
    },
    GET_INSURANCES_BY_CLINIC_ID: {
      URL: () => `insurance/list/by-clinic?`,
      QUERY_PARAMS: (queryParams?: InsurancesByClinicIdQueryParamOptions): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    ADD_NEW_INSURANCE: {
      URL: () => `insurance`,
    },
    DELETE_INSURANCE: {
      URL: (id: number) => `insurance/${id}`,
    },
  };
  public static readonly SERVICE_API = {
    CREATE_SERVICE: {
      URL: (): string => `service`,
    },
    UPDATE_SERVICE_BY_ID: {
      URL: (serviceId: string): string => `service/${serviceId}`,
    },
    GET_SERVICE_DETAILS_BY_ID: {
      URL: (serviceId: string): string => `service/${serviceId}`,
    },
    DELETE_SERVICE_DETAILS_BY_ID: {
      URL: (serviceId: string): string => `service/${serviceId}`,
    },
    LIST_SERVICES: {
      URL: (): string => 'service/list?',
      QUERY_PARAMS: (queryParams: IServiceListQueryParams): string =>
        queryString.stringify(queryParams),
    },
    LIST_SERVICES_BY_CLINIC: {
      URL: (): string => 'service/list/by-clinic?',
      QUERY_PARAMS: (queryParams?: IServiceClinicOnlyQuery): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
  };
  public static readonly PATIENT_API = {
    LIST_PATIENTS: {
      URL: (): string => `patient/list?`,
      QUERY_PARAMS: (queryParams: IPatientListQueryParams): string =>
        queryString.stringify(queryParams),
    },
    LIST_ODCONNECTION_CLINIC: {
      URL: (patientId: string) => `patient/${patientId}/open-dental-connections?`,
      QUERY_PARAMS: (queryParams?: IListQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    PATIENT_DETAILS: {
      URL: (id: string): string => `patient/${id}`,
    },
    DELETE_PATIENT: {
      URL: (id: string): string => `patient/${id}`,
    },
    LIST_PATIENT_APPOINTMENT: {
      URL: (patientId: string) => `patient/booking/list/${patientId}?`,
      QUERY_PARAMS: (queryParams?: IListQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    PATIENT_ADD_INSURANCE: {
      URL: (patientId: string) => `/patient/${patientId}/by-staff/insurance`,
      QUERY_PARAMS: (queryParams?: IListQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    LIST_FAMILY_MEMBERS: {
      URL: (patientId: string) => `patient/${patientId}/family-member/list?`,
      QUERY_PARAMS: (queryParams?: IListQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    FAMILY_ADD_INSURANCE: {
      URL: (familyId: string) => `/patient/family-member/${familyId}/by-staff/insurance`,
      QUERY_PARAMS: (queryParams?: IListQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_PATIENT_INSURANCE: {
      URL: (patientId: string) => `/patient/${patientId}/staff/insurance`,
    },
    GET_FAMILY_INSURANCE: {
      URL: (familyId: string) => `/patient/family-member/${familyId}/staff/insurance`,
    },
    UPDATE_PRIMARY_PATIENT_INSURANCE: {
      URL: (patientId: string) => `patient/${patientId}/staff/insurance`,
    },
    UPDATE_FAMILY_MEMBER_INSURANCE: {
      URL: (patientId: string) => `patient/family-member/${patientId}/staff/insurance`,
    },
    FAMILY_DETAILS: {
      URL: (patientId: string, memberId: string) =>
        `patient/${patientId}/family-member/${memberId}`,
    },
    UPDATE_OD: {
      URL: (patientId: string) => `patient/${patientId}/link/open-dental`,
    },
    REMOVE_OD: {
      URL: (patientId: string, clinicId: string) =>
        `patient/${patientId}/link/open-dental/${clinicId}`,
    },
    GET_AVAILABLE_POINTS_BY_PATIENTID: {
      URL: (patientId: string) => `patient/${patientId}/points/available`,
    },
    LIST_POINTS_HISTORY: {
      URL: (patientId: string) => `reward/redeem/patient/history/${patientId}?`,
      QUERY_PARAMS: (queryParams: IRewardRedeemHistoryQuery): string =>
        queryString.stringify(queryParams),
    },
    PATIENT_INSURANCE_DETAILS: {
      URL: (patientId: string) => `patient/${patientId}/staff/insurance`,
    },
    FAMILY_INSURANCE_DETAILS: {
      URL: (familyId: string) => `patient/family-member/${familyId}/staff/insurance`,
    },
    DELETE_PATIENT_INSURANCE_DETAILS: {
      URL: (patientId: string): string => `patient/${patientId}/staff/insurance`,
    },
    DELETE_FAMILY_INSURANCE_DETAILS: {
      URL: (familyId: string): string => `patient/family-member/${familyId}/staff/insurance`,
    },
    UPDATE_PATIENT_PROFILE: {
      URL: (patientId: string): string => `patient/${patientId}`,
    },
    // UPDATE_FAMILY_PROFILE: {
    //   URL: (memberId: string): string => `patient/family-member/${memberId}`,
    // },
  };
  public static readonly MANAGE_API = {
    LIST_DSO_BLOCKOUT: {
      URL: () => `setting/blockout?`,
      QUERY_PARAMS: (queryParams?: IQueryParam): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    // same endpoint for detail, delete, update
    CRUD_DSO_BLOCKOUT_BY_ID: {
      URL: (id: string) => `setting/blockout/${id}`,
    },
    ADD_DSO_BLOCKOUT: {
      URL: () => 'setting/blockout',
    },
    GET_AND_UPDATE_DSO_NPPW: {
      URL: () => 'setting/nppw',
    },
    GET_LANDING_IMAGES: {
      URL: () => 'setting/app-configuration/images',
    },
    UPDATE_LANDING_IMAGES: {
      URL: () => 'setting/app-configuration/images',
    },
    GET_AND_UPDATE_DSO_LEGAL_DOCUMENT: {
      URL: () => 'setting/legal-document',
    },
    GET_PENDING_BALANCE: {
      URL: () => 'setting/dso/pending-balance',
    },
    UPDATE_PENDING_BALANCE: {
      URL: (pendingBalanceStatus: boolean) =>
        `setting/dso/pending-balance?showPendingBalance=${pendingBalanceStatus}`,
    },
    ADD_REWARD_CATEGORY: {
      URL: () => `setting/dso/reward-category`,
    },
    REWARD_CATEGORY_BY_ID: {
      URL: (categoryId: number) => `setting/dso/reward-category/${categoryId}`,
    },
    GET_AND_UPDATE_REFERRAL_DETAILS: {
      URL: () => 'setting/dso/referral-setting',
    },
    GET_FAQ_LIST: {
      URL: () => 'setting/faq',
    },
    GET_FAQ_BY_ID: {
      URL: (id: number) => `setting/faq/${id}`,
    },
    ADD_FAQ: {
      URL: () => 'setting/faq',
    },
    DELETE_FAQ_BY_ID: {
      URL: (id: number) => `setting/faq/${id}`,
    },
    EDIT_FAQ_BY_ID: {
      URL: (id: number) => `setting/faq/${id}`,
    },
    UPDATE_FAQ_ORDER: {
      URL: () => '/setting/faq/displayOrder',
    },
    GET_APPOINTMENT_NOTIFICATIONS_LIST: {
      URL: () => 'setting/notification-cadence',
    },
    GET_APPOINTMENT_NOTIFICATION_BY_ID: {
      URL: (id: number) => `setting/notification-cadence/${id}`,
    },
    ADD_APPOINTMENT_NOTIFICATIONS: {
      URL: () => 'setting/notification-cadence',
    },
    EDIT_APPOINTMENT_NOTIFICATIONS: {
      URL: (id: number) => `setting/notification-cadence/${id}`,
    },
    DELETE_NOTIFICATION_BY_ID: {
      URL: (id: number) => `setting/notification-cadence/${id}`,
    },
    GET_SERVICES_ICON_LIST: {
      URL: () => 'setting/library/icon',
    },
    ADD_SERVICES_ICON: {
      URL: () => 'setting/library/icon',
    },
    DELETE_AND_UPDATE_ICON_BY_ID: {
      URL: (id: number) => `setting/library/icon/${id}`,
    },
    GET_VIDEOS_LIST: {
      URL: () => 'setting/media-link',
    },
    ADD_VIDEO_LINK: {
      URL: () => 'setting/media-link',
    },
    VIEW_EDIT_DELETE_VIDEO_LINK_BY_ID: {
      URL: (id: number) => `setting/media-link/${id}`,
    },
    UPDATE_VIDEOS_ORDER: {
      URL: () => 'setting/media-link/displayOrder',
    },
    GET_LINK_LIST: {
      URL: (linkType: string) => `setting/link?linkType=${linkType}`,
    },
    UPDATE_OTHER_LIST_ORDER: {
      URL: () => 'setting/link/displayOrder',
    },
    VIEW_EDIT_DELETE_LINK_BY_ID: {
      URL: (linkId: number) => `setting/link/${linkId}`,
    },
    ADD_LINK: {
      URL: () => 'setting/link',
    },
  };
  public static readonly REWARDS_API = {
    LIST_CONFIGURED_REWARDS: {
      URL: () => `reward/list?`,
      QUERY_PARAMS: (queryParams: IRewardQueryParam): string => queryString.stringify(queryParams),
    },
    LIST_REDEEMED_HISTORY_REWARDS: {
      URL: () => `reward/redeem/history/staff?`,
      QUERY_PARAMS: (queryParams: IRewardQueryParam): string => queryString.stringify(queryParams),
    },
    LIST_CATEGORY_REWARDS: {
      URL: () => `reward/category/list?`,
      QUERY_PARAMS: (queryParams: IRewardQueryParam): string => queryString.stringify(queryParams),
    },
    ADD_GIFT_REWARD: {
      URL: () => `reward/gift`,
    },
    ADD_POINT_CREDIT_REWARD: {
      URL: () => `reward/points-credit`,
    },
    REDEEM_REWARD: {
      URL: () => `reward/redeem`,
    },
    EDIT_GIFT_REWARD: {
      URL: (id: number) => `reward/gift/${id}`,
    },
    EDIT_POINT_CREDIT_REWARD: {
      URL: (id: number) => `reward/points-credit/${id}`,
    },
    // View, delete
    REWARD_BY_ID: {
      URL: (id: number) => `reward/${id}`,
    },
    VIEW_REDEEM_REWARD_HISTORY_BY_ID: {
      URL: (id: number) => `reward/redeem/history/${id}/staff`,
    },
    UPDATE_AUTO_REWARD: {
      URL: (id: number, status: boolean) =>
        `reward/auto-assign-rewards/${id}/status?status=${status}`,
    },
    LIST_AUTO_REWARDS: {
      URL: () => `reward/auto-assign-rewards/list?`,
    },
    LIST_ELECTIVE_PROCEDURE_AUTO_REWARDS: {
      URL: () => `reward/auto-assign-rewards/elective-procedure/list?`,
      QUERY_PARAMS: (queryParams: IQueryParam): string => queryString.stringify(queryParams),
    },
    AUTO_REWARD_BY_ID: {
      URL: (id: number) => `reward/auto-assign-rewards/${id}`,
    },
    ADD_ELECTIVE_PROCEDURE: {
      URL: () => `reward/auto-assign-rewards/elective-procedure`,
    },
  };
  public static readonly ANALYTICS_API = {
    LIST_APPOINTMENT_DETAIL: {
      URL: () => `appointment/details/list?`,
      QUERY_PARAMS: (queryParams: IAppointmentDetailsQueryParams): string =>
        queryString.stringify(queryParams),
    },
    GET_INSURANCE_ANALYTICS: {
      URL: () => `analytics/appointment/insurance?`,
      QUERY_PARAMS: (queryParams?: IAppointmentInsuranceQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    SERVICE_BREAKDOWN: {
      URL: () => `analytics/appointment/service?`,
      QUERY_PARAMS: (queryParams?: IAppointmentDetailsQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_APPOINTMENT_PATIENT_GENDER_ANALYTICS: {
      URL: () => `analytics/appointment/patient?`,
      QUERY_PARAMS: (queryParams?: IDashboardQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
    GET_APPOINTMENT_ANALYTICS: {
      URL: () => `analytics/appointment?`,
      QUERY_PARAMS: (queryParams?: IDashboardQueryParams): string =>
        Object.entries(queryParams || {})
          .map((element) => element.join('='))
          .join('&'),
    },
  };
}
