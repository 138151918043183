/*
 * File: Rewards.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:45:35 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thrusday, 15th June 2023 12:37:08 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Rewards.css';

import {
  FilterArray,
  IListRedeemRewardHistoryPaginationResponse,
  IListRewardPaginationResponse,
  IListRewardRO,
  IListRewardRedeemHistoryRO,
  IRewardQueryParam,
} from '../../model';
import React, { FC, useEffect, useState } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import { Badge } from '@mui/material';
import Buttons from '../../components/shared/Buttons';
import { Constants } from '../../utilities/constants';
import CustomTab from '../../components/shared/CustomTab';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterConfigureRewardPopup from '../../components/feature/reward/ConfigureRewards/FilterConfigureRewards/FilterConfigureRewardPopup';
import FilterRedeemRewardPopup from '../../components/feature/reward/RedeemRewards/FilterRedeemRewards/FilterRedeemRewardPopup';
import ListAutoRewards from '../../components/feature/reward/AutoRewards/ListAutoRewards';
import ListConfigureRewards from '../../components/feature/reward/ConfigureRewards/ListConfigureRewards/ListConfigureRewards';
import ListRedeemHistoryRewards from '../../components/feature/reward/RedeemRewards/ListRedeemHistoryRewards/ListRedeemHistoryRewards';
import Loader from '../../components/shared/Loader/Loader';
import { RewardService } from '../../service/reward.service';
import { RoleIds } from '../../utilities/roles';
import SearchBar from '../../components/shared/SearchBar';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../theme';

interface FilterParams {
  gift: boolean;
  pointCredits: boolean;
}

export enum RewardsTabs {
  REWARDS_POINTS_HISTORY = 0,
  AUTO_ASSIGN_POINTS,
  REDEEM_HISTORY,
}

const Rewards: FC = () => {
  const defaultFilter = {
    gift: false,
    pointCredits: false,
  };
  const getPermission = getPermissionForModule();
  const loggedInUserRoleId = getPermission.roleId;
  const permission = getPermission.permissions.Rewards;
  const autoRewardsPermission = getPermission.permissions.AutoAssignPoints;
  const navigate = useNavigate();

  const [rewardList, setRewardList] = useState<IListRewardPaginationResponse>({
    total: 0,
    rewards: [],
  });
  const [redeemList, setRedeemList] = useState<IListRedeemRewardHistoryPaginationResponse>({
    total: 0,
    rewardRedeemHistory: [],
  });
  const [redeemDataPage, setRedeemDataPage] = useState(1);
  const [rewardDataPage, setRewardDataPage] = useState(1);

  const [rewardSearchValue, setRewardSearchValue] = useState('');
  const [redeemSearchValue, setRedeemSearchValue] = useState('');

  const [defaultTab, setDefaultTab] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMsg, setSnackbarMsg] = useState('');
  const deletedReward = sessionStorage.getItem('deletedReward');
  const addedReward = sessionStorage.getItem('addedReward');
  const redeemReward = sessionStorage.getItem('redeemReward');
  const redeem = sessionStorage.getItem('redeemReward') || sessionStorage.getItem('redeem-cancel');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  // Filter rewards popup states
  const [openRewardFilterPopup, setOpenRewardFilterPopup] = useState(false);
  const [selectedRewardChips, setSelectedRewardChips] = useState<FilterArray[] | null>();
  const [filterRewardOptions, setFilterRewardOption] = useState<FilterParams | any>();

  // Filter Redeem rewards popup states
  const [openRedeemFilterPopup, setOpenRedeemFilterPopup] = useState(false);
  const [selectedRedeemChips, setSelectedRedeemChips] = useState<FilterArray[] | null>();
  const [filterRedeemOptions, setFilterRedeemOption] = useState<FilterParams | any>();

  const onTabChange = (index: number) => {
    setDefaultTab(index);
    setRewardSearchValue('');
    setRedeemSearchValue('');
    clearAllFilter();
  };
  const onRewardDataPageChange = (page: number) => {
    setRewardDataPage(page);
  };
  const onRedeemDataPageChange = (page: number) => {
    setRedeemDataPage(page);
  };

  const onRewardSearch = (inputValue: string) => {
    setRewardSearchValue(inputValue);
    setRewardDataPage(1);
  };

  const onRedeemSearch = (inputValue: string) => {
    setRedeemDataPage(1);
    setRedeemSearchValue(inputValue);
  };
  const addedElectiveProcedure = sessionStorage.getItem('addedElectiveProcedure');

  useEffect(() => {
    if (redeem) {
      onTabChange(2);
      sessionStorage.removeItem('redeem-cancel');
    }
  }, []);
  useEffect(() => {
    setShowLoader(true);

    if (deletedReward) {
      setShowSnackbar(true);
      setSnackbarMsg(`${deletedReward} is deleted`);
      sessionStorage.removeItem('deletedReward');
    }
    if (addedReward) {
      setShowSnackbar(true);
      setSnackbarMsg(`${addedReward} is added`);
      sessionStorage.removeItem('addedReward');
    }
    if (redeemReward) {
      setShowSnackbar(true);
      setSnackbarMsg(`${redeemReward} is redeemed`);
      sessionStorage.removeItem('redeemReward');
    }
    getRewardList();
  }, [
    rewardDataPage,
    rewardSearchValue,
    selectedRewardChips && selectedRewardChips.length && JSON.stringify(selectedRewardChips),
  ]);

  useEffect(() => {
    setShowLoader(true);
    if (loggedInUserRoleId !== RoleIds.MARKETING_ADMIN) {
      getRedeemHistoryList();
    }
  }, [
    redeemDataPage,
    redeemSearchValue,
    selectedRedeemChips && selectedRedeemChips.length && JSON.stringify(selectedRedeemChips),
  ]);
  useEffect(() => {
    if (addedElectiveProcedure) {
      setShowSnackbar(true);
      setSnackbarMsg('Elective Procedures Added');
      sessionStorage.removeItem('addedElectiveProcedure');
      setDefaultTab(RewardsTabs.AUTO_ASSIGN_POINTS);
    }
  }, []);
  const getRewardList = async () => {
    if (rewardSearchValue && rewardSearchValue.length < 3) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (rewardDataPage - 1) * limit;
      const rewardListQueryParams: IRewardQueryParam = {
        limit,
        skip,
        ...(rewardSearchValue &&
          rewardSearchValue.length > 2 && {
          searchString: rewardSearchValue,
          skip: rewardDataPage !== 1 ? skip : 0,
        }),
        ...(selectedRewardChips &&
          selectedRewardChips.length && {
          rewardType: selectedRewardChips.map((i) =>
            i.label === 'gift' ? i.label : 'points_credit',
          ),
          skip: rewardDataPage !== 1 ? skip : 0,
        }),
      };
      const response: IListRewardRO = await RewardService.getConfigRewardList(
        rewardListQueryParams,
      );
      setRewardList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const getRedeemHistoryList = async () => {
    if (
      loggedInUserRoleId !== RoleIds.MARKETING_ADMIN &&
      redeemSearchValue &&
      redeemSearchValue.length < 3
    ) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (redeemDataPage - 1) * limit;
      const redeemHistoryQueryParams: IRewardQueryParam = {
        limit,
        skip,
        ...(redeemSearchValue &&
          redeemSearchValue.length > 2 && {
          searchString: redeemSearchValue,
          skip: redeemDataPage !== 1 ? skip : 0,
        }),
        ...(selectedRedeemChips &&
          selectedRedeemChips.length && {
          type: selectedRedeemChips.map((i) => (i.label === 'gift' ? i.label : 'points_credit')),
          skip: redeemDataPage !== 1 ? skip : 0,
        }),
      };
      const response: IListRewardRedeemHistoryRO = await RewardService.getRedeemRewardHistoryList(
        redeemHistoryQueryParams,
      );
      setRedeemList(() => response?.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const handleFilterPopupOpen = () => {
    if (defaultTab === RewardsTabs.REDEEM_HISTORY) {
      setOpenRedeemFilterPopup(true);
      handleSelectedOptions(selectedRedeemChips);
    } else {
      setOpenRewardFilterPopup(true);
      handleSelectedOptions(selectedRewardChips);
    }
  };
  const handleSelectedOptions = (selectedChips: FilterArray[] | null | undefined) => {
    if (selectedChips && selectedChips.length > 0) {
      const newObj = Object.assign(
        defaultFilter,
        ...selectedChips.map((item) => ({ [item.label]: item.value })),
      );
      defaultTab === RewardsTabs.REDEEM_HISTORY
        ? setFilterRedeemOption(newObj)
        : setFilterRewardOption(newObj);
    }
  };
  const handleFilterService = (inputValue: any) => {
    const arrayForChips = Object.keys(inputValue).map((key: string, index: number) => ({
      id: index,
      label: key,
      value: inputValue[key],
    }));
    const selectedOptions = arrayForChips.filter((i: FilterArray) => i.value === true);
    if (defaultTab === RewardsTabs.REDEEM_HISTORY) {
      selectedOptions.length
        ? setSelectedRedeemChips(selectedOptions)
        : setSelectedRedeemChips(undefined);
      setOpenRedeemFilterPopup(false);
      setRedeemDataPage(1);
    } else {
      selectedOptions.length
        ? setSelectedRewardChips(selectedOptions)
        : setSelectedRewardChips(undefined);
      setOpenRewardFilterPopup(false);
      setRewardDataPage(1);
    }
  };

  const clearAllFilter = () => {
    if (defaultTab === RewardsTabs.REDEEM_HISTORY) {
      setSelectedRedeemChips(undefined);
      setFilterRedeemOption(defaultFilter);
      setRedeemDataPage(1);
    } else {
      setSelectedRewardChips(undefined);
      setFilterRewardOption(defaultFilter);
      setRewardDataPage(1);
    }
  };

  const handleDelete = (data: any) => {
    if (defaultTab === RewardsTabs.REDEEM_HISTORY) {
      if (selectedRedeemChips?.filter((chip: any) => chip.id !== data.id).length === 0) {
        clearAllFilter();
      } else {
        setSelectedRedeemChips((chips) => chips?.filter((chip: any) => chip.id !== data.id));
        setRedeemDataPage(1);
      }
    } else {
      if (selectedRewardChips?.filter((chip: any) => chip.id !== data.id).length === 0) {
        clearAllFilter();
      } else {
        setSelectedRewardChips((chips) => chips?.filter((chip: any) => chip.id !== data.id));
        setRewardDataPage(1);
      }
    }
  };

  const tabs = [
    {
      label: 'REWARDS & POINTS HISTORY',
      isHidden: false,
      id: 1,
      Component:
        permission.read && rewardList?.total >= 0 && !showLoader ? (
          <ListConfigureRewards
            rewardList={rewardList}
            onPageChangeHandler={onRewardDataPageChange}
            currentPage={rewardDataPage}
            selectedChipOption={selectedRewardChips}
            onFilterClear={clearAllFilter}
            onFilterOptionDel={handleDelete}
          />
        ) : (
          rewardList.total === 0 &&
          !showLoader && (
            <section style={{ marginTop: '8rem' }}>
              <EmptyState
                path={permission.create ? '/rewards/add' : ''}
                text={'No rewards to show'}
                subText={permission.create ? 'Start by adding a new reward.' : ''}
                buttonText={permission.create ? 'ADD NEW REWARD' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </section>
          )
        ),
    },
    {
      label: 'AUTO-ASSIGN POINTS',
      isHidden: false,
      id: 2,
      Component: autoRewardsPermission.read && <ListAutoRewards />,
    },
    {
      label: 'REDEEM HISTORY',
      isHidden: loggedInUserRoleId === RoleIds.MARKETING_ADMIN ? true : false,
      id: 3,
      Component:
        permission.read && redeemList?.total >= 0 && !showLoader ? (
          <ListRedeemHistoryRewards
            redeemList={redeemList}
            onPageChangeHandler={onRedeemDataPageChange}
            currentPage={redeemDataPage}
            selectedRedeemChipOption={selectedRedeemChips}
            onFilterClear={clearAllFilter}
            onFilterOptionDel={handleDelete}
          />
        ) : (
          redeemList.total === 0 &&
          !showLoader && (
            <section style={{ marginTop: '8rem' }}>
              <EmptyState
                path={permission.create ? '/rewards/redeem' : ''}
                text={'No redeem rewards history to show'}
                subText={permission.create ? 'Start by redeeming one' : ''}
                buttonText={permission.create ? 'REDEEM REWARD' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </section>
          )
        ),
    },
  ];

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='clinicContainer'>
          <div className='clinicHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='clinicTitle' color='inherit'>
                Loyalty Program
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <div style={{ display: 'flex', gap: '16px' }}>
                  {defaultTab !== RewardsTabs.AUTO_ASSIGN_POINTS && (
                    <SearchBar
                      onSearchHandler={
                        defaultTab === RewardsTabs.REDEEM_HISTORY ? onRedeemSearch : onRewardSearch
                      }
                      searchValue={
                        defaultTab === RewardsTabs.REDEEM_HISTORY
                          ? redeemSearchValue
                          : rewardSearchValue
                      }
                      placeholderText={'Search by Reward Name'}
                    />
                  )}
                  {defaultTab !== RewardsTabs.AUTO_ASSIGN_POINTS && (
                    <div
                      style={{
                        border: `1px solid ${zeplinColor.Primary}`,
                      }}
                      className='iconButton'
                    >
                      <Badge
                        overlap='rectangular'
                        color='error'
                        variant='dot'
                        invisible={
                          defaultTab !== RewardsTabs.REDEEM_HISTORY
                            ? !selectedRewardChips || selectedRewardChips.length === 0
                            : !selectedRedeemChips || selectedRedeemChips.length === 0
                        }
                      >
                        <FilterAltOutlinedIcon
                          onClick={handleFilterPopupOpen}
                          sx={{
                            color: zeplinColor.Primary,
                            width: '24px',
                            height: '24px',
                            borderRadius: '4px',
                            margin: '8px 8px 0px 8px',
                          }}
                        ></FilterAltOutlinedIcon>
                      </Badge>
                      {defaultTab === RewardsTabs.REDEEM_HISTORY ? (
                        <FilterRedeemRewardPopup
                          selectedChips={filterRedeemOptions}
                          open={openRedeemFilterPopup}
                          setOpen={setOpenRedeemFilterPopup}
                          onFilterClick={handleFilterService}
                        />
                      ) : (
                        defaultTab == RewardsTabs.REWARDS_POINTS_HISTORY && (
                          <FilterConfigureRewardPopup
                            selectedChips={filterRewardOptions}
                            open={openRewardFilterPopup}
                            setOpen={setOpenRewardFilterPopup}
                            onFilterClick={handleFilterService}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>

                {permission.create && defaultTab !== RewardsTabs.AUTO_ASSIGN_POINTS && (
                  <Buttons
                    text={
                      defaultTab === RewardsTabs.REWARDS_POINTS_HISTORY
                        ? 'Add New Reward'
                        : defaultTab === RewardsTabs.REDEEM_HISTORY
                          ? 'Redeem Reward'
                          : ''
                    }
                    width='15em'
                    disable={false}
                    onClick={() =>
                      navigate(
                        defaultTab === RewardsTabs.REWARDS_POINTS_HISTORY
                          ? '/rewards/add'
                          : defaultTab === RewardsTabs.REDEEM_HISTORY
                            ? '/rewards/redeem'
                            : '',
                      )
                    }
                  />
                )}
              </div>
            </Toolbar>
            <div>
              <CustomTab tabs={tabs} onTabChange={onTabChange} defaultTab={defaultTab} />
            </div>
          </div>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};
export default Rewards;
