/*
 * File: EditReferFriend.tsx
 * Project: mint-portal
 * File Created: Wednesday, 2nd November 2022 3:03:15 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 3rd November 2022 12:54:01 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { FC, useState, useEffect } from 'react';
import './EditReferFriend.css';
import { IReferralDetailResponse } from '../../../../../model';
import CustomNumericInput from '../../../../shared/CustomNumericField';
import { Constants } from '../../../../../utilities/constants';
import { Paper } from '@mui/material';
import Loader from '../../../../shared/Loader/Loader';
import { handleKeyPressForEscapeSymbols } from '../../../../../utilities/validate';

type editProps = {
  edit: boolean;
  referralDetails: IReferralDetailResponse;
  setUpdatePayload: React.Dispatch<React.SetStateAction<IReferralDetailResponse | undefined>>;
  updateLoader: boolean;
  setEditBtn: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditReferFriend: FC<editProps> = ({
  edit,
  referralDetails,
  setUpdatePayload,
  updateLoader,
  setEditBtn,
}) => {
  const [referrerPoints, setReferrerPoints] = useState(referralDetails?.referredByPoints);
  const [refereePoints, setRefereePoints] = useState(referralDetails?.referredToPoints);
  const handleReferrerPointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferrerPoints(+event.target.value);
  };
  const handleRefereePointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefereePoints(+event.target.value);
  };
  const createPayload: IReferralDetailResponse = {
    referredToPoints: refereePoints,
    referredByPoints: referrerPoints,
  };

  useEffect(() => {
    validatePayload();
    if (edit && refereePoints > 0 && referrerPoints > 0) {
      setUpdatePayload && setUpdatePayload(createPayload);
    }
  }, [referrerPoints, refereePoints]);

  const validatePayload = async () => {
    if (
      referrerPoints === 0 ||
      referrerPoints > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE ||
      refereePoints === 0 ||
      refereePoints > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE
    ) {
      setEditBtn && setEditBtn(true);
    } else {
      setEditBtn && setEditBtn(false);
    }
  };

  const onPaste = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      {updateLoader && <Loader />}
      <div className='referContainer'>
        <Paper className='referBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div className='referBox'>
            <section>
              <CustomNumericInput
                name='point value by referrer'
                label='Points earned by the Referrer*'
                width='44%'
                value={referrerPoints}
                error={
                  referrerPoints &&
                  (referrerPoints === 0 ||
                    referrerPoints > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE)
                    ? true
                    : false
                }
                icon={false}
                onChange={handleReferrerPointChange}
                marginTop='8px'
                onKeyPress={handleKeyPressForEscapeSymbols}
                min={1}
                onPaste={onPaste}
              />
            </section>
            <section>
              <CustomNumericInput
                name='point value by referee'
                label='Points earned by the Referee*'
                width='44%'
                value={refereePoints}
                error={
                  refereePoints &&
                  (refereePoints === 0 ||
                    refereePoints > Constants.REWARDS_VALIDATION.MAX_POINT_VALUE)
                    ? true
                    : false
                }
                icon={false}
                onChange={handleRefereePointChange}
                marginTop='8px'
                onKeyPress={handleKeyPressForEscapeSymbols}
                min={1}
                onPaste={onPaste}
              />
            </section>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default EditReferFriend;
