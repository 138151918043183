/*
 * File: ServiceOption.tsx
 * Project: mint-portal
 * File Created: Monday, 5th September 2022 8:29:19 pm
 * Author: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 22nd May 2023 9:32:20 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { zeplinColor } from '../../../theme';
import { IServiceList } from '../../../model/service.model';
import { Constants } from '../../../utilities/constants';

type prop = {
  valprop: IServiceList;
  remove?: (id: number, name: string) => void;
  view ?:boolean;
  length?:number
};
const ServiceOption: FC<prop> = ({ valprop, remove , view=false,length}: prop) => {
  const {
    id,
    name,
    ageType,
    patientType,
    duration,
    serviceImageDetails, 
  } = valprop;
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '98%',
        margin: 'auto',
      }}
    >
      <Box style={{ width: '98%', display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Box
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          {serviceImageDetails && <img
            src={serviceImageDetails?.location || Constants.SERVICE_FALL_BACK_ICON}
            // width='25px'
            // height='25px'
            style={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />}
          <Typography variant='h6' style={{ color: zeplinColor.Background30 }}>
            {name}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', gap: 15 }}>
          <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {/* <img src={BrokenTooth} style={{ width: 16, height: 16, borderRadius: '100%' }} /> */}
            <PermIdentityIcon style={{ color: zeplinColor.Background30, width: 16, height: 16 }} />
            <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
              {ageType}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {/* <img src={BrokenTooth} style={{ width: 16, height: 16, borderRadius: '100%' }} /> */}
            <AssignmentTurnedInOutlinedIcon
              style={{ color: zeplinColor.Background30, width: 16, height: 16 }}
            />
            <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
              {patientType}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {/* <img src={BrokenTooth} style={{ width: 16, height: 16, borderRadius: '100%' }} /> */}
            <AccessTimeIcon style={{ color: zeplinColor.Background30, width: 16, height: 16 }} />
            <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
              {duration} Min
            </Typography>
          </Box>
        </Box>
      </Box>
      {(remove && !view && length && length>1) ? (
        <Box style={{ width: '5%' }}>
          <IconButton disableRipple onClick={() => remove(id, name)} >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default ServiceOption;
