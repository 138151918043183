/*
 * File: ListPatient.tsx
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 9:02:07 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 1st March 2023 11:38:26 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { IPatientList, IPatientListAPIResponse } from '../../../model/patient.model';
import { Constants } from '../../../utilities/constants';
import CustomToolTip from '../../shared/CustomToolTip';

interface IPatientProps {
  patientList: IPatientListAPIResponse;
  onPageChangeHandler: any;
  currentPage: number;
}

interface PatientListColumn {
  id: 'patient_name' | 'unique_code' | 'home_clinic' | 'phone';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const patientListColumns: PatientListColumn[] = [
  { id: 'patient_name', label: 'Name', minWidth: 300 },
  { id: 'unique_code', label: 'Unique Code', minWidth: 150 },
  { id: 'home_clinic', label: 'Home Clinic', minWidth: 150 },
  { id: 'phone', label: 'Phone', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListPatient: FC<IPatientProps> = ({ patientList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const navigate = useNavigate();

  return (
    <div>
      <Paper className='containerdiv' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {patientListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {patientList.total &&
                patientList.patients.length &&
                patientList.patients.map((patientObj: IPatientList) => {
                  return (
                    <TableRow
                      hover
                      classes={{ hover: classes.tableRow }}
                      key={patientObj.id}
                      onClick={() =>
                        navigate({
                          pathname: `/patient/${patientObj.id}`,
                        })
                      }
                    >
                      <TableCell>
                        <CustomToolTip
                          title={patientObj.firstName + ' ' + patientObj.lastName}
                          width={'275px'}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{patientObj.code}</Typography>
                      </TableCell>
                      <TableCell>
                        {patientObj.defaultClinic === null ? (
                          <Typography>-</Typography>
                        ) : (
                          <CustomToolTip title={patientObj.defaultClinic.name} width={'230px'} />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography>{patientObj.phoneNumber}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={
          patientList.total <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(patientList.total / Constants.PAGINATION_OPTIONS.limit)
        }
        onChange={onDataPageChange}
        page={dataPage}
        variant='outlined'
        shape='rounded'
        className='pagination'
        classes={{ outlined: classes.ul }}
      />
    </div>
  );
};

export default ListPatient;
