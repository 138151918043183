/*
 * File: patient-details.tsx
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 4:40:00 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 6th September 2023 6:52:12 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './Details.css';
import { CustomCard } from '../../../shared/CustomCard/CustomCard';
import CustomTab from '../../../shared/CustomTab';
import {
  InsuranceListAPIResponse,
  InsuranceListQueryParams,
  InsuranceListRO,
  IFamilyMemberDetails,
  IFamilyMemberDetailsRO,
  IFamilyMembersListRO,
  IListClinicODPatientResponse,
  IListClinicODPatientRO,
  IListFamilyMembersProfilesResponse,
  IListQueryParams,
  IPatientDetails,
  IPatientDetailsData,
  IAppointmentListQueryParams,
  IPatientAppointmentListRO,
  IPatientAppointmentListAPIResponse,
  IAvailablePointRewardRO,
  IRewardRedeemHistoryQuery,
  IRewardRedeemHistoryListRO,
  IRedeemRewardHistoryPaginationResponse,
  IRewardQueryParam,
  IListRewardRO,
  IListRewardPaginationResponse,
  IListRewardResponse,
  RewardType,
} from '../../../../model';
import { zeplinColor } from '../../../../theme';
import { PatientService } from '../../../../service/patient.service';
import PatientDetailCard from '../DetailCard/DetailCard';
import ListODClinics from '../ListODClinics';
import { Constants } from '../../../../utilities/constants';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import ListFamilyMembers from '../ListFamilyMembers';
import { useLocation, useNavigate } from 'react-router-dom';
import DeletePopup from '../../../shared/DeletePopup';
import Loader from '../../../shared/Loader/Loader';
import { styled } from '@mui/material/styles';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import { InsuranceService } from '../../../../service/insurance.service';
import ListAppointments from '../ListAppointments';
import getPermissionForModule from '../../../../utilities/getPermission';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import ListPointsHistory from '../ListPointsHistory';
import { RewardService } from '../../../../service/reward.service';
import Buttons from '../../../shared/Buttons';
import UpdatePatientProfile from '../UpdatePatientProfile/UpdatePatientProfile';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3, 1, 3),
    maxHeight: '26.5rem',
    minWidth: '35rem',
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogBoxTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1 0 1 0' }} {...other}>
      {children}
    </DialogTitle>
  );
};

const Details = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Patient;
  const removedPatient = sessionStorage.getItem('deletedPatient');
  const patientInsurance = sessionStorage.getItem('patient-insurance');
  const [dataPageClinic, setDataPageClinic] = useState(1);
  const [dataPageAppointment, setDataPageAppointment] = useState(1);
  const [dataPageFamilyList, setDataPageFamilyList] = useState(1);
  const [dataPagePointsHistoryList, setDataPagePointsHistoryList] = useState(1);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [insuranceList, setInsuranceList] = useState<InsuranceListRO>({ insurances: [], total: 0 });
  const [selected, setSelected] = useState({ id: 0, insuranceName: '', isAllClinicAccess: false });
  const [familyDetails, setFamilyDetails] = useState<IFamilyMemberDetails>();
  const [patientDetails, setPatientDetails] = useState<IPatientDetailsData | undefined>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isEmailVerified: false,
    isLegalDocumentsAccepted: false,
    dateOfBirth: '',
    gender: 'other', // sample default value
    profileCompletedAt: '',
    code: '',
    defaultClinic: { id: '', name: '' },
    insuranceDetails: { id: '', insuranceName: '' },
  });

  const [odClinicList, setODClinicList] = useState<IListClinicODPatientResponse>({
    total: 0,
    openDentalConnections: [],
  });
  const [familyMemberList, setFamilyMemberList] = useState<IListFamilyMembersProfilesResponse>({
    total: 0,
    familyMembers: [],
  });
  const [appointmentList, setAppointmentList] = useState<IPatientAppointmentListAPIResponse>({
    total: 0,
    appointments: [],
  });
  const [availableRewardPoints, setavailableRewardPoints] = useState<number>();
  const [pointsHistoryList, setPointsHistoryList] =
    useState<IRedeemRewardHistoryPaginationResponse>({
      total: 0,
      rewardRedeemHistory: [],
    });
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const [addRewardModal, setAddRewardModal] = useState(false);

  // const [shortCut, setShortCut] = useState(0);
  const [rewardConfirmModal, setRewardConfirmModal] = useState(false);
  const route = useLocation();
  const navigate = useNavigate();
  const prevLink =
    route.pathname.split('/').length <= 3
      ? [{ path: '/patient', label: 'Patients' }]
      : [
        { path: '/patient', label: 'Patients' },
        {
          path: `/patient/${route.pathname.split('/')[2]}`,
          label:
              familyDetails &&
              `${familyDetails.primaryPatientDetails.firstName} ${familyDetails.primaryPatientDetails.lastName}`,
        },
      ];
  const [patientId, setPatientId] = useState('');
  const [rewardSearchValue, setRewardSearchValue] = useState('');
  const [rewardList, setRewardList] = useState<IListRewardPaginationResponse>({
    total: 0,
    rewards: [],
  });
  const [rewardPoints, setRewardPoints] = useState<IListRewardResponse>({
    name: '',
    rewardType: RewardType.PointsCredit,
    id: 0,
    pointsValue: 0,
    inventoryCount: 0,
  });
  const deletedInsuranceDetail = sessionStorage.getItem('deletedInsuranceDetail');

  useEffect(() => {
    if (route.pathname.split('/')[4]) {
      const famId = route.pathname.split('/')[4];
      getFamilyDetails(route.pathname.split('/')[2], famId);
      getODClinicList(famId);
      getAppointmentList(famId);
      getPointsHistoryList(famId);
      setPatientDetails(undefined);
    } else if (route.pathname.split('/')[2]) {
      const patId = route.pathname.split('/')[2];
      setPatientId(patId);
      getDetails(patId);
      getODClinicList(patId);
      getAppointmentList(patId);
      getFamilyMemberList(patId);
      getPointsHistoryList(patId);
      setFamilyDetails(undefined);
    }
    setShowSnackbar(removedPatient ? true : false);
    setSnackbarMessage(`${removedPatient} is removed.`);
    if (removedPatient) {
      sessionStorage.removeItem('deletedPatient');
    }
    if (patientInsurance) {
      setShowSnackbar(true);
      setSnackbarMessage(`${patientInsurance}`);
      sessionStorage.removeItem('patient-insurance');
    }
  }, [route.pathname]);

  useEffect(() => {
    if (patientId && !familyDetails?.id) {
      getODClinicList(patientId);
      getAppointmentList(patientId);
      getFamilyMemberList(patientId);
      getPointsHistoryList(patientId);
    } else if (familyDetails?.id) {
      getODClinicList(familyDetails.id);
      getAppointmentList(familyDetails.id);
      getFamilyMemberList(familyDetails.id);
      getPointsHistoryList(familyDetails.id);
    }
  }, [dataPageClinic, dataPageFamilyList, dataPageAppointment, dataPagePointsHistoryList]);

  useEffect(() => {
    updatePopUp && getInsuranceList('');
    if (!updatePopUp && familyDetails?.id && patientId) {
      getFamilyDetails(patientId, familyDetails?.id);
    } else if (!updatePopUp && patientId) {
      getDetails(patientId);
    }
  }, [updatePopUp]);

  useEffect(() => {
    getRewardPoints();
  }, [patientId]);

  useEffect(() => {
    if (deletedInsuranceDetail) {
      setShowSnackbar(true);
      setSnackbarMessage('Insurance Deleted');
      sessionStorage.removeItem('deletedInsuranceDetail');
    }
  }, []);

  const onDataPageClinicChange = (page: number) => {
    setDataPageClinic(page);
  };

  const onDataPageFamilyMemberChange = (page: number) => {
    setDataPageFamilyList(page);
  };
  const onDataPageAppointmentChange = (page: number) => {
    setDataPageAppointment(page);
  };
  const onDataPagePointsHistoryChange = (page: number) => {
    setDataPagePointsHistoryList(page);
  };

  const onTabChange = () => {
    setDataPageClinic(1);
    setDataPageAppointment(1);
    setDataPageFamilyList(1);
    setDataPagePointsHistoryList(1);
  };

  const selectHandler = (input: any) => {
    setSelected(input);
  };

  const onCancel = () => {
    setUpdatePopUp(false);
  };

  const search = (e: any) => {
    getInsuranceList(e);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const handleProfileUpdatePopupOpen = () => {
    setOpenPopup(true);
  };

  const getODClinicList = async (id: string) => {
    setShowLoader(true);
    try {
      const limit = Constants.LIMIT;
      const skip = (dataPageClinic - 1) * limit;
      const query: IListQueryParams = {
        limit,
        skip,
      };
      const response: IListClinicODPatientRO =
        await PatientService.getPatientODConnectionClinicList(id, query);
      setODClinicList(() => response?.data);
    } catch (err) {
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getFamilyMemberList = async (id: string) => {
    setShowLoader(true);
    try {
      const limit = Constants.LIMIT;
      const skip = (dataPageFamilyList - 1) * limit;
      const query: IListQueryParams = {
        limit,
        skip,
      };
      const response: IFamilyMembersListRO = await PatientService.getFamilyMembersList(id, query);
      setFamilyMemberList(() => response?.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getAppointmentList = async (id: string) => {
    setShowLoader(true);
    try {
      const limit = Constants.LIMIT;
      const skip = (dataPageAppointment - 1) * limit;
      const query: IAppointmentListQueryParams = {
        limit,
        skip,
      };
      const response: IPatientAppointmentListRO = await PatientService.getPatientAppointmentList(
        id,
        query,
      );
      setAppointmentList(() => response?.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getDetails = async (id: string) => {
    setShowLoader(true);
    try {
      const res: IPatientDetails = await PatientService.getPatient(id);
      setPatientDetails(res.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const getFamilyDetails = async (primary: string, family: string) => {
    setShowLoader(true);
    try {
      const res: IFamilyMemberDetailsRO = await PatientService.getFamilyMemberDetails(
        primary,
        family,
      );
      setFamilyDetails(res.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeletePopUp = () => {
    setOpenDeletePopup(true);
  };

  const handleDeletePatient = async () => {
    setShowLoader(true);
    try {
      const res = await PatientService.deletePatient(familyDetails?.id || patientId);
      if (res) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          ` ${
            (familyDetails?.firstName || patientDetails?.firstName) +
            ' ' +
            (familyDetails?.lastName || patientDetails?.lastName)
          } is removed`,
        );
        setOpenDeletePopup(false);
        sessionStorage.setItem(
          'deletedPatient',
          (familyDetails?.firstName || patientDetails?.firstName) +
            ' ' +
            (familyDetails?.lastName || patientDetails?.lastName),
        );
        navigate(familyDetails?.id ? `/patient/${patientId}` : '/patient');
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const handleUpdate = async () => {
    setShowLoader(true);
    try {
      const res = familyDetails?.id
        ? await PatientService.updateFamilyMemberInsurance(familyDetails?.id, {
          insuranceId: selected.id,
        })
        : await PatientService.updatePrimaryInsurance(patientId, {
          insuranceId: selected.id,
        });
      setUpdatePopUp(false);
      setShowSnackbar(true);
      setSnackbarMessage(res?.data?.insuranceName + ' is updated');
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getInsuranceList = async (searchString: string) => {
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = 0;
      const listInsuranceQuery: InsuranceListQueryParams = {
        limit,
        skip,
        ...(searchString?.length > 2 ? { searchString } : ''),
      };
      const response: InsuranceListAPIResponse = await InsuranceService.getInsuranceList(
        listInsuranceQuery,
      );
      setInsuranceList(response.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getRewardPoints = async () => {
    if (patientId) {
      try {
        const response: IAvailablePointRewardRO =
          await PatientService.getAvailablePointsByPatientId(patientId);
        setavailableRewardPoints(response?.data?.availablePoints);
      } catch (error) {
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof error === 'string' ? error : (error as any).response.data.error.message[0],
        );
      }
    }
  };

  const getPointsHistoryList = async (id: string) => {
    try {
      const limit = Constants.LIMIT;
      const skip = (dataPagePointsHistoryList - 1) * limit;
      const pointsHistoryQueryParams: IRewardRedeemHistoryQuery = {
        limit,
        skip,
      };
      const response: IRewardRedeemHistoryListRO = await PatientService.getPointsHistoryList(
        id,
        pointsHistoryQueryParams,
      );
      setPointsHistoryList(() => response?.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const tabs = [
    {
      label: 'Clinics',
      isHidden: false,
      id: 1,
      Component: (
        <>
          <ListODClinics
            odClinicList={odClinicList}
            onPageChangeHandler={onDataPageClinicChange}
            currentPage={dataPageClinic}
            getODClinicList={() => getODClinicList(route.pathname.split('/')[4] || route.pathname.split('/')[2])}
          />
        </>
      ),
    },
    {
      label: 'Appointments',
      isHidden: false,
      id: 2,
      Component: (
        <>
          <ListAppointments
            appointmentList={appointmentList}
            onPageChangeHandler={onDataPageAppointmentChange}
            currentPage={dataPageAppointment}
          />
        </>
      ),
    },
    {
      label: 'Family Members',
      isHidden: familyDetails?.id ? true : false,
      id: 3,
      Component: (
        <>
          <ListFamilyMembers
            familyMemberList={familyMemberList}
            onPageChangeHandler={onDataPageFamilyMemberChange}
            currentPage={dataPageFamilyList}
          />
        </>
      ),
    },
    {
      label: 'Points History',
      isHidden: familyDetails?.id ? true : false,
      id: 3,
      Component: (
        <>
          <ListPointsHistory
            pointsHistoryList={pointsHistoryList}
            currentPage={dataPagePointsHistoryList}
            onPageChangeHandler={onDataPagePointsHistoryChange}
          />
        </>
      ),
    },
  ];
  const handleClose = () => {
    setAddRewardModal(false);
    setRewardSearchValue('');
    setRewardPoints({
      name: '',
      rewardType: RewardType.PointsCredit,
      id: 0,
      pointsValue: 0,
      inventoryCount: 0,
    });
  };
  const handleRewardPointChange = (e: any) => {
    setRewardPoints(e);
  };

  const handleOpenConfirmAdd = () => {
    setRewardConfirmModal(true);
    setAddRewardModal(false);
  };

  const handleAddPoints = async () => {
    setShowLoader(true);
    setRewardConfirmModal(false);
    try {
      await RewardService.redeemReward({
        patientId: patientId,
        rewardType: RewardType.PointsCredit,
        rewardId: rewardPoints.id,
      });
      getRewardPoints();
      getPointsHistoryList(patientId);
      setRewardPoints({
        name: '',
        rewardType: RewardType.PointsCredit,
        id: 0,
        pointsValue: 0,
        inventoryCount: 0,
      });
      setShowSnackbar(true);
      setSnackbarMessage(`${rewardPoints.pointsValue} points credited`);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getRewardList = async () => {
    try {
      const rewardListQueryParams: IRewardQueryParam = {
        limit: Constants.PAGINATION_OPTIONS.limit,
        skip: 0,
        patientId: patientId,
        rewardType: ['points_credit'],
        ...(rewardSearchValue &&
          rewardSearchValue.length >= 3 && {
          searchString: rewardSearchValue,
          skip: 0,
        }),
      };
      const response: IListRewardRO = await RewardService.getConfigRewardList(
        rewardListQueryParams,
      );
      setRewardList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const onSearchCredit = (searchVal: string) => {
    setRewardSearchValue(searchVal);
  };

  useEffect(() => {
    (rewardSearchValue.length >= 3 || rewardSearchValue.length === 0) &&
      patientId &&
      getRewardList();
  }, [rewardSearchValue, addRewardModal]);

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (familyDetails || patientDetails) && (
        <Box className='patient-detail-container'>
          <Box className='patient-detail-interior'>
            <Box className='flex-row-btwn'>
              <div>
                <Typography variant='h5'>Patient Details</Typography>
                <BreadCrumbs
                  prevLink={prevLink}
                  activeLink={
                    route.pathname.split('/').length > 3
                      ? familyDetails?.firstName + ' ' + familyDetails?.lastName
                      : patientDetails?.firstName + ' ' + patientDetails?.lastName
                  }
                />
              </div>
              <div style={{ display: 'flex', gap: '16px' }}>
                {permission.update && (
                  <Buttons
                    text='Edit Details'
                    width='12em'
                    disable={false}
                    onClick={handleProfileUpdatePopupOpen}
                  />
                )}
                {permission.delete && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteBox'
                    onClick={handleDeletePopUp}
                  >
                    <DeleteOutlineSharpIcon style={{ width: '24px', height: '24px' }} />
                  </Button>
                )}
              </div>
            </Box>
            {(familyDetails?.id || patientDetails?.id) && (
              <Box>
                <CustomCard>
                  <PatientDetailCard
                    familyDetails={familyDetails}
                    patientDetails={patientDetails}
                    setUpdatePopUp={setUpdatePopUp}
                    availableRewardPoints={availableRewardPoints}
                    setAddRewardModal={setAddRewardModal}
                    getRewardList={getRewardList}
                  />
                </CustomCard>
              </Box>
            )}
          </Box>
          {(familyDetails?.id || patientDetails?.id) && (
            <Box>
              <CustomTab tabs={tabs} onTabChange={onTabChange} />
            </Box>
          )}

          <DeletePopup
            title={'Delete Patient'}
            description={`${
              (familyDetails?.firstName || patientDetails?.firstName) +
              ' ' +
              (familyDetails?.lastName || patientDetails?.lastName)
            }`}
            subDescription={''}
            open={openDeletePopup}
            buttonText={`Delete`}
            setOpen={setOpenDeletePopup}
            onDeleteClick={handleDeletePatient}
          />
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
          {updatePopUp && (
            <DialogBox
              onClose={onCancel}
              aria-labelledby='customized-dialog-title'
              open={updatePopUp}
            >
              <div style={{ paddingBottom: ' 1.5rem' }}>
                <DialogBoxTitle id='customized-dialog-title' onClose={onCancel}>
                  <Typography variant='h5'>Update Insurance</Typography>{' '}
                </DialogBoxTitle>
                <DialogContent id='scroll'>
                  <div style={{ marginTop: 10 }}>
                    <SearchDropdown
                      searchList={insuranceList?.insurances}
                      width={'100%'}
                      label={'Insurance'}
                      onSelectHandler={selectHandler}
                      onSearchHandler={search}
                      value={selected}
                      multiple={false}
                      insurances={true}
                      // fixed={selected ? true : false}
                    />
                  </div>
                  <DialogActions>
                    <div
                      style={{
                        display: 'flex',
                        gap: 20,
                        justifyContent: 'flex-end',
                        marginTop: 55,
                      }}
                    >
                      <Typography
                        style={{ color: zeplinColor.Background50, cursor: 'pointer' }}
                        onClick={onCancel}
                      >
                        Cancel
                      </Typography>
                      <Typography
                        variant='button'
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                        onClick={handleUpdate}
                      >
                        Update
                      </Typography>
                    </div>
                  </DialogActions>
                </DialogContent>
              </div>
            </DialogBox>
          )}
          <Dialog
            open={addRewardModal}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle>
              <Typography variant='h5' component={'span'}>
                Add Reward Points
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id='alert-dialog-description'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: 460,
                }}
              >
                <SearchDropdown
                  searchList={rewardList.rewards}
                  width={'100%'}
                  onSelectHandler={handleRewardPointChange}
                  onSearchHandler={onSearchCredit}
                  multiple={false}
                  value={rewardPoints}
                  staticValue={true}
                  label={'Select Reward*'}
                  fixed={false}
                  setRewardSearchValue={setRewardSearchValue}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                onClick={handleOpenConfirmAdd}
                style={{ color: `${zeplinColor.Primary}` }}
                autoFocus
                disableRipple
              >
                ADD POINTS
              </Button>
            </DialogActions>
          </Dialog>
          {rewardConfirmModal && (
            <DeletePopup
              title={'Add Reward Points'}
              description={''}
              customDescription={`Are you sure you want to credit ${rewardPoints.pointsValue} points to this patient’s profile?`}
              subDescription={''}
              open={rewardConfirmModal}
              buttonText={`ADD POINTS`}
              setOpen={setRewardConfirmModal}
              onDeleteClick={handleAddPoints}
              customButtonColor
              setRewardPoints={setRewardPoints}
            />
          )}
          <UpdatePatientProfile
            open={openPopup}
            setOpen={setOpenPopup}
            patientDetails={patientDetails}
            familyDetails={familyDetails}
            getPatientDetails={getDetails}
            getFamilyDetails={getFamilyDetails}
          />
        </Box>
      )}
    </>
  );
};

export default Details;
