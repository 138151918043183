/*
 * File: ViewClinic.tsx
 * Project: mint-portal
 * File Created: Monday, 12th September 2022 10:37:57 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 30th March 2023 2:52:05 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
// @flow
import * as React from 'react';
import './ViewClinic.css';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ClinicService } from '../../../../service/clinic.service';
import { IClinicImage, IViewClinicDetailsData, IClinicBooleanRO } from '../../../../model';
import { zeplinColor } from '../../../../theme';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { CustomCard } from '../../../shared/CustomCard/CustomCard';
import DeletePopup from '../../../shared/DeletePopup';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import PublicIcon from '@mui/icons-material/Public';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import 'react-multi-carousel/lib/styles.css';
import Loader from '../../../shared/Loader/Loader';
import getPermissionForModule from '../../../../utilities/getPermission';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import { Constants } from '../../../../utilities/constants';
import { Utilities } from '../../../../utilities/utilities';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const ViewClinic = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Clinics;
  const staffPermission = getPermission.permissions.Staff;
  const route = useLocation();
  const nav = useNavigate();
  const [data, setData] = useState<IViewClinicDetailsData>();
  const [status, setStatus] = useState(false);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const prevLink = [{ path: '/clinics', label: 'Clinics' }];
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  useEffect(() => {
    getClinicDetails();
  }, []);

  const onEdit = (path: string) => {
    nav(`/clinics/edit/${path}?id=${data?.id}`);
  };

  const onView = (path: string) => {
    nav(`/clinics/view/${path}?id=${data?.id}`);
  };

  const handleStatusPopupOpen = () => {
    setOpenStatusPopup(true);
  };

  const getClinicDetails = async () => {
    setShowLoader(true);
    try {
      const path = route.pathname.split('/')[3];
      const res: any = await ClinicService.viewClinicById(path);
      if (res?.data) {
        setData(res?.data);
        setStatus(res?.data?.appListing);
      }
      setShowLoader(false);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowLoader(false);
      setShowSnackbar(true);
    }
  };

  const toggleActive = async () => {
    if (data) {
      setShowLoader(true);
      try {
        const res: IClinicBooleanRO = await ClinicService.switchClinicAppListing(data?.id, !status);
        res?.message && setStatus(!status);
        getClinicDetails();
        setOpenStatusPopup(false);
        setShowSnackbar(true);
        setShowLoader(false);
        setShowSnackbarMessage('Clinic status updated');
      } catch (err) {
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowLoader(false);
        setShowSnackbar(true);
      }
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <Box className='view-clinic-container'>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Box>
              <Typography variant='h5'>View Clinic</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'View Clinic'} />
            </Box>
            {permission.update && (
              <Buttons
                text='Edit Clinic'
                width='160px'
                disable={false}
                onClick={() => onEdit('clinic-details')}
              />
            )}
          </Box>
          {data && data.servicesCount && data.servicesCount.unmapped > 0 && (
            <div>
              <CustomCard>
                <div style={{ display: 'flex', gap: 10, margin: 16 }}>
                  <div>
                    <ErrorIcon style={{ color: zeplinColor.Error, width: 32, height: 32 }} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <Typography variant='subtitle1'>
                      We’ve identified that some services have not been assigned operatories. Please
                      assign them to ensure that these services are displayed on the MINT dentistry
                      mobile app.
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                    >
                      <section onClick={() => onEdit('map-operatories')}>
                        Assign Operatories Now
                      </section>
                    </Typography>
                  </div>
                </div>
              </CustomCard>
            </div>
          )}
          {data && data.id && (
            <>
              <div>
                <CustomCard>
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: `solid 1px ${zeplinColor.Background90}`,
                      padding: '25px 32px 17px 30px',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                      <Typography variant='h2'>
                        {data && Object.keys(data) ? data.name : ''}
                      </Typography>
                      <Typography variant='body1'>{data?.address}</Typography>
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!permission.update}
                            color='primary'
                            checked={status}
                            onChange={handleStatusPopupOpen}
                          />
                        }
                        label={
                          status ? (
                            <Typography variant='h5' color='primary'>
                              Active
                            </Typography>
                          ) : (
                            <Typography variant='h5' color='error'>
                              Offline
                            </Typography>
                          )
                        }
                        labelPlacement='start'
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      padding: '20px 32px 35px 30px',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                      <div style={{ display: 'flex', gap: 20 }}>
                        <PublicIcon style={{ color: zeplinColor.Background70 }} />
                        <Typography variant='body1'>
                          {data?.serverAgent
                            ? data?.serverAgent?.timezone
                            : 'Timezone not available'}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', gap: 20 }}>
                        <div>
                          <WatchLaterIcon style={{ color: zeplinColor.Background70 }} />
                        </div>
                        <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                          {data?.availability
                            ? Object.keys(data?.availability).map((d: string, key: number) => (
                              <div style={{ display: 'flex', gap: 30 }} key={key}>
                                <Typography variant='body1' style={{ width: 85 }}>
                                  {d}
                                </Typography>
                                <Typography variant='body1'>
                                  {`${
                                    data?.availability[d].isOpen
                                      ? moment(
                                        data?.availability[d]?.startTime,
                                        Constants.MOMENT_FORMATS.HH_MM,
                                      ).format(Constants.MOMENT_FORMATS.TIME) +
                                          ' ' +
                                          '-' +
                                          ' ' +
                                          moment(
                                            data?.availability[d]?.closeTime,
                                            Constants.MOMENT_FORMATS.HH_MM,
                                          ).format(Constants.MOMENT_FORMATS.TIME) +
                                          ' '
                                      : 'closed'
                                  }`}{' '}
                                </Typography>
                              </div>
                            ))
                            : ''}
                        </div>
                      </div>
                      <div style={{ display: 'flex', gap: 20 }}>
                        <PhoneIcon style={{ color: zeplinColor.Background70 }} />
                        <Typography variant='body1'>
                          {Utilities.usFormatMobile(data?.phoneNumber)}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', gap: 20 }}>
                        <MailIcon style={{ color: zeplinColor.Background70 }} />
                        <Typography variant='body1'>{data?.email}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        gap: 40,
                        paddingTop: 10,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 16,
                          border: `solid 1px ${zeplinColor.Background90}`,
                        }}
                        className='view-clinic-carousel'
                      >
                        <Carousel responsive={responsive} showDots>
                          {data?.clinicImageDetails?.length ? (
                            data?.clinicImageDetails?.map((d: IClinicImage) => (
                              <img
                                src={d?.location}
                                key={d?.s3Key}
                                height={284}
                                width={480}
                                style={{ objectFit: 'contain' }}
                                alt='close-button'
                              />
                            ))
                          ) : (
                            <img
                              src={'/default-carousel.webp'}
                              height={'100%'}
                              width={480}
                              style={{ objectFit: 'contain' }}
                              alt='close-button'
                            />
                          )}
                        </Carousel>
                      </div>
                      <Typography
                        variant='body1'
                        style={{ color: zeplinColor.Background50, textAlign: 'end' }}
                      >{`Created on ${moment(data?.createdAt).format('DD MMM, YYYY')}`}</Typography>
                    </div>
                  </div>
                </CustomCard>
              </div>
              <div className='view-clinic-grid'>
                <CustomCard>
                  <Box style={{ padding: '20px 32px 20px 30px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>OpenDental Status</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('open-dental-configuration')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    {data?.serverAgent ? (
                      <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
                        Last synced on{' '}
                        {moment(data?.serverAgent?.lastSync).format(Constants.MOMENT_FORMATS.DATE)}{' '}
                        {moment(data?.serverAgent?.lastSync).format(Constants.MOMENT_FORMATS.TIME)}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {data?.openDentalConfig?.serverAgentStatus ? (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        {' '}
                        <CheckCircleIcon
                          style={{ color: zeplinColor.Primary, width: 32, height: 32 }}
                        />
                        <Typography variant='h5' style={{ color: zeplinColor.Primary }}>
                          Connected
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        <ErrorIcon style={{ color: zeplinColor.Error, width: 32, height: 32 }} />
                        <Typography variant='h5' style={{ color: zeplinColor.Error }}>
                          Disconnected
                        </Typography>
                      </div>
                    )}
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Box
                    style={{
                      padding: '20px 32px 20px 30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>Bookings &amp; Blockouts</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('booking')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    {data?.bookingStatus !== 'not_accepting' ? (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        {' '}
                        <CheckCircleIcon
                          style={{ color: zeplinColor.Primary, width: 32, height: 32 }}
                        />
                        <Typography variant='h5' style={{ color: zeplinColor.Primary }}>
                          Accepting
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        <ErrorIcon style={{ color: zeplinColor.Error, width: 32, height: 32 }} />
                        <Typography variant='h5' style={{ color: zeplinColor.Error }}>
                          Not Accepting
                        </Typography>
                      </div>
                    )}
                  </Box>
                </CustomCard>
                {staffPermission.read && (
                  <CustomCard>
                    <Box style={{ padding: '20px 32px 20px 30px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant='h6'>Clinic Staff</Typography>
                        <KeyboardArrowRightIcon
                          onClick={() => onView('clinic-staff')}
                          style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                        />
                      </div>
                      <div style={{ display: 'flex', marginTop: 20 }}>
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                            flexDirection: 'column',
                            borderRight: `solid 1px ${zeplinColor.Background90}`,
                            paddingRight: 30,
                          }}
                        >
                          <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
                            Total Staff
                          </Typography>
                          <Typography variant='h4'>{data?.staffCount.total}</Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                            flexDirection: 'column',
                            borderRight: `solid 1px ${zeplinColor.Background90}`,
                            padding: '0px 30px',
                          }}
                        >
                          <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
                            Active Staff
                          </Typography>
                          <Typography variant='h4' style={{ color: zeplinColor.Primary }}>
                            {data?.staffCount?.confirmed}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                            flexDirection: 'column',
                            paddingLeft: 30,
                          }}
                        >
                          <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
                            Pending Staff
                          </Typography>
                          <Typography variant='h4' style={{ color: zeplinColor.LightOrange }}>
                            {data?.staffCount?.invited}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </CustomCard>
                )}
                <CustomCard>
                  <Box
                    style={{
                      padding: '20px 32px 20px 30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>Operatories</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('map-operatories')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                      <div
                        style={{
                          display: 'flex',
                          gap: 10,
                          flexDirection: 'column',
                          borderRight: `solid 1px ${zeplinColor.Background90}`,
                          paddingRight: 30,
                        }}
                      >
                        <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
                          Mapped
                        </Typography>
                        <Typography variant='h4' style={{ color: zeplinColor.Primary }}>
                          {data?.operatoriesCount?.mapped}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: 10,
                          flexDirection: 'column',
                          paddingLeft: 30,
                        }}
                      >
                        <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
                          Unmapped
                        </Typography>
                        <Typography variant='h4' style={{ color: zeplinColor.LightOrange }}>
                          {data?.operatoriesCount?.unmapped}
                        </Typography>
                      </div>
                    </div>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Box style={{ padding: '20px 32px 20px 30px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>Services</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('services')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    <Typography variant='h4' style={{ marginTop: 20 }}>
                      {data?.servicesCount?.total || 0}
                    </Typography>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Box style={{ padding: '20px 32px 20px 30px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>Insurances</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('insurances')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    <Typography variant='h4' style={{ marginTop: 20 }}>
                      {data?.insurancesCount}
                    </Typography>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Box style={{ padding: '20px 32px 20px 30px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>Providers</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('providers')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    <Typography variant='subtitle1' style={{ marginTop: 20 }}>
                      {data?.provider?.providerFirstName ||
                        '' + ' ' + data?.provider?.providerLastName ||
                        ''}
                    </Typography>
                    <Typography variant='subtitle1' style={{}}>
                      {data?.primaryProvider?.providerFirstName ||
                        '' + ' ' + data?.primaryProvider?.providerLastName ||
                        ''}
                    </Typography>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Box
                    style={{
                      padding: '20px 32px 20px 30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>NPPWK</Typography>
                      <KeyboardArrowRightIcon
                        onClick={() => onView('nppwk')}
                        style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                      />
                    </div>
                    {data?.isNppwLinkExists ? (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        {' '}
                        <CheckCircleIcon
                          style={{ color: zeplinColor.Primary, width: 32, height: 32 }}
                        />
                        <Typography variant='h5' style={{ color: zeplinColor.Primary }}>
                          Available
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
                        <ErrorIcon style={{ color: zeplinColor.Error, width: 32, height: 32 }} />
                        <Typography variant='h5' style={{ color: zeplinColor.Error }}>
                          Not Available
                        </Typography>
                      </div>
                    )}
                  </Box>
                </CustomCard>
              </div>
            </>
          )}
          {data && data?.name && (
            <DeletePopup
              title={!status ? 'List Clinic' : 'Unlist Clinic'}
              customDescription={
                <>
                  Are you sure you want to {!status ? 'list' : 'unlist'} &#34;<b>{data?.name}</b>
                  &#34; ?
                </>
              }
              subDescription={
                !status
                  ? 'Upon listing, clinic will become active.'
                  : 'Upon unlisting, clinic will become offline.'
              }
              open={openStatusPopup}
              buttonText={!status ? 'Go Online' : 'Go Offline'}
              setOpen={setOpenStatusPopup}
              onDeleteClick={toggleActive}
              customButtonColor={!status ? true : false}
            />
          )}
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </Box>
      )}
    </>
  );
};
