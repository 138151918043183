/*
 * File: InsuranceDetail.tsx
 * Project: mint-portal
 * File Created: Wednesday, 29th March 2023 8:42:40 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 19th May 2023 8:13:43 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Button, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { Paper } from '@mui/material';
import './InsuranceDetails.css';
import { IViewPatientInsurance, IViewPatientInsuranceRO } from '../../../../model';
import { PatientService } from '../../../../service/patient.service';
import moment from 'moment';
import { Constants } from '../../../../utilities/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import DeletePopup from '../../../shared/DeletePopup';
import { Utilities } from '../../../../utilities/utilities';
import CustomToolTip from '../../../shared/CustomToolTip';
import EmptyState from '../../../shared/EmptyState/EmptyState';

const InsuranceDetails = () => {
  const { id } = useParams();
  const [insuranceDetails, setInsuranceDetails] = useState<IViewPatientInsurance>();
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const navigate = useNavigate();
  const route = useLocation();
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const patientId = route.pathname.split('/')[2];
  const familyId = route.pathname.includes('family-member') ? route.pathname.split('/')[4] : '';
  const prevLink = [
    { path: '/patient', label: 'Patients' },
    {
      path: familyId ? `/patient/${id}/family-member/${familyId}` : `/patient/${id}`,
      label: `${
        insuranceDetails?.policyHolderFirstName + ' ' + insuranceDetails?.policyHolderLastName
      }`,
    },
  ];

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const handleDeleteInsuranceDetails = async () => {
    if (id) {
      setShowLoader(true);
      try {
        if (insuranceDetails && familyId) {
          await PatientService.deleteFamilyInsuranceDetail(familyId);
          sessionStorage.setItem(
            'deletedInsuranceDetail',
            insuranceDetails?.policyHolderFirstName + ' ' + insuranceDetails?.policyHolderLastName,
          );
          navigate(`/patient/${id}/family-member/${familyId}`);
        } else if (insuranceDetails) {
          await PatientService.deletePatientInsuranceDetail(id);
          sessionStorage.setItem(
            'deletedInsuranceDetail',
            insuranceDetails?.policyHolderFirstName + ' ' + insuranceDetails?.policyHolderLastName,
          );
          navigate(`/patient/${id}`);
        }
      } catch (err) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      } finally {
        setShowLoader(false);
      }
    }
  };

  const getInsuranceDetailsById = async (id: string) => {
    try {
      const response: IViewPatientInsuranceRO = await PatientService.getInsuranceDetail(id);
      setInsuranceDetails(response?.data);
    } catch (error) {
      setShowSnackbar(true);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const getFamilyInsuranceDetailsById = async (familyId: string) => {
    try {
      const response: IViewPatientInsuranceRO = await PatientService.getFamilyInsuranceDetail(
        familyId,
      );
      setInsuranceDetails(response?.data);
    } catch (error) {
      setShowSnackbar(true);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (route.pathname.includes('family-member') && familyId) {
      setShowLoader(true);
      getFamilyInsuranceDetailsById(familyId);
    } else if (id) {
      setShowLoader(true);
      getInsuranceDetailsById(id);
    }
  }, [id, familyId]);

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='insuranceContainer'>
          <div className='insuranceDetailHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='insuranceTitle' color='inherit'>
                Insurance Details
                <br></br>
                {!showLoader && (
                  <BreadCrumbs prevLink={prevLink} activeLink={'Insurance Details'} />
                )}
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                <Button
                  variant='outlined'
                  color='secondary'
                  className='deleteButton'
                  onClick={handleDeletePopupOpen}
                >
                  <DeleteOutlineSharpIcon style={{ width: '24px', height: '24px' }} />
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    navigate(
                      familyId
                        ? `/patient/${patientId}/family-member/${familyId}/edit-insurance/${insuranceDetails?.policyHolderFirstName}/${insuranceDetails?.policyHolderLastName}/`
                        : `/patient/${patientId}/edit-insurance/${insuranceDetails?.policyHolderFirstName}/${insuranceDetails?.policyHolderLastName}`,
                    )
                  }
                >
                  EDIT DETAILS
                </Button>
              </div>
            </Toolbar>
          </div>
          {insuranceDetails && (
            <Paper className='insuranceDetailsBox' sx={{ borderRadius: '16px' }}>
              <div className='insuranceDetail'>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                  <div>
                    <Typography variant='subtitle1'>Insurance Carrier Name</Typography>
                    <CustomToolTip
                      title={insuranceDetails?.insurance.insuranceName}
                      width={'65rem'}
                    />
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Group Number</Typography>
                    <Typography>{insuranceDetails.groupNumber}</Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Insurance Phone Number</Typography>
                    <Typography>
                      {Utilities.usFormatMobile(insuranceDetails.insurancePhoneNumber)}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Member Id Number</Typography>
                    <Typography>{insuranceDetails.memberIdNumber}</Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Employer Name</Typography>
                    <Typography>{insuranceDetails.employerName}</Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Home Zipcode</Typography>
                    <Typography>{insuranceDetails.homeZipCode}</Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>
                      Is patient the insurance policy holder?
                    </Typography>
                    <Typography>
                      {insuranceDetails.isInsurancePolicyHolder ? 'Yes' : 'No'}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Policy Holder’s First Name</Typography>
                    <Typography>{insuranceDetails.policyHolderFirstName}</Typography>
                  </div>

                  <div>
                    <Typography variant='subtitle1'>Policy Holder’s Last Name</Typography>
                    <Typography>{insuranceDetails.policyHolderLastName}</Typography>
                  </div>

                  <div style={{ marginBottom: '32px' }}>
                    <Typography variant='subtitle1'>Policy Holder’s DOB</Typography>
                    <Typography>
                      {moment(insuranceDetails.dateOfBirth).format(Constants.MOMENT_FORMATS.DATE)}
                    </Typography>
                  </div>
                </div>
              </div>
              {insuranceDetails && (
                <DeletePopup
                  title={'Delete Insurance'}
                  customDescription={`Are you sure you want to delete the insurance from the patient’s profile? This action can’t be undone.`}
                  subDescription={''}
                  open={openDeletePopup}
                  buttonText={`Delete`}
                  setOpen={setOpenDeletePopup}
                  onDeleteClick={handleDeleteInsuranceDetails}
                />
              )}
            </Paper>
          )}
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </div>
      )}
    </>
  );
};

export default InsuranceDetails;
