/*
 * File: Manage.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:46:09 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 30th May 2023 6:02:08 pm
 * Modified By: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Manage.css';

import {
  AppointmentNotifications,
  DSOCard,
  Links,
  FAQ,
  ICard,
  LandingImage,
  LegalDocument,
  NPPWK,
  ReferFriend,
  RewardCategory,
  ServicesIconLibrary,
  ShowPendingBalance,
  Video,
} from './ManageCardConstants';
import React, { useEffect, useState } from 'react';
import { Switch, Toolbar, Typography } from '@material-ui/core';

import { Box } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { CustomCard } from '../../components/shared/CustomCard/CustomCard';
import DeletePopup from '../../components/shared/DeletePopup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Loader from '../../components/shared/Loader/Loader';
import { ManageService } from '../../service/manage.service';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../theme';

const Manage = () => {
  const navigate = useNavigate();
  const [balanceToggle, setBalanceToggle] = React.useState(false);
  const [showSnack, setShowSnack] = React.useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = React.useState<string>('');
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [showLoader, setShowLoader] = React.useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDetails, setAlertDetails] = useState<React.ChangeEvent<HTMLInputElement>>();

  useEffect(() => {
    if (ShowPendingBalance.roleAccess.includes(roleId)) getPendingBalance();
  }, []);

  const getPendingBalance = async () => {
    try {
      const result = await ManageService.getDsoPendingBalance();
      setBalanceToggle(result.data.showPendingBalance);
    } catch (err) {
      setShowSnack(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const showAlert = () => {
    if (alertDetails) {
      handlePendingBalance(alertDetails);
    }
    setAlert(false);
    setAlertDetails(undefined);
  };

  const customCard = (card: ICard) => (
    <CustomCard>
      <Box
        sx={{
          height: 96,
          width: '37.5vw',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0px 20px 0px 20px',
          cursor: 'pointer',
        }}
        onClick={() => (card.name !== ShowPendingBalance.name ? navigate(card.routeTo) : '')}
      >
        <section style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
          {card.icon}
          <Typography variant='h6'> {card.name}</Typography>
        </section>
        {card.name === ShowPendingBalance.name ? (
          <Switch
            color='primary'
            onChange={(e) => {
              setAlert(true);
              setAlertDetails(e);
            }}
            checked={balanceToggle}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => navigate(card.routeTo)}
            sx={{ color: zeplinColor.Primary, cursor: 'pointer' }}
          />
        )}
      </Box>
    </CustomCard>
  );

  const handlePendingBalance = async (value: React.ChangeEvent<HTMLInputElement>) => {
    setShowLoader(true);
    try {
      const check = !value.target.checked;
      const result = await ManageService.updateDsoPendingBalance(check);
      setShowSnack(true);
      setShowSnackbarMessage('Pending balance configuration updated');
      setBalanceToggle(result.data.showPendingBalance);
    } catch (err) {
      setShowSnack(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const showDSOConfigCard = () => {
    if (
      DSOCard.roleAccess.includes(roleId) ||
      NPPWK.roleAccess.includes(roleId) ||
      LegalDocument.roleAccess.includes(roleId) ||
      RewardCategory.roleAccess.includes(roleId) ||
      Links.roleAccess.includes(roleId)
    ) {
      return true;
    }
    return false;
  };

  const showAppConfigCard = () => {
    if (
      LandingImage.roleAccess.includes(roleId) ||
      ShowPendingBalance.roleAccess.includes(roleId) ||
      ReferFriend.roleAccess.includes(roleId) ||
      FAQ.roleAccess.includes(roleId)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='manageHeader'>
        <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
          <Typography variant='h5' className='manageTitle' color='inherit'>
            Manage
          </Typography>
        </Toolbar>
      </div>
      <div className='manageSection'>
        <>
          {showAppConfigCard() && (
            <section id='app'>
              <Box
                sx={{
                  height: 60,
                  width: 60,
                  borderRadius: 2,
                  marginBottom: 2.5,
                  backgroundColor: zeplinColor.Background50,
                }}
              >
                <PhoneIphoneIcon
                  sx={{ width: 32, height: 32, margin: 1.75, color: zeplinColor.Surface }}
                />
              </Box>
              <Typography variant='h2'>App Configuration</Typography>
              {Video.roleAccess.includes(roleId) && (
                <section style={{ marginTop: 24, marginBottom: 16 }}>{customCard(Video)}</section>
              )}
              {LandingImage.roleAccess.includes(roleId) && (
                <section style={{ marginTop: 24, marginBottom: 16 }}>
                  {customCard(LandingImage)}
                </section>
              )}
              {ReferFriend.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(ReferFriend)}</section>
              )}
              {FAQ.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(FAQ)}</section>
              )}
              {Links.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(Links)}</section>
              )}
              {AppointmentNotifications.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}>
                  {' '}
                  {customCard(AppointmentNotifications)}
                </section>
              )}
              {ShowPendingBalance.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(ShowPendingBalance)}</section>
              )}
            </section>
          )}
          {showDSOConfigCard() && (
            <section id='dso'>
              <Box
                sx={{
                  height: 60,
                  width: 60,
                  borderRadius: 2,
                  marginBottom: 2.5,
                  backgroundColor: zeplinColor.Background50,
                }}
              >
                <BusinessIcon
                  sx={{ width: 32, height: 32, margin: 1.75, color: zeplinColor.Surface }}
                />
              </Box>
              <Typography variant='h2'>DSO Settings</Typography>

              {DSOCard.roleAccess.includes(roleId) && (
                <section style={{ marginTop: 24, marginBottom: 16 }}>{customCard(DSOCard)}</section>
              )}
              {NPPWK.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(NPPWK)}</section>
              )}
              {LegalDocument.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(LegalDocument)}</section>
              )}
              {RewardCategory.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(RewardCategory)}</section>
              )}
              {ServicesIconLibrary.roleAccess.includes(roleId) && (
                <section style={{ marginBottom: 16 }}> {customCard(ServicesIconLibrary)}</section>
              )}
            </section>
          )}
        </>
      </div>
      {showSnack && <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnack} />}
      {alert && (
        <DeletePopup
          title={'Pending Balance'}
          description={''}
          customDescription={`Are you sure you want to ${
            balanceToggle ? 'hide' : 'show'
          } the pending balance on the MINT dentistry mobile app?`}
          subDescription={''}
          open={alert}
          buttonText={`YES`}
          setOpen={setAlert}
          onDeleteClick={showAlert}
          customButtonColor
        />
      )}
    </>
  );
};

export default Manage;
