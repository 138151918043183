/*
 * File: DetailDsoBlockout.tsx
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 5:06:45 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:35 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './DetailDsoBlockout.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toolbar, Typography, Button } from '@material-ui/core';
import { Paper } from '@mui/material';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Loader from '../../../../shared/Loader/Loader';
import { Constants } from '../../../../../utilities/constants';
import moment from 'moment';
import { zeplinColor } from '../../../../../theme';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import getPermissionForModule from '../../../../../utilities/getPermission';

import Buttons from '../../../../shared/Buttons';
import DeletePopup from '../../../../shared/DeletePopup';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { ManageService } from '../../../../../service/manage.service';
import { IDSOBlockout } from '../../../../../model';
import AddDsoBlockout from '../AddDsoBlockout/AddDsoBlockout';
import EmptyState from '../../../../shared/EmptyState/EmptyState';

const DetailDsoBlockout = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Booking;
  const [dsoBlockoutDetail, SetDsoBlockoutDetail] = useState<any>();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: '/manage/dso-blockouts', label: 'DSO Blockouts' },
  ];
  const navigate = useNavigate();
  const { id } = useParams();

  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<IDSOBlockout>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const onCancelEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      getDsoBlockoutDetail();
    }
  }, [id]);

  const getDsoBlockoutDetail = async () => {
    if (id) {
      try {
        const detail = await ManageService.getDsoBlockoutDetail(id);
        SetDsoBlockoutDetail(detail?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
          setForbiddenAccess(true);
        }
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackbar(true);
      }
    }
  };

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const handleDeleteBlockout = async () => {
    if (id) {
      setShowLoader(true);
      try {
        await ManageService.deleteDsoBlockoutDetail(id);
        sessionStorage.setItem('deletedDsoBlockout', dsoBlockoutDetail?.title);
        setShowLoader(false);
        navigate(prevLink[1].path);
      } catch (err) {
        setShowLoader(false);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackbar(true);
      }
    }
  };

  const updateDsoBlockout = async () => {
    if (id) {
      setUpdateLoader(true);
      try {
        const detail =
          updatePayload && (await ManageService.updateDsoBlockoutDetail(id, updatePayload));
        getDsoBlockoutDetail();
        setUpdateLoader(false);
        if (detail?.data) {
          setShowSnackbar(true);
          setSnackbarMessage(`${detail.data.title} changes saved`);
          setEdit(false);
        }
      } catch (err) {
        setUpdateLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='blockoutDetailContainer'>
          <div className='blockoutDetailHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                {!edit ? 'View DSO Blockout' : 'Edit DSO Blockout'}
                <br></br>
                <BreadCrumbs
                  prevLink={prevLink}
                  activeLink={!edit ? 'View DSO Blockout' : 'Edit DSO Blockout'}
                />
              </Typography>
              {!edit ? (
                <div style={{ display: 'flex', gap: '16px' }}>
                  {permission.delete && id && (
                    <Button
                      variant='outlined'
                      color='secondary'
                      className='deleteBox'
                      onClick={handleDeletePopupOpen}
                      disabled={false}
                    >
                      <DeleteOutlineSharpIcon
                        className='deleteIcon'
                        style={{ color: `${zeplinColor.Error}` }}
                      />
                    </Button>
                  )}
                  {permission.update && (
                    <Buttons
                      text='Edit Details'
                      width='12em'
                      disable={editBtn}
                      onClick={() => setEdit(!edit)}
                    />
                  )}
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    disabled={showLoader ? true : false}
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={onCancelEdit}
                  >
                    Cancel
                  </Button>
                  <Buttons
                    text='SAVE CHANGES'
                    width='100%'
                    disable={editBtn || showLoader ? true : false}
                    onClick={updateDsoBlockout}
                  />
                </div>
              )}
            </Toolbar>
          </div>
          {edit ? (
            <div>
              <AddDsoBlockout
                edit
                dsoBlockoutDetail={dsoBlockoutDetail}
                setUpdatePayload={setUpdatePayload}
                updateLoader={updateLoader}
                setEditBtn={setEditBtn}
              />
            </div>
          ) : (
            <>
              {dsoBlockoutDetail && (
                <Paper className='blockoutDetailBox' sx={{ borderRadius: '16px' }}>
                  <div
                    className='blockoutDetail'
                    style={{ display: 'flex', flexDirection: 'column', gap: 32 }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='subtitle1'>Blockout Title</Typography>
                      <Typography variant='body1'>{dsoBlockoutDetail?.title}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='subtitle1'>Duration</Typography>
                      <Typography variant='body1'>
                        {!dsoBlockoutDetail?.allDay
                          ? moment(dsoBlockoutDetail?.startDate).format(
                            Constants.MOMENT_FORMATS.DATE,
                          ) +
                            ' ' +
                            moment(dsoBlockoutDetail?.startTime, 'hh:mm:ss').format(
                              Constants.MOMENT_FORMATS.TIME,
                            ) +
                            ' TO ' +
                            moment(dsoBlockoutDetail?.endDate).format(
                              Constants.MOMENT_FORMATS.DATE,
                            ) +
                            ' ' +
                            moment(dsoBlockoutDetail?.endTime, 'hh:mm:ss').format(
                              Constants.MOMENT_FORMATS.TIME,
                            )
                          : moment(dsoBlockoutDetail.startDate).format(
                            Constants.MOMENT_FORMATS.DATE,
                          ) + ` All Day`}
                      </Typography>
                    </div>
                  </div>
                  {dsoBlockoutDetail && (
                    <DeletePopup
                      title={'Delete DSO Blockout'}
                      description={`"${dsoBlockoutDetail?.title}"`}
                      subDescription={''}
                      open={openDeletePopup}
                      buttonText={`Delete`}
                      setOpen={setOpenDeletePopup}
                      onDeleteClick={handleDeleteBlockout}
                    />
                  )}
                </Paper>
              )}
            </>
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};
export default DetailDsoBlockout;
