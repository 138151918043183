/*
 * File: AddStaff.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 8:30:10 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 4th October 2023 8:49:26 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Avatar, Button, Card, CardContent, Radio, styled, Typography } from '@material-ui/core';
import React, { useEffect, useState, FC } from 'react';
import './AddStaff.css';
import { StaffService } from '../../../../service/staff.service';
import { zeplinColor } from '../../../../theme';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { Constants } from '../../../../utilities/constants';
import { FileService } from '../../../../service/file.service';
import {
  IAddStaffPayload,
  IStaffDetailRO,
  IStaffDetails,
  IUpdateStaffPayload,
} from '../../../../model/staff.model';
import {
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
  IUpdateProfilePayload,
} from '../../../../model';
import { ClinicService } from '../../../../service/clinic.service';
// import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import Loader from '../../../shared/Loader/Loader';
import CustomTextField from '../../../shared/CustomTextField';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { Toolbar, Paper } from '@mui/material';
import Buttons from '../../../shared/Buttons';
import PhoneFieldInput from '../../../shared/CustomPhoneField/CustomPhoneField';
import { IFileDetails } from '../../../../model/file.model';
import getPermissionForModule from '../../../../utilities/getPermission';
import { RoleIds } from '../../../../utilities/roles';
import { validateEmail } from '../../../../utilities/validate';
import InfiniteDropDown from '../../../shared/InfiniteDropDown/InfiniteDropDown';

type editProps = {
  edit?: boolean;
  staffDetail?: IStaffDetails;
  setUpdatePayload?: React.Dispatch<React.SetStateAction<IUpdateStaffPayload | undefined>>;
  setUpdateProfile?: React.Dispatch<any>;
  updateLoader?: boolean;
  setEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  profileData?: any;
  setProfilePayload?: React.Dispatch<React.SetStateAction<IUpdateProfilePayload | undefined>>;
};

const cardStyles = {
  backgroundColor: zeplinColor.Primary50,
  border: `1px solid ${zeplinColor.Primary}`,
};

const disableCardStyle = {
  background: zeplinColor.Background90,
};

const CustomRadioButton = styled(Radio)({
  '&.Mui-checked': {
    color: zeplinColor.Primary,
  },
});

const AddStaff: FC<editProps> = ({
  edit,
  staffDetail,
  setUpdatePayload,
  setUpdateProfile,
  updateLoader,
  setEditBtn,
  profileData,
  setProfilePayload,
}: editProps) => {
  const getPermission = getPermissionForModule();
  const loggedInUserRoleId = getPermission.roleId;
  const [profileURL, setProfileURL] = useState<IFileDetails | string | ArrayBuffer | null>(
    staffDetail
      ? staffDetail?.profileImageDetails?.location
      : profileData
        ? profileData?.profileImageDetails?.location
        : '',
  );
  const [isImageError, setIsImageError] = useState(false);
  const [inputValue, setInputValue] = useState({
    firstName: staffDetail ? staffDetail.firstName : profileData ? profileData?.firstName : '',
    lastName: staffDetail ? staffDetail.lastName : profileData ? profileData?.lastName : '',
    email: staffDetail ? staffDetail.email : '',
    phone: staffDetail ? staffDetail.phoneNumber : profileData ? profileData?.phoneNumber : '',
  });
  const [radioButtonValue, setRadioButtonValue] = React.useState(
    staffDetail ? staffDetail?.roles[0].id : undefined,
  );
  const { firstName, lastName, email, phone } = inputValue;
  const [cardKey, setCardKey] = useState(staffDetail ? staffDetail?.roles[0].id : 0);
  const [profileImageObj, setProfileImageObj] = useState();
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [searchClinicList, setSearchClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [selectValue, setSelectValue] = useState<any>(
    staffDetail
      ? staffDetail?.roles[0].id === 4
        ? staffDetail?.clinics[0]
        : staffDetail?.clinics
      : [],
  );
  const [isEmailValid, setEmailValid] = useState(true);
  const [searchClinic, setSearchClinic] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackMessage, setSnackMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [disableAdd, setAddButtonActive] = useState<boolean>(true);
  const prevLink = [{ path: '/staff', label: 'Staff' }];
  const [clinicOptionsOpen, setClinicOptionsOpen] = useState(false);
  // const [searchClinic, setsearchClinic] = useState('');
  const [counter, setCounter] = useState(0);

  const navigate = useNavigate();
  const createStaffPayload: IUpdateStaffPayload = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phoneNumber: phone,
    profileImageS3Key: profileURL as string,
    roleId: cardKey,
    clinicIds:
      cardKey !== 4
        ? selectValue?.map((obj: any) => {
          return obj.id;
        })
        : [selectValue?.id],
  };
  const routes = useLocation();
  const profilePayload: IUpdateProfilePayload = {
    firstName: firstName,
    lastName: lastName,
    profileImageS3Key: profileURL as string,
    phoneNumber: phone,
  };

  const onSelectClinics = (e: any) => {
    // setSelectValue(e);
    setSearchClinic('');
    const unselect = selectValue?.length && selectValue.filter((v: any) => v.id === e.id);
    setSelectValue(
      unselect?.length
        ? [...selectValue.filter((v: any) => v.id !== e.id)]
        : selectValue?.length
          ? [...selectValue, e]
          : [e],
    );
    handleClinicOptionsOpen();
  };

  const getClinicUserList = async (skipArg?: number) => {
    try {
      setCounter(counter + 4);
      const queryParam: IClinicQueryParamOptions =
        searchClinic.length > 2
          ? { searchString: searchClinic }
          : { limit: 4, skip: skipArg ? skipArg : 0 };
      const response: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      const prevVal = searchClinicList?.clinics?.filter((e: any) =>
        e.name.toLowerCase().match(searchClinic),
      );

      console.log(prevVal);
      searchClinic.length > 2
        ? setSearchClinicList({
          clinics: prevVal?.length
            ? [
              ...prevVal,
              ...response?.data?.clinics?.filter(
                (e: any) => !prevVal.filter((ref: any) => ref.id == e.id).length,
              ),
            ]
            : response?.data?.clinics,
          total: response.data.total,
        })
        : setClinicList((prev) => ({
          clinics:
              skipArg && !searchClinic
                ? [
                  ...prev?.clinics,
                  ...response.data.clinics?.filter(
                    (e: any) => !prev?.clinics.filter((ref: any) => ref.id == e.id).length,
                  ),
                ]
                : response.data.clinics,
          total: response.data.total,
        }));
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setSnackMessage(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    }
  };

  useEffect(() => {
    getClinicUserList();
  }, [searchClinic]);

  const onSearchClinics = (searchVal: any) => {
    console.log(searchVal);
    setSearchClinic(searchVal?.target?.value);
  };
  console.log(searchClinic);
  const uploadProfileImage = async (e: any) => {
    const result = Math.round(Number(e.target.files[0].size / (1024 * 1024)) * 10) / 10;
    setProfileImageObj(e.target.files[0]);
    setUpdateProfile && setUpdateProfile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2 && result < 1) {
        setProfileURL(reader.result);
        setIsImageError(false);
      } else {
        setProfileImageObj(undefined);
        setIsImageError(true);
        setProfileURL('');
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const addStaff = async () => {
    setShowLoader(true);
    const addStaffPayload: IAddStaffPayload = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone,
      email: email,
      profileImageS3Key: '',
      roleId: cardKey,
      clinicIds:
        cardKey !== 4
          ? selectValue.map((obj: any) => {
            return obj.id;
          })
          : [selectValue.id],
    };
    try {
      const imageUploadRes = await FileService.getSignedUrlToUploadFile(
        Constants.FILE_UPLOAD_CATEGORY.STAFF_PROFILE_IMAGE,
        profileImageObj,
        { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
      );

      addStaffPayload.profileImageS3Key = imageUploadRes?.signedUrl?.data?.data?.s3Key;
      const response: IStaffDetailRO = await StaffService.addStaff(addStaffPayload);
      if (response?.data) {
        const staffName = response.data?.firstName + ' ' + response.data?.lastName;
        sessionStorage.setItem('addedStaff', staffName);
      }
      setShowSnackBar(false);
      setShowLoader(false);
      navigate('/staff');
    } catch (err) {
      setShowSnackBar(true);
      setSnackMessage(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const handleCardClick = (key: number) => {
    setCardKey(key);
    setRadioButtonValue(key);
    key === 4 ? setSelectValue({ id: '', name: '' }) : setSelectValue([]);
  };

  useEffect(() => {
    if (firstName && lastName && email && isEmailValid && cardKey !== 0 && phone.length === 12) {
      setAddButtonActive(false);
      setEditBtn && setEditBtn(false);
    } else {
      setAddButtonActive(true);
      setEditBtn && setEditBtn(true);
    }
    if (edit) {
      setUpdatePayload && setUpdatePayload(createStaffPayload);
    }
    if (
      (cardKey === 4 || cardKey === 3) &&
      (selectValue?.id === '' ||
        selectValue === undefined ||
        selectValue === null ||
        selectValue?.length === 0)
    ) {
      setAddButtonActive(true);
      setEditBtn && setEditBtn(true);
    }
  }, [firstName, lastName, email, cardKey, phone, profileURL, selectValue]);

  useEffect(() => {
    if (profilePayload && firstName && lastName && profileURL) {
      setEditBtn && setEditBtn(false);
    } else {
      setEditBtn && setEditBtn(true);
    }
    if (edit) {
      profilePayload.phoneNumber = phone;
      setProfilePayload && setProfilePayload(profilePayload);
    }
  }, [firstName, lastName, profileURL, phone]);

  const handleChangeText = (e: any) => {
    if (typeof e === 'string') {
      setInputValue({ ...inputValue, phone: e });
    } else {
      const { name, value } = e.target;
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (name === 'email') {
        emailCheck(value);
      }
    }
  };

  const emailCheck = (value: string) => {
    const isValid = validateEmail(value);
    setEmailValid(isValid);
  };

  const handleClinicOptionsOpen = () => {
    setSearchClinic('');
    setClinicOptionsOpen(!clinicOptionsOpen);
  };

  return (
    <>
      {(showLoader || updateLoader) && <Loader />}
      <div className='staffAddContainer'>
        {edit ? (
          ''
        ) : (
          <div className='staffAddHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                Add New Staff
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Add New Staff'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant='outlined'
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() => navigate('/staff')}
                >
                  Cancel
                </Button>
                <Buttons
                  text='Add New Staff'
                  width='100%'
                  disable={disableAdd}
                  onClick={() => addStaff()}
                />
              </div>
            </Toolbar>
          </div>
        )}

        <Paper className='staffAddBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div className='staffAdd'>
            <div className='staffProfile'>
              <Typography style={{ marginLeft: 14 }} color='textPrimary' variant='subtitle1'>
                Profile Photo
              </Typography>
              <input
                type='file'
                name='image-upload'
                id='input'
                accept='.jpg,.jpeg,.png'
                onChange={uploadProfileImage}
              />
              <label
                htmlFor='input'
                style={{ backgroundColor: zeplinColor.Surface, cursor: 'pointer' }}
              >
                <Avatar className='staffImageClass' src={profileURL as string} />
                <div className='staffIconClass' style={{ backgroundColor: zeplinColor.Surface }}>
                  {' '}
                  <CameraAltOutlinedIcon className='staffCameraIcon' />
                </div>
              </label>

              <div className='profileText'>
                <Typography className='profileDescription' variant='caption' color='textSecondary'>
                  JPG or PNG. Max size of 1 MB
                </Typography>
                {isImageError && (
                  <Typography className='profileDescription' variant='caption' color='error'>
                    Max size of 1 MB exceeded.
                  </Typography>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
              <div className='namesClass'>
                <CustomTextField
                  width='280px'
                  name='firstName'
                  value={firstName}
                  onChange={handleChangeText}
                  label='First Name*'
                ></CustomTextField>
                <CustomTextField
                  width='280px'
                  name='lastName'
                  value={lastName}
                  onChange={handleChangeText}
                  label='Last Name*'
                ></CustomTextField>
              </div>
              <div className='emailField'>
                <CustomTextField
                  style={{ width: '584px' }}
                  error={email ? !isEmailValid : false}
                  name='email'
                  value={email}
                  onChange={handleChangeText}
                  label='Email Address*'
                  disable={routes?.pathname?.includes('settings')}
                ></CustomTextField>
              </div>

              <div className='countryCodeParent'>
                <PhoneFieldInput
                  name='phone'
                  value={phone}
                  width='584px'
                  onChange={handleChangeText}
                  placeholder='Phone Number*'
                  padding={false}
                  margin={true}
                  error={false}
                  disable={routes?.pathname?.includes('settings')}
                />
              </div>
            </div>
            <Typography style={{ marginLeft: 14 }} color='textPrimary' variant='subtitle1'>
              User Role*
            </Typography>
            <div className='cardsRowClass'>
              <Card
                style={
                  (edit &&
                    loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                    routes?.pathname?.includes('staff')) ||
                  routes?.pathname?.includes('settings')
                    ? disableCardStyle
                    : cardKey === 1
                      ? cardStyles
                      : {}
                }
                className='smallCard1'
              >
                <CardContent>
                  <div className='radioButtonClass'>
                    <Typography>DSO Admin</Typography>
                    <CustomRadioButton
                      checked={radioButtonValue === 1}
                      onChange={() => handleCardClick(1)}
                      value={1}
                      name='color-radio-button-demo'
                      inputProps={{ 'aria-label': '1' }}
                      disabled={
                        showLoader ||
                        updateLoader ||
                        (edit &&
                          loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                          routes?.pathname?.includes('staff')) ||
                        routes?.pathname?.includes('settings')
                      }
                      style={{
                        left: '10px',
                        bottom: '1px',
                      }}
                    />
                  </div>
                  <Typography color='textSecondary' variant='caption' className=''>
                    This user has access to all clinics and their associated data
                  </Typography>
                  <div className='checkIconRow'>
                    {Constants.ROLE_DESCRIPTION[1].map((roleDesc, index) => {
                      return (
                        <div key={index} className='listItem'>
                          <CheckCircleOutlineIcon
                            sx={{ color: zeplinColor.Primary }}
                            style={{ height: '18px', width: '18px' }}
                          />
                          <Typography
                            style={{ lineHeight: '1.2', marginBottom: '10px' }}
                            variant='caption'
                          >
                            {roleDesc}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>

              <Card
                style={
                  (edit &&
                    loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                    routes?.pathname?.includes('staff')) ||
                  routes?.pathname?.includes('settings')
                    ? disableCardStyle
                    : cardKey === 3
                      ? cardStyles
                      : {}
                }
                className='smallCard2'
              >
                <CardContent>
                  <div className='radioButtonClass'>
                    <Typography>Clinic Admin</Typography>
                    <CustomRadioButton
                      checked={radioButtonValue === 3}
                      onChange={() => handleCardClick(3)}
                      value={3}
                      name='color-radio-button-demo'
                      inputProps={{ 'aria-label': '3' }}
                      disabled={
                        showLoader ||
                        updateLoader ||
                        (edit &&
                          loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                          routes?.pathname?.includes('staff')) ||
                        routes?.pathname?.includes('settings')
                      }
                      style={{
                        left: '10px',
                        bottom: '1px',
                      }}
                    />
                  </div>
                  <Typography color='textSecondary' variant='caption' className=''>
                    This user has access to assigned clinics and their associated data
                  </Typography>
                  <div className='checkIconRow'>
                    {Constants.ROLE_DESCRIPTION[3].map((roleDesc, index) => {
                      return (
                        <div key={index} className='listItem'>
                          <CheckCircleOutlineIcon
                            sx={{ color: zeplinColor.Primary }}
                            style={{ height: '18px', width: '18px' }}
                          />
                          <Typography style={{ lineHeight: '1.2' }} variant='caption'>
                            {roleDesc}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>

              <Card
                style={
                  (edit &&
                    loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                    routes?.pathname?.includes('staff')) ||
                  routes?.pathname?.includes('settings')
                    ? disableCardStyle
                    : cardKey === 4
                      ? cardStyles
                      : {}
                }
                className='smallCard3'
              >
                <CardContent>
                  <div className='radioButtonClass'>
                    <Typography>Office Manager</Typography>
                    <CustomRadioButton
                      checked={radioButtonValue === 4}
                      onChange={() => handleCardClick(4)}
                      value={4}
                      name='color-radio-button-demo'
                      inputProps={{ 'aria-label': '4' }}
                      disabled={
                        showLoader ||
                        updateLoader ||
                        (edit &&
                          loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                          routes?.pathname?.includes('staff')) ||
                        routes?.pathname?.includes('settings')
                      }
                      style={{
                        left: '10px',
                        bottom: '1px',
                      }}
                    />
                  </div>
                  <Typography color='textSecondary' variant='caption' className=''>
                    This user has access to assigned clinics and their associated data
                  </Typography>
                  <div className='checkIconRow'>
                    {Constants.ROLE_DESCRIPTION[4].map((roleDesc, index) => {
                      return (
                        <div key={index} className='listItem'>
                          <CheckCircleOutlineIcon
                            sx={{ color: zeplinColor.Primary }}
                            style={{ height: '18px', width: '18px' }}
                          />
                          <Typography
                            style={{ lineHeight: '1.2', marginBottom: '10px' }}
                            variant='caption'
                          >
                            {roleDesc}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>

              <Card
                style={
                  (edit &&
                    loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                    routes?.pathname?.includes('staff')) ||
                  routes?.pathname?.includes('settings')
                    ? disableCardStyle
                    : cardKey === 2
                      ? cardStyles
                      : {}
                }
                className='smallCard4'
              >
                <CardContent>
                  <div className='radioButtonClass4'>
                    <Typography className='marketingHeading'>Marketing Manager</Typography>
                    <CustomRadioButton
                      checked={radioButtonValue === 2}
                      onChange={() => handleCardClick(2)}
                      value={2}
                      name='color-radio-button-demo'
                      inputProps={{ 'aria-label': '2' }}
                      disabled={
                        showLoader ||
                        updateLoader ||
                        (edit &&
                          loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                          routes?.pathname?.includes('staff')) ||
                        routes?.pathname?.includes('settings')
                      }
                      style={{
                        left: '138px',
                        bottom: '4px',
                      }}
                    />
                  </div>
                  <Typography color='textSecondary' variant='caption' className=''>
                    This user has access to all clinics and their associated data
                  </Typography>
                  <div className='checkIconRow'>
                    {Constants.ROLE_DESCRIPTION[2].map((roleDesc, index) => {
                      return (
                        <div key={index} className='listItem'>
                          <CheckCircleOutlineIcon
                            sx={{ color: zeplinColor.Primary }}
                            style={{ height: '18px', width: '18px' }}
                          />
                          <Typography
                            style={{ lineHeight: '1.2', marginBottom: '10px' }}
                            variant='caption'
                          >
                            {roleDesc}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className='clinicsInput'>
              {cardKey === 1 || cardKey === 2 ? (
                <CustomTextField
                  width='552px'
                  value={'All Clinics'}
                  disable={
                    (edit &&
                      loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                      routes?.pathname?.includes('staff')) ||
                    routes?.pathname?.includes('settings')
                  }
                ></CustomTextField>
              ) : (
                <>
                  {/* <SearchDropdown
                    searchList={clinicList?.clinics}
                    label={'Assign Clinics'}
                    onSelectHandler={onSelectClinics}
                    onSearchHandler={onSearchClinics}
                    value={selectValue}
                    multiple={cardKey === 4 ? false : true}
                    frontOffice={cardKey === 4 ? true : false}
                    placeholderPosition={true}
                    disable={
                      (edit &&
                        loggedInUserRoleId !== RoleIds.DSO_ADMIN &&
                        routes?.pathname?.includes('staff')) ||
                      routes?.pathname?.includes('settings')
                    }
                  /> */}
                  <InfiniteDropDown
                    handleOpen={handleClinicOptionsOpen}
                    open={clinicOptionsOpen}
                    searchList={searchClinic ? searchClinicList?.clinics : clinicList?.clinics}
                    name={'staff'}
                    zIndex={'10'}
                    onSelectHandler={onSelectClinics}
                    setSelected={setSelectValue}
                    onSearchHandler={onSearchClinics}
                    search={searchClinic}
                    value={selectValue}
                    disable={false}
                    label={'Assign Clinics'}
                    infinite={''}
                    dataLength={
                      searchClinic ? searchClinicList?.clinics?.length : clinicList?.clinics?.length
                    }
                    hasMore={
                      searchClinic
                        ? searchClinicList?.clinics?.length < searchClinicList?.total
                        : clinicList?.clinics?.length < clinicList?.total
                    }
                    next={getClinicUserList}
                    counter={counter}
                    clearSearch={false}
                    multiple={true}
                    width={'584px'}
                  />
                </>
              )}
            </div>
          </div>
        </Paper>
        {showSnackBar && (
          <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};
export default AddStaff;
