/*
 * File: Staff.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 8:46:00 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 19th June 2023 6:49:54 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Staff.css';

import { Badge, Chip } from '@mui/material';
import { Button, Toolbar, Typography } from '@material-ui/core';
import {
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
  IClinics,
  IStaffList,
  IStaffListQueryParams,
  IStaffListResponse,
} from '../../model';
import React, { FC, useEffect, useState } from 'react';

import Buttons from '../../components/shared/Buttons';
import { ClinicService } from '../../service/clinic.service';
import { Constants } from '../../utilities/constants';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterStaff from '../../components/feature/staff/FilterStaff/FilterStaff';
import ListStaff from '../../components/feature/staff/ListStaff/ListStaff';
import Loader from '../../components/shared/Loader/Loader';
import SearchBar from '../../components/shared/SearchBar';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import { StaffService } from '../../service/staff.service';
import getPermissionForModule from '../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../theme';

type UserRole = {
  id: number;
  name: string;
};

const userRoles: UserRole[] = [
  { id: 1, name: 'DSO Admin' },
  { id: 3, name: 'Clinic Admin' },
  { id: 4, name: 'Office Manager' },
  { id: 2, name: 'Marketing Manager' },
];
const Staff: FC = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Staff;
  const deletedStaff = sessionStorage.getItem('deletedStaff');
  const addedStaff = sessionStorage.getItem('addedStaff');
  const navigate = useNavigate();
  const [staffList, setStaffList] = useState<IStaffList>({ total: 0, staff: [] });
  const [dataPage, setDataPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackBarMsg, setSnackBarMsg] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  // Filter
  const [selectRoles, setSelectRoles] = useState<UserRole[]>([]);
  const [searchRole, setSearchRole] = useState('');
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [selectClinics, setSelectClinics] = useState<IClinics[]>([]);
  const [searchClinic, setSearchClinic] = useState('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  console.log(searchRole);
  /**
   * Triggered from ListStaff component on page change
   * sets the page number accordingly
   * @param {number} page
   */
  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
    setDataPage(1);
  };

  const onSearchRoles = (searchRoles: any) => {
    setSearchRole(searchRoles);
  };

  const onSearchClinics = (searchVal: string) => {
    setSearchClinic(searchVal);
  };

  useEffect(() => {
    getClinicUserList();
  }, [searchClinic]);

  useEffect(() => {
    if (deletedStaff) {
      setShowSnackbar(true);
      setSnackBarMsg(`${deletedStaff} is deleted`);
      sessionStorage.removeItem('deletedStaff');
    }
    if (addedStaff) {
      setShowSnackbar(true);
      setSnackBarMsg(`${addedStaff} is added`);
      sessionStorage.removeItem('addedStaff');
    }
    setShowLoader(true);
    getStaffUserList();
  }, [dataPage, searchValue, selectClinics, selectRoles]);

  /**
   * Gets the list of staff
   */
  const getStaffUserList = async () => {
    setShowLoader(true);
    try {
      if (searchValue && searchValue.length < 3) {
        setShowLoader(false);
      }
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const queryParam: IStaffListQueryParams = {
        limit,
        skip,
        ...(searchValue &&
          searchValue.length >= 3 && {
          searchString: searchValue,
          skip: dataPage !== 1 ? skip : 0,
        }),
        ...(selectRoles && {
          roleIds: selectRoles.map((role: any) => role?.id),
        }),
        ...(selectClinics && {
          clinicIds: selectClinics.map((clinic: any) => clinic?.id),
        }),
      };
      const response: IStaffListResponse = await StaffService.getStaffList(queryParam);
      if (response.data.total <= limit) {
        setDataPage(1);
      }
      setStaffList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const getClinicUserList = async () => {
    setShowLoader(true);
    try {
      const queryParam: IClinicQueryParamOptions =
        searchClinic.length > 2 ? { searchString: searchClinic } : { limit: 3, skip: 0 };
      const response: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };
  const filterData = (roleList: UserRole[], clinicList: IClinics[]) => {
    setSelectClinics(clinicList);
    setSelectRoles(roleList);
    setShowFilter(false);
    setDataPage(1);
  };

  const closeFilters = () => {
    setShowFilter(false);
  };

  const handleDeleteChip = (values: UserRole | IClinics, type: string) => () => {
    if (type === 'role') {
      const newarray = selectRoles.filter((i: UserRole) => {
        return i.id !== values.id;
      });
      setSelectRoles(newarray);
    } else {
      const newarray = selectClinics.filter((i: IClinics) => {
        return i.id !== values.id;
      });
      setSelectClinics(newarray);
    }
    setDataPage(1);
  };

  const clearAllFilter = () => {
    setSelectRoles([]);
    setSelectClinics([]);
  };
  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='staffContainer'>
          <div className='staffHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
                Staff
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <SearchBar onSearchHandler={onSearch} placeholderText={'Search by Staff Name'} />
                <div style={{ border: `1px solid ${zeplinColor.Primary}` }} className='filterDiv'>
                  <Badge
                    overlap="rectangular"
                    color='error'
                    variant='dot'
                    invisible={
                      selectClinics &&
                      selectClinics.length === 0 &&
                      selectRoles &&
                      selectRoles.length === 0
                    }
                  >
                    <FilterAltOutlinedIcon
                      onClick={() => {
                        setShowFilter(true);
                      }}
                      sx={{
                        color: zeplinColor.Primary,
                        width: '24px',
                        height: '24px',
                        borderRadius: '4px',
                        margin: '8px 8px 0px 8px',
                      }}
                    ></FilterAltOutlinedIcon>
                  </Badge>
                  {showFilter && (
                    <FilterStaff
                      open={showFilter}
                      onClick={closeFilters}
                      onFilterClick={filterData}
                      roles={userRoles}
                      selectedRoles={selectRoles}
                      searchRole={onSearchRoles}
                      clinicLists={clinicList?.clinics}
                      searchClinic={onSearchClinics}
                      selectedClinics={selectClinics}
                    />
                  )}
                </div>
                {permission.create && (
                  <Buttons
                    text='Add new staff'
                    width='12em'
                    disable={false}
                    onClick={() => navigate('/staff/add')}
                  />
                )}
              </div>
            </Toolbar>
          </div>
          <div style={{ marginLeft: '28px', width: '76.5vw' }}>
            {!!selectRoles &&
              selectRoles?.map((i: any) => {
                return (
                  <Chip
                    style={{ textTransform: 'capitalize', marginRight: 8, marginTop: 2 }}
                    key={i.id}
                    label={i.name}
                    onDelete={handleDeleteChip(i, 'role')}
                  />
                );
              })}
            {!!selectClinics &&
              selectClinics?.map((i: any) => {
                return (
                  <Chip
                    style={{ textTransform: 'capitalize', marginRight: 8, marginTop: 2 }}
                    key={i.id}
                    label={i.name}
                    onDelete={handleDeleteChip(i, 'clinic')}
                  />
                );
              })}
            {(selectClinics?.length > 0 || selectRoles?.length > 0) && (
              <Button
                onClick={clearAllFilter}
                variant='text'
                style={{ color: zeplinColor.Primary }}
              >
                Clear all
              </Button>
            )}
          </div>
          {staffList.staff.length > 0 && !showLoader && (
            <ListStaff
              staffList={staffList}
              onPageChangeHandler={onDataPageChange}
              currentPage={dataPage}
            />
          )}

          <div style={{ marginTop: '15%' }}>
            {!staffList.staff.length && !showLoader && (
              <EmptyState
                path={'/staff/add'}
                text={'No staff to show'}
                subText={permission.create ? 'Get started by clicking on Add New Staff.' : ''}
                buttonText={permission.create ? 'ADD NEW STAFF' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            )}
          </div>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackBarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default Staff;
