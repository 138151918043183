/*
 * File: booking.tsx
 * Project: mint-portal
 * File Created: Tuesday, 13th September 2022 2:36:03 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 12th June 2023 6:15:20 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState, useEffect } from 'react';
import Loader from '../../components/shared/Loader/Loader';
import './Booking.css';
import { Typography, Toolbar, Switch } from '@material-ui/core';
import getPermissionForModule from '../../utilities/getPermission';
import Buttons from '../../components/shared/Buttons';
import { useNavigate, useLocation } from 'react-router-dom';
import BreadCrumbs from '../../components/shared/BreadCrumbs/BreadCrumbs';
import CustomCard from '../../components/shared/CustomCard';
import { FormControlLabel } from '@mui/material';
import CustomTab from '../../components/shared/CustomTab';
import ListBlockouts from '../../components/feature/booking/ListBlockouts/ListBlockouts';
import {
  IClinicBlockoutListResponse,
  IProviderByClinicIdQueryParamOptions,
  IClinicBlockoutListRO,
  IOngoingBlockoutRO,
  IOngoingBlockoutDetails,
  BookingStatus,
  IClinicOpenDentalBlockoutListRO,
  IClinicOpenDentalBlockoutListResponse,
  IViewClinicDetails,
} from '../../model';
import { Constants } from '../../utilities/constants';
import { ClinicService } from '../../service/clinic.service';
import OnGoingBlockout from '../../components/feature/booking/OngoingBlockout/OngoingBlockout';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import ListOpenDentalBlockouts from '../../components/feature/booking/ListOpenDentalBlockouts/ListOpenDentalBlockouts';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import DeletePopup from '../../components/shared/DeletePopup';

const Booking: FC = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Booking;
  const deletedBlockout = sessionStorage.getItem('deletedBlockout');
  const addedBlockout = sessionStorage.getItem('addedBlockout');
  const [showLoader, setShowLoader] = useState(false);
  const [dataPage, setDataPage] = useState(1);
  const [blockoutList, setBlockoutList] = useState<IClinicBlockoutListResponse>({
    total: 0,
    blockouts: [],
  });
  const [openDentalBlockoutList, setOpenDentalBlockoutList] =
    useState<IClinicOpenDentalBlockoutListResponse>({
      total: 0,
      blockouts: [],
    });
  const [isOngoingBlockout, setIsOngoingBlockout] = useState<IOngoingBlockoutDetails>();
  const [bookingStatus, setBookingStatus] = useState<boolean>(false); // Map status from props
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertDetails, setAlertDetails] = useState<React.ChangeEvent<HTMLInputElement>>();

  const navigate = useNavigate();
  const prevLink = [
    { path: '/clinics', label: 'Clinics' },
    { path: `/clinics/detail/${id}`, label: 'View Clinic' },
  ];

  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  useEffect(() => {
    if (id) getClinicDetailsById();
  }, [id]);

  useEffect(() => {
    if (deletedBlockout) {
      setShowSnackbar(true);
      setSnackbarMessage(`${deletedBlockout} is deleted`);
      sessionStorage.removeItem('deletedBlockout');
    }
    if (addedBlockout) {
      setShowSnackbar(true);
      setSnackbarMessage(`${addedBlockout} is added`);
      sessionStorage.removeItem('addedBlockout');
    }
    getOngoingBlockout();
    getBlockoutList();
    getOpenDentalBlockoutList();
  }, [dataPage]);

  const BookingTabs = [
    {
      label: 'Blockouts',
      id: 1,
      isHidden: false,
      Component: permission.read && !showLoader && (
        <ListBlockouts
          blockoutList={blockoutList}
          onPageChangeHandler={onDataPageChange}
          currentPage={dataPage}
        />
      ),
    },
    {
      label: 'Open dental blockouts',
      id: 2,
      isHidden: false,
      Component: permission.read && !showLoader && (
        <ListOpenDentalBlockouts
          openDentalBlockoutList={openDentalBlockoutList}
          onPageChangeHandler={onDataPageChange}
          currentPage={dataPage}
        />
      ),
    },
  ];

  const onTabChange = () => {
    setDataPage(1);
  };

  const getClinicDetailsById = async () => {
    if (id) {
      setShowLoader(true);
      try {
        const res: IViewClinicDetails = await ClinicService.viewClinicById(id);
        if (res?.data) {
          setBookingStatus(res?.data?.bookingStatus === BookingStatus.ACCEPTING ? true : false);
        }
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const getBlockoutList = async () => {
    if (id) {
      setShowLoader(true);
      try {
        const limit = Constants.LIMIT;
        const skip = (dataPage - 1) * limit;

        const blockoutListQueryParams: IProviderByClinicIdQueryParamOptions = {
          limit,
          skip,
        };
        const response: IClinicBlockoutListRO = await ClinicService.getBlockoutListByClinicId(
          id,
          blockoutListQueryParams,
        );
        setBlockoutList(() => response.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
          setForbiddenAccess(true);
        }
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const getOpenDentalBlockoutList = async () => {
    if (id) {
      setShowLoader(true);
      try {
        const limit = Constants.LIMIT;
        const skip = (dataPage - 1) * limit;

        const blockoutListQueryParams: IProviderByClinicIdQueryParamOptions = {
          limit,
          skip,
        };
        const response: IClinicOpenDentalBlockoutListRO =
          await ClinicService.getOpenDentalBlockoutsByClinicId(id, blockoutListQueryParams);
        setOpenDentalBlockoutList(() => response.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
          setForbiddenAccess(true);
        }
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const getOngoingBlockout = async () => {
    if (id) {
      setShowLoader(true);
      try {
        const response: IOngoingBlockoutRO = await ClinicService.getOngoingBlockout(id);
        setIsOngoingBlockout(() => response.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
          setForbiddenAccess(true);
        }
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const updateBookingStatus = async (statusBooking: boolean) => {
    if (id) {
      setShowLoader(true);
      try {
        const status = statusBooking ? BookingStatus.ACCEPTING : BookingStatus.NOT_ACCEPITNG;
        await ClinicService.updateClinicBookingStatus(id, status);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const handleBookingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookingStatus(() => !event.target.checked);
    updateBookingStatus(!event.target.checked);
  };
  const showAlert = () => {
    if (alertDetails) {
      handleBookingStatus(alertDetails);
    }
    setAlert(false);
    setAlertDetails(undefined);
  };

  return (
    <>
      {showLoader && <Loader />}
      {isForbiddenAccess || !permission.read ? (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      ) : (
        <div className='booking' style={{ gap: 30 }}>
          <div className='bookingHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='bookingTitle' color='inherit'>
                Clinic Details
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Bookings'} />
              </Typography>

              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                {permission.create && (
                  <Buttons
                    text='Add Blockout'
                    width='11.5em'
                    disable={false}
                    onClick={() => navigate(`/clinics/add/booking?id=${id}`)}
                  />
                )}
              </div>
            </Toolbar>
          </div>
          <section>
            <CustomCard width={'76.5vw'} margin={'0px 0px 0px 28px'}>
              <section className='bookingStatusCard'>
                <Typography variant='h6'>Booking Status</Typography>
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      disabled={!permission.update}
                      color='primary'
                      onChange={(e) => {
                        setAlert(true);
                        setAlertDetails(e);
                      }}
                      checked={bookingStatus}
                    />
                  }
                  label={
                    bookingStatus ? (
                      <Typography variant='h5' color='primary'>
                        Accepting
                      </Typography>
                    ) : (
                      <Typography variant='h5' color='error'>
                        Not Accepting
                      </Typography>
                    )
                  }
                />
              </section>
            </CustomCard>
          </section>
          {isOngoingBlockout && Object.keys(isOngoingBlockout).length > 0 && (
            <section>
              <OnGoingBlockout isOngoingBlockout={isOngoingBlockout} />
            </section>
          )}
          <section className='customTab'>
            <CustomTab tabs={BookingTabs} onTabChange={onTabChange} />
          </section>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
      {alert && (
        <DeletePopup
          title={'Booking Status'}
          description={''}
          customDescription={`Are you sure you want to update the booking status for this clinic?`}
          subDescription={''}
          open={alert}
          buttonText={`YES`}
          setOpen={setAlert}
          onDeleteClick={showAlert}
          customButtonColor
        />
      )}
    </>
  );
};
export default Booking;
