/*
 * File: CustomLegend.tsx
 * Project: mint-portal
 * File Created: Friday, 18th November 2022 10:22:13 am
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th December 2022 5:53:04 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { zeplinColor } from '../../../../theme';
import CustomToolTip from '../../CustomToolTip';

type legendProps = { color: string; label: string; percentage: number };

const CustomLegend = ({ color, label, percentage }: legendProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
      style={{ gap: 10 }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        style={{ gap: 8 }}
      >
        <SquareRoundedIcon sx={{ color: color }} />
        <CustomToolTip title={label} width={'140px'} size={12} />
      </Box>
      <Typography variant='caption' style={{ color: zeplinColor.Background70 }}>
        {' '}
        {percentage + '%'}
      </Typography>
    </Box>
  );
};

export default CustomLegend;
