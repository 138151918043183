/*
 * File: ClinicDetails.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:10 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 14th September 2023 7:10:06 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Buttons from '../../../../shared/Buttons';
import Field from '../../../../shared/CustomTextField';
import { useDropzone } from 'react-dropzone';
import './ClinicDetails.css';
import { zeplinColor } from '../../../../../theme';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PhoneFieldInput from '../../../../shared/CustomPhoneField/CustomPhoneField';
import { FileService } from '../../../../../service/file.service';
import { Constants } from '../../../../../utilities/constants';
import { ClinicService } from '../../../../../service/clinic.service';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { IAddClinic, IViewClinicDetailsData } from '../../../../../model';
import Loader from '../../../../shared/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { validateEmail, validPhone } from '../../../../../utilities/validate';
import InfoTextBox from '../../../../shared/InfoText/InfoText';

type clinicDetailsProps = {
  setAddClinic: React.Dispatch<
    React.SetStateAction<IAddClinic | undefined | IViewClinicDetailsData>
  >;
  addClinic?: undefined | IAddClinic | IViewClinicDetailsData;
  setUpdatedClinicDetails: React.Dispatch<React.SetStateAction<IViewClinicDetailsData | undefined>>;
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
  getClinicDetails: () => Promise<void>;
};

const ClinicDetails = ({
  setAddClinic,
  addClinic,
  setUpdatedClinicDetails,
  updatedClinicDetails,
  handleCreateClinicBtn,
  getClinicDetails,
}: //
clinicDetailsProps) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disable, setDisable] = useState(false);
  const [errorImg, setErrorImg] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [profileImgObj, setProfileImgObj] = useState<any>([]);
  const [profile, setProfile] = useState<any[]>([]);
  const [resImage, setResImage] = useState<any[]>([]);
  const nav = useNavigate();
  const route = useLocation();
  const ref = useRef<any>();

  useEffect(() => {
    if (updatedClinicDetails?.id) {
      setClinicName(updatedClinicDetails?.name);
      setEmail(updatedClinicDetails?.email);
      setPhone(updatedClinicDetails?.phoneNumber);
      setWebsite(updatedClinicDetails?.website);
      setProfile(updatedClinicDetails?.clinicImageDetails);
      setResImage(updatedClinicDetails?.clinicImageDetails);
    } else if (addClinic?.id) {
      setClinicName(addClinic?.name);
      setEmail(addClinic?.email);
      setPhone(
        addClinic?.phoneNumber.includes('+1')
          ? (addClinic?.phoneNumber).split('+1')[1]
          : addClinic?.phoneNumber,
      );
      setWebsite(addClinic?.website);
      setProfile(addClinic?.clinicImageDetails);
      setResImage(addClinic?.clinicImageDetails);
    }
  }, [updatedClinicDetails?.id, addClinic?.id]);
  useEffect(() => {
    if (!validPhone(phone) || !validateEmail(email)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [email, phone]);

  useEffect(() => {
    if (
      !updatedClinicDetails ||
      !addClinic ||
      updatedClinicDetails?.isPartialData ||
      addClinic?.isPartialData
    ) {
      handleCreateClinicBtn(true);
    } else {
      handleCreateClinicBtn(false);
    }
  }, [
    updatedClinicDetails,
    addClinic,
    updatedClinicDetails?.id,
    updatedClinicDetails?.isPartialData,
    addClinic?.isPartialData,
  ]);

  const handleClinicNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClinicName(event.target.value);
  };
  const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWebsite(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (e: string) => {
    const value = e?.split('+');
    if ((value[1] && value[1]?.length <= 11) || !value[1]) {
      setPhone(e.toString());
    }
  };

  const postClinic = async (clinicImagesS3Keys?: string[] | []) => {
    try {
      const payload = {
        name: clinicName,
        email,
        website,
        phoneNumber: phone,
        clinicImagesS3Keys:
          resImage?.length && !clinicImagesS3Keys
            ? [...resImage.map((d) => d?.s3Key)]
            : resImage?.length && clinicImagesS3Keys
              ? [...resImage.map((d) => d?.s3Key), ...clinicImagesS3Keys]
              : clinicImagesS3Keys,
      };

      const res: any =
        addClinic?.id || route?.search
          ? await ClinicService.editClinicDetails(payload, addClinic?.id || route?.search)
          : await ClinicService.addClinicDetails(payload);

      res?.data?.data ? setUpdatedClinicDetails(res?.data.data) : setAddClinic(res.data);
      setResImage(res?.data?.data ? res.data.data.clinicImageDetails : res.data.clinicImageDetails);
      setProfile(res?.data?.data ? res.data.data.clinicImageDetails : res.data.clinicImageDetails);
      setProfileImgObj([]);
      setErrorImg('');
      setShowLoader(false);
      setShowSnackbar(true);
      setShowSnackbarMessage('Clinic Details Saved');
      route?.pathname?.includes('add')
        ? nav(`/clinics/add/clinic-details?id=${addClinic?.id ? addClinic?.id : res?.data?.id}`)
        : nav(`/clinics/edit/clinic-details?id=${addClinic?.id ? addClinic?.id : res?.data?.id}`);
      getClinicDetails();
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      // setAddClinic(undefined);
    }
  };

  const clinicAction = async () => {
    setShowLoader(true);
    try {
      const imageArr =
        (await profileImgObj?.length) &&
        profileImgObj.map(async (d: any) => {
          return d?.s3Key
            ? d.s3Key
            : await FileService.getSignedUrlToUploadFile(
              Constants.FILE_UPLOAD_CATEGORY.CLINIC_IMAGE,
              d,
              { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
            );
        });
      if (imageArr) {
        Promise.all(imageArr)
          .then(async (val) => {
            const resolveArr = val
              .filter((d: any) => d?.signedUrl)
              .map((d: any) => d?.signedUrl?.data?.data?.s3Key);
            postClinic(resolveArr);
          })
          .catch((err) => {
            setShowSnackbar(true);
            setShowSnackbarMessage(
              typeof err === 'string' ? err : (err as any).response.data.error.message[0],
            );
            setShowLoader(false);
          });
      } else {
        postClinic();
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
    }
    window.scrollTo(0, 0);
  };

  const handleImageErrors = (acceptedFiles: any) => {
    acceptedFiles.map((i: any, file: any) => {
      if (acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_ALL_IMAGES);
      }
      if (acceptedFiles?.length == 1 && acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_SIZE_EXCEED);
      }
    });
    if (acceptedFiles?.length && profile.length === 5) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_EXCEED);
    }
    if (
      acceptedFiles.length > 5 ||
      acceptedFiles.length + profileImgObj.length > 5 ||
      acceptedFiles.length + profile.length > 5
    ) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_FILES_5);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp'],
    },
    onDrop: (acceptedFiles) => {
      handleImageErrors(acceptedFiles);
      if (
        acceptedFiles.filter((value) => value.size > 1048576).length === 0 &&
        acceptedFiles.length <= 5 - profile.length &&
        ref.current >= acceptedFiles.length
      ) {
        setErrorImg('');
        setProfile((prevFiles: any) => {
          return [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                location: URL.createObjectURL(file),
              }),
            ),
          ];
        });
        setProfileImgObj((prevFiles: any) => {
          return [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                location: URL.createObjectURL(file),
              }),
            ),
          ];
        });
      }
    },
  });

  useEffect(() => {
    ref.current = 5 - profile.length;
  }, [profile]);

  useEffect(() => {
    setProfileImgObj(profile);
  }, [profile]);

  const removeImg = (ind: number, type: string) => {
    setErrorImg('');
    if (type) {
      setResImage((resImage as any[])?.filter((d: any) => type !== d?.s3Key));
    } else {
      setProfileImgObj((profileImgObj as any[])?.filter((d: any, ref: number) => ref !== ind));
    }
    setProfile((profile as any[])?.filter((d: any, ref: number) => ref !== ind));
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      slidesToSlide: 2,
    },
  };

  return (
    <div className='clinic-details-container'>
      {showLoader && <Loader margin={true} />}
      <Typography
        variant='h6'
        style={{ marginLeft: 10, marginBottom: !route?.pathname?.includes('add') ? 20 : '' }}
      >
        Clinic Details
      </Typography>
      <Box sx={{ margin: '32px auto' }}>
        <InfoTextBox
          content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
          width={'100%'}
          show={true}
        />
      </Box>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          width: '100%',
        }}
      >
        <section>
          <Field
            name='clinicName'
            label='Clinic Name*'
            placeholder='Clinic Name'
            showIcon={false}
            style={{ width: '100%' }}
            value={clinicName}
            onChange={handleClinicNameChange}
            disable={route?.pathname?.split('/')[2] === 'view'}
          />
        </section>
        <section>
          <Field
            name='Website'
            label='Website*'
            placeholder='Website'
            showIcon={false}
            style={{ width: '100%' }}
            value={website}
            onChange={handleWebsiteChange}
            disable={route?.pathname?.split('/')[2] === 'view'}
          />
        </section>
        <section>
          <Field
            name='Email Address'
            label='Email Address*'
            placeholder='Email Address'
            showIcon={false}
            style={{ width: '100%' }}
            value={email}
            onChange={handleEmailChange}
            type={'email'}
            error={email ? !validateEmail(email) : false}
            disable={route?.pathname?.split('/')[2] === 'view'}
          />
        </section>
        <section className='phone-section'>
          <PhoneFieldInput
            name='phone'
            value={phone}
            width='100%'
            onChange={handlePhoneChange}
            placeholder='Phone Number*'
            padding={false}
            margin={true}
            error={false}
            disable={route?.pathname?.split('/')[2] === 'view'}
          />
        </section>
        {route?.pathname?.split('/')[2] === 'view' ? (
          <></>
        ) : (
          <section>
            <Typography variant='h6' style={{ marginLeft: 10 }}>
              Upload Clinic Photos
            </Typography>
            <Typography
              variant='body1'
              style={{ marginLeft: 10, marginBottom: 7, color: zeplinColor.Background50 }}
            >
              {' '}
              You can upload upto 5 images
            </Typography>
            <div
              style={{
                borderStyle: 'dashed',
                borderWidth: '0.2rem',
                borderColor: zeplinColor.Primary,
                backgroundColor: zeplinColor.Background97,
                marginLeft: 10,
              }}
              // className='upload-btn'
              {...getRootProps({ className: 'dropzone upload-btn' })}
            >
              <input {...getInputProps()} />
              {/* <img src='/UploadSimple.svg' alt='upload' /> */}
              <SaveAltIcon />
              <Typography variant='body1'>Drag & drop or click to upload</Typography>
              <Typography variant='caption'>JPG or PNG, 16:9 ratio and max size of 1 MB</Typography>
            </div>
          </section>
        )}
        <section>
          {route?.pathname?.includes('view') ? (
            <></>
          ) : (
            <div className='profileText' style={{ marginTop: '-20px', marginBottom: 10 }}>
              {errorImg && (
                <Typography
                  className='profileDescription'
                  variant='body1'
                  style={{ color: zeplinColor.Error }}
                >
                  {errorImg}
                </Typography>
              )}
            </div>
          )}
          {route?.pathname?.includes('view') ? (
            <Typography variant='h6' style={{ margin: '10px 0px 10px 10px' }}>
              Clinic Photos
            </Typography>
          ) : (
            ''
          )}
          {profile?.length ? (
            <Carousel responsive={responsive} showDots containerClass='upload'>
              {profile?.map((d, ind) => (
                <div style={{ position: 'relative', cursor: 'pointer' }} key={ind}>
                  <img
                    src={d?.location ? d?.location : (d as string)}
                    className='carousel-img'
                    key={ind}
                    alt='clinic-photos'
                  />
                  {route?.pathname?.split('/')[2] === 'view' ? (
                    <></>
                  ) : (
                    <img
                      src={'/close-selected.svg'}
                      className='close-img'
                      onClick={() => (d?.s3Key ? removeImg(ind, d?.s3Key) : removeImg(ind, ''))}
                      alt='close-button'
                    />
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            ''
          )}
        </section>
        {route?.pathname?.split('/')[2] === 'view' ? (
          <></>
        ) : (
          <section style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Buttons
              text='SAVE DETAILS'
              width='160px'
              disable={phone && clinicName && website && email && !disable ? false : true}
              onClick={clinicAction}
            />
          </section>
        )}
      </section>
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default ClinicDetails;
