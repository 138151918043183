/*
 * File: StaffOptions.tsx
 * Project: mint-portal
 * File Created: Tuesday, 6th September 2022 1:41:53 pm
 * Author: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 22nd September 2022 4:45:06 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import React,{FC} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { zeplinColor } from '../../../theme';
import { IStaff } from '../../../model';
import { Constants } from '../../../utilities/constants';


  type prop={
    valprop:IStaff;
    remove?:(id: string,name:string)=>void;
    view?:boolean
  }
const StaffOption: FC<prop> = ({ valprop,remove,view=false }: prop) => {
  const {
    id,
    firstName,lastName,roles,
    profileImageDetails,
  } = valprop ;
  const [profileSuccess, setProfileSuccess] = React.useState(false);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent:remove ? 'space-between':'flex-start',
        width: '98%',
        margin: 'auto',
        padding:!remove ? '5px 0px':0
      }}
    >
      <Box style={{ width: '98%',display:'flex',justifyContent:remove ? 'space-between':'flex-start',alignItems: 'center',gap:10 }}>
        <Box style={{flex:remove && 1,display:'flex',gap:10,alignItems:'center'}}>
          <img
            src={profileSuccess ? profileImageDetails?.location: Constants.FALL_BACK_IMAGE}
            // width='25px'
            // height='25px'
            style={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            
            }}
            onError={() => setProfileSuccess(false)}
          />
          {remove ? <Typography variant='body1' style={{ color: zeplinColor.Background30,flex:1 }}>
            {firstName+' '+lastName}
          </Typography>:'' }
        </Box>
        <Typography variant='body1' style={{ color: zeplinColor.Background30,flex:remove && 1 }}>
          {remove ? roles[0].name:firstName+' '+lastName+' ' + '|' +' '+roles[0].name}
        </Typography>
       
      </Box>
      {remove && !view ? <Box style={{ width: '5%' }}>
        <IconButton disableRipple onClick={() => remove(id,firstName)}>
          <CloseIcon />
        </IconButton>
      </Box>:''}
    </Box>
  );
};


export default StaffOption