/*
 * File: RoutePaths.tsx
 * Project: mint-portal
 * File Created: Wednesday, 1st June 2022 1:44:30 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 28th October 2022 8:40:34 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
enum RoutePaths {
  Root = '/',
  Login = '/login',
  SetPassword = '/set-password',
  Dashboard = '/dashboard',
  Settings = '/settings',
  Clinics = '/clinics',
  Patient = '/patient',
  Rewards = '/rewards',
  Services = '/services',
  Manage = '/manage',
  Staff = '/staff',
  Insurances = '/insurances',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password'
}

export default RoutePaths;
