/*
 * File: ListAppointments.tsx
 * Project: mint-portal
 * File Created: Thursday, 6th October 2022 9:45:11 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 17th August 2023 10:54:30 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { IPatientAppointmentDetails, IPatientAppointmentListAPIResponse } from '../../../model';
import EmptyState from '../../shared/EmptyState/EmptyState';
import CustomToolTip from '../../shared/CustomToolTip';
import { Constants } from '../../../utilities/constants';
import moment from 'moment';
import { Status } from '../../../model/analytics.model';

interface IProps {
  appointmentList: IPatientAppointmentListAPIResponse;
  onPageChangeHandler: any;
  currentPage: number;
}

interface AppointmentsListColumn {
  id: 'service' | 'clinic' | 'date_and_time' | 'appointment' | 'insurance' | 'status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const appointmentsListColumns: AppointmentsListColumn[] = [
  { id: 'service', label: 'Service', minWidth: 150 },
  { id: 'clinic', label: 'Clinic', minWidth: 150 },
  { id: 'date_and_time', label: 'Date and Time', minWidth: 150 },
  { id: 'appointment', label: 'Appointment #', minWidth: 150 },
  { id: 'insurance', label: 'Insurance', minWidth: 150 },
  { id: 'status', label: 'Status', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));

const ListAppointments: FC<IProps> = ({ appointmentList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const classes = useStyles();
  const getAppointmentStatus = (type: Status) => {
    switch (type) {
    case Status.SCHEDULED:
      return 'Scheduled';
    case Status.UNSCHEDULEDLIST:
      return 'Unscheduled';
    case Status.COMPLETE:
      return 'Complete';
    case Status.ASAP:
      return 'ASAP';
    case Status.BROKEN:
      return 'Broken';
    case Status.PLANNED:
      return 'Planned';
    case Status.PTNOTE:
      return 'PT Note';
    default:
      return 'PT Note Completed';
    }
  };

  return (
    <>
      {appointmentList.total && appointmentList.appointments.length > 0 ? (
        <div>
          <Paper
            className='containerdiv'
            sx={{ borderRadius: '16px', margin: '8px 0px 24px -16px' }}
          >
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {appointmentsListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointmentList.total &&
                    appointmentList.appointments.length &&
                    appointmentList.appointments.map(
                      (appointmentObj: IPatientAppointmentDetails, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell >
                              <CustomToolTip
                                procedureCodes={true}
                                title={
                                  appointmentObj?.service?.name
                                    ? appointmentObj?.service?.name
                                    : appointmentObj.procedureCodes.length
                                      ? appointmentObj.procedureCodes.join(', ')
                                      : '-'
                                }
                                width={'230px'}
                              />
                              {appointmentObj.service && (
                                <Typography>
                                  ({appointmentObj.service.duration} min -{' '}
                                  {appointmentObj.service.patientType})
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              <CustomToolTip
                                title={appointmentObj.clinicDetails.name}
                                width={'220px'}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {moment(appointmentObj.appointmentDateTime).format(
                                  Constants.MOMENT_FORMATS.DATE,
                                )}
                              </Typography>
                              <Typography>
                                {moment(appointmentObj.appointmentDateTime).format(
                                  Constants.MOMENT_FORMATS.TIME_LOWERCASE,
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{appointmentObj?.appointmentNumber}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {appointmentObj.isMobileAppBooking
                                  ? appointmentObj.isInsuranceUsed
                                    ? 'Yes'
                                    : 'No'
                                  : appointmentObj.isInsuranceUsed
                                    ? 'Yes'
                                    : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {getAppointmentStatus(appointmentObj.appointmentStatus)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              appointmentList.total <= Constants.LIMIT
                ? 1
                : Math.ceil(appointmentList.total / Constants.LIMIT)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </div>
      ) : (
        appointmentList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={''}
              text={'No appointments to show'}
              subText={''}
              buttonText={''}
              showAddButton={false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListAppointments;
