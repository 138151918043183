/*
 * File: withTheme.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 4:48:17 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 31st May 2022 5:13:53 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { CssBaseline } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { FC, ReactElement } from 'react';

export default (theme: Theme, Component: any, isBaseline = false): FC => {
  const baselineComponent = isBaseline ? <CssBaseline /> : <></>;
  // eslint-disable-next-line react/display-name
  return (props: object): ReactElement => (
    <ThemeProvider theme={theme}>
      {baselineComponent}
      <Component {...props} />
    </ThemeProvider>
  );
};
