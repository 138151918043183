/*
 * File: ServicesIconLibrary.tsx
 * Project: mint-portal
 * File Created: Monday, 15th May 2023 1:08:48 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 10th July 2023 8:29:52 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import './ServicesIconLibrary.css';

import React, { useEffect, useRef, useState } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { Constants } from '../../../../utilities/constants';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import { FileService } from '../../../../service/file.service';
import { IIconListRO } from '../../../../model';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import ListIconLibrary from './ListIconLibrary';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import { ServicesIconLibrary } from '../../../../pages/Manage/ManageCardConstants';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../../../utilities/getPermission';

const ServiceIconLibrary = () => {
  const [iconList, setIconList] = useState<IIconListRO>();
  const [showLoader, setShowLoader] = useState(false);
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const inputRef = useRef<HTMLInputElement[]>([]);

  const getIconList = async (update?: string) => {
    setShowLoader(true);
    try {
      const response: IIconListRO = await ManageService.getServiceIconList();
      setIconList(response);
      setShowLoader(false);
      update && setShowSnackbar(true);
      update && setShowSnackbarMessage(`Icon ${update}`);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const addIcon = async (e: any) => {
    setShowLoader(true);
    try {
      console.log(e);
      const imageUploadRes = await FileService.getSignedUrlToUploadFile(
        Constants.FILE_UPLOAD_CATEGORY.ICON_LIBRARY,
        e.target.files[0],
        { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
      );
      const s3Key = imageUploadRes?.signedUrl?.data?.data?.s3Key;
      const fileName = e.target.files[0].name;
      const fileExtension = fileName.split('.').pop();
      const fileNameWithoutExtension = fileName.replace('.' + fileExtension, '');
      const response = await ManageService.addServiceIcon({
        iconName: fileNameWithoutExtension,
        iconS3Key: s3Key,
      });

      if (response) {
        setShowSnackbar(true);
        setShowSnackbarMessage('New icon added to the library');
        e.target.value = null;
      }
      getIconList();
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
      e.target.value = null;
    }
  };

  useEffect(() => {
    getIconList();
  }, []);

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      <div className='serviceIconContainer'>
        <div className='serviceIconHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' className='serviceIconTitle' color='inherit'>
              Services Icon Library
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Services Icon Library'} />
            </Typography>
            {ServicesIconLibrary.roleAccess.includes(roleId) && (
              <div
                className='addButtonContainer'
                style={{ display: 'flex', marginRight: '-3.75%' }}
              >
                <input
                  type='file'
                  accept='image/*'
                  onChange={addIcon}
                  disabled={
                    iconList?.data.length === Constants.SERVICE_ICON_LIBRARY_LIMIT ? true : false
                  }
                  title=''
                  ref={(e) => {
                    if (e) {
                      inputRef.current[0] = e;
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    pointerEvents: 'auto',
                    width: '20px',
                  }}
                />
                <Buttons
                  disable={
                    iconList?.data.length === Constants.SERVICE_ICON_LIBRARY_LIMIT ? true : false
                  }
                  width='12rem'
                  text='ADD NEW ICON'
                  classname='addButtonstyle'
                  onClick={() => {
                    inputRef && inputRef?.current[0]?.click();
                  }}
                />
              </div>
            )}
          </Toolbar>
          <div style={{ margin: '33px 0px 0px 20px' }}>
            <InfoTextBox
              content={Constants.INFO_TEXTS.SERVICE_ICON_LIBRARY}
              show={true}
              width={'76.5vw'}
            />
          </div>
        </div>
        {!showLoader && iconList?.data.length === 0 ? (
          <section style={{ margin: '15% auto' }}>
            <EmptyState
              path={'/manage'}
              text={'No icons to show'}
              subText={'Get started by clicking on Add New Icon'}
              buttonText={'Add New Icon'}
              showAddButton={true}
              image={true}
              serviceIconLibrary={true}
              onChangeIcon={addIcon}
              inputRef={inputRef}
            />
          </section>
        ) : (
          !showLoader &&
          iconList && <ListIconLibrary iconsList={iconList} getIconList={getIconList} />
        )}
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
    </>
  );
};

export default ServiceIconLibrary;
