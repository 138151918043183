/*
 * File: appTheme.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 12:28:53 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 5th August 2022 1:08:02 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { createTheme } from '@material-ui/core/styles';
import ZeplinColor from './zeplinColor';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const LightMode = 'light';
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonLarge: true;
    buttonMedium: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  buttonLarge: React.CSSProperties;
  buttonMedium: React.CSSProperties;
}

const appTheme = createTheme({
  typography: {
    fontFamily: 'HelveticaNeue',
  },
  palette: {
    type: LightMode,
    primary: {
      main: ZeplinColor.Primary,
    },
    error: {
      main: ZeplinColor.Error,
    },
    background: {
      default: ZeplinColor.Background,
      paper: ZeplinColor.Surface,
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 45,
        fontWeight: 400,
      },
      h2: {
        fontSize: 36,
        fontWeight: 400,
      },
      h3: {
        fontSize: 32,
        fontWeight: 600,
      },
      h4: {
        fontSize: 28,
        fontWeight: 300,
      },
      h5: {
        fontSize: 22,
        fontWeight: 500,
      },
      h6: {
        fontSize: 20,
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 600,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
      caption: {
        fontSize: 12,
        fontWeight: 400,
      },
      buttonLarge: {
        fontSize: 15,
        fontWeight: 600,
      },
      buttonMedium: {
        fontSize: 14,
        fontWeight: 500,
      },
    } as ExtendedTypographyOptions,
  },
});

export default appTheme;
