/*
 * File: insurance.service.tsx
 * Project: mint-portal
 * File Created: Thursday, 28th July 2022 12:54:58 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 3rd April 2023 4:27:31 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import axios from '../core/axios';
import { ApiConstants } from './api-constants';
import {
  InsuranceListQueryParams,
  InsuranceListAPIResponse,
  InsuranceDetailRO,
  IAddNewInsurancePayload,
  ICreatedInsuranceRO,
  IEmptyRO,
  InsurancesByClinicIdQueryParamOptions,
  InsurancesByClinicIdRO,
  InsuranceRO,
  InsuranceDTO,
  InsurancePatientPayload,
  InsuranceAddRO,
  InsuranceFamilyPayload,
} from '../model';

export class InsuranceService {
  static getInsuranceList = async (
    insuranceQueryParams: InsuranceListQueryParams,
  ): Promise<InsuranceListAPIResponse> => {
    return await (
      await axios.get(
        ApiConstants.INSURANCE_API.LIST_INSURANCE.URL() +
          ApiConstants.INSURANCE_API.LIST_INSURANCE.QUERY_PARAMS(insuranceQueryParams),
      )
    ).data;
  };

  static getInsuranceDetailById = async (insuranceId: string): Promise<InsuranceDetailRO> => {
    return await (
      await axios.get(ApiConstants.INSURANCE_API.GET_INSURANCE_BY_ID.URL(insuranceId))
    ).data;
  };

  static addInsurance = async (
    insurancePayload: IAddNewInsurancePayload,
  ): Promise<ICreatedInsuranceRO> => {
    return await (
      await axios.post(ApiConstants.INSURANCE_API.ADD_NEW_INSURANCE.URL(), insurancePayload)
    ).data;
  };

  static deleteInsurance = async (insuranceId: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.INSURANCE_API.DELETE_INSURANCE.URL(insuranceId))
    ).data;
  };

  static getInsurancesListByClinicId = async (
    queryParams: InsurancesByClinicIdQueryParamOptions,
  ): Promise<InsurancesByClinicIdRO> => {
    return await (
      await axios.get(
        ApiConstants.INSURANCE_API.GET_INSURANCES_BY_CLINIC_ID.URL() +
          ApiConstants.INSURANCE_API.GET_INSURANCES_BY_CLINIC_ID.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static saveInsurances = async (insurancePayload: InsuranceDTO): Promise<InsuranceRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.SAVE_CLINIC_INSURANCES.URL(), insurancePayload)
    ).data;
  };
  static addPatientInsurance = async (
    insurancePayload: InsurancePatientPayload,
    patientId: string,
  ): Promise<InsuranceAddRO> => {
    return await (
      await axios.put(
        ApiConstants.PATIENT_API.PATIENT_ADD_INSURANCE.URL(patientId),
        insurancePayload,
      )
    ).data;
  };
  static addFamilyInsurance = async (
    insurancePayload: InsuranceFamilyPayload,
    familyId: string,
  ): Promise<InsuranceAddRO> => {
    return await (
      await axios.put(ApiConstants.PATIENT_API.FAMILY_ADD_INSURANCE.URL(familyId), insurancePayload)
    ).data;
  };
  static getInsuranceByPatient = async (patientId: string): Promise<InsuranceAddRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.GET_PATIENT_INSURANCE.URL(patientId))
    ).data;
  };
  static getInsuranceByFamily = async (familyId: string): Promise<InsuranceAddRO> => {
    return await (
      await axios.get(ApiConstants.PATIENT_API.GET_FAMILY_INSURANCE.URL(familyId))
    ).data;
  };
}
