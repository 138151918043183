/*
 * File: ResetPassword.tsx
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 5:57:02 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 14th December 2022 1:40:18 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState, useEffect, useRef } from 'react';
import LandingPage from '../../components/shared/Landing-page/LandingPage';
import { Card, CardContent, TextField } from '@mui/material';
import { Typography } from '@material-ui/core';
import './ResetPassword.css';
import Buttons from '../../components/shared/Buttons';
import Password from '../../components/shared/PasswordField';
import { resetPasswordSubmit } from '../../service/amplify.service';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../components/shared/Loader/Loader';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import { zeplinColor } from '../../theme';
import { Constants } from '../../utilities/constants';

const useStyles = makeStyles({
  root: {
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      height: '59px',
      width: '56px',
      '&. MuiOutlinedInput-notchedOutline': {
        borderColor: zeplinColor.Primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: zeplinColor.Primary,
      },
    },
  },
});

type otpRef = {
  [key: number]: React.RefObject<HTMLDivElement>;
};
type code = {
  [key: string]: any;
};

const ResetPassword: FC = () => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [email] = useState(sessionStorage.getItem('email'));
  const [showSnackMessage, setSnackMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [codeValue, setCode] = useState<code>({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  });
  const { otp1, otp2, otp3, otp4, otp5, otp6 } = codeValue;
  const [validateError, setValidateError] = useState(false);
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false);
  const otpRef: otpRef = {
    1: useRef<HTMLDivElement>(null),
    2: useRef<HTMLDivElement>(null),
    3: useRef<HTMLDivElement>(null),
    4: useRef<HTMLDivElement>(null),
    5: useRef<HTMLDivElement>(null),
    6: useRef<HTMLDivElement>(null),
  };
  const navigate = useNavigate();

  useEffect(() => {
    const input = otpRef['1'].current?.children[0].children[0] as HTMLElement;
    input.focus();
    // console.log(otpRef[1].current?.children[0].children[0]);
  }, [otpRef[1]]);

  useEffect(() => {
    const code = Object.values(codeValue).flat().join('').length;
    if (password?.length >= Constants.PASSWORD_MIN_LIMIT && code === 6) {
      setEnableSubmitBtn(true);
    } else {
      setEnableSubmitBtn(false);
    }
  }, [password, codeValue]);

  const resetPassword = async () => {
    setShowLoader(true);
    try {
      if (email) {
        const code = Object.values(codeValue).flat().join('');
        await resetPasswordSubmit({ email, code, password });
        sessionStorage.setItem('reset-password', 'success');
        navigate('/login');
      }
    } catch (error) {
      if (error instanceof Error) {
        setShowSnackBar(true);
        setSnackMessage(error.message);
        setValidateError(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setValidateError(false);
  };

  const handleCodeChange = (value: string, e: any) => {
    const input = e.target.value;
    Constants.ESCAPE_SYMBOLS.includes(input) && e.preventDefault();
    if (input.length <= 1 && input.length >= 0)
      setCode((prev) => ({
        ...prev,
        [value]: input,
      }));
    setValidateError(false);
    // console.log(otpRef[1].current, value.split('p')[1]);
    const current = parseInt(value.split('p')[1]);
    if (current >= 1 && current <= 5) {
      const inputRef = otpRef[current + 1].current?.children[0].children[0] as HTMLElement;
      input && inputRef.focus();
    }
  };
  const handleNext = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    Constants.ESCAPE_SYMBOLS.includes(e.key) && e.preventDefault();
    const current = parseInt(value.split('p')[1]);
    if (current >= 1 && current <= 5) {
      const forwardRef = otpRef[current + 1].current?.children[0].children[0] as HTMLElement;
      e.key === 'Enter' && codeValue[value] && forwardRef.focus();
    }
    if (current >= 2 && current <= 6) {
      const BackRef = otpRef[current - 1].current?.children[0].children[0] as HTMLElement;
      e.key === 'Backspace' && !codeValue[value] && BackRef.focus();
    }
  };

  const handleCopyPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      {showLoader && <Loader />}
      <LandingPage showFooter={false} />
      <form className='resetPassword'>
        <Card className='container'>
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h3'>Reset Password</Typography>
            <Typography color='textSecondary' className='sub-header' variant='h6'>
              Please enter the confirmation code sent to<br></br>
              <Typography style={{ color: zeplinColor.Background0 }} variant='h6'>
                {email}
              </Typography>
              While setting your new password, use 8 or more characters with a mix of uppercase and
              lowercase letters, numbers and symbols.
            </Typography>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '3rem' }}
            >
              <Typography color='textSecondary'>Confirmation Code</Typography>
              <div className='grid-box-container'>
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  type='number'
                  value={otp1}
                  id='1'
                  variant='outlined'
                  className={classes.root}
                  error={validateError}
                  onKeyDown={(e) => handleNext(e, 'otp1')}
                  onChange={(e) => handleCodeChange('otp1', e)}
                  ref={otpRef[1]}
                />
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  className={classes.root}
                  error={validateError}
                  type='number'
                  value={otp2}
                  id='2'
                  variant='outlined'
                  onKeyDown={(e) => handleNext(e, 'otp2')}
                  onChange={(e) => handleCodeChange('otp2', e)}
                  ref={otpRef[2]}
                />
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  id='3'
                  variant='outlined'
                  className={classes.root}
                  error={validateError}
                  type='number'
                  value={otp3}
                  onKeyDown={(e) => handleNext(e, 'otp3')}
                  onChange={(e) => handleCodeChange('otp3', e)}
                  ref={otpRef[3]}
                />
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  id='4'
                  variant='outlined'
                  className={classes.root}
                  error={validateError}
                  type='number'
                  value={otp4}
                  onKeyDown={(e) => handleNext(e, 'otp4')}
                  onChange={(e) => handleCodeChange('otp4', e)}
                  ref={otpRef[4]}
                />
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  id='5'
                  variant='outlined'
                  className={classes.root}
                  error={validateError}
                  type='number'
                  value={otp5}
                  onKeyDown={(e) => handleNext(e, 'otp5')}
                  onChange={(e) => handleCodeChange('otp5', e)}
                  ref={otpRef[5]}
                />
                <TextField
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                  inputProps={{ style: { textAlign: 'center' } }}
                  id='6'
                  variant='outlined'
                  className={classes.root}
                  error={validateError}
                  type='number'
                  value={otp6}
                  onKeyDown={(e) => handleNext(e, 'otp6')}
                  onChange={(e) => handleCodeChange('otp6', e)}
                  ref={otpRef[6]}
                />
              </div>
            </div>
            <div style={{ marginLeft: '-10px' }}>
              <Password
                name='New Password'
                label='New Password'
                width='592px'
                error={
                  password?.length < Constants.PASSWORD_MIN_LIMIT && password.length ? true : false
                }
                iconChanged={false}
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className='btn-container'>
              <Buttons
                text='Confirm'
                width='11.5em'
                disable={!enableSubmitBtn || validateError}
                onClick={resetPassword}
              />
            </div>
          </CardContent>
        </Card>
        {showSnackBar && (
          <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </form>
    </>
  );
};

export default ResetPassword;
