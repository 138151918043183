/*
 * File: DetailConfigureRewards.tsx
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:01:48 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 9th January 2023 7:47:33 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './DetailConfigureReward.css';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Toolbar, Button, Typography } from '@material-ui/core';
import { Paper } from '@mui/material';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import { zeplinColor } from '../../../../../theme';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { RewardService } from '../../../../../service/reward.service';
import Loader from '../../../../shared/Loader/Loader';
import DeletePopup from '../../../../shared/DeletePopup';
import getPermissionForModule from '../../../../../utilities/getPermission';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import {
  IDetailRewardResponse,
  IDetailRewardRO,
  RewardType,
  IAddPointCreditRewardPayload,
} from '../../../../../model';
import Buttons from '../../../../shared/Buttons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AddConfigureRewards from '../AddConfigureRewards/AddConfigureRewards';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const DetailConfigureReward = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Rewards;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [rewardDetail, setRewardDetail] = useState<IDetailRewardResponse>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const updatedGiftReward = sessionStorage.getItem('updatedReward');

  const prevLink = [{ path: '/rewards', label: 'Rewards' }];

  // For edit modal
  const [edit, setEdit] = useState(false);
  const [updatePointCreditPayload, setPointCreditPayload] =
    useState<IAddPointCreditRewardPayload>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [updateGift, setUpdateGift] = useState(false);
  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  useEffect(() => {
    if (updatedGiftReward) {
      setShowSnackBar(true);
      setShowSnackBarMessage(`${updatedGiftReward} changes saved `);
      sessionStorage.removeItem('updatedReward');
    }
    if (id) {
      setShowLoader(true);
      getRewardDetailsById();
    }
  }, [id, updateGift]);

  const handleDeleteReward = async () => {
    setShowLoader(true);
    try {
      if (rewardDetail) {
        await RewardService.deleteRewardById(rewardDetail?.id);
        sessionStorage.setItem('deletedReward', rewardDetail?.name);
        setShowLoader(false);
        navigate(prevLink[0].path);
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getRewardDetailsById = async () => {
    if (id) {
      try {
        const rewardDetails: IDetailRewardRO = await RewardService.getRewardById(+id);
        setRewardDetail(rewardDetails?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackBar(true);
        setShowSnackBarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const onCancelEdit = () => {
    setEdit(false);
  };

  const updateGiftReward = () => {
    setUpdateGift(true);
  };

  const updatePointCreditReward = async () => {
    if (id) {
      setUpdateLoader(true);
      try {
        const res =
          rewardDetail &&
          updatePointCreditPayload &&
          (await RewardService.editPointCreditReward(+id, updatePointCreditPayload));
        getRewardDetailsById();
        setUpdateLoader(false);
        if (res?.data) {
          setShowSnackBar(true);
          setShowSnackBarMessage(`${res.data.name} changes saved`);
          setEdit(false);
        }
      } catch (err) {
        setShowSnackBar(true);
        setShowSnackBarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowLoader(false);
        setUpdateLoader(false);
      }
    }
  };

  return (
    <>
      {(showLoader || updateGift) && <Loader />}
      <div className='rewardDetailContainer'>
        <div className='rewardDetailHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              Reward Details
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Reward Details'} />
            </Typography>
            {!edit ? (
              <div style={{ display: 'flex', gap: '16px' }}>
                {permission.delete && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteBox'
                    onClick={handleDeletePopupOpen}
                  >
                    <DeleteOutlineSharpIcon
                      className='deleteIcon'
                      style={{ color: `${zeplinColor.Error}` }}
                    />
                  </Button>
                )}
                {permission.update && (
                  <Buttons
                    text='Edit Details'
                    width='12em'
                    disable={false}
                    onClick={() => setEdit(!edit)}
                  />
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                <Button
                  variant='outlined'
                  disabled={showLoader ? true : false}
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </Button>
                <Buttons
                  text='SAVE CHANGES'
                  width='100%'
                  disable={editBtn || showLoader ? true : false}
                  onClick={
                    rewardDetail?.rewardType === RewardType.PointsCredit
                      ? updatePointCreditReward
                      : updateGiftReward
                  }
                />
              </div>
            )}
          </Toolbar>
        </div>
        {edit ? (
          <div>
            <AddConfigureRewards
              edit
              rewardDetail={rewardDetail}
              setPointCreditPayload={setPointCreditPayload}
              updateLoader={updateLoader}
              setEditBtn={setEditBtn}
              handleUpdateGift={updateGift}
              handleUpdateGiftState={setUpdateGift}
              id={id}
              handleGiftState={setEdit}
            />
          </div>
        ) : (
          <div>
            {rewardDetail && (
              <Paper className='rewardDetailBox' sx={{ borderRadius: '16px' }}>
                <div className='rewardDetail'>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32 }}>
                    <Typography variant='h5'>{rewardDetail?.name}</Typography>
                  </div>
                  {rewardDetail.pointsCreditReward ? (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Reward Type</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.rewardType === RewardType.PointsCredit
                            ? 'Points Credit'
                            : '-'}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Reason for Points Credit</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.pointsCreditReward?.reason || '-'}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: 16,
                          border: `solid 1px ${zeplinColor.Background90}`,
                          width: 'fit-content',
                        }}
                        className='reward-carousel'
                      >
                        <Carousel responsive={responsive} showDots>
                          {rewardDetail?.giftReward?.images?.length ? (
                            rewardDetail?.giftReward?.images.map((image: any) => (
                              <img
                                src={image?.location}
                                key={image?.s3Key}
                                height={'100%'}
                                width={480}
                                style={{ objectFit: 'contain' }}
                                alt='close-button'
                              />
                            ))
                          ) : (
                            <img
                              src={'/default-carousel.webp'}
                              height={'100%'}
                              width={480}
                              style={{ objectFit: 'contain' }}
                              alt='close-button'
                            />
                          )}
                        </Carousel>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Reward Type</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.rewardType === RewardType.Gift ? 'Gift' : '-'}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Reward Category</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.giftReward?.categoryName}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Brief Description</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.giftReward?.description}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>Inventory Count</Typography>
                        <Typography variant='body1'>
                          {rewardDetail?.giftReward?.inventoryCount}
                        </Typography>
                      </div>
                    </>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 18 }}>
                    <Typography variant='subtitle1'>Points Value</Typography>
                    <Typography variant='body1'>{rewardDetail?.pointsValue}</Typography>
                  </div>
                </div>
                {rewardDetail && (
                  <DeletePopup
                    title={'Delete Reward'}
                    description={`"${rewardDetail?.name}"`}
                    subDescription={''}
                    open={openDeletePopup}
                    buttonText={`Delete Reward`}
                    setOpen={setOpenDeletePopup}
                    onDeleteClick={handleDeleteReward}
                  />
                )}
              </Paper>
            )}
          </div>
        )}
        {showSnackBar && (
          <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};

export default DetailConfigureReward;
