/*
 * File: ViewEditDeleteMediaLink.tsx
 * Project: mint-portal
 * File Created: Monday, 29th May 2023 7:24:46 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 3:15:58 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import CustomCard from '../../../shared/CustomCard';
import CustomTextField from '../../../shared/CustomTextField';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import DeletePopup from '../../../shared/DeletePopup';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import { IVideoLinkDetailsResponse } from '../../../../model';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { Video } from '../../../../pages/Manage/ManageCardConstants';
import getPermissionForModule from '../../../../utilities/getPermission';
import { validateYoutubeUrl } from '../../../../utilities/validate';
import { zeplinColor } from '../../../../theme';

const useStyles = makeStyles(() => ({
  box: {
    maxWidth: '72vw',
    wordWrap: 'break-word',
  },
}));

const VideoLinkDetail = () => {
  const styles = useStyles();
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const navigate = useNavigate();
  const [videoDetails, setVideoDetails] = useState<IVideoLinkDetailsResponse>({
    createdAt: '',
    updatedAt: '',
    id: 0,
    title: '',
    link: '',
    displayOrder: 0,
  });
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: `/manage/videos`, label: 'Videos' },
  ];
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [payload, setPayload] = useState({ title: '', link: '' });
  const [edit, setEdit] = useState(false);

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const onCancelEdit = () => {
    setEdit(false);
    setPayload({ title: videoDetails.title, link: videoDetails.link });
  };

  const handleText = (e: { target: { name: string; value: string } }) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };


  const getVideoDetailsById = async () => {
    setShowLoader(true);
    try {
      if (id) {
        const res = await ManageService.getVideoLinkById(+id);
        setVideoDetails(res.data);
        setPayload({title: res.data.title, link: res.data.link})
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteVideo = async () => {
    if (id) {
      setShowLoader(true);
      try {
        await ManageService.deleteVideoLinkById(+id);
        sessionStorage.setItem('deletedVideo', 'deleted');
        navigate('/manage/videos');
      } catch (err) {
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackBar(true);
      } finally {
        setShowLoader(false);
      }
    }
  };

  const updateVideoDetail = async () => {
    setShowLoader(true);
    try {
      if (id) {
        await ManageService.editVideoLink(+id, payload);
        await getVideoDetailsById();
        setEdit(false);
        setShowSnackBar(true);
        setShowSnackbarMessage('Video Updated');
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(()=>{
    getVideoDetailsById()
  },[])

  return (
    <>
      {showLoader && <Loader />}
      {!Video.roleAccess.includes(roleId) && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {Video.roleAccess.includes(roleId) && (
        <Box sx={{ width: '76.5vw', margin: '32px 0px 32px 24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            <Box>
              <Typography variant='h5'>Video Details</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Video Details'} />
            </Box>
            <Box>
              {!edit ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteButton'
                    onClick={handleDeletePopupOpen}
                  >
                    <DeleteOutlineSharpIcon style={{ width: '24px' }} />
                  </Button>
                  <Buttons
                    text='EDIT'
                    width='79px'
                    disable={false}
                    onClick={() => setEdit(!edit)}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    disabled={showLoader ? true : false}
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={onCancelEdit}
                  >
                      Cancel
                  </Button>
                  <Buttons
                    text='SAVE CHANGES'
                    width='162px'
                    disable={Object.values(payload).some((d) => !d) || showLoader ? true : false || !validateYoutubeUrl(payload.link)}
                    onClick={updateVideoDetail}
                  />
                </div>
              )}
            </Box>
          </Box>
          {!showLoader && <Box sx={{ marginTop: '28px' }}>
            {!edit ? (
              <CustomCard>
                <Box style={{ padding: '32px 32px 47px 32px' }}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1,1fr)',
                      gridRowGap: '32px',
                    }}
                  >
                    <Box className={styles.box}>
                      <Typography variant='subtitle1'>Title</Typography>
                      <Typography variant='body1'>{videoDetails.title}</Typography>
                    </Box>
                    <Box className={styles.box}>
                      <Typography variant='subtitle1'>URL</Typography>
                      <Typography variant='body1'>{videoDetails.link}</Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomCard>
            ) : (
              <CustomCard>
                <Box style={{ padding: '32px 32px 47px 32px' }}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1,1fr)',
                      gridRowGap: '32px',
                    }}
                  >
                    <Box>
                      <CustomTextField
                        name='title'
                        label='Title*'
                        multiline={true}
                        value={payload.title}
                        width={'584px'}
                        onChange={handleText}
                      />
                    </Box>
                    <Box>
                      <CustomTextField
                        name='link'
                        label='URL*'
                        multiline={true}
                        value={payload.link}
                        width={'584px'}
                        onChange={handleText}
                        error={payload.link ? !validateYoutubeUrl(payload.link) : false}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomCard>
            )}
          </Box>}
          {videoDetails && (
            <DeletePopup
              title={'Delete Video'}
              customDescription={
                'Are you sure you want to delete the video? This action can’t be undone.'
              }
              subDescription={''}
              open={openDeletePopup}
              buttonText={`Delete`}
              setOpen={setOpenDeletePopup}
              onDeleteClick={handleDeleteVideo}
            />
          )}
          {showSnackBar && (
            <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
          )}
        </Box>
      )}
    </>
  )
}

export default VideoLinkDetail