/*
 * File: clinic.service.tsx
 * Project: mint-portal
 * File Created: Wednesday, 13th July 2022 10:04:59 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 16th October 2023 5:31:42 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import axios from '../core/axios';
import { ApiConstants } from './api-constants';
import {
  IAddClinicPayload,
  IAddClinicResponse,
  IClinicListResponse,
  IClinicQueryParamOptions,
  IOpenDentalPayload,
  IOpenDentalRO,
  IClinicLocationPayload,
  IClinicLocationRO,
  IViewClinicDetails,
  IProviderByClinicIdQueryParamOptions,
  IProviderByClinicIdRO,
  IProviderSavePayload,
  IClinicProviderDetailRO,
  IServiceOperatoriesQueryParamOptions,
  IServiceOperatoriesRO,
  IOperatoriesSaveRO,
  IServiceSaveOperatoriesPayload,
  IAvailabilityWeek,
  ISaveAvailabilityRO,
  IClinicBooleanRO,
  BookingStatus,
  IClinicBlockoutListRO,
  IAddBlockoutPayload,
  IAddBlockoutRO,
  IOngoingBlockoutRO,
  IClinicOperatoriesRO,
  IClinicOpenDentalBlockoutListRO,
  IEmptyRO,
  IOpenDentalDetailBlockoutRO,
  IClinicNppwLinkRO,
  IUpdateClinicNppw,
  IEditClinicServicePayload,
  IEditClinicStaffPayload,
  IOpenDentalSyncStatusRO,
  IGetClinicApiKeyRO,
  IRegenerateClinicApiKeyRO,
} from '../model';

export class ClinicService {
  static getClinicList = async (
    queryParams: IClinicQueryParamOptions,
  ): Promise<IClinicListResponse> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.LIST_CLINIC.URL() +
          ApiConstants.CLINIC_API.LIST_CLINIC.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static saveOpenDentalConfig = async (
    openDentalPayload: IOpenDentalPayload,
    clinicId: string,
  ): Promise<IOpenDentalRO> => {
    return await (
      await axios.put(
        ApiConstants.CLINIC_API.SAVE_OPEN_DENTAL_CONFIG.URL(clinicId),
        openDentalPayload,
      )
    ).data;
  };

  static getClinicApiKey = async (clinicId: string): Promise<IGetClinicApiKeyRO> => {
    return (await axios.get(ApiConstants.CLINIC_API.GET_CLINIC_API_KEY.URL(clinicId))).data;
  };

  static regenerateClinicApiKey = async (clinicId: string): Promise<IRegenerateClinicApiKeyRO> => {
    return (await axios.patch(ApiConstants.CLINIC_API.REGENERATE_CLINIC_API_KEY.URL(clinicId)))
      .data;
  };

  static getOpenDentalSyncStatus = async (clinicId: string): Promise<IOpenDentalSyncStatusRO> => {
    return (await axios.get(ApiConstants.CLINIC_API.GET_OPEN_DENTAL_SYNC_STATUS.URL(clinicId)))
      ?.data?.data;
  };

  static viewClinicById = async (id: string): Promise<IViewClinicDetails> => {
    return await (
      await axios.get(ApiConstants.CLINIC_API.VIEW_CLINIC.URL(id))
    ).data;
  };

  static addClinicDetails = async (
    clinicPayload: IAddClinicPayload,
  ): Promise<IAddClinicResponse> => {
    return await (
      await axios.post(ApiConstants.CLINIC_API.SAVE_CLINIC.URL(), clinicPayload)
    ).data;
  };

  static saveLocationDetails = async (
    locationPayload: IClinicLocationPayload,
    clinicId: string,
  ): Promise<IClinicLocationRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.SAVE_CLINIC_LOCATION.URL(clinicId), locationPayload)
    ).data;
  };

  static editClinicDetails = async (
    clinicPayload: IAddClinicPayload,
    id: string,
  ): Promise<IViewClinicDetails> => {
    return await axios.put(ApiConstants.CLINIC_API.EDIT_CLINIC.URL(id), clinicPayload);
  };

  static getProviderListByClinicId = async (
    queryParams: IProviderByClinicIdQueryParamOptions,
    clinicId: string,
  ): Promise<IProviderByClinicIdRO> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.GET_PROVIDERS_BY_CLINIC_ID.URL(clinicId) +
          ApiConstants.CLINIC_API.GET_PROVIDERS_BY_CLINIC_ID.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static saveClinicProvider = async (
    clinicId: string,
    providerPayload: IProviderSavePayload,
  ): Promise<IClinicProviderDetailRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.SAVE_CLINIC_PROVIDERS.URL(clinicId), providerPayload)
    ).data;
  };

  static getServiceOperatoriesByClinicId = async (
    queryParams: IServiceOperatoriesQueryParamOptions,
  ): Promise<IServiceOperatoriesRO> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.GET_SERVICE_MAP_OPERATORIES_BY_CLINIC_ID.URL() +
          ApiConstants.CLINIC_API.GET_SERVICE_MAP_OPERATORIES_BY_CLINIC_ID.QUERY_PARAMS(
            queryParams,
          ),
      )
    ).data;
  };

  static saveClinicMapOperatories = async (
    clinicId: string,
    operatoriesPayload: IServiceSaveOperatoriesPayload,
  ): Promise<IOperatoriesSaveRO> => {
    return await (
      await axios.put(
        ApiConstants.CLINIC_API.SAVE_CLINIC_MAP_OPERATORIES.URL(clinicId),
        operatoriesPayload,
      )
    ).data;
  };

  static saveClinicAvailability = async (
    clinicId: string,
    availablityPayload: IAvailabilityWeek,
  ): Promise<ISaveAvailabilityRO> => {
    return await (
      await axios.put(
        ApiConstants.CLINIC_API.SAVE_CLINIC_OPENING_HOURS.URL(clinicId),
        availablityPayload,
      )
    ).data;
  };

  static updateClinicDraftStatus = async (clinicId: string): Promise<IClinicBooleanRO> => {
    return await axios.put(ApiConstants.CLINIC_API.UPDATE_CLINIC_DRAFT_STATUS.URL(clinicId));
  };
  static switchClinicAppListing = async (
    clinicId: string,
    switchStatus: boolean,
  ): Promise<IClinicBooleanRO> => {
    return await axios.put(ApiConstants.CLINIC_API.SWITCH_APP_LISTING.URL(clinicId, switchStatus));
  };

  static updateClinicBookingStatus = async (
    clinicId: string,
    bookingStatus: BookingStatus,
  ): Promise<IClinicBooleanRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.UPDATE_BOOKING_STATUS.URL(clinicId, bookingStatus))
    ).data;
  };

  static getBlockoutListByClinicId = async (
    clinicId: string,
    queryParams: IProviderByClinicIdQueryParamOptions,
  ): Promise<IClinicBlockoutListRO> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.LIST_BLOCKOUTS_BY_CLINIC_ID.URL(clinicId) +
          ApiConstants.CLINIC_API.LIST_BLOCKOUTS_BY_CLINIC_ID.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getOpenDentalBlockoutsByClinicId = async (
    clinicId: string,
    queryParams: IProviderByClinicIdQueryParamOptions,
  ): Promise<IClinicOpenDentalBlockoutListRO> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.LIST_OPEN_DENTAL_BLOCKOUTS_BY_CLINIC_ID.URL(clinicId) +
          ApiConstants.CLINIC_API.LIST_OPEN_DENTAL_BLOCKOUTS_BY_CLINIC_ID.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static addClinicBlockout = async (
    clinicId: string,
    blockoutPayload: IAddBlockoutPayload,
  ): Promise<IAddBlockoutRO> => {
    return await (
      await axios.post(ApiConstants.CLINIC_API.ADD_BLOCKOUT.URL(clinicId), blockoutPayload)
    ).data;
  };

  static updateClinicBlockout = async (
    clinicId: string,
    blockoutId: string,
    blockoutPayload: IAddBlockoutPayload,
  ): Promise<IAddBlockoutRO> => {
    return await (
      await axios.put(
        ApiConstants.CLINIC_API.UPDATE_BLOCKOUT_DETAILS_BY_ID.URL(clinicId, blockoutId),
        blockoutPayload,
      )
    ).data;
  };

  static getOngoingBlockout = async (clinicId: string): Promise<IOngoingBlockoutRO> => {
    return await (
      await axios.get(ApiConstants.CLINIC_API.GET_ONGOING_BLOCKOUTS_BY_CLINIC_ID.URL(clinicId))
    ).data;
  };

  static getOperatoriesByClinicId = async (clinicId: string, queryParams: IProviderByClinicIdQueryParamOptions) : Promise<IClinicOperatoriesRO> => {
    return await (
      await axios.get(ApiConstants.CLINIC_API.GET_OPERATORIES_BY_CLINIC_ID.URL(clinicId) + ApiConstants.CLINIC_API.GET_OPERATORIES_BY_CLINIC_ID.QUERY_PARAMS(queryParams))
    ).data;
  };

  static getBlockoutDetailByClinicId = async (
    clinicId: string,
    blockoutId: string,
  ): Promise<IAddBlockoutRO> => {
    return await (
      await axios.get(ApiConstants.CLINIC_API.GET_BLOCKOUT_DETAILS_BY_ID.URL(clinicId, blockoutId))
    ).data;
  };

  static getOpenDentalBlockoutDetailByClinicId = async (
    clinicId: string,
    blockoutId: string,
  ): Promise<IOpenDentalDetailBlockoutRO> => {
    return await (
      await axios.get(
        ApiConstants.CLINIC_API.GET_OPEN_DENTAL_BLOCKOUT_DETAILS_BY_ID.URL(clinicId, blockoutId),
      )
    ).data;
  };

  static getNppwLinkByClinicId = async (clinicId: string): Promise<IClinicNppwLinkRO> => {
    return await (
      await axios.get(ApiConstants.CLINIC_API.GET_NPPWK_BY_CLINIC_ID.URL(clinicId))
    ).data;
  };

  static updateNppwLinkByClinicId = async (
    clinicId: string,
    payload: IUpdateClinicNppw,
  ): Promise<IClinicNppwLinkRO> => {
    return await (
      await axios.patch(ApiConstants.CLINIC_API.UPDATE_NPPW_BY_CLINIC_ID.URL(clinicId), payload)
    ).data;
  };

  static deleteBlockout = async (clinicId: string, blockoutId: string): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.CLINIC_API.DELETE_BLOCKOUT.URL(clinicId, blockoutId))
    ).data;
  };
  static editClinicService = async (
    servicePayload: IEditClinicServicePayload,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.EDIT_CLINIC_SERVICES.URL(), servicePayload)
    ).data;
  };
  static editClinicStaff = async (staffPayload: IEditClinicStaffPayload): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.CLINIC_API.EDIT_CLINIC_STAFF.URL(), staffPayload)
    ).data;
  };
}
