/*
 * File: AutoRewards.tsx
 * Project: mint-portal
 * File Created: Friday, 9th June 2023 3:05:39 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th June 2023 7:59:48 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import {
  AutoRewardsBased,
  IElectiveProcedureAutoRewards,
  IListAutoAssignRewardsRO,
  IListAutoAssignRewardsResponse,
  IListElectiveProceduresRO,
} from '../../../../model';
import React, { useEffect, useState } from 'react';
import AccordionTable from '../../../shared/AccordionTable/AccordionTable';
import { Constants } from '../../../../utilities/constants';
import Loader from '../../../shared/Loader/Loader';
import { RewardService } from '../../../../service/reward.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { useNavigate } from 'react-router-dom';
import getPermissionForModule from '../../../../utilities/getPermission';

interface AutoRewardsListColumn {
  id: 'title' | 'primaryPatient' | 'familyMember' | 'status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const autoRewardsListColumn: AutoRewardsListColumn[] = [
  { id: 'title', label: 'Title', minWidth: 350 },
  { id: 'primaryPatient', label: 'Primary Patient', minWidth: 150 },
  { id: 'familyMember', label: 'Family Member', minWidth: 150 },
  { id: 'status', label: 'Status', minWidth: 150 },
];
const ListAutoRewards = () => {
  const navigate = useNavigate();
  const [autoRewardsList, setAutoRewardsList] = useState<IListAutoAssignRewardsResponse[]>([
    {
      createdAt: '',
      updatedAt: '',
      id: 0,
      name: '',
      primaryPatientPointsValue: 0,
      familyPatientPointsValue: 0,
      status: true,
      canConfigured: true,
    },
  ]);
  const [electiveProcedure, setElectiveProcedure] = useState<IElectiveProcedureAutoRewards[]>([
    {
      createdAt: '',
      updatedAt: '',
      id: 0,
      name: '',
      primaryPatientPointsValue: 0,
      familyPatientPointsValue: 0,
      status: true,
      canConfigured: true,
      procedureCodes: [],
    },
  ]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackBarMsg, setSnackBarMsg] = useState('');
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.AutoAssignPoints;

  const handleStatus = async (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setShowLoader(true);
    try {
      await RewardService.updateAutoReward(id, event.target.checked);
      setShowSnackbar(true);
      setSnackBarMsg(`Status Updated`);
      getAutoRewardsList();
      getElectiveProcedureList();
    } catch (err) {
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };
  const handleButton = (e: any) => {
    e.stopPropagation();
    navigate('/rewards/elective-procedure/add');
  };

  const [dataPage, setDataPage] = useState<number>(1);
  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const getAutoRewardsList = async () => {
    try {
      const response: IListAutoAssignRewardsRO = await RewardService.getAutoRewardsList();
      setAutoRewardsList(response.data);
    } catch (err) {
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const getElectiveProcedureList = async () => {
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const query = { limit, skip };
      const response: IListElectiveProceduresRO =
        await RewardService.getElectiveProcedureAutoRewardsList(query);
      setElectiveProcedure(response.data.electiveProcedures);
    } catch (err) {
      setShowSnackbar(true);
      setSnackBarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getAutoRewardsList();
  }, []);

  useEffect(() => {
    getElectiveProcedureList();
  }, [dataPage]);

  return (
    <>
      {showLoader && <Loader />}
      <AccordionTable
        title={AutoRewardsBased.AppointmentBased}
        isButton={false}
        columnHeaders={autoRewardsListColumn}
        tableContents={autoRewardsList.filter(
          (e: any) => e.rewardCategory === AutoRewardsBased.AppointmentBased,
        )}
        handleStatus={handleStatus}
        isViewDetail={false}
        isPagination={false}
        currentPage={0}
      />
      <AccordionTable
        title={AutoRewardsBased.MobileAppUseBased}
        isButton={false}
        columnHeaders={autoRewardsListColumn}
        tableContents={autoRewardsList.filter(
          (e: any) => e.rewardCategory === AutoRewardsBased.MobileAppUseBased,
        )}
        handleStatus={handleStatus}
        isViewDetail={false}
        isPagination={false}
        currentPage={0}
      />
      <AccordionTable
        title={AutoRewardsBased.FamilyAndFriends}
        isButton={false}
        columnHeaders={autoRewardsListColumn}
        tableContents={autoRewardsList.filter(
          (e: any) => e.rewardCategory === AutoRewardsBased.FamilyAndFriends,
        )}
        handleStatus={handleStatus}
        isViewDetail={false}
        isPagination={false}
        currentPage={0}
      />
      <AccordionTable
        title={AutoRewardsBased.Promotional}
        isButton={false}
        columnHeaders={autoRewardsListColumn}
        tableContents={autoRewardsList.filter(
          (e: any) => e.rewardCategory === AutoRewardsBased.Promotional,
        )}
        handleStatus={handleStatus}
        isViewDetail={false}
        isPagination={false}
        currentPage={0}
      />
      <AccordionTable
        title='Elective Procedures'
        isButton={permission.create ? true : false}
        buttonText={'Add Procedures'}
        onClick={handleButton}
        columnHeaders={autoRewardsListColumn}
        tableContents={electiveProcedure}
        handleStatus={handleStatus}
        isViewDetail={true}
        pathName={'elective-procedure/details'}
        isPagination={true}
        onPageChangeHandler={onDataPageChange}
        currentPage={dataPage}
        count={
          electiveProcedure.length <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(electiveProcedure.length / Constants.PAGINATION_OPTIONS.limit)
        }
      />
      {showSnackbar && (
        <SnackBar message={showSnackBarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ListAutoRewards;
