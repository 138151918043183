/*
 * File: SearchDropdown.tsx
 * Project: mint-portal
 * File Created: Tuesday, 12th July 2022 8:19:58 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 23rd August 2023 7:53:01 am
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { FC, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, Typography, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import ServiceOption from '../ServiceOptions/ServiceOption';
import { zeplinColor } from '../../../theme';
import StaffOption from '../StaffOptions/StaffOptions';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@material-ui/core/styles';

const CustomPaper = ({ props }: { props: any }) => {
  return <Paper elevation={8} {...props}></Paper>;
};
type IProp = {
  searchList: any;
  label: string;
  onSelectHandler: any;
  onSearchHandler: any;
  placeHolderText?: string;
  value?: any[] | any;
  width?: string;
  service?: boolean;
  multiple?: boolean;
  provider?: boolean;
  disable?: boolean;
  staff?: boolean;
  operatory?: boolean;
  frontOffice?: boolean;
  insurances?: boolean;
  patient?: boolean;
  paddingLeft?: number;
  staticValue?: boolean;
  handleDrawerOpen?: () => void;
  fixed?: boolean;
  placeholderPosition?: boolean;
  setRewardSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  patientInsurance?: boolean;
  clearIcon?: boolean;
};

const useStyles = makeStyles(() => ({
  field: {
    '&.MuiAutocomplete-inputRoot': {
      paddingRight: '0px !important',
    },
  },
}));
const SearchDropdown: FC<IProp> = ({
  handleDrawerOpen,
  searchList,
  label,
  onSelectHandler,
  onSearchHandler,
  value,
  width,
  service = false,
  multiple = true,
  provider = false,
  disable = false,
  staff = false,
  operatory = false,
  insurances = false,
  frontOffice = false,
  patient = false,
  paddingLeft,
  staticValue = false,
  fixed = false,
  placeholderPosition = false,
  placeHolderText = '',
  patientInsurance = false,
  setRewardSearchValue,
  clearIcon = true,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleChange = (event?: any, value?: any) => {
    console.log(value);
    if (event.key !== 'Backspace' || (!staff && !service && !insurances)) {
      onSelectHandler(value);
    }
  };

  const handleSearch = (event: any) => {
    onSearchHandler(event?.target?.value);
  };

  const handleOpen = () => {
    setOpen(true);
    onSearchHandler('');
  };

  return (
    <Autocomplete
      onChange={handleChange}
      placeholder={placeHolderText}
      popupIcon={''}
      ListboxProps={{ style: { maxHeight: service ? '12rem' : '7.5rem' } }}
      style={{ width: width ? width : 552 }}
      sx={{
        '& .MuiFormLabel-root': {
          paddingLeft: patientInsurance ? '0.8rem' : placeholderPosition ? 1 : 0,
          marginTop: placeholderPosition ? -1.5 : 0,
        },
        '&  .MuiInput-input': {
          paddingLeft: patientInsurance ? '0.6rem !important' : 0,
        },
      }}
      id='controllable-states-demo'
      PaperComponent={(param: any) => <CustomPaper props={param} />}
      options={searchList}
      multiple={multiple}
      disabled={disable}
      classes={{ inputRoot: classes.field }}
      getOptionLabel={(option: any) =>
        !option
          ? null
          : provider
            ? (option?.providerFirstName !== null ? option?.providerFirstName : '') +
            (option?.providerLastName !== null ? option?.providerLastName : '')
            : staff
              ? option?.firstName
              : operatory
                ? option?.operatoryName
                : insurances
                  ? option?.insuranceName
                  : frontOffice
                    ? option.name
                    : patient
                      ? option?.firstName + ' ' + option?.lastName + '   ' + option?.code
                      : option.pointsValue
                        ? `${option.name} (${option.pointsValue} points)`
                        : option.name
      }
      // onInputChange={(e,v)=>handleChange('',v)}
      // rendertags works  only for multiple
      renderTags={(tagValue, getTagProps) => {
        return tagValue?.map((option, index) =>
          staff || service || operatory || frontOffice || insurances ? (
            <div></div>
          ) : staticValue ? (
            option?.name
          ) : (
            <Chip label={option?.name} {...getTagProps({ index })} key={index} />
          ),
        );
      }}
      renderInput={(params) => {
        params.inputProps.readOnly = fixed ? true : false;
        return (
          <TextField
            onChange={handleSearch}
            {...params}
            label={label}
            variant='standard'
            style={{
              paddingLeft: paddingLeft ? paddingLeft : '',
              paddingRight: 0,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return !option ? null : service ? ( // </InfiniteScroll>
          <li
            {...props}
            style={{
              borderBottom: `1px solid ${zeplinColor.Background90}`,
              padding: '20px 0px 20px 10px',
              cursor: 'pointer',
              width: '100%',
              // zIndex:'-1'
            }}
            key={option?.id}
          >
            <ServiceOption valprop={option} />
          </li>
        ) : staff ? (
          <li
            {...props}
            style={{ borderBottom: `1px solid ${zeplinColor.Background90}`, cursor: 'pointer' }}
            key={option?.id}
          >
            <StaffOption valprop={option} />
          </li>
        ) : provider ? (
          <li {...props} key={option?.providerNumber}>
            {option?.providerFirstName + option?.providerLastName}
          </li>
        ) : operatory ? (
          <li {...props} key={option?.operatoryNumber}>
            {option?.operatoryName}
          </li>
        ) : insurances ? (
          <li {...props} key={option?.insuranceId}>
            {option?.insuranceName}
          </li>
        ) : frontOffice ? (
          <li {...props} key={option?.id}>
            {option?.name}
          </li>
        ) : patient ? (
          <li {...props} key={option?.code}>
            <Box style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <Typography style={{ width: 240 }}>
                {option?.firstName + ' ' + option?.lastName}
              </Typography>
              <Typography>{option?.code}</Typography>
            </Box>
          </li>
        ) : option.name === 'Select Clinics' ? (
          <li {...props}>
            <Box style={{ width: '240px' }} onClick={handleDrawerOpen}>
              {option.name}
            </Box>
          </li>
        ) : (
          <li {...props}>
            {option.pointsValue ? `${option.name} (${option.pointsValue} points)` : option.name}
          </li>
        );
      }}
      clearIcon={
        service || staff || insurances || patient || staticValue || !clearIcon ? (
          <></>
        ) : (
          <ClearIcon fontSize='small' />
        )
      }
      autoHighlight
      value={value}
      disableCloseOnSelect={multiple}
      selectOnFocus
      isOptionEqualToValue={(option, value) =>
        !option
          ? false
          : provider
            ? option?.providerFirstName + option?.providerLastName ===
            value?.providerFirstName + value?.providerLastName
            : staff || service || patient
              ? option.id === value?.id
              : operatory
                ? option?.operatoryName === value?.operatoryName
                : frontOffice
                  ? option.name === value?.name
                  : insurances
                    ? option?.insuranceName === value?.insuranceName
                    : option?.name === value?.name
      }
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false);
        setRewardSearchValue && setRewardSearchValue('');
      }}
      open={open}
      blurOnSelect={true}
    />
  );
};

export default SearchDropdown;
