/*
 * File: Services.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:43:40 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:10:18 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Services.css';

import { Badge, Chip } from '@mui/material';
import { Button, Toolbar, Typography } from '@material-ui/core';
import {
  FilterParams,
  IServiceListAPIResponse,
  IServiceListQueryParams,
  IServiceListRO,
} from '../../model/service.model';
import React, { FC, useEffect, useState } from 'react';

import Buttons from '../../components/shared/Buttons';
import { Constants } from '../../utilities/constants';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterServicePopup from '../../components/feature/service/FilterService/FilterServicePopup';
import ListService from '../../components/feature/service/ListService/ListService';
import Loader from '../../components/shared/Loader/Loader';
import SearchBar from '../../components/shared/SearchBar';
import { ServiceService } from '../../service/service.service';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../theme';

interface FilterArray {
  id: number;
  label: string;
  value: boolean;
  type: string;
}

const Services: FC = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Service;

  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState<IServiceListRO>({ total: 0, services: [] });
  const [dataPage, setDataPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const deletedService = sessionStorage.getItem('deletedService');
  const addedService = sessionStorage.getItem('addedService');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMsg, setShowSnackbarMsg] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  // Filter popup states
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedChips, setSelectedChips] = useState<FilterArray[] | null>();
  const [filterOptions, setFilterOption] = useState<FilterParams | any>();

  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
    setDataPage(1);
  };

  const handleFilterPopupOpen = () => {
    setOpenFilterPopup(true);
    if (selectedChips && selectedChips.length > 0) {
      const newObj = Object.assign(
        { newPatient: false, existing: false, adult: false, child: false },
        ...selectedChips.map((item) => ({ [item.label]: item.value })),
      );
      setFilterOption(newObj);
    }
  };
  const handleFilterService = (inputValue: any) => {
    const arrayForChips = Object.keys(inputValue).map((key: string, index: number) => ({
      id: index,
      label: key,
      value: inputValue[key],
      type: key === 'adult' || key === 'child' ? 'ageType' : 'patientType',
    }));
    const selectedOptions = arrayForChips.filter((i: FilterArray) => i.value === true);
    selectedOptions.length ? setSelectedChips(selectedOptions) : setSelectedChips(undefined);
    setOpenFilterPopup(false);
    setDataPage(1);
  };

  const handleDelete = (chipToDelete: FilterArray) => () => {
    if (selectedChips?.filter((chip: any) => chip.id !== chipToDelete.id).length === 0) {
      clearAllFilter();
    } else {
      setSelectedChips((chips) => chips?.filter((chip: any) => chip.id !== chipToDelete.id));
      setDataPage(1);
    }
  };

  const clearAllFilter = () => {
    setSelectedChips(undefined);
    setFilterOption({ newPatient: false, existing: false, adult: false, child: false });
    setDataPage(1);
  };

  useEffect(() => {
    if (deletedService) {
      setShowSnackbar(true);
      setShowSnackbarMsg(`${deletedService} is deleted`);
      sessionStorage.removeItem('deletedService');
    }
    if (addedService) {
      setShowSnackbar(true);
      setShowSnackbarMsg(`${addedService} is added`);
      sessionStorage.removeItem('addedService');
    }
    setShowLoader(true);
    getServiceList();
  }, [
    dataPage,
    searchValue,
    selectedChips && selectedChips.length && JSON.stringify(selectedChips),
  ]);

  const getServiceList = async () => {
    if (searchValue && searchValue.length < 3) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const patientType =
        selectedChips &&
        selectedChips.length > 0 &&
        selectedChips.filter((i) => i.type === 'patientType');
      const ageType =
        selectedChips &&
        selectedChips.length > 0 &&
        selectedChips.filter((i) => i.type === 'ageType');
      const serviceListQueryParams: IServiceListQueryParams = {
        limit,
        skip,
        ...(searchValue &&
          searchValue.length >= 3 && {
          searchString: searchValue,
          skip: dataPage !== 1 ? skip : 0,
        }),
        ...(patientType &&
          patientType.length && {
          skip: dataPage !== 1 ? skip : 0,
          patientType:
              patientType && patientType.map((i) => (i.label === 'newPatient' ? 'new' : i.label)),
        }),
        ...(ageType &&
          ageType.length && {
          skip: dataPage !== 1 ? skip : 0,
          ageType: ageType && ageType.map((i) => i.label),
        }),
      };
      const response: IServiceListAPIResponse = await ServiceService.getServiceList(
        serviceListQueryParams,
      );
      setServiceList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMsg(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='serviceContainer'>
          <div className='serviceHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='serviceTitle' color='inherit'>
                Services
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <SearchBar onSearchHandler={onSearch} placeholderText={'Search by Service Name'} />
                <div style={{ border: `1px solid ${zeplinColor.Primary}` }} className='filterDiv'>
                  <Badge
                    overlap="rectangular"
                    color='error'
                    variant='dot'
                    invisible={!selectedChips || selectedChips.length === 0}
                  >
                    <FilterAltOutlinedIcon
                      onClick={handleFilterPopupOpen}
                      sx={{
                        color: zeplinColor.Primary,
                        width: '24px',
                        height: '24px',
                        borderRadius: '4px',
                        margin: '8px 8px 0px 8px',
                      }}
                    ></FilterAltOutlinedIcon>
                  </Badge>
                  <FilterServicePopup
                    selectedChips={filterOptions}
                    open={openFilterPopup}
                    setOpen={setOpenFilterPopup}
                    onFilterClick={handleFilterService}
                  />
                </div>
                {permission.create && (
                  <Buttons
                    text='Add new service'
                    width='15em'
                    disable={false}
                    onClick={() => navigate('/services/add')}
                  />
                )}
              </div>
            </Toolbar>
            <div style={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
              {!!selectedChips &&
                selectedChips?.map((data: FilterArray) => {
                  return (
                    <Chip
                      style={{ textTransform: 'capitalize' }}
                      key={data.id}
                      label={data.label === 'newPatient' ? 'new' : data.label}
                      onDelete={handleDelete(data)}
                    />
                  );
                })}
              {selectedChips?.length && (
                <Button
                  onClick={clearAllFilter}
                  variant='text'
                  style={{ color: zeplinColor.Primary }}
                >
                  Clear all
                </Button>
              )}
            </div>
          </div>
          {permission.read && serviceList.total > 0 && !showLoader ? (
            <ListService
              serviceList={serviceList}
              onPageChangeHandler={onDataPageChange}
              currentPage={dataPage}
            />
          ) : (
            !showLoader && (
              <div style={{ marginTop: '15%' }}>
                <EmptyState
                  image={true}
                  path={'/services/add'}
                  text={'No services to show'}
                  subText={permission.create ? 'Get started by clicking on Add New Service.' : ''}
                  buttonText={permission.create ? 'ADD NEW SERVICE' : ''}
                  showAddButton={permission.create ? true : false}
                />
              </div>
            )
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default Services;
