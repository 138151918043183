/*
 * File: DetailService.tsx
 * Project: mint-portal
 * File Created: Tuesday, 9th August 2022 5:40:07 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 22nd June 2023 11:02:39 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './DetailService.css';
import { Button, Toolbar, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Constants } from '../../../../utilities/constants';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import ListPopup from '../../../shared/ListPopup';
import DeletePopup from '../../../shared/DeletePopup';
import { ServiceService } from '../../../../service/service.service';
import {
  IDetailServiceByIdRO,
  IDetailService,
  ICreateServicePayload,
} from '../../../../model/service.model';
import { zeplinColor } from '../../../../theme';
import AddService from '../AddService/AddService';
import Buttons from '../../../shared/Buttons';
import getPermissionForModule from '../../../../utilities/getPermission';

const DetailService: FC = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Service;
  const navigate = useNavigate();
  const { id } = useParams();
  const [serviceDetail, setServiceDetail] = useState<IDetailService>();
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  // For edit modal
  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<ICreateServicePayload>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const prevLink = [{ path: '/services', label: 'Services' }];
  // For clinic list modal popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  useEffect(() => {
    setShowLoader(true);
    getServiceDetailById();
  }, [id]);

  const getServiceDetailById = async () => {
    setShowLoader(true);
    try {
      if (id) {
        const response: IDetailServiceByIdRO = await ServiceService.getServiceById(id);
        setServiceDetail(() => response.data);
        edit && setEdit(false);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteService = async () => {
    setShowLoader(true);
    try {
      if (serviceDetail) {
        await ServiceService.deleteService(serviceDetail?.id);
        sessionStorage.setItem('deletedService', serviceDetail?.name);
        setShowLoader(false);
        navigate('/services');
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const onCancelEdit = () => {
    setEdit(false);
  };

  const updateService = async () => {
    setUpdateLoader(true);
    try {
      const res =
        serviceDetail &&
        updatePayload &&
        (await ServiceService.updateServiceById(serviceDetail.id, {
          ...updatePayload,
        }));
      getServiceDetailById();
      if (res?.data) {
        setShowSnackBar(true);
        setShowSnackBarMessage(`${res.data.name} changes saved`);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
    } finally {
      setUpdateLoader(false);
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div className='serviceDetailContainer'>
        <div className='serviceDetailHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='serviceTitle' color='inherit'>
              Service Details
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Service Details'} />
            </Typography>
            {!edit ? (
              <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                {permission.delete && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteButton'
                    onClick={handleDeletePopupOpen}
                  >
                    <DeleteOutlineSharpIcon style={{ width: '24px', height: '24px' }} />
                  </Button>
                )}
                {/* TODO: Update this button using resuable component */}
                {permission.update && (
                  <Button
                    variant='contained'
                    color='primary'
                    // onClick={() => navigate('/staff/add')}
                    onClick={() => setEdit(!edit)}
                  >
                    EDIT DETAILS
                  </Button>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '16px', marginRight: '-22px' }}>
                <Button
                  variant='outlined'
                  disabled={showLoader ? true : false}
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </Button>
                <Buttons
                  text='SAVE CHANGES'
                  width='100%'
                  disable={editBtn || showLoader ? true : false}
                  onClick={updateService}
                />
              </div>
            )}
          </Toolbar>
        </div>
        {edit ? (
          <div>
            <AddService
              edit
              serviceDetail={serviceDetail}
              setUpdatePayload={setUpdatePayload}
              updateLoader={updateLoader}
              setEditBtn={setEditBtn}
            />
          </div>
        ) : (
          <div>
            {serviceDetail && (
              <Paper className='serviceDetailBox' sx={{ borderRadius: '16px' }}>
                <div className='serviceDetail'>
                  <div style={{ display: 'flex' }}>
                    <img
                      className='profileImage'
                      src={
                        serviceDetail?.serviceImageDetails?.location ||
                        Constants.SERVICE_FALL_BACK_ICON
                      }
                      alt='profile'
                      onError={(e: any) => (e.target.src = Constants.SERVICE_FALL_BACK_ICON)}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                    <div>
                      <Typography variant='subtitle1'>Service Name</Typography>
                      <Typography>{serviceDetail?.name}</Typography>
                    </div>

                    <div>
                      <Typography variant='subtitle1'>Age Type</Typography>
                      <Typography>{Constants.SERVICE.AGE_TYPE[serviceDetail?.ageType]}</Typography>
                    </div>

                    <div>
                      <Typography variant='subtitle1'>Patient Type</Typography>
                      <Typography>
                        {Constants.SERVICE.PATIENT_TYPE[serviceDetail?.patientType]}
                      </Typography>
                    </div>

                    <div>
                      <Typography variant='subtitle1'>Service Codes</Typography>
                      <Typography>
                        {serviceDetail?.serviceCodes.length > 1
                          ? serviceDetail?.serviceCodes.join(',')
                          : serviceDetail?.serviceCodes}
                      </Typography>
                    </div>

                    <div>
                      <Typography variant='subtitle1'>Duration</Typography>
                      <Typography>{serviceDetail?.duration} Min</Typography>
                    </div>

                    <div>
                      <Typography variant='subtitle1'>Selected Clinics</Typography>
                      <div style={{ marginBottom: '161px' }}>
                        {serviceDetail?.clinics.length === 0 ? (
                          <Typography>No Clinics Assigned</Typography>
                        ) : serviceDetail?.clinics.length <= 5 ? (
                          serviceDetail?.clinics?.map((clinic) => {
                            return (
                              <div key={clinic.id} className='listItem'>
                                <Typography>{clinic.name}</Typography>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <Typography
                              variant='subtitle1'
                              color='primary'
                              onClick={handleClickOpen}
                              style={{ cursor: 'pointer' }}
                            >
                              {serviceDetail?.clinics.length} Clinics Selected
                            </Typography>
                          </div>
                        )}
                      </div>
                      <ListPopup
                        title={'Selected Clinics'}
                        description={'Below is the list of clinics the service will have access to'}
                        open={open}
                        assignedClinic={serviceDetail?.clinics}
                        setOpen={setOpen}
                      />
                    </div>
                  </div>
                </div>
                {serviceDetail && (
                  <DeletePopup
                    title={'Delete service'}
                    description={`"${serviceDetail?.name}"`}
                    subDescription={`Upon deletion, this service will be removed from ${serviceDetail?.clinics?.length} clinic(s).`}
                    open={openDeletePopup}
                    buttonText={`Delete service`}
                    setOpen={setOpenDeletePopup}
                    onDeleteClick={handleDeleteService}
                  />
                )}
              </Paper>
            )}
          </div>
        )}
        {showSnackBar && (
          <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};

export default DetailService;
