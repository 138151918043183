/*
 * File: InusranceBreakdown.tsx
 * Project: mint-portal
 * File Created: Tuesday, 15th November 2022 9:40:19 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 16th December 2022 3:57:01 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { dashboardColorTheme, zeplinColor } from '../../../../theme';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box } from '@material-ui/core';
import CustomLegend from '../CustomLegend/CustomLegend';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface DoughnutProps {
  name: string[];
  value: number[];
  percentage?: number[];
}
const DoughnutChart: FC<DoughnutProps> = ({ name, value, percentage = [0] }) => {
  const [userData, setUserData] = React.useState({
    labels: name,
    datasets: [
      {
        label: '',
        data: value,
        backgroundColor: [''],
        borderColor: [''],
        legend: [''],
      },
    ],
  });

  React.useEffect(() => {
    setUserData({
      labels: name,
      datasets: [
        {
          label: '1',
          data: value,
          backgroundColor: dashboardColorTheme.map((val) => val?.color),
          borderColor: dashboardColorTheme.map((val) => val?.color),
          legend: name,
        },
      ],
    });
  }, [value, name]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        padding: 16,
        bodyFont: {
          family: 'HelveticaNeue',
          size: 14,
          weight: 'bold',
        },
        boxPadding: 10,
        callbacks: {
          title: function () {
            return '';
          },
          label: function (tooltipItem: any) {
            return tooltipItem?.label + '   ' + percentage[tooltipItem?.dataIndex] + '%';
          },
        },
      },
      title: {
        display: false,
        text: '',
      },
    },
  };


  return(
    <Box style={{display: 'flex', flexDirection:'row',marginTop: '10px', gap:'48px'}}>
      <Box style={{ height: '300px', width:'300px',margin:'10px 103px'}}>

        <Doughnut options={options} data={userData} />
      </Box>
      <Box
        style={{

          display: 'grid',
          gridTemplateColumns: 'repeat(2,1fr)',
          gridColumnGap: '48px',
          gridAutoFlow: 'row',
          gridAutoRows: '40px',
          gap: '20px',
          color: zeplinColor.Background50
        }}
      >
        {value.map((val: number, index: number) =>
          <Box key={index}>
            <CustomLegend
              color={dashboardColorTheme.filter((val: any) => val?.id === index + 1)[0]?.color}
              label={name[index]}
              percentage={percentage[index]}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DoughnutChart;
