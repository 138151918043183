/*
 * File: ListService.tsx
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 6:42:13 pm
 * Author: Paras Kapoor (paras.kapoor@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 24th May 2023 8:29:01 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../../theme';
import React, { FC, useState } from 'react';
import './ListService.css';
import { Constants } from '../../../../utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import { IServiceList, IServiceListRO } from '../../../../model/service.model';
import { useNavigate } from 'react-router-dom';
import CustomToolTip from '../../../shared/CustomToolTip';

interface IProps {
  serviceList: IServiceListRO;
  onPageChangeHandler: any;
  currentPage: number;
}

interface ServiceListColumn {
  id: 'service_name' | 'age_type' | 'patient_type' | 'duration';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const serviceListColumns: ServiceListColumn[] = [
  { id: 'service_name', label: 'Service Name' },
  { id: 'age_type', label: 'Age Type' },
  { id: 'patient_type', label: 'Patient Type' },
  { id: 'duration', label: 'Duration' },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListService: FC<IProps> = ({ serviceList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    // triggers function in the service component to get next set of services
    onPageChangeHandler(page);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper className='containerdiv' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {serviceListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {serviceList.total &&
                serviceList.services.length &&
                serviceList.services.map((serviceObj: IServiceList) => {
                  return (
                    <TableRow
                      hover
                      classes={{ hover: classes.tableRow }}
                      key={serviceObj.id}
                      onClick={() =>
                        navigate({
                          pathname: `/services/detail/${serviceObj.id}`,
                        })
                      }
                    >
                      <TableCell>
                        <Typography className='serviceName'>
                          <img
                            className='avatar'
                            // need to check for green tick in zeplin
                            src={
                              serviceObj?.serviceImageDetails?.location || Constants.SERVICE_FALL_BACK_ICON
                            }
                            alt=''
                            onError={(e: any) => (e.target.src = Constants.SERVICE_FALL_BACK_ICON)}
                          ></img>

                          <CustomToolTip title={serviceObj.name} width={'275px'} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {Constants.SERVICE.AGE_TYPE[serviceObj.ageType] || ''}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {Constants.SERVICE.PATIENT_TYPE[serviceObj.patientType] || ''}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {serviceObj.duration} {Constants.SERVICE.DURATION_UNIT}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={
          serviceList.total <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(serviceList.total / Constants.PAGINATION_OPTIONS.limit)
        }
        onChange={onDataPageChange}
        page={dataPage}
        variant='outlined'
        shape='rounded'
        className='pagination'
        classes={{ outlined: classes.ul }}
      />
    </div>
  );
};

export default ListService;
