/*
 * File: roles.ts
 * Project: mint-portal
 * File Created: Friday, 1st July 2022 4:13:17 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 13th June 2023 8:23:34 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

export enum RoleIds {
  DSO_ADMIN = 1,
  MARKETING_ADMIN,
  CLINIC_ADMIN,
  FRONT_OFFICE_MANAGER,
}

export interface AccessType {
  create: boolean;
  update: boolean;
  delete: boolean;
  read: boolean;
}

export interface ClinicAccessType extends AccessType {
  readApikey: boolean;
  regenerateAPiKey: boolean;
}

export interface AppModules {
  Dashboard: AccessType;
  Staff: AccessType;
  Service: AccessType;
  Clinics: ClinicAccessType;
  Patient: AccessType;
  Rewards: AccessType;
  AutoAssignPoints: AccessType;
  Insurances: AccessType;
  Manage: AccessType;
  Booking: AccessType;
}
