/*
 * File: MapOperatories.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:37 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 19th February 2023 11:06:20 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './MapOperatories.css';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { Card, Pagination } from '@mui/material';
import {
  IOpenDentalSyncStatusRO,
  IOperatoriesSaveRO,
  ISaveOperatoriesPayload,
  IServiceOperatoriesQueryParamOptions,
  IServiceOperatoriesRO,
  IServiceOperatoriesResponse,
  IServiceSaveOperatoriesPayload,
  IViewClinicDetailsData,
} from '../../../../../model';
import React, { FC, useEffect, useState } from 'react';

import Buttons from '../../../../shared/Buttons';
import { ClinicService } from '../../../../../service/clinic.service';
import { Constants } from '../../../../../utilities/constants';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import Loader from '../../../../shared/Loader/Loader';
import MapOperatoriesCard from './MapOperatoriesCard';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { Utilities } from '../../../../../utilities/utilities';
import { useLocation } from 'react-router-dom';
import { zeplinColor } from '../../../../../theme';

type mapProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));

const MapOperatories: FC<mapProps> = ({
  updatedClinicDetails,
  handleCreateClinicBtn,
}: mapProps) => {
  const classes = useStyles();
  const route = useLocation();
  const [dataPage, setDataPage] = useState<number>(1);
  const [service, setService] = useState<IServiceOperatoriesResponse>();
  const [selectedOperatories, setSelectedOperatories] = useState<ISaveOperatoriesPayload[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [saveBtn, setSaveBtn] = useState<boolean>(true);
  const [trigger, setTrigger] = useState<number>();
  const [count, setCount] = useState<number>(0);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [openDentalSyncStatus, setOpenDentalSyncStatus] = useState<boolean>(false);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
  };
  const onSelectOperatories = (inputValue: any) => {
    setSelectedOperatories(inputValue);
  };

  const getOpenDentalSyncStatus = async () => {
    setShowLoader(true);
    try {
      let openDentalSyncStatusRO: IOpenDentalSyncStatusRO = {
        openDentalSync: false, // TODO: not checking this key because we have no server agent ready yet.
        operatories: false,
        procedureCodes: false,
        providers: false,
      };
      if (updatedClinicDetails?.id) {
        openDentalSyncStatusRO = await ClinicService.getOpenDentalSyncStatus(
          updatedClinicDetails.id,
        );
      }
      const isOpenDentalSyncDone =
        !!openDentalSyncStatusRO?.procedureCodes && !!openDentalSyncStatusRO?.operatories;
      setOpenDentalSyncStatus(isOpenDentalSyncDone);
      if (isOpenDentalSyncDone) {
        await getServiceOperatories(); // it is required, because we need to fetch latest operatories after sync done
      }
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any)?.response?.data?.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dataPage]);

  useEffect(() => {
    if (openDentalSyncStatus) {
      // if open dental not in sync, then no need to call service operatory apis
      getServiceOperatories();
    }
  }, [updatedClinicDetails && updatedClinicDetails?.id, dataPage]);

  useEffect(() => {
    if (count === service?.total || !service?.isPartialData) {
      setSaveBtn(false);
    }
    if (
      selectedOperatories.map((i) => i.operatoryNumbers.length).includes(0) &&
      service?.isPartialData
    ) {
      setSaveBtn(true);
    }
  }, [selectedOperatories.length, trigger, count, service]);

  useEffect(() => {
    if (
      updatedClinicDetails?.id &&
      updatedClinicDetails?.isDraft &&
      updatedClinicDetails?.openDentalConfig?.id
    ) {
      // GET OPEN DENTAL STATUS ONLY IF CLINIC IS IN DRAFT AND OPEN DENTAL CONFIG SET
      getOpenDentalSyncStatus();
    }
    if (updatedClinicDetails?.isDraft === false) {
      // if clinic is not in draft state, then no need to check open dental sync status
      setOpenDentalSyncStatus(true);
      getServiceOperatories();
    }
  }, [updatedClinicDetails?.id]);

  const getServiceOperatories = async () => {
    if (updatedClinicDetails && updatedClinicDetails.id) {
      const queryParams: IServiceOperatoriesQueryParamOptions = {
        limit: 5, // Constants.PAGINATION_OPTIONS.limit,
        skip: (dataPage - 1) * 5,
        clinicId: updatedClinicDetails.id,
      };
      try {
        const res: IServiceOperatoriesRO = await ClinicService.getServiceOperatoriesByClinicId(
          queryParams,
        );
        if (count < res?.data?.total) {
          const a = res.data?.services.map((i) => i.operatories.filter((j) => j.isMapped === true));
          setCount(a.length + count);
        }
        setService(res?.data);
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  const saveServiceMapOperatories = async () => {
    setShowLoader(true);
    if (updatedClinicDetails && updatedClinicDetails.id) {
      const payload: IServiceSaveOperatoriesPayload = { mapOperatories: selectedOperatories };
      try {
        const res: IOperatoriesSaveRO = await ClinicService.saveClinicMapOperatories(
          updatedClinicDetails?.id,
          payload,
        );
        if (res.data && !res.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        window.scrollTo(0, 0);
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Map Operatories Saved');
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (saveBtn || !updatedClinicDetails) {
        handleCreateClinicBtn(true);
      } else {
        handleCreateClinicBtn(false);
      }
    }
  }, [saveBtn]);

  return (
    <div>
      {!updatedClinicDetails?.id ||
      !updatedClinicDetails?.openDentalConfig?.id ||
      !openDentalSyncStatus ? (
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              justifyContent: 'center',
              alignItems: 'center',
              height: '712px',
            }}
          >
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.text}
              subText={
                Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.subText
              }
              buttonText={''}
              showAddButton={false}
              image={false}
            />
            {updatedClinicDetails?.openDentalConfig?.id && !openDentalSyncStatus && (
              <div style={{ position: 'absolute', top: '48.5rem' }}>
                <Buttons
                  text='refresh status'
                  width='170px'
                  disable={false}
                  onClick={getOpenDentalSyncStatus}
                />
              </div>
            )}
          </section>
        ) : (
          <div className='map-operatories-details-container'>
            {showLoader && <Loader margin={true} />}
            <section>
              <Typography variant='h6'>Map Operatories</Typography>
              <Box sx={{ margin: '32px auto' }}>
                <InfoTextBox
                  content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
                  width={'100%'}
                  show={true}
                />
              </Box>
              <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
              Please select at least one seat for each service to proceed
              </Typography>
            </section>
            <section
              style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: 32 }}
            >
              {service &&
              service.services.map((serviceObj) => {
                return (
                  <section key={serviceObj.id}>
                    <Card
                      sx={{
                        border: '1px Solid',
                        borderRadius: 8,
                        borderColor: zeplinColor.Background90,
                        backgroundColor: zeplinColor.Surface,
                      }}
                      elevation={0}
                      key={serviceObj.id}
                    >
                      <MapOperatoriesCard
                        isViewMode={route.pathname.split('/')[2] === 'view' ? true : false}
                        serviceDetails={serviceObj}
                        onSelectOperatories={onSelectOperatories}
                        selectedOperatories={selectedOperatories}
                        onTrigger={(e: any) => setTrigger(e)}
                      ></MapOperatoriesCard>
                    </Card>
                  </section>
                );
              })}
              <section
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <Pagination
                  color='primary'
                  count={service && (service.total <= 5 ? 1 : Math.ceil(service.total / 5))}
                  onChange={onDataPageChange}
                  page={dataPage}
                  variant='outlined'
                  shape='rounded'
                  classes={{ outlined: classes.ul }}
                />
                {route?.pathname?.split('/')[2] === 'view' ? (
                  <></>
                ) : (
                  <Buttons
                    text='SAVE DETAILS'
                    width='160px'
                    disable={saveBtn}
                    onClick={saveServiceMapOperatories}
                  />
                )}
              </section>
            </section>
          </div>
        )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default MapOperatories;
