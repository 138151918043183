import './UpdatePassword.css';

/*
 * File: UpdatePassword.tsx
 * Project: mint-portal
 * File Created: Friday, 21st October 2022 12:59:07 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 14th December 2022 4:30:02 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Button, Toolbar, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { Constants } from '../../../../utilities/constants';
import { Paper } from '@mui/material';
import Password from '../../../shared/PasswordField';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { updatePassword } from '../../../../service/amplify.service';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';
const useStyles = makeStyles((theme) => ({
  toolbar: {
    '&.MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiToolbar-gutters': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));

const UpdatePassword = () => {
  const navigate = useNavigate();
  const style = useStyles();
  const prevLink = [{ path: '/settings', label: 'Settings' }];
  const [password, setPassword] = useState({ current: '', newpassword: '', confirm: '' });
  const [snackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMsg, setShowSnackBarMsg] = useState('');
  const { current, newpassword, confirm } = password;
  const handleChange = (e: any) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
    // e.target.name = e.target.value;
  };

  const validate = () => {
    const str = newpassword.slice(0, confirm.length);
    Object.values(password).map((d) => console.log(d.length < 8));
    if ((str !== confirm || confirm.length !== newpassword.length) && confirm.length) {
      return true;
    }
    return false;
  };
  const handleCancel = () => {
    navigate('/settings');
  };
  const handleUpdate = async () => {
    try {
      await updatePassword(current, newpassword);
      sessionStorage.setItem('updatePassword', 'true');
      navigate('/settings');
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackBarMsg(
        typeof err === 'string'
          ? err
          : (err as any).message.includes('username')
            ? 'Incorrect current password'
            : (err as any).message,
      );
    }
  };
  return (
    <Box className='updatePasswordHeader'>
      <div>
        <Toolbar className={style.toolbar}>
          <Typography variant='h5'>
            Settings
            <br></br>
            <BreadCrumbs prevLink={prevLink} activeLink={'Update Password'} />
          </Typography>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button
              variant='outlined'
              style={{
                width: '8em',
                height: '34px',
                backgroundColor: `${zeplinColor.Background97}`,
                border: `1px solid ${zeplinColor.Background70}`,
                color: `${zeplinColor.Background70}`,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
            <Buttons
              text='UPDATE'
              width='100%'
              disable={
                password.newpassword.length < Constants.PASSWORD_MIN_LIMIT ||
                password.confirm.length < Constants.PASSWORD_MIN_LIMIT
                  ? true
                  : false || validate()
              }
              onClick={handleUpdate}
            />
          </div>
        </Toolbar>
      </div>
      <Paper
        className='update-password-card '
        elevation={0}
        sx={{
          borderRadius: `16px`,
          border: `1px solid ${zeplinColor.Background90}`,
          padding: '30px',
          paddingBottom: '80px',
          marginTop: '22px',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant='h6'>Update Password</Typography>
          <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
            We recommend that you create a new and strong password for security reasons.
            <br /> Use 08 or more characters with a mix of uppercase and lowercase letters,
            <br /> numbers and symbols.
          </Typography>
          <Box
            sx={{ marginTop: '32px', display: 'flex', flexDirection: 'column' }}
            style={{ gap: 32 }}
          >
            <Password
              name='current'
              width='558px'
              error={false}
              label='Current Password'
              iconChanged=''
              value={current}
              onChange={handleChange}
            />
            <Password
              name='newpassword'
              width='558px'
              error={
                password.newpassword.length < Constants.PASSWORD_MIN_LIMIT &&
                password.newpassword.length
                  ? true
                  : false
              }
              label='New Password'
              iconChanged=''
              value={newpassword}
              onChange={handleChange}
            />
            <Password
              name='confirm'
              width='558px'
              error={
                password.confirm.length < Constants.PASSWORD_MIN_LIMIT && password.confirm
                  ? true
                  : false || validate()
              }
              label='Confirm New Password'
              iconChanged=''
              value={confirm}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Paper>
      {snackBar && <SnackBar message={showSnackBarMsg} show={snackBar} setShow={setShowSnackBar} />}
    </Box>
  );
};

export default UpdatePassword;
