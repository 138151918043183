/*
 * File: service.service.tsx
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 8:00:02 pm
 * Author: Paras Kapoor (paras.kapoor@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 1st September 2022 1:03:50 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import axios from '../core/axios';
import { ApiConstants } from './api-constants';
import {
  ICreateServicePayload,
  ICreateServiceRO,
  IServiceListAPIResponse,
  IServiceListQueryParams,
  IDetailServiceByIdRO,
  IServiceClinicOnlyQuery,
  IServiceListByClinic,
} from '../model/service.model';
import { IEmptyRO } from '../model';

export class ServiceService {
  static getServiceList = async (
    serviceListQueryParams: IServiceListQueryParams,
  ): Promise<IServiceListAPIResponse> => {
    return (
      await axios.get(
        ApiConstants.SERVICE_API.LIST_SERVICES.URL() +
          ApiConstants.SERVICE_API.LIST_SERVICES.QUERY_PARAMS(serviceListQueryParams),
      )
    ).data;
  };

  static createService = async (
    createServicePayload: ICreateServicePayload,
  ): Promise<ICreateServiceRO> => {
    return (await axios.post(ApiConstants.SERVICE_API.CREATE_SERVICE.URL(), createServicePayload))
      .data;
  };

  static getServiceById = async (serviceId: string): Promise<IDetailServiceByIdRO> => {
    return (await axios.get(ApiConstants.SERVICE_API.GET_SERVICE_DETAILS_BY_ID.URL(serviceId)))
      .data;
  };

  static updateServiceById = async (
    serviceId: string,
    createServicePayload: ICreateServicePayload,
  ): Promise<IDetailServiceByIdRO> => {
    return (
      await axios.put(
        ApiConstants.SERVICE_API.UPDATE_SERVICE_BY_ID.URL(serviceId),
        createServicePayload,
      )
    ).data;
  };

  static deleteService = async (serviceId: string): Promise<IEmptyRO> => {
    return (
      await axios.delete(ApiConstants.SERVICE_API.DELETE_SERVICE_DETAILS_BY_ID.URL(serviceId))
    ).data;
  };
  static getServiceByClinic = async (
    serviceClinicOnlyQuery?: IServiceClinicOnlyQuery,
  ): Promise<IServiceListByClinic> => {
    return (
      await axios.get(
        ApiConstants.SERVICE_API.LIST_SERVICES_BY_CLINIC.URL() +
          ApiConstants.SERVICE_API.LIST_SERVICES_BY_CLINIC.QUERY_PARAMS(serviceClinicOnlyQuery),
      )
    ).data;
  };
}
