/*
 * File: AddService.tsx
 * Project: mint-portal
 * File Created: Wednesday, 17th August 2022 2:47:24 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 23rd June 2023 11:52:44 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './AddService.css';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
} from '@mui/material';
import {
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
  IIconListRO,
} from '../../../../model';
import { ICreateServicePayload, IDetailService } from '../../../../model/service.model';
import React, { FC, useEffect, useState } from 'react';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { ClinicService } from '../../../../service/clinic.service';
import { Constants } from '../../../../utilities/constants';
import CustomNumericInput from '../../../shared/CustomNumericField';
import CustomTextField from '../../../shared/CustomTextField';
import Loader from '../../../shared/Loader/Loader';
import { ServiceService } from '../../../../service/service.service';
import SideDrawer from '../../../shared/SideDrawer/SideDrawer';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';
import { ManageService } from '../../../../service/manage.service';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Avatar,
  Typography,
} from '@material-ui/core';
import { IIconDetailsResponse } from '../../../../model/manage.model';

type editProps = {
  edit?: boolean;
  serviceDetail?: IDetailService;
  setUpdatePayload?: React.Dispatch<React.SetStateAction<ICreateServicePayload | undefined>>;
  setUpdateProfile?: React.Dispatch<any>;
  updateLoader?: boolean;
  setEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    position: 'sticky',
    bottom: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AddService: FC<editProps> = ({
  edit,
  serviceDetail,
  setUpdatePayload,
  setUpdateProfile,
  updateLoader,
  setEditBtn,
}: editProps) => {
  const navigate = useNavigate();
  // TODO: handle image upload for service
  const [serviceName, setServiceName] = useState<string>(serviceDetail ? serviceDetail.name : '');
  const [selectAgeTypeOption, setSelectAgeTypeOption] = useState<string>(
    serviceDetail ? serviceDetail.ageType : '',
  );
  const [selectPatientTypeOption, setSelectPatientTypeOption] = useState<string>(
    serviceDetail ? serviceDetail.patientType : '',
  );
  const [serviceCodes, setServiceCodes] = useState<string>(
    serviceDetail ? serviceDetail.serviceCodes.join() : '',
  );
  const [duration, setDuration] = useState<number>(serviceDetail ? serviceDetail.duration : 15);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disableAdd, setAddButtonActive] = useState<boolean>(true);
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');

  const [searchCustomClinic, setSearchCustomClinic] = useState('');
  const [selectClinics, setSelectClinics] = useState<any[]>(
    serviceDetail ? serviceDetail?.clinics.map((clinic) => clinic.id) : [],
  );
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [chipData, setChipData] = useState(serviceDetail?.clinics.length || 0);
  const [moreClinics, setMoreClinics] = useState<any>(clinicList.clinics);
  const [counter, setCounter] = useState(0);

  const prevLink = [{ path: '/services', label: 'Service' }];
  // service icon popup
  const [iconList, setIconList] = useState<IIconListRO>();
  const [selectedIconId, setSelectedIconId] = useState<number | null>(
    serviceDetail?.iconId ? serviceDetail?.iconId : null,
  );
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [iconObj, setIconObj] = useState<any>(
    serviceDetail?.serviceImageDetails
      ? serviceDetail?.serviceImageDetails?.location
      : Constants.SERVICE_FALL_BACK_ICON,
  );
  const createServicePayload: ICreateServicePayload = {
    name: serviceName.trim(),
    iconId: selectedIconId || null,
    ageType: selectAgeTypeOption,
    patientType: selectPatientTypeOption,
    serviceCodes: serviceCodes?.split(','),
    duration,
    clinicIds: selectClinics?.length ? selectClinics : [],
  };

  useEffect(() => {
    if (
      Object.values(createServicePayload).every((ele) => ele !== '') &&
      serviceCodes &&
      !(duration % 5 || duration < 15)
    ) {
      setAddButtonActive(false);
      setEditBtn && setEditBtn(false);
    } else {
      setAddButtonActive(true);
      setEditBtn && setEditBtn(true);
    }
    if (edit) {
      setUpdatePayload && setUpdatePayload(createServicePayload);
    }
  }, [
    serviceName,
    selectAgeTypeOption,
    selectPatientTypeOption,
    serviceCodes.trim(),
    iconObj,
    duration,
  ]);

  // For virtual list of clinics
  useEffect(() => {
    if (moreClinics.length > 0 && !searchCustomClinic) {
      getClinic();
    }
  }, [moreClinics.length]);

  // Inital load
  useEffect(() => {
    getClinic();
  }, []);

  // for search clinics
  useEffect(() => {
    getSearchedClinics();
    setCounter(0);
    if (searchCustomClinic.trim().length < 3 && moreClinics.length !== clinicList.total) {
      getClinic();
    }
  }, [searchCustomClinic.trim()]);

  // list service icons
  useEffect(() => {
    getIconList();
  }, []);

  // to search and select clinics for side drawer
  const onSearch = (inputValue: string) => {
    setSearchCustomClinic(inputValue);
  };

  const onSelectClinics = (inputValue: any) => {
    if (edit) {
      createServicePayload.clinicIds = inputValue.length ? inputValue : [];
      setUpdatePayload && setUpdatePayload(createServicePayload);
    }
    setSelectClinics(inputValue);
  };

  const getClinic = async () => {
    if (clinicList.total > 0 && clinicList?.total !== moreClinics?.length) {
      setCounter((prev) => prev + 13);
    } else if (clinicList.total > 0 && clinicList?.total === moreClinics?.length) {
      return;
    }
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(clinicList.total > 0 &&
          clinicList?.total !== moreClinics?.length && {
          skip: counter,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      if (moreClinics.length === 0) {
        setMoreClinics(() => listClinic.data?.clinics);
      } else {
        const len = moreClinics.filter((o1: any) => {
          return listClinic.data.clinics.some((o2: any) => {
            return o1.id === o2.id; // return the ones with equal id
          });
        }).length;
        if (len === 0) {
          const newArray = moreClinics.map((a: any) => ({ ...a }));
          setMoreClinics(() => newArray.concat(listClinic.data?.clinics));
        } else {
          setMoreClinics(() => listClinic.data?.clinics);
        }
      }
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  const getSearchedClinics = async () => {
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(searchCustomClinic &&
          searchCustomClinic.length >= 3 && {
          searchString: searchCustomClinic,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      setMoreClinics(() => listClinic.data?.clinics);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  const addNewService = async () => {
    setShowLoader(true);
    try {
      const res = await ServiceService.createService(createServicePayload);
      setShowLoader(false);
      if (res?.data) {
        sessionStorage.setItem('addedService', res.data.name);
      }
      navigate('/services');
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
      setShowLoader(false);
    }
  };

  const handleServiceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceName(event.target.value);
  };

  const handleSelectAgeTypeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAgeTypeOption(event.target.value);
  };

  const handleSelectPatientTypeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPatientTypeOption(event.target.value);
  };

  const handleServiceCodesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceCodes(event.target.value);
  };

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(+event.target.value);
  };
  const onIncrement = () => {
    !showLoader ? setDuration((prev) => prev + 5) : '';
  };
  const onDecrement = () => {
    !showLoader ? setDuration((prev) => prev - 5) : '';
  };

  const handleDrawerOpen = () => {
    setSearchCustomClinic('');
    setSideDrawerOpen(true);
  };

  const getIconList = async () => {
    setShowLoader(true);
    try {
      const response: IIconListRO = await ManageService.getServiceIconList();
      setIconList(response);
      setShowLoader(false);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleIconPopupOpen = () => {
    setOpen(true);
    setUpdateProfile && setUpdateProfile(iconObj);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedIconId(null);
  };

  const handleIconClick = (id: any) => {
    setSelectedIconId(id);
  };

  const handleDone = () => {
    setOpen(false);
    const selectedIcon = iconList?.data.find((image: any) => image.id === selectedIconId);
    setIconObj(selectedIcon?.iconDetails.location);
  };

  return (
    <>
      {(showLoader || updateLoader) && <Loader />}
      <div className='serviceAddContainer'>
        {edit ? (
          ''
        ) : (
          <div className='serviceAddHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' component='div' className='serviceTitle' color='inherit'>
                Add New Service
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Add New Service'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant='outlined'
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() => navigate('/services')}
                >
                  Cancel
                </Button>
                <Buttons
                  text='Add New Service'
                  width='100%'
                  disable={disableAdd}
                  onClick={() => addNewService()}
                />
              </div>
            </Toolbar>
          </div>
        )}
        <Paper className='serviceAddBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div className='serviceAdd'>
            <div className='serviceProfile'>
              <label
                htmlFor='input'
                style={{ backgroundColor: zeplinColor.Surface, cursor: 'pointer' }}
                onClick={handleIconPopupOpen}
              >
                <Avatar className='serviceImageClass' src={iconObj} />
                <div className='serviceIconClass' style={{ backgroundColor: zeplinColor.Surface }}>
                  {' '}
                  <CameraAltOutlinedIcon className='serviceCameraIcon' />
                </div>
              </label>
            </div>
            <div className='textFieldSection'>
              <CustomTextField
                name='serviceName'
                label='Service Name*'
                placeholder='Service Name'
                showIcon={false}
                value={serviceName}
                onChange={handleServiceNameChange}
              />
            </div>
            <div className='radioButtonSection'>
              <Typography variant='subtitle1' style={{ marginLeft: '-8px' }}>
                Age Type*
              </Typography>
              <FormControl style={{ marginTop: '12px', position: 'inherit' }}>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={selectAgeTypeOption}
                  onChange={handleSelectAgeTypeOption}
                  sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                  <FormControlLabel
                    value='adult'
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Adult</Typography>}
                  />
                  <FormControlLabel
                    value='child'
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Child</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='radioButtonSection'>
              <Typography variant='subtitle1' style={{ marginLeft: '-8px' }}>
                Patient Type*
              </Typography>
              <FormControl style={{ marginTop: '12px', position: 'inherit' }}>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={selectPatientTypeOption}
                  onChange={handleSelectPatientTypeOption}
                  sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                  <FormControlLabel
                    value='existing'
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Existing</Typography>}
                  />
                  <FormControlLabel
                    value='new'
                    control={
                      <Radio
                        disabled={showLoader ? true : false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>New</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='textFieldSection'>
              <CustomTextField
                name='serviceCodes'
                label='Service Codes*'
                placeholder='Service Codes'
                showIcon={false}
                value={serviceCodes}
                disable={showLoader ? true : false}
                onChange={handleServiceCodesChange}
              />
            </div>
            <div className='textFieldSection'>
              <CustomNumericInput
                name='duration'
                label='Duration (Minutes)*'
                placeholder='Duration'
                value={duration}
                error={duration % 5 || duration < 15 ? true : false}
                disabled={showLoader ? true : false}
                onChange={handleDurationChange}
                onIncrement={onIncrement}
                onDecrement={onDecrement}
              />

              {duration % 5 || duration < 15 ? (
                <Typography className='profileDescription' variant='caption' color='error'>
                  Duration must be multiple of 5 & atleast 15.
                </Typography>
              ) : (
                ''
              )}
            </div>

            <Typography variant='subtitle1'>Select Clinics</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Buttons
                text='Assign to clinics'
                width='35%'
                bgWhite={true}
                disable={showLoader ? true : false}
                onClick={handleDrawerOpen}
              />

              {
                <SideDrawer
                  setSideDrawerOpen={setSideDrawerOpen}
                  sideDrawerOpen={sideDrawerOpen}
                  clinicListData={clinicList}
                  onSearch={onSearch}
                  selectCustomClinic={selectClinics}
                  onSelectClinics={onSelectClinics}
                  disableSave={false}
                  setChipData={setChipData}
                  moreClinics={moreClinics}
                  getClinics={getClinic}
                  getSearchedClinics={getSearchedClinics}
                  searchLength={searchCustomClinic.length}
                />
              }
              {
                <Typography color={chipData ? 'primary' : 'textSecondary'} variant='caption'>
                  {chipData ? (selectClinics.length < 10 ? '0' + chipData : chipData) : 'No'}{' '}
                  Clinics Selected
                </Typography>
              }
            </div>
          </div>
        </Paper>
        {showSnackbar && (
          <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
        )}
      </div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>
          <Typography variant='h5'>Select Icon</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: '500px' }}>
            {iconList?.data?.map((obj: IIconDetailsResponse) => (
              <Grid item key={obj.id}>
                <Button
                  onClick={() => handleIconClick(obj.id)}
                  style={{
                    border:
                      selectedIconId === obj.id
                        ? `2px ${zeplinColor.Primary} solid`
                        : `1px ${zeplinColor.Background80} solid`,
                    borderRadius: '8px',
                    gap: '24px',
                    width: '80px',
                    height: '80px',
                  }}
                >
                  <img
                    src={
                      obj.iconDetails?.location
                        ? obj.iconDetails?.location
                        : Constants.SERVICE_FALL_BACK_ICON
                    }
                    alt={`Image ${obj.id}`}
                    style={{ width: '48px', height: '48px' }}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.cancelButton}>
          <Button onClick={handleCancel} style={{ color: `${zeplinColor.Background70}` }}>
            Cancel
          </Button>
          <Button
            onClick={handleDone}
            style={{
              color:
                selectedIconId === null ? `${zeplinColor.Background70}` : `${zeplinColor.Primary}`,
            }}
            disabled={selectedIconId === null ? true : false}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddService;
