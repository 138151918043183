/*
 * File: UpdatePatientProfile.tsx
 * Project: mint-portal
 * File Created: Wednesday, 9th August 2023 3:19:09 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 25th September 2023 11:48:19 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Button, Typography } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import CustomTextField from '../../../shared/CustomTextField';
import PhoneFieldInput from '../../../shared/CustomPhoneField/CustomPhoneField';
import zeplinColor from '../../../../theme/zeplinColor';
import './UpdatePatientProfile.css';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import { Constants } from '../../../../utilities/constants';
import {
  IFamilyMemberDetails,
  IPatientDetailUpdate,
  IPatientDetailsData,
  IFamilyMemberPayload,
} from '../../../../model/patient.model';
import { validateEmail } from '../../../../utilities/validate';
import moment from 'moment';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import { PatientService } from '../../../../service/patient.service';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { useLocation } from 'react-router-dom';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';

type updateProfileProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  patientDetails: IPatientDetailsData | undefined;
  familyDetails: IFamilyMemberDetails | undefined;
  getPatientDetails: (id: string) => Promise<void>;
  getFamilyDetails: (primary: string, family: string) => Promise<void>;
};

const UpdatePatientProfile = ({
  open,
  setOpen,
  patientDetails,
  familyDetails,
  getPatientDetails,
  getFamilyDetails,
}: updateProfileProps) => {
  const route = useLocation();
  const relationshipOptions = [
    { name: 'Parent', id: 'Parent' },
    { name: 'Spouse', id: 'Spouse' },
    { name: 'Child', id: 'Child' },
    { name: 'Sibling', id: 'Sibling' },
  ];
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    phone: '',
    email: '',
  });
  const { firstName, lastName, gender, phone, email } = input;
  const [relationShip, setRelationship] = useState(
    { name: familyDetails?.relationship, id: familyDetails?.relationship } || '',
  );
  const [isEmailValid, setEmailValid] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState(
    patientDetails
      ? moment(patientDetails?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
      : '',
  );
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackMessage, setSnackMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [patientExist, setPatientExist] = useState(false);
  const [phoneNumberExist, setPhoneNumberExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const handleChangeText = (e: any) => {
    if (typeof e === 'string') {
      setInput({ ...input, phone: e });
    } else {
      const { name, value } = e.target;
      if (name === 'dateOfBirth') {
        setDateOfBirth(moment(value.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
      } else {
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (name === 'email') {
          emailCheck(value);
        }
      }
    }
  };

  const handleGenderChange = (selectedGender: any) => {
    setInput((prev) => ({
      ...prev,
      gender: selectedGender,
    }));
  };

  const emailCheck = (value: string) => {
    const isValid = value === '' || validateEmail(value);
    setEmailValid(isValid);
  };

  const handleDate = (newValue: string | Date | null) => {
    setDateOfBirth(moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    setInput({
      ...input,
      dateOfBirth: moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    });
  };

  const handleClose = () => {
    setOpen(false);
    setInput({
      firstName: patientDetails?.firstName || familyDetails?.firstName || '',
      lastName: patientDetails?.lastName || familyDetails?.lastName || '',
      gender: patientDetails?.gender || familyDetails?.gender || '',
      dateOfBirth: patientDetails?.dateOfBirth || familyDetails?.dateOfBirth || '',
      phone: patientDetails?.phoneNumber || '',
      email: patientDetails?.email || '',
    });
  };

  useEffect(() => {
    setInput({
      firstName: patientDetails?.firstName || familyDetails?.firstName || '',
      lastName: patientDetails?.lastName || familyDetails?.lastName || '',
      gender: patientDetails?.gender || familyDetails?.gender || '',
      dateOfBirth: patientDetails?.dateOfBirth || familyDetails?.dateOfBirth || '',
      phone: patientDetails?.phoneNumber || '',
      email: patientDetails?.email || '',
    });
    setDateOfBirth(
      patientDetails
        ? moment(patientDetails?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
        : familyDetails
          ? moment(familyDetails?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : '',
    );
    setPatientExist(false);
    setPhoneNumberExist(false);
    setEmailExist(false);
    if (familyDetails)
      setRelationship({ name: familyDetails?.relationship, id: familyDetails?.relationship });
  }, [patientDetails, familyDetails, open]);

  const editPatientDetail = async () => {
    if (patientDetails?.id) {
      const editPayload: IPatientDetailUpdate = {
        isPrimaryPatient: patientDetails && true,
        primaryPatient: {
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          dateOfBirth: dateOfBirth,
          email: email,
          phoneNumber: phone,
        },
      };
      try {
        const response = await PatientService.updatePatientProfile(patientDetails?.id, editPayload);
        setOpen(false);
        setShowLoader(true);
        getPatientDetails(patientDetails?.id);
        setShowSnackBar(true);
        setSnackMessage(response.message);
      } catch (err: any) {
        if(err.response.data.error.errorObject.isNameAndDobExists)
          setPatientExist(true)
        if(err.response.data.error.errorObject.isPhoneNumberExists)
          setPhoneNumberExist(true)
        if(err.response.data.error.errorObject.isEmailExists)
          setEmailExist(true)
        setOpen(true);
        setShowSnackBar(true);
        setSnackMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      } finally {
        setShowLoader(false);
        // setOpen(false);
      }
    } else if (familyDetails && relationShip?.id) {
      const editPayload: IFamilyMemberPayload = {
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        dateOfBirth: dateOfBirth,
        relationship: relationShip.id,
      };
      try {
        const patId = route.pathname.split('/')[2];
        const response = await PatientService.updatePatientProfile(familyDetails?.id, {
          isPrimaryPatient: false,
          familyMember: editPayload,
        });
        setShowLoader(true);
        setOpen(false);
        getFamilyDetails(patId, familyDetails?.id);
        setShowSnackBar(true);
        setSnackMessage(response.message);
      } catch (err: any) {
        if(err.response.data.error.errorObject.isNameAndDobExists)
          setPatientExist(true)
        if(err.response.data.error.errorObject.isPhoneNumberExists)
          setPhoneNumberExist(true)
        if(err.response.data.error.errorObject.isEmailExists)
          setEmailExist(true)
        setOpen(true);
        setShowSnackBar(true);
        setSnackMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      } finally {
        setShowLoader(false);
      }
    }
  };

  const isValidCondition = (
    (familyDetails && relationShip?.id) && Constants.REGEX.name.test(firstName) && Constants.REGEX.name.test(lastName) && gender && dateOfBirth ||
    (Constants.REGEX.name.test(firstName) && Constants.REGEX.name.test(lastName)  && email !== '' && isEmailValid && phone && gender && dateOfBirth)
  );
  
  const selectRelationship = (e: any) => {
    setRelationship(e);
  };

  return (
    <>
      {showLoader && <Loader />}
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography color='textPrimary' variant='subtitle1'>
            Edit Patient Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <InfoTextBox
              content={Constants.INFO_TEXTS.PATIENT_PROFILE_EDIT_INFO_TEXT}
              show={true}
              width={''}
            />
            {patientExist ? (
              <Typography style={{ color: zeplinColor.Error }} variant='subtitle1'>
                {Constants.INFO_TEXTS.EDIT_PATIENT_EXISTING_ALERT}
              </Typography>
            ) : (
              ''
            )}
            <div className='nameField'>
              <CustomTextField
                width='280px'
                name='firstName'
                value={firstName}
                onChange={handleChangeText}
                label='First Name'
                error={patientExist || (firstName ? !Constants.REGEX.name.test(firstName) : false)}
              ></CustomTextField>
              <CustomTextField
                width='280px'
                name='lastName'
                value={lastName}
                onChange={handleChangeText}
                label='Last Name'
                error={patientExist || (lastName ? !Constants.REGEX.name.test(lastName) : false)}
              ></CustomTextField>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Typography color='textPrimary' variant='subtitle1'>
                Gender
              </Typography>
              <FormControl style={{ position: 'inherit' }}>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={gender}
                  onChange={(event) => handleGenderChange(event.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
                >
                  <FormControlLabel
                    value='male'
                    control={
                      <Radio
                        disabled={false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Male</Typography>}
                  />
                  <FormControlLabel
                    value='female'
                    control={
                      <Radio
                        disabled={false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Female</Typography>}
                  />
                  <FormControlLabel
                    value='other'
                    control={
                      <Radio
                        disabled={false}
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='body1'>Others</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ marginLeft: '10px' }}>
              {' '}
              <CustomDatePicker
                value={moment(dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)}
                handleDateChange={handleDate}
                disablePast={false}
                width={'535px'}
                label='Date Of Birth'
                error={patientExist}
              />
            </div>
            {patientDetails && (
              <>
                <div className='phoneNumber' style={{display:'flex', flexDirection:'column'}}>
                  <PhoneFieldInput
                    name='phone'
                    value={phone}
                    width='538px'
                    onChange={handleChangeText}
                    placeholder='Phone Number'
                    padding={false}
                    margin={true}
                    error={phoneNumberExist}
                  />
                  {phoneNumberExist &&  <Typography variant='body1' style={{ color: zeplinColor.Error , paddingLeft:'8px'}}>
                    {Constants.ERROR_MESSAGES.UPDATE_PATIENT.PHONE_NUMBER_EXISTS}
                  </Typography>}
                </div>
                <div className='emailField'>
                  <CustomTextField
                    style={{ width: '538px' }}
                    error={email ? (!isEmailValid || emailExist) : false}
                    name='email'
                    value={email}
                    onChange={handleChangeText}
                    label='Email Address'
                  ></CustomTextField>
                  {emailExist &&  <Typography variant='body1' style={{ color: zeplinColor.Error , paddingLeft:'8px'}}>
                    {Constants.ERROR_MESSAGES.UPDATE_PATIENT.EMAIL_EXISTS}
                  </Typography>}
                </div>
              </>
            )}
            {familyDetails && (
              <div style={{ paddingLeft: '10px' }}>
                <SearchDropdown
                  data-testid='dropdown'
                  searchList={relationshipOptions}
                  width={'100%'}
                  label={'Relationship'}
                  onSelectHandler={selectRelationship}
                  onSearchHandler={() => {
                    return;
                  }}
                  multiple={false}
                  fixed={true}
                  value={relationShip}
                  clearIcon={false}
                  // fixed={selectOperatory ? true : false}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: `${zeplinColor.Background70}`,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            onClick={() => editPatientDetail()}
            style={{
              color:
               isValidCondition
                 ? `${zeplinColor.Primary}`
                 : `${zeplinColor.Background70}`,
              marginRight: '20px',
            }}
            autoFocus
            disableRipple
            disabled={
              isValidCondition ? false : true
            }
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      {showSnackBar && (
        <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
      )}
    </>
  );
};

export default UpdatePatientProfile;
