/*
 * File: reward.service.ts
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 11:37:59 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 13th June 2023 1:58:46 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import {
  IAddGiftRewardPayload,
  IAddPointCreditRewardPayload,
  IAutoRewardRO,
  IDetailRedeemRewardHistoryRO,
  IDetailRewardRO,
  IEmptyRO,
  IListRewardRO,
  IListRewardRedeemHistoryRO,
  IRedeemPayload,
  IQueryParam,
  IListElectiveProceduresRO,
  IListAutoAssignRewardsRO,
  IRewardCategoryRO,
  IRewardQueryParam,
  IElectiveProcedurePayload,
  IElectiveProcedure,
} from '../model';

import { ApiConstants } from './api-constants';
import axios from '../core/axios';

export class RewardService {
  static getConfigRewardList = async (queryParams: IRewardQueryParam): Promise<IListRewardRO> => {
    return await (
      await axios.get(
        ApiConstants.REWARDS_API.LIST_CONFIGURED_REWARDS.URL() +
          ApiConstants.REWARDS_API.LIST_CONFIGURED_REWARDS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getRedeemRewardHistoryList = async (
    queryParams: IRewardQueryParam,
  ): Promise<IListRewardRedeemHistoryRO> => {
    return await (
      await axios.get(
        ApiConstants.REWARDS_API.LIST_REDEEMED_HISTORY_REWARDS.URL() +
          ApiConstants.REWARDS_API.LIST_REDEEMED_HISTORY_REWARDS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getRewardById = async (id: number): Promise<IDetailRewardRO> => {
    return await (
      await axios.get(ApiConstants.REWARDS_API.REWARD_BY_ID.URL(id))
    ).data;
  };

  static getRedeemRewardHistoryById = async (id: number): Promise<IDetailRedeemRewardHistoryRO> => {
    return await (
      await axios.get(ApiConstants.REWARDS_API.VIEW_REDEEM_REWARD_HISTORY_BY_ID.URL(id))
    ).data;
  };

  static getRewardCategory = async (queryParams: IRewardQueryParam): Promise<IRewardCategoryRO> => {
    return await (
      await axios.get(
        ApiConstants.REWARDS_API.LIST_CATEGORY_REWARDS.URL() +
          ApiConstants.REWARDS_API.LIST_CATEGORY_REWARDS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static deleteRewardById = async (id: number): Promise<IEmptyRO> => {
    return await (
      await axios.delete(ApiConstants.REWARDS_API.REWARD_BY_ID.URL(id))
    ).data;
  };

  static addGiftReward = async (giftPayload: IAddGiftRewardPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.REWARDS_API.ADD_GIFT_REWARD.URL(), giftPayload)
    ).data;
  };

  static addPointCreditReward = async (
    pointCreditPayload: IAddPointCreditRewardPayload,
  ): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.REWARDS_API.ADD_POINT_CREDIT_REWARD.URL(), pointCreditPayload)
    ).data;
  };

  static editPointCreditReward = async (
    id: number,
    pointCreditPayload: IAddPointCreditRewardPayload,
  ): Promise<IDetailRewardRO> => {
    return await (
      await axios.put(ApiConstants.REWARDS_API.EDIT_POINT_CREDIT_REWARD.URL(id), pointCreditPayload)
    ).data;
  };

  static editGiftReward = async (
    id: number,
    giftPayload: IAddGiftRewardPayload,
  ): Promise<IDetailRewardRO> => {
    return await (
      await axios.put(ApiConstants.REWARDS_API.EDIT_GIFT_REWARD.URL(id), giftPayload)
    ).data;
  };

  static redeemReward = async (redeemPayload: IRedeemPayload): Promise<IEmptyRO> => {
    return await (
      await axios.post(ApiConstants.REWARDS_API.REDEEM_REWARD.URL(), redeemPayload)
    ).data;
  };
  static updateAutoReward = async (id: number, status: boolean): Promise<IEmptyRO> => {
    return await (
      await axios.put(ApiConstants.REWARDS_API.UPDATE_AUTO_REWARD.URL(id, status))
    ).data;
  };

  static getAutoRewardsList = async (): Promise<IListAutoAssignRewardsRO> => {
    return await (
      await axios.get(ApiConstants.REWARDS_API.LIST_AUTO_REWARDS.URL())
    ).data;
  };

  static getElectiveProcedureAutoRewardsList = async (
    queryParams: IQueryParam,
  ): Promise<IListElectiveProceduresRO> => {
    return await (
      await axios.get(
        ApiConstants.REWARDS_API.LIST_ELECTIVE_PROCEDURE_AUTO_REWARDS.URL() +
          ApiConstants.REWARDS_API.LIST_ELECTIVE_PROCEDURE_AUTO_REWARDS.QUERY_PARAMS(queryParams),
      )
    ).data;
  };

  static getAutoRewardById = async (id: number): Promise<IAutoRewardRO> => {
    return await (
      await axios.get(ApiConstants.REWARDS_API.AUTO_REWARD_BY_ID.URL(id))
    ).data;
  };

  static addElectiveProcedure = async (
    procedurePayload: IElectiveProcedurePayload,
  ): Promise<IElectiveProcedure> => {
    return await (
      await axios.post(ApiConstants.REWARDS_API.ADD_ELECTIVE_PROCEDURE.URL(), procedurePayload)
    ).data;
  };
}
