/*
 * File: mainLayout.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 8:17:27 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 19th October 2022 2:38:29 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React from 'react';
import SideBar from '../components/shared/Side-bar/sideBar';
import Header from '../components/shared/Header/header';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import './mainLayout.css';

const MainLayout = () => {
  return (
    <>
      <Header />
      <Grid container spacing={1} columnSpacing={{ xs: 2 }} className='grid'>
        <Grid item md={2}>
          <SideBar />
        </Grid>
        <Grid style={{ marginLeft: '3%' }}></Grid>
        <Grid item md={9} style={{ paddingLeft: '0px' }}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayout;
