/*
 * File: PatientDetailCard.tsx
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 12:59:49 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 26th May 2023 4:15:54 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './DetailCard.css';

import { Button, Typography } from '@material-ui/core';
import { IFamilyMemberDetails, IPatientDetailsData } from '../../../../model';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CakeIcon from '@mui/icons-material/Cake';
import { Constants } from '../../../../utilities/constants';
import CustomToolTip from '../../../shared/CustomToolTip';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import React from 'react';
import { Utilities } from '../../../../utilities/utilities';
import moment from 'moment';
import { zeplinColor } from '../../../../theme';

type prop = {
  patientDetails: IPatientDetailsData | undefined;
  familyDetails: IFamilyMemberDetails | undefined;
  availableRewardPoints: number | undefined;
  setUpdatePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setAddRewardModal: React.Dispatch<React.SetStateAction<boolean>>;
  getRewardList: () => Promise<void>;
};

const PatientDetailCard = ({
  familyDetails,
  patientDetails,
  availableRewardPoints,
  setUpdatePopUp,
  setAddRewardModal,
  getRewardList,
}: prop) => {
  // const getPermission = getPermissionForModule();
  // const permission = getPermission.permissions.Patient;
  const handleUpdatePopUp = () => {
    // setUpdatePopUp(true);
    console.log(setUpdatePopUp);
  };

  const navigate = useNavigate();
  const [isFamily, setIsFamily] = React.useState<boolean>(false);
  const { id } = useParams();

  React.useEffect(() => {
    if (familyDetails?.id) {
      setIsFamily(true);
    } else {
      setIsFamily(false);
    }
  }, [familyDetails, patientDetails]);

  return (
    <div>
      <div
        className='infocard-top'
        style={{
          borderBottom: `1px solid ${zeplinColor.Background90}`,
          //   padding: '25px 32px 17px 30px'
        }}
      >
        <div className='info-flex-30'>
          <Typography variant='h2' style={{ width: '50rem' }}>
            {(familyDetails?.firstName || patientDetails?.firstName) +
              ' ' +
              (familyDetails?.lastName || patientDetails?.lastName)}
          </Typography>
          <Typography
            variant='h6'
            className='patient-chip'
            style={{
              width: '12rem',
              color: isFamily ? zeplinColor.Blue : zeplinColor.Primary,
              backgroundColor: isFamily ? zeplinColor.Blue01 : zeplinColor.Primary10,
            }}
          >
            {isFamily ? 'Family Member' : 'Primary Patient'}
          </Typography>
        </div>
        <div className='info-flex-50'>
          <div className='info-flex-10'>
            <PersonIcon style={{ color: zeplinColor.Background70 }} />
            <Typography variant='body1' style={{ textTransform: 'capitalize' }}>
              {isFamily && familyDetails
                ? Constants.PATIENTS.GENDER[familyDetails.gender]
                : patientDetails && Constants.PATIENTS.GENDER[patientDetails.gender]}
            </Typography>
          </div>
          <div className='info-flex-10'>
            <CakeIcon style={{ color: zeplinColor.Background70 }} />
            <Typography variant='body1'>
              {moment(familyDetails?.dateOfBirth || patientDetails?.dateOfBirth).format(
                Constants.MOMENT_FORMATS.DATE_FORMAT,
              )}
            </Typography>
          </div>
          <div className='info-flex-10'>
            <PhoneIcon style={{ color: zeplinColor.Background70 }} />
            <Typography variant='body1'>
              {isFamily && familyDetails && familyDetails?.primaryPatientDetails
                ? Utilities.usFormatMobile(familyDetails?.primaryPatientDetails?.phoneNumber)
                : patientDetails && Utilities.usFormatMobile(patientDetails?.phoneNumber)}
            </Typography>
          </div>
          <div className='info-flex-10'>
            <EmailIcon style={{ color: zeplinColor.Background70 }} />
            <CustomToolTip
              title={
                familyDetails?.primaryPatientDetails?.email
                  ? familyDetails?.primaryPatientDetails.email
                  : patientDetails?.email
              }
              width={'255px'}
              variant='body1'
            />
          </div>
          <div className='info-flex-10'>
            <Typography
              variant='subtitle1'
              style={
                patientDetails?.isEmailVerified ||
                familyDetails?.primaryPatientDetails?.isEmailVerified
                  ? { color: zeplinColor.Primary }
                  : { color: zeplinColor.Error }
              }
            >
              {(familyDetails?.primaryPatientDetails &&
                familyDetails?.primaryPatientDetails?.isEmailVerified) ||
              patientDetails?.isEmailVerified
                ? 'Verified'.toLocaleUpperCase()
                : 'Not Verified'.toLocaleUpperCase()}
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ margin: '28px 0px 0px 24px' }}>
        <Typography variant='h6' style={{ color: zeplinColor.Background50 }}>
          Patient Information
        </Typography>
      </div>
      <div className='info-flex-row info-gap' style={{ padding: '24px 0px 24px 24px' }}>
        <div style={{ width: '270px' }}>
          <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
            {familyDetails?.primaryPatientDetails?.id
              ? 'Relationship with Primary Patient'
              : 'Home Clinic'}
          </Typography>
          <Typography variant='subtitle1'>
            {familyDetails?.primaryPatientDetails?.id
              ? familyDetails?.relationship
              : patientDetails?.defaultClinic
                ? patientDetails?.defaultClinic?.name
                : '-'}
          </Typography>
        </div>
        <div style={{ width: '400px' }}>
          <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
            Insurance Carrier
          </Typography>
          <div style={{ display: 'flex', gap: 8 }}>
            {familyDetails?.insuranceDetails?.insuranceName ||
            patientDetails?.insuranceDetails?.insuranceName ? (
                <>
                  <CustomToolTip
                    variant='subtitle1'
                    title={
                      familyDetails && familyDetails?.insuranceDetails
                        ? familyDetails?.insuranceDetails?.insuranceName
                        : patientDetails?.insuranceDetails?.insuranceName
                    }
                    width={'130px'}
                  />
                  {familyDetails && familyDetails?.isUsingPrimaryInsurance && (
                    <Typography variant='body2' style={{ color: zeplinColor.Background50 , marginTop:'5px'}}>
                    (from Primary Patient)
                    </Typography>
                  )}
                  <Button
                    color='primary'
                    style={{ paddingTop: 3 }}
                    onClick={() =>
                      navigate(
                        isFamily
                          ? `/patient/${id}/family-member/${familyDetails?.id}/insurance-details`
                          : `/patient/${id}/insurance-details`,
                      )
                    }
                  >
                  View details
                  </Button>
                </>
              ) : (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={
                    familyDetails?.id
                      ? `/patient/${familyDetails?.primaryPatientDetails.id}/family-member/${familyDetails?.id}/add-insurance/${familyDetails?.firstName}/${familyDetails?.lastName}/`
                      : `/patient/${patientDetails?.id}/add-insurance/${patientDetails?.firstName}/${patientDetails?.lastName}`
                  }
                >
                  <Button color='primary' style={{ paddingTop: 3, marginLeft: -7 }} onClick={handleUpdatePopUp}>
                  Add Insurance
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className='info-flex-row info-gap' style={{ padding: '0px 0px 24px 24px' }}>
        <div style={{ width: '270px' }}>
          <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
            Patient Unique Code
          </Typography>
          <Typography variant='h5'>{familyDetails?.code || patientDetails?.code}</Typography>
        </div>
        <div style={{ width: '270px' }}>
          {!isFamily && (
            <>
              <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
                Reward Points Available
              </Typography>
              <div className='info-flex-row' style={{ gap: '12px' }}>
                <Typography variant='h5'>{availableRewardPoints}</Typography>
                <Button
                  color='primary'
                  style={{ paddingTop: 3 }}
                  onClick={() => {
                    setAddRewardModal(true);
                    getRewardList();
                  }}
                >
                  ADD POINTS
                </Button>
              </div>
            </>
          )}
        </div>
        <div style={{ margin: '24px 0px 0px 40px' }}>
          <Typography variant='body1' style={{ color: zeplinColor.Background50 }}>
            {`Joined on ${moment(
              familyDetails?.primaryPatientDetails?.id
                ? familyDetails?.createdAt
                : patientDetails?.profileCompletedAt,
            ).format(Constants.MOMENT_FORMATS.DATE_FORMAT)}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PatientDetailCard;
