/*
 * File: Nppwk.tsx
 * Project: mint-portal
 * File Created: Sunday, 25th September 2022 11:11:48 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 19th February 2023 11:05:37 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Radio, FormControl, RadioGroup, FormControlLabel, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IClinicNppwLinkRO,
  IUpdateClinicNppw,
  IViewClinicDetailsData,
  IViewClinicNppwDetails,
} from '../../../../../model';
import { ClinicService } from '../../../../../service/clinic.service';
import { zeplinColor } from '../../../../../theme';
import Buttons from '../../../../shared/Buttons';
import CustomTextField from '../../../../shared/CustomTextField';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../shared/Loader/Loader';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { Constants } from '../../../../../utilities/constants';

type nppwkProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const NPPW_OPTIONS = {
  GLOBAL: 'global',
  CUSTOM: 'custom',
};

const ClinicNppwk = ({ updatedClinicDetails, handleCreateClinicBtn }: nppwkProps) => {
  const route = useLocation();
  const [nppwk, setNppwk] = useState<IViewClinicNppwDetails>();
  const [selectNppwkOption, setSelectNppwkOption] = React.useState('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [customNppwk, setCustomNppwk] = useState('');

  const handleSelectNppwkOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectNppwkOption(event.target.value);
  };

  const handleCustomNppwk = (input: { target: { value: React.SetStateAction<string> } }) => {
    setCustomNppwk(input.target.value);
  };

  useEffect(() => {
    if (updatedClinicDetails?.id) {
      getNppwk();
    }
  }, [updatedClinicDetails?.id]);

  const getNppwk = async () => {
    if (updatedClinicDetails?.id) {
      try {
        const response: IClinicNppwLinkRO = await ClinicService.getNppwLinkByClinicId(
          updatedClinicDetails?.id,
        );
        setNppwk(response.data);
        setSelectNppwkOption(
          response.data.customNPPWLink === null ? NPPW_OPTIONS.GLOBAL : NPPW_OPTIONS.CUSTOM,
        );
        setCustomNppwk(response.data.customNPPWLink !== null ? response?.data.customNPPWLink : '');
      } catch (error) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof error === 'string' ? error : (error as any).response.data.error.message[0],
        );
      }
    }
  };
  const handleSave = async () => {
    if (updatedClinicDetails?.id && selectNppwkOption) {
      setShowLoader(true);
      try {
        const payLoad: IUpdateClinicNppw = {
          customLink: selectNppwkOption === NPPW_OPTIONS.GLOBAL ? null : customNppwk.trim(),
        };
        const res = await ClinicService.updateNppwLinkByClinicId(updatedClinicDetails?.id, payLoad);
        if (res.data) {
          handleCreateClinicBtn(false);
        }
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Nppwk Saved');
      } catch (err) {
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowLoader(false);
      }
    }
  };

  return (
    <>
      {showLoader && <Loader margin={true} />}
      <div style={{ padding: '36px 24px' }}>
        <Typography variant='h6' style={{ marginBottom: 36 }}>
          New Patient Paperwork Link
        </Typography>
        {nppwk && (
          <Box>
            <Typography style={{ marginBottom: '20px' }} variant='subtitle1'>
              {nppwk?.customNPPWLink ? 'Custom' : 'Mint Global'}
            </Typography>

            {route?.pathname?.split('/')[2] === 'view' ? (
              <Link
                target='_blank'
                rel='noopener'
                variant='body1'
                underline='none'
                href={
                  nppwk?.customNPPWLink !== null
                    ? nppwk && Constants.REGEX.urlRegex.test(nppwk?.customNPPWLink)
                      ? nppwk?.customNPPWLink
                      : '//' + nppwk?.customNPPWLink
                    : Constants.REGEX.urlRegex.test(nppwk?.global?.NPPWLink)
                      ? nppwk?.global?.NPPWLink
                      : '//' + nppwk?.global?.NPPWLink
                }
                style={{
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: `${zeplinColor.BlueLink}`,
                }}
              >
                {nppwk?.customNPPWLink !== null ? nppwk?.customNPPWLink : nppwk?.global?.NPPWLink}
              </Link>
            ) : (
              <section>
                <FormControl>
                  <RadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    onChange={handleSelectNppwkOption}
                    value={selectNppwkOption}
                  >
                    <FormControlLabel
                      value={NPPW_OPTIONS.GLOBAL}
                      style={{ overflowWrap: 'break-word' }}
                      control={
                        <Radio
                          disabled={showLoader ? true : false}
                          sx={{
                            '&.Mui-checked': {
                              color: `${zeplinColor.Primary}`,
                            },
                          }}
                        />
                      }
                      label={<Typography variant='body1'>Mint Global</Typography>}
                    />
                    <Typography
                      style={{
                        marginLeft: '32px',
                        overflowWrap: 'break-word',
                        width: '39.3rem',
                        color: zeplinColor.Background50,
                      }}
                    >
                      {nppwk?.global?.NPPWLink}
                    </Typography>
                    <FormControlLabel
                      value={NPPW_OPTIONS.CUSTOM}
                      control={
                        <Radio
                          disabled={showLoader ? true : false}
                          sx={{
                            '&.Mui-checked': {
                              color: `${zeplinColor.Primary}`,
                            },
                          }}
                        />
                      }
                      label={<Typography variant='body1'>Custom</Typography>}
                    />
                    {selectNppwkOption === NPPW_OPTIONS.CUSTOM && (
                      <CustomTextField
                        name='Nppwk link'
                        label='Nppwk link'
                        showIcon={false}
                        value={customNppwk}
                        onChange={handleCustomNppwk}
                        width={630}
                        style={{ marginLeft: '32px' }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                <section style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
                  <Buttons text='SAVE DETAILS' width='160px' disable={false} onClick={handleSave} />
                </section>
              </section>
            )}
            {showSnackbar && (
              <SnackBar
                message={showSnackbarMessage}
                show={showSnackbar}
                setShow={setShowSnackbar}
              />
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default ClinicNppwk;
