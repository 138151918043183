/*
 * File: index.tsx
 * Project: mint-portal
 * File Created: Wednesday, 1st June 2022 12:06:03 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 24th August 2022 8:13:27 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './core/interceptor';
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode> // errors in googlemapreact npm package
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
