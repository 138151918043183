/*
 * File: ViewLinkDetail.tsx
 * Project: mint-portal
 * File Created: Wednesday, 31st May 2023 12:53:52 am
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 11:55:26 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { useParams, useNavigate } from 'react-router-dom';
import getPermissionForModule from '../../../../../../utilities/getPermission';
import { ManageService } from '../../../../../../service/manage.service';
import BreadCrumbs from '../../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../../shared/Buttons';
import { CustomCard } from '../../../../../shared/CustomCard/CustomCard';
import CustomTextField from '../../../../../shared/CustomTextField';
import DeletePopup from '../../../../../shared/DeletePopup';
import SnackBar from '../../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../../shared/Loader/Loader';
import EmptyState from '../../../../../shared/EmptyState/EmptyState';
import { Links } from '../../../../../../pages/Manage/ManageCardConstants';
import { ILinkAddPayload, ILinkDetailResponse } from '../../../../../../model';
import { Constants } from '../../../../../../utilities/constants';
import './ViewLinkDetail.css';
import zeplinColor from '../../../../../../theme/zeplinColor';
import { Avatar, Paper } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { FileService } from '../../../../../../service/file.service';

const useStyles = makeStyles(() => ({
  box: {
    maxWidth: '72vw',
    wordWrap: 'break-word',
  },
}));

const ViewOtherLinkDetail = () => {
  const styles = useStyles();
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [linkDetails, setLinkDetails] = useState<ILinkDetailResponse>();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: `/manage/links`, label: 'Links' },
    { path: `/manage/links`, label: 'Other Links' },
  ];
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [payload, setPayload] = useState({ title: '', url: '' });
  const [edit, setEdit] = useState(false);
  const [s3Key, setS3Key] = useState('');
  const [imageUrl, setImageUrl] = useState<string>();

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const onCancelEdit = () => {
    setEdit(false);
    setPayload({ title: linkDetails?.title ? linkDetails?.title : '', url: linkDetails?.url ? linkDetails?.url : '' });
  };

  const handleText = (e: { target: { name: string; value: string } }) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleIconUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      setShowLoader(true);
    }
    const imageUploadRes = await FileService.getSignedUrlToUploadFile(
      Constants.FILE_UPLOAD_CATEGORY.LINK_ICON_IMAGES,
      e.target.files[0],
      { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
    );
    setS3Key(imageUploadRes?.signedUrl?.data?.data?.s3Key);
    setShowLoader(false);
  };

  const getLinkDetailsById = async () => {
    setShowLoader(true);
    try {
      if (id) {
        const res = await ManageService.getLinkById(+id);
        setLinkDetails(res.data);
        setPayload({ title: res.data.title, url: res.data.url });
        setS3Key(res.data.imageDetails.s3Key);
        setImageUrl(res.data.imageDetails.location);
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteLink = async () => {
    if (id) {
      setShowLoader(true);
      try {
        await ManageService.deleteLinkById(+id);
        sessionStorage.setItem('deletedLink', 'deleted');
        navigate('/manage/links');
      } catch (err) {
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackBar(true);
      } finally {
        setShowLoader(false);
      }
    }
  };

  const updateOtherLink = async () => {
    setShowLoader(true);
    const payLoad: ILinkAddPayload = {
      title: payload.title,
      url: payload.url,
      s3Key: s3Key,
    };
    try {
      if (id) {
        await ManageService.updateLink(payLoad, +id);
        await getLinkDetailsById();
        setEdit(false);
        setShowSnackBar(true);
        setShowSnackbarMessage('Link Updated');
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getLinkDetailsById();
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      {!Links.roleAccess.includes(roleId) && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {Links.roleAccess.includes(roleId) && (
        <Box sx={{ width: '76.5vw', margin: '32px 0px 32px 24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            <Box>
              <Typography variant='h5'>Link Details</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Link Details'} />
            </Box>
            <Box>
              {!edit ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteButton'
                    onClick={handleDeletePopupOpen}
                  >
                    <DeleteOutlineSharpIcon style={{ width: '24px' }} />
                  </Button>
                  <Buttons
                    text='EDIT'
                    width='79px'
                    disable={false}
                    onClick={() => setEdit(!edit)}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    disabled={showLoader ? true : false}
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={onCancelEdit}
                  >
                    Cancel
                  </Button>
                  <Buttons
                    text='SAVE CHANGES'
                    width='162px'
                    disable={Object.values(payload).some((d) => !d) || showLoader ? true : false}
                    onClick={updateOtherLink}
                  />
                </div>
              )}
            </Box>
          </Box>
          {!showLoader && (
            <Box sx={{ marginTop: '28px' }}>
              {!edit ? (
                <CustomCard>
                  <Box style={{ padding: '32px 32px 47px 32px' }}>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1,1fr)',
                        gridRowGap: '32px',
                      }}
                    >
                      {' '}
                      <div
                        className='imageContainer'
                        style={{
                          border: `1px solid ${zeplinColor.Background90}`,
                          borderRadius: '50%',
                          width: '91px',
                          height: '93px',
                        }}
                      >
                        <img
                          src={linkDetails?.imageDetails?.location || Constants.FALL_BACK_IMAGE}
                          className='linkImage'
                          alt='profile'
                          onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
                        />
                      </div>
                      <Box className={styles.box}>
                        <Typography variant='subtitle1'>Title</Typography>
                        <Typography variant='body1'>{linkDetails?.title}</Typography>
                      </Box>
                      <Box className={styles.box}>
                        <Typography variant='subtitle1'>URL</Typography>
                        <Typography variant='body1'>{linkDetails?.url}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </CustomCard>
              ) : (
                <Paper className='viewOtherLinkCard' sx={{ borderRadius: '16px' }}>
                  <div className='viewOtherLinkDiv'>
                    <div className='viewOtherLinkProfile'>
                      <input
                        type='file'
                        name='image-upload'
                        id='input'
                        accept={Constants.IMAGE_TYPE}
                        onChange={handleIconUpload}
                      />
                      <label
                        htmlFor='input'
                        style={{ backgroundColor: zeplinColor.Surface, cursor: 'pointer' }}
                      >
                        <Avatar
                          className='viewOtherLinkImageClass'
                          src={imageUrl as string}
                          style={{ backgroundColor: zeplinColor.Background90 }}
                        >
                          <ImageOutlinedIcon style={{ color: zeplinColor.Background60, width: 45, height: 45 }} />
                        </Avatar>
                        <div
                          className='viewOtherLinkIconClass'
                          style={{ backgroundColor: zeplinColor.Surface }}
                        >
                          {' '}
                          <CameraAltOutlinedIcon className='viewOtherLinkCameraIcon' />
                        </div>
                      </label>

                      <div className='imageText'>
                        <Typography variant='caption' color='textSecondary'>
                          Upload a 64 x 64 PNG or JPG icon here
                        </Typography>
                      </div>
                    </div>
                    <CustomTextField
                      name='title'
                      label='Title*'
                      multiline={true}
                      value={payload.title}
                      width={'584px'}
                      onChange={handleText}
                    />
                    <CustomTextField
                      name='url'
                      label='URL*'
                      multiline={true}
                      value={payload.url}
                      width={'584px'}
                      onChange={handleText}
                    />
                  </div>
                </Paper>
              )}
            </Box>
          )}
          {linkDetails && (
            <DeletePopup
              title={'Delete Link'}
              customDescription={
                'Are you sure you want to delete the link? This action can’t be undone.'
              }
              subDescription={''}
              open={openDeletePopup}
              buttonText={`Delete`}
              setOpen={setOpenDeletePopup}
              onDeleteClick={handleDeleteLink}
            />
          )}
          {showSnackBar && (
            <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
          )}
        </Box>
      )}
    </>
  );
};

export default ViewOtherLinkDetail;
