/*
 * File: clinic.model.tsx
 * Project: mint-portal
 * File Created: Wednesday, 13th July 2022 9:54:04 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 12th October 2023 10:10:01 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

export interface IClinics {
  id: string;
  name: string;
  appListing: boolean;
  bookingStatus: BookingStatus;
  serverAgentStatus: boolean;
  isServiceMapped: boolean;
  isDraft: boolean;
  updatedAt: string;
}
export enum BookingStatus {
  ACCEPTING = 'accepting',
  NOT_ACCEPITNG = 'not_accepting',
  BLOCK_OUT = 'block_out',
}

export interface IClinicList {
  total: number;
  clinics: IClinics[];
}

export interface IClinicListResponse {
  data: IClinicList;
  message: string;
}

export interface IClinicQueryParamOptions {
  limit?: number;
  skip?: number;
  notStaffId?: string;
  isDraft?: boolean;
  serviceIdForMap?: string;
  searchString?: string;
  appListing?: boolean[];
  bookingStatus?: string[];
  serverAgentStatus?: boolean[];
}

export interface FilterParams {
  active1: boolean;
  offline: boolean;
  accepting: boolean;
  not_accepting: boolean;
  connected: boolean;
  disconnected: boolean;
}

export interface IOpenDentalPayload {
  customerKey: string;
  developerKey: string;
}

export interface IOpenDentalRO {
  data: IClinicOpenDentalResponse;
  message: string;
}

export interface IGetClinicApiKeyRO {
  data: IClinicApiKeyResponse;
  message: string;
}

export interface IRegenerateClinicApiKeyRO {
  data: Omit<IClinicApiKeyResponse, 'isDraft'>;
  message: string;
}

export interface IAddClinicPayload {
  name: string;
  email: string;
  website: string;
  phoneNumber: string;
  clinicImagesS3Keys?: string[];
}
export interface IClinicImage {
  location: string;
  s3Key: string;
  isSignedURL: boolean;
  expiresInSeconds: number;
}
export interface IAddClinic {
  id: string;
  name: string;
  email: string;
  website: string;
  phoneNumber: string;
  clinicImageDetails: IClinicImage[];
  isPartialData: boolean;
}

export interface IAddClinicResponse {
  data: IAddClinic;
  message: string;
}

export interface IClinicOpenDentalResponse {
  id: string;
  isDraft: boolean;
  openDentalConfig: IOpenDentalResponse;
  isPartialData: boolean;
}

export interface IClinicApiKeyResponse {
  id: 'string';
  isDraft: boolean;
  apiKey: 'string';
}
export interface IOpenDentalResponse {
  createdAt: string;
  id: number;
  customerKey: string;
  developerKey: string;
  serverAgentStatus: boolean;
}

export interface IClinicLocationPayload {
  address: string;
  accountId: string;
  accountName: string;
  locationId: string;
  locationTitle: string;
  placeId: string;
  lat: number;
  long: number;
}

export interface IClinicLocationRO {
  data: IClinicLocationResponse;
  message: string;
}
export interface IClinicLocationResponse {
  id: string;
  address: string;
  accountName: string;
  locationTitle: string;
  isDraft: boolean;
  isPartialData: boolean;
}
export interface availabilityDay {
  isOpen: boolean;
  startTime: string;
  closeTime: string;
}
interface IObjectKeys {
  [key: string]: availabilityDay;
}
export interface IAvailabilityWeek extends IObjectKeys {
  Monday: availabilityDay;
  Tuesday: availabilityDay;
  Wednesday: availabilityDay;
  Thursday: availabilityDay;
  Friday: availabilityDay;
  Saturday: availabilityDay;
  Sunday: availabilityDay;
}

export interface serverAgentData {
  createdAt: string;
  id: number;
  lastSync: string;
  version: string;
  agentHostName: string;
  agentSyncData: string;
  isAgentSettingsUpdated: boolean;
  timezone: string;
}
export interface staffCountData {
  total: number;
  invited: number;
  confirmed: number;
}

export interface serviceCountData {
  total: number;
  mapped: number;
  unmapped: number;
}

export interface dentalConfigData {
  createdAt: string;
  id: number;
  customerKey: string;
  developerKey: string;
  serverAgentStatus: boolean;
}

export interface operatoriesCountData {
  total: number;
  mapped: number;
  unmapped: number;
}
export interface IViewClinicDetailsData {
  createdAt: string;
  id: string;
  name: string;
  email: string;
  website: string;
  phoneNumber: string;
  address: string;
  accountId: string;
  accountName: string;
  locationId: string;
  locationTitle: string;
  lat: number;
  long: number;
  availability: IAvailabilityWeek;
  status: boolean;
  isDraft: boolean;
  isPartialData: boolean;
  bookingStatus: string;
  serverAgent: serverAgentData;
  clinicImageDetails: IClinicImage[];
  openDentalConfig: dentalConfigData;
  staffCount: staffCountData;
  servicesCount: serviceCountData;
  operatoriesCount: operatoriesCountData;
  provider: IProviderGetResponse;
  primaryProvider: IProviderGetResponse;
  insurancesCount: number;
  isNppwLinkExists: boolean;
}

export interface IViewClinicDetails {
  data: IViewClinicDetailsData;
}
export interface IProviderByClinicIdQueryParamOptions {
  limit?: number;
  skip?: number;
  searchString?: string;
}

export interface IProviderByClinicIdRO {
  data: IProviderByClinicIdResponse;
  message: string;
}

export interface IProviderByClinicIdResponse {
  total: number;
  providers: IProviderGetResponse[];
}

export interface IProviderGetResponse {
  providerNumber: number;
  providerFirstName: string;
  providerLastName: string;
  providerCode: string;
}

export interface IProviderSavePayload {
  provider: string;
  primaryProvider: string;
}

export interface IClinicProviderDetailRO {
  data: IClinicProviderDetailResponse;
  message: string;
}
export interface IClinicProviderDetailResponse {
  id: string;
  isDraft: boolean;
  isPartialData: boolean;
  provider: IProviderGetResponse;
  primaryProvider: IProviderGetResponse;
}

export interface IClinicStaffServiceData {
  id: string;
  isDraft: boolean;
  isPartialData: boolean;
}
export interface IClinicServiceRO {
  data: IClinicStaffServiceData;
  message: string;
}
export interface IServiceOperatoriesQueryParamOptions {
  limit?: number;
  skip?: number;
  clinicId: string;
}

export interface IServiceOperatoriesRO {
  data: IServiceOperatoriesResponse;
  message: string;
}

export interface IServiceOperatoriesResponse {
  id: string;
  isDraft: boolean;
  isPartialData: boolean;
  services: IServiceOperatoriesDetails[];
  total: number;
}

export interface IServiceOperatoriesDetails {
  id: string;
  name: string;
  ageType: 'adult' | 'child' | string;
  patientType: 'existing' | 'new' | string;
  duration: number;
  operatories: IOperatoriesDetails[];
}

export interface IOperatoriesDetails {
  operatoryNumber: string;
  operatoryName: string;
  operatoryCode: string;
  isMapped: boolean;
}

export interface IServiceSaveOperatoriesPayload {
  mapOperatories: ISaveOperatoriesPayload[];
}

export interface ISaveOperatoriesPayload {
  serviceId: string;
  operatoryNumbers: string[];
}

export interface IOperatoriesSaveRO {
  data: IOperatoriesSaveDetails;
  message: string;
}

export interface IOperatoriesSaveDetails {
  id: string;
  isDraft: boolean;
  isPartialData: boolean;
}

export interface ISaveAvailabilityRO {
  data: ISaveAvailabilityDetails;
  message: string;
}

export interface ISaveAvailabilityDetails {
  availability: IAvailabilityWeek;
  id: string;
  isDraft: boolean;
  isPartialData: boolean;
}
export interface IClinicStaffPayload {
  staffIds: string[];
}

export interface IClinicStaffRO {
  data: IClinicStaffServiceData;
  message: string;
}

export interface IClinicBooleanRO {
  data: boolean;
  message: string;
}

export interface IClinicBlockoutListRO {
  data: IClinicBlockoutListResponse;
  message: string;
}

export interface IClinicBlockoutListResponse {
  blockouts: IClinicBlockoutDetailResponse[];
  total: number;
}
export interface IClinicOpenDentalBlockoutListResponse {
  blockouts: IClinicOpenDentalBlockoutDetailResponse[];
  total: number;
}

export interface IClinicOpenDentalBlockoutListRO {
  data: IClinicOpenDentalBlockoutListResponse;
  message: string;
}

export interface IClinicBlockoutDetailResponse {
  createdAt: string;
  id: number;
  title: string;
  allDay: boolean;
  type: BlockOutType;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  clinicId: string;
  clinicName: string;
  operatoryNumber: string;
  operatoryName: string;
}

export interface IClinicOpenDentalBlockoutDetailResponse {
  createdAt: string;
  id: number;
  title: string;
  allDay: boolean;
  type: BlockOutType;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  operatoryName: string;
  note: string;
}

export enum BlockOutType {
  DSO = 'DSO',
  CLINIC = 'CLINIC',
  OPERATORY = 'OPERATORY',
}

export interface IAddBlockoutPayload {
  title: string;
  allDay: boolean;
  startDate: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  type: string;
  operatoryNumber?: string;
}

export interface IAddBlockoutRO {
  data: IClinicBlockoutDetailResponse;
  message: string;
}

export interface IOpenDentalDetailBlockoutRO {
  data: IClinicOpenDentalBlockoutDetailResponse;
  message: string;
}

export interface IOngoingBlockoutRO {
  data: IOngoingBlockoutDetails;
  message: string;
}

export interface IOngoingBlockoutDetails {
  id: number;
  title: string;
  allDay: boolean;
  type: BlockOutType;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export interface IClinicOperatories {
  operatories: IClinicOperatoriesResponse[];
  total: number;
}

export interface IClinicOperatoriesResponse{
  operatoryNumber: string;
  operatoryName: string;
  operatoryCode: string;
}

export interface IClinicOperatoriesRO {
  data: IClinicOperatories;
  message: string;
}

export interface IUpdateClinicNppw {
  customLink: string | null;
}

export interface IClinicNppwLinkRO {
  data: IViewClinicNppwDetails;
  message: string;
}
export interface IViewClinicNppwDetails {
  global: {
    NPPWLink: string;
    NPPWTitle: string;
  };
  customNPPWLink: string | null;
}
export interface IEditClinicServicePayload {
  clinicId: string;
  action: 'assign' | 'unassign';
  serviceId: string;
}

export interface IEditClinicStaffPayload {
  clinicId: string;
  action: 'assign' | 'unassign';
  staffId: string;
}

export interface IOpenDentalSyncStatusRO {
  openDentalSync: boolean;
  procedureCodes: boolean;
  operatories: boolean;
  providers: boolean;
}
