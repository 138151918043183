/*
 * File: CustomCard.tsx
 * Project: mint-portal
 * File Created: Wednesday, 14th September 2022 12:22:15 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 14th September 2022 1:47:39 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import { makeStyles, Card, CardContent, Box } from '@material-ui/core';
import { zeplinColor } from '../../theme';

type cardProps = {
  children: React.ReactNode;
  width?: string;
  margin?: string;
};
const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '16px',
    borderStyle: 'solid',
    borderColor: zeplinColor.Background90,
    backgroundColor: zeplinColor.Surface,
  },
}));
const CustomCard = ({ children, width, margin }: cardProps) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      elevation={0}
      style={{
        width: width,
        margin: margin,
      }}
    >
      <Box>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '16px',
          }}
        >
          {children}
        </CardContent>
      </Box>
    </Card>
  );
};

export default CustomCard;
