/*
 * File: validate.ts
 * Project: mint-portal
 * File Created: Monday, 29th August 2022 12:13:46 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 1:25:49 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

// import validator from 'validator';
import { Constants } from './constants';
import isAlpha from 'validator/lib/isAlpha';
import isEmail from 'validator/lib/isEmail';

export const validateEmail = (email: string) => {
  if (isEmail(email)) {
    return true;
  }
  return false;
};

export const validPhone = (phone: string) => {
  if (phone) {
    if (
      phone?.length !== 10 ||
      !Constants.REGEX.digitsRegex.test(phone) ||
      /[a-zA-Z]/.test(phone)
    ) {
      return true;
    }
  }
  return false;
};

export const validateYoutubeUrl = (link: string) => {
  if (/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(link)) {
    return true;
  }
  return false;
};

export const isTextOnly = (text: string) => {
  if (isAlpha(text, 'en-US', { ignore: ' ' }) || !text) {
    return true;
  }
  return false;
};

export const handleKeyPressForEscapeSymbols = (e: any) => {
  if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
    e.preventDefault();
  }
  Constants.ESCAPE_SYMBOLS.includes(e.key) && e.preventDefault();
};
