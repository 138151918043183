/*
 * File: ViewNppwrk.tsx
 * Project: mint-portal
 * File Created: Tuesday, 4th October 2022 4:02:42 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:04 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useState, useEffect } from 'react';
import './ViewNppwrk.css';
import getPermissionForModule from '../../../../../utilities/getPermission';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../shared/Loader/Loader';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import { Typography, Toolbar, Link, Button } from '@material-ui/core';
import Buttons from '../../../../shared/Buttons';
import { ManageService } from '../../../../../service/manage.service';
import { IDSONppw } from '../../../../../model';
import { Paper } from '@mui/material';
import { zeplinColor } from '../../../../../theme';
import EditNppwrk from '../EditNppwrk/EditNppwrk';
import { Constants } from '../../../../../utilities/constants';

const ViewNppwrk = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Manage;
  const [showLoader, setShowLoader] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const [nppwDetail, setNppwDetail] = useState<IDSONppw>({ NPPWTitle: '', NPPWLink: '' });
  const [edit, setEdit] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<IDSONppw>();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const onCancelEdit = () => {
    setEdit(false);
    setEditBtn(false);
  };
  const prevLink = [{ path: '/manage', label: 'Manage' }];

  useEffect(() => {
    setShowLoader(true);
    getNppwrk();
  }, []);

  const getNppwrk = async () => {
    try {
      const nppwkRes = await ManageService.getDsoNppw();
      setNppwDetail(nppwkRes.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const updateNppw = async () => {
    if (updatePayload) {
      setUpdateLoader(true);
      try {
        const nppwkRes = await ManageService.updateDsoNppw(updatePayload);
        setNppwDetail(nppwkRes.data);
        setUpdateLoader(false);
        if (nppwkRes?.data) {
          setShowSnackbar(true);
          setSnackbarMessage(`${nppwkRes.data.NPPWTitle} changes saved`);
          setEdit(false);
        }
      } catch (err) {
        setUpdateLoader(false);
        setShowSnackbar(true);
        setSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div>
          <div className='nppwHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='nppwTitle' color='inherit'>
                NPPWK
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'NPPWK'} />
              </Typography>
              {permission.update && !showLoader && nppwDetail && (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                  {!edit ? (
                    <Buttons
                      text={nppwDetail?.NPPWTitle && nppwDetail?.NPPWLink ? 'EDIT' : 'ADD'}
                      width='6em'
                      disable={editBtn}
                      onClick={() => setEdit(!edit)}
                    />
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        disabled={showLoader ? true : false}
                        style={{
                          width: '8em',
                          height: '34px',
                          backgroundColor: `${zeplinColor.Background97}`,
                          border: `1px solid ${zeplinColor.Background70}`,
                          color: `${zeplinColor.Background70}`,
                        }}
                        onClick={onCancelEdit}
                      >
                        Cancel
                      </Button>
                      <Buttons
                        text='SAVE CHANGES'
                        width='100%'
                        disable={editBtn || showLoader ? true : false}
                        onClick={updateNppw}
                      />
                    </>
                  )}
                </div>
              )}
            </Toolbar>
          </div>
          {edit ? (
            <div>
              <EditNppwrk
                edit
                nppwDetail={nppwDetail}
                setUpdatePayload={setUpdatePayload}
                updateLoader={updateLoader}
                setEditBtn={setEditBtn}
              />
            </div>
          ) : (
            <>
              {permission.read && nppwDetail && !nppwDetail.NPPWLink && !showLoader ? (
                <div style={{ marginTop: '15%' }}>
                  <EmptyState
                    path={''}
                    text={'No NPPWK link to show'}
                    subText={''}
                    buttonText={''}
                    showAddButton={false}
                    image={true}
                  />
                </div>
              ) : (
                <Paper className='nppwDetailBox' sx={{ borderRadius: '16px' }}>
                  <div
                    className='nppwDetail'
                    style={{ display: 'flex', flexDirection: 'column', gap: 32 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        marginTop: '32px',
                      }}
                    >
                      <Typography variant='subtitle1'>{nppwDetail.NPPWTitle}</Typography>
                      <Link
                        target='_blank'
                        rel='noopener'
                        variant='body1'
                        underline='none'
                        href={
                          Constants.REGEX.urlRegex.test(nppwDetail.NPPWLink)
                            ? nppwDetail.NPPWLink
                            : '//' + nppwDetail.NPPWLink
                        }
                        style={{
                          width: '72vw',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: `${zeplinColor.BlueLink}`,
                        }}
                      >
                        {nppwDetail.NPPWLink}
                      </Link>
                    </div>
                  </div>
                </Paper>
              )}
            </>
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ViewNppwrk;
