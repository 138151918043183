/*
 * File: Patient.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:45:23 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:12:36 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ListPatient from '../components/feature/patient/ListPatient';
import EmptyState from '../components/shared/EmptyState/EmptyState';
import Loader from '../components/shared/Loader/Loader';
import SearchBar from '../components/shared/SearchBar';
import SnackBar from '../components/shared/Snack-Bar/snackBar';
import {
  IPatientListQueryParams,
  IPatientListAPIResponse,
  IPatientListRO,
} from '../model/patient.model';
import { PatientService } from '../service/patient.service';
import { Constants } from '../utilities/constants';

const Patient = () => {
  const [patientList, setPatientList] = useState<IPatientListAPIResponse>({
    total: 0,
    patients: [],
  });
  const [dataPage, setDataPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const removedPatient = sessionStorage.getItem('deletedPatient');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
    setDataPage(1);
  };

  useEffect(() => {
    setShowLoader(true);
    getPatientList();
    setShowSnackbar(removedPatient ? true : false);
    setShowSnackbarMessage(`${removedPatient} is removed.`);
    sessionStorage.removeItem('deletedPatient');
  }, [dataPage, searchValue]);

  const getPatientList = async () => {
    if (searchValue && searchValue.length < 3) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const listPatientQuery: IPatientListQueryParams = {
        limit,
        skip,
        ...(searchValue &&
          searchValue.length >= 3 && {
          searchString: searchValue,
          skip: dataPage !== 1 ? skip : 0,
        }),
      };
      const response: IPatientListRO = await PatientService.getPatientList(listPatientQuery);
      if (response?.data?.total <= limit) {
        setDataPage(1);
      }
      setPatientList(() => response?.data);
    } catch (error) {
      setShowSnackbar(true);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='serviceContainer'>
          <div className='serviceHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='serviceTitle' color='inherit'>
                Patients
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-2.75%' }}>
                <SearchBar onSearchHandler={onSearch} placeholderText={'Search for a patient'} />
              </div>
            </Toolbar>
          </div>
          {patientList.total === 0 && !showLoader ? (
            <div style={{ marginTop: '15%' }}>
              <EmptyState
                path={''}
                text={'No patients to show'}
                subText={''}
                buttonText={''}
                showAddButton={false}
                image={true}
              />
            </div>
          ) : (
            !showLoader && (
              <ListPatient
                patientList={patientList}
                onPageChangeHandler={onDataPageChange}
                currentPage={dataPage}
              />
            )
          )}
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </div>
      )}
    </>
  );
};

export default Patient;
