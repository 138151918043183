/*
 * File: CustomDatePicker.tsx
 * Project: mint-portal
 * File Created: Monday, 19th September 2022 11:24:36 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 8th September 2023 8:04:26 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment, { Moment } from 'moment';
import { styled } from '@material-ui/core';
import zeplinColor from '../../theme/zeplinColor';

type ICustomProps = {
  value: Moment | null | string;
  handleDateChange: (value: string | Date | null, keyboardInputValue?: string) => void;
  disablePast?: boolean;
  width?: number | string;
  label?: string;
  fromFormatted?: string;
  endDate?: string;
  disabled?: boolean;
  error?: boolean;
};

const CustomDateField = styled(TextField)({
  '& .MuiTextField-root': {
    '& label': {
      color: zeplinColor.Primary,
      paddingLeft: '0.7rem',
    },
  },
  '& label.Mui-focused': {
    color: zeplinColor.Primary,
  },
  '& .MuiInputBase-input': {
    caretColor: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Primary,
  },
  '& label.Mui-error.Mui-focused': {
    color: zeplinColor.Error,
  },
  '& .Mui-error.MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Error,
    color: zeplinColor.Error,
  },
});

const CustomDatePicker: FC<ICustomProps> = ({
  value,
  handleDateChange,
  disablePast = true,
  width,
  label,
  fromFormatted,
  endDate,
  disabled,
  error,
}) => {
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        label={label}
        inputFormat='MM/DD/YYYY'
        value={value}
        onChange={handleDateChange}
        disabled={disabled}
        disablePast={disablePast}
        PaperProps={{
          sx: {
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: `${zeplinColor.Primary} !important`,
              },
            },
          },
        }}
        renderInput={(params: any) => (
          <CustomDateField
            onKeyDown={onKeyDown}
            variant='standard'
            {...params}
            style={{ width: width ? width : '' }}
            error={error}
          />
        )}
        shouldDisableDate={(dateParam) => {
          const currentDate = moment(dateParam?.toString()).format('YYYY-MM-DD');
          return fromFormatted
            ? currentDate < fromFormatted
              ? true
              : false
            : endDate
              ? currentDate > endDate
                ? true
                : false
              : false;
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
