/*
 * File: EditNppwrk.tsx
 * Project: mint-portal
 * File Created: Tuesday, 4th October 2022 4:03:06 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 13th October 2022 1:33:48 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useState, FC, useEffect } from 'react';
import './EditNppwrk.css';
import Loader from '../../../../shared/Loader/Loader';
import { IDSONppw } from '../../../../../model';
import { Paper } from '@mui/material';
import Field from '../../../../shared/CustomTextField';

type editProps = {
  edit: boolean;
  nppwDetail: IDSONppw;
  setUpdatePayload: React.Dispatch<React.SetStateAction<IDSONppw | undefined>>;
  updateLoader: boolean;
  setEditBtn: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditNppwrk: FC<editProps> = ({
  edit,
  nppwDetail,
  setUpdatePayload,
  updateLoader,
  setEditBtn,
}) => {
  const [title, setTitle] = useState<string>(nppwDetail?.NPPWTitle || '');
  const [link, setLink] = useState<string>(nppwDetail?.NPPWLink || '');
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };
  const createNppwPayload: IDSONppw = {
    NPPWTitle: title,
    NPPWLink: link,
  };

  useEffect(() => {
    validateNppwPayload();
    if (edit) {
      setUpdatePayload && setUpdatePayload(createNppwPayload);
    }
  }, [title, link]);

  const validateNppwPayload = async () => {
    if (!title.trim() || !link.trim()) {
      setEditBtn && setEditBtn(true);
    } else {
      setEditBtn && setEditBtn(false);
    }
  };
  return (
    <>
      {updateLoader && <Loader />}
      <div className='nppwContainer'>
        <Paper className='nppwBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
          <div className='nppwBox'>
            <section>
              <Field
                name='title'
                label='Title*'
                placeholder='Title'
                showIcon={false}
                style={{ width: '720px', marginLeft: '0px' }}
                value={title}
                onChange={handleTitleChange}
              />
            </section>
            <section>
              <Field
                name='link'
                label='Link*'
                placeholder='Link'
                showIcon={false}
                style={{ width: '720px', marginLeft: '0px' }}
                value={link}
                onChange={handleLinkChange}
              />
            </section>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default EditNppwrk;
