/*
 * File: PhoneField.tsx
 * Project: mint-portal
 * File Created: Saturday, 23rd July 2022 4:47:43 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 14th September 2023 8:56:59 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

// import { styled } from '@material-ui/core';
// import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { zeplinColor } from '../../../theme';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input-mobile';
import './CustomPhoneField.css';
import { Typography } from '@material-ui/core';

interface FieldProps {
  value?: string;
  name?: string;
  width?: any;
  placeholder?: any;
  type?: any;
  onChange?: (params: any) => any;
  padding?: boolean;
  margin?: boolean;
  error?: boolean;
  disable?: boolean;
}

const PhoneFieldInput: React.FC<FieldProps> = ({
  placeholder,
  width,
  value,
  onChange,
  padding = true,
  margin = false,
  disable = false,
  error,
}) => {
  const [border, setBorder] = useState(zeplinColor.Background0);
  return (
    <>
      <Input
        country='US'
        value={value}
        labels='Phone'
        placeHolder={placeholder}
        error={isValidPhoneNumber || error}
        disabled={disable}
        maxLength={14}
        onChange={(e) => {
          onChange && onChange(e ? e : '');
        }}
        style={{
          margin: padding ? 1 : '0px 0px 0px 8px',
          marginLeft: !margin ? '0px' : '8px',
          width: width,
          height: '55px',
          marginTop: padding ? '32px' : '0px',
          backgroundColor: zeplinColor.Background,
          color: disable ? zeplinColor.Background50 : zeplinColor.Background0,
          padding: !padding ? '1px 0px 0px 2px' : '0px 0px 0px 40px',
          borderWidth: 0,
          outline: 0,
          borderBottom: `${
            disable ? '0.5px' : border === zeplinColor.Primary || error ? '2px' : '1px'
          } ${disable ? 'dashed' : 'solid'} ${border}`,
          borderColor: error && zeplinColor.Error,
        }}
        onFocus={() => !error && setBorder(zeplinColor.Primary)}
        onBlur={() => !error && setBorder(zeplinColor.Background0)}
      />
      <Typography
        className='label'
        style={{
          color: error
            ? zeplinColor.Error
            : disable
              ? zeplinColor.Background50
              : border === zeplinColor.Primary
                ? border
                : zeplinColor.Background30,
          fontSize: '12px',
          display: value ? 'block' : 'none',
        }}
      >
        {placeholder}
      </Typography>
    </>
  );
};

export default PhoneFieldInput;
