/*
 * File: DetailRedeemReward.tsx
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:01:48 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 14th October 2022 12:04:57 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './DetailRedeemReward.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Toolbar, Typography } from '@material-ui/core';
import { Paper } from '@mui/material';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import { RewardService } from '../../../../../service/reward.service';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import {
  IDetailRewardRedeemResponse,
  RewardType,
  IDetailRedeemRewardHistoryRO,
} from '../../../../../model';
import moment from 'moment';
import { Constants } from '../../../../../utilities/constants';

const DetailRedeemReward = () => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [redeemDetail, setRedeemDetail] = useState<IDetailRewardRedeemResponse>();
  const { id } = useParams();
  const [showLoader, setShowLoader] = useState(false);

  const prevLink = [{ path: '/rewards', label: 'Rewards' }];

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      getRedeemDetailsById(+id);
    }
  }, [id]);

  const getRedeemDetailsById = async (id: number) => {
    try {
      const redeemDetails: IDetailRedeemRewardHistoryRO =
        await RewardService.getRedeemRewardHistoryById(id);
      setRedeemDetail(redeemDetails?.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='redeemDetailContainer'>
        <div className='redeemDetailHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              Reward Redeem Details
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Reward Redeem Details'} />
            </Typography>
          </Toolbar>
        </div>
        {redeemDetail && (
          <Paper className='redeemDetailBox' sx={{ borderRadius: '16px' }}>
            <div className='redeemDetail'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Patient Name</Typography>
                <Typography variant='body1'>
                  {redeemDetail?.patient?.firstName + ' ' + redeemDetail?.patient?.lastName}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Patient Unique Code</Typography>
                <Typography variant='body1'>{redeemDetail?.patient?.code}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Reward Type</Typography>
                <Typography variant='body1'>
                  {redeemDetail?.type === RewardType.PointsCredit ? 'Points Credit' : 'Gift'}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Reward Name</Typography>
                <Typography variant='body1'>{redeemDetail?.rewardName}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Points Value</Typography>
                <Typography variant='body1'>{redeemDetail?.pointsValue}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Redeemed On</Typography>
                <Typography variant='body1'>
                  {moment(redeemDetail?.createdAt).format(Constants.MOMENT_FORMATS.DATE_FORMAT)}
                </Typography>
              </div>{' '}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Redeemed By</Typography>
                <Typography variant='body1'>
                  {redeemDetail?.staff?.firstName + '' + redeemDetail?.staff?.lastName}
                </Typography>
              </div>
            </div>
          </Paper>
        )}
        {showSnackBar && (
          <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};

export default DetailRedeemReward;
