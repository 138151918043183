/*
 * File: ListReward.tsx
 * Project: mint-portal
 * File Created: Monday, 31st October 2022 5:09:10 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 2nd November 2022 1:49:07 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { ICategoryDetail } from '../../../../model';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';

type props = {
  rewardCategories: ICategoryDetail[];
};
const useStyles = makeStyles(() => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListReward = ({ rewardCategories }: props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getRewardById = (data: ICategoryDetail) => {
    sessionStorage.setItem('reward-category', JSON.stringify(data));
    navigate('/manage/reward-categories/reward-category-details');
  };
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={0} align={'left'}>
                <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                  Reward Category
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewardCategories?.length &&
              rewardCategories.map((data, index) => (
                <TableRow
                  hover
                  classes={{ hover: classes.tableRow }}
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => getRewardById(data)}
                >
                  <TableCell>
                    <Typography
                      variant='body1'
                      style={{ width: '72vw', overflowWrap: 'break-word' }}
                    >
                      {data?.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListReward;
