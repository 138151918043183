/*
 * File: snackBar.tsx
 * Project: mint-portal
 * File Created: Monday, 13th June 2022 8:12:33 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 19th February 2023 11:05:02 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Button, Snackbar } from '@material-ui/core';
import React, { Dispatch } from 'react';
import './SnackBar.css';
import { Constants } from '../../../utilities/constants';


type snackBarProps = {
  message: string;
  show: boolean;
  setShow: Dispatch<boolean>;
};

const snackBar = ({ message, show, setShow }: snackBarProps) => {

  const handleClose = () => {
    setShow(false)
  }
  return (
    <div>

      <Snackbar
        className='snackBar'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={show}
        autoHideDuration={Constants.SNACKBAR_DURATION}
        onClose={handleClose}
        message={message}
        action={
          <>
            <Button onClick={handleClose} color='primary'>
              Okay
            </Button>
          </>
        }
      />
    </div>
  );
}

export default snackBar;
