/*
 * File: Reward.tsx
 * Project: mint-portal
 * File Created: Monday, 31st October 2022 4:05:51 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th June 2023 8:16:46 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import { Pagination, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IRewardCategoryList, IRewardCategoryRO, IRewardQueryParam } from '../../../../model';
import { RewardService } from '../../../../service/reward.service';
import { zeplinColor } from '../../../../theme';
import { Constants } from '../../../../utilities/constants';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import ListReward from './ListReward';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CustomTextField from '../../../shared/CustomTextField';
import { ManageService } from '../../../../service/manage.service';
import './RewardCategories.css';
import { isTextOnly } from '../../../../utilities/validate';
import Buttons from '../../../shared/Buttons';
import getPermissionForModule from '../../../../utilities/getPermission';
import { RewardCategory } from '../../../../pages/Manage/ManageCardConstants';
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogBox = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2, 3, 1, 3),
    maxHeight: '26.5rem',
    minWidth: '35rem',
  },
}));

const DialogBoxTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1 0 1 0' }} {...other}>
      {children}
    </DialogTitle>
  );
};

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));

const RewardCategories = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const classes = useStyles();
  const [dataPage, setDataPage] = useState(1);
  const [rewardCategory, setRewardCategory] = useState<IRewardCategoryList>({
    categories: [],
    total: 0,
  });
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState<string>('');
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [rewardCategoryTitle, setRewardCategoryTitle] = useState('');
  const deletedRewardCategory = sessionStorage.getItem('deleted-category');
  const [error, setError] = useState(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const onDataPageChange = (event: any, page: number) => {
    setDataPage(page);
  };
  const handleAddModal = () => {
    setUpdatePopUp(true);
  };
  const handleAdd = async () => {
    setShowLoader(true);
    try {
      if (rewardCategoryTitle && isTextOnly(rewardCategoryTitle)) {
        await ManageService.addRewardCategory({ name: rewardCategoryTitle });
        setUpdatePopUp(false);
        setShowSnackBar(true);
        setShowSnackBarMessage(`${rewardCategoryTitle} category added`);
        setShowLoader(false);
        getRewardCategory(1);
        setRewardCategoryTitle('');
      } else {
        setError(true);
        setShowLoader(false);
      }
    } catch (err) {
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackBar(true);
      setShowLoader(false);
    }
  };
  const onCancel = () => {
    setUpdatePopUp(false);
    setRewardCategoryTitle('');
    setError(false);
  };
  const getRewardCategory = async (page: number) => {
    setShowLoader(true);
    try {
      const queryParam: IRewardQueryParam = {
        limit: Constants.PAGINATION_OPTIONS.limit,
        skip: (page - 1) * 10,
      };
      const res: IRewardCategoryRO = await RewardService.getRewardCategory(queryParam);
      setRewardCategory(res.data);
      setShowLoader(false);
    } catch (err) {
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowLoader(false);
      setShowSnackBar(true);
    }
  };
  useEffect(() => {
    if (deletedRewardCategory) {
      const deleted = JSON.parse(deletedRewardCategory);
      setShowSnackBar(true);
      setShowSnackBarMessage(`${deleted?.name} deleted successfully`);
      sessionStorage.removeItem('deleted-category');
    }
    getRewardCategory(dataPage);
  }, [dataPage]);
  useEffect(() => {
    if (rewardCategoryTitle) {
      setError(false);
    }
  }, [rewardCategoryTitle]);
  const handleChangeText = (e: any) => {
    setRewardCategoryTitle(e.target.value);
  };
  return (
    <>
      {isForbiddenAccess ||
        (!RewardCategory.roleAccess.includes(roleId) && (
          <EmptyState
            path={``}
            text={'Sorry, you don’t have access to this section'}
            subText={''}
            buttonText={''}
            showAddButton={false}
            image={true}
            removeImage={false}
            forbiddenAccess={true}
          />
        ))}
      {!isForbiddenAccess && RewardCategory.roleAccess.includes(roleId) && (
        <Box sx={{ width: '76.5vw', margin: '32px 0px 32px 24px' }}>
          {showLoader && <Loader />}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant='h5'>Reward Categories</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Reward Categories'} />
            </Box>
            {RewardCategory.roleAccess.includes(roleId) && (
              <Buttons
                disable={false}
                width='12rem'
                text='ADD NEW CATEGORY'
                onClick={handleAddModal}
              />
            )}
          </Box>
          {rewardCategory?.total && !showLoader ? (
            <>
              <Paper
                className=''
                sx={{
                  borderRadius: '16px',
                  border: `1px solid ${zeplinColor.Background90}`,
                  marginTop: '32px',
                }}
                elevation={0}
              >
                <ListReward rewardCategories={rewardCategory.categories} />
              </Paper>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32 }}>
                <Pagination
                  count={
                    rewardCategory.total <= Constants.PAGINATION_OPTIONS.limit
                      ? 1
                      : Math.ceil(rewardCategory.total / Constants.PAGINATION_OPTIONS.limit)
                  }
                  onChange={onDataPageChange}
                  page={dataPage}
                  variant='outlined'
                  shape='rounded'
                  // className='pagination'
                  classes={{ outlined: classes.ul }}
                />
              </Box>
            </>
          ) : (
            !showLoader && (
              <Box sx={{ marginTop: '15%' }}>
                <EmptyState
                  path={''}
                  text={'No Reward Category to show'}
                  subText={''}
                  buttonText={''}
                  showAddButton={false}
                  image={false}
                />
              </Box>
            )
          )}
          {showSnackBar && (
            <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
          )}
          {updatePopUp && !showLoader && (
            <DialogBox
              onClose={onCancel}
              aria-labelledby='customized-dialog-title'
              open={updatePopUp}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column' }}
                style={{ gap: 10, width: '600px' }}
              >
                <DialogBoxTitle id='customized-dialog-title' onClose={onCancel}>
                  <Typography variant='h5'>Add New Category</Typography>{' '}
                </DialogBoxTitle>
                <DialogContent id='scroll'>
                  <Box>
                    <CustomTextField
                      width='100%'
                      name='rewardCategoryTitle'
                      value={rewardCategoryTitle}
                      onChange={handleChangeText}
                      label='Reward Category Title*'
                      error={!isTextOnly(rewardCategoryTitle)}
                    ></CustomTextField>
                    {error && (
                      <Typography
                        className='profileDescription'
                        variant='body1'
                        style={{ color: zeplinColor.Error }}
                      >
                        {rewardCategoryTitle
                          ? Constants.ERROR_MESSAGES.TEXT_VALIDATION.ALLOW_ALPHABET_MESSAGE
                          : 'This field is required'}
                      </Typography>
                    )}
                  </Box>
                  <DialogActions>
                    <div
                      style={{
                        display: 'flex',
                        gap: 20,
                        justifyContent: 'flex-end',
                        marginTop: 25,
                      }}
                    >
                      <Button style={{ color: zeplinColor.Background50 }} onClick={onCancel}>
                        CANCEL
                      </Button>
                      <Button
                        disableRipple
                        onClick={handleAdd}
                        style={{ color: zeplinColor.Primary }}
                      >
                        ADD CATEGORY
                      </Button>
                    </div>
                  </DialogActions>
                </DialogContent>
              </Box>
            </DialogBox>
          )}
        </Box>
      )}
    </>
  );
};

export default RewardCategories;
