/* eslint-disable react/prop-types */
/*
 * File: BreadCrumbs.tsx
 * Project: mint-portal
 * File Created: Monday, 27th June 2022 10:59:29 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 19th September 2022 5:33:15 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import React from 'react';
import './BreadCrumbs.css';

type breadCrumbsProps = {
  prevLink: Array<any>;
  activeLink: string | undefined;
};

const BreadCrumbs = ({ prevLink, activeLink }: breadCrumbsProps) => {
  return (
    <div role='presentation' className='breadCrumbs'>
      <Breadcrumbs aria-label='breadcrumb'>
        {prevLink.map((i: any) => (
          <Link key={i} color='inherit' href={i.path} className='prevLink' variant='subtitle2'>
            {i.label}
          </Link>
        ))}
        <Typography color='textPrimary' className='activeLink' variant='subtitle2'>
          {activeLink}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbs;
