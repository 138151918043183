/*
 * File: header.tsx
 * Project: mint-portal
 * File Created: Tuesday, 21st June 2022 6:07:33 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 18th January 2023 11:25:55 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './header.css';

import {
  AppBar,
  Box,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { Link, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Constants } from '../../../utilities/constants';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IUserProfileResponse } from '../../../model';
import Logo from '../../../assests/mintlogo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { logoutUser } from '../../../service/amplify.service';
import { useGlobalContext } from '../../../utilities/sessionStorage';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../theme';

const Header = () => {
  const profileDataFromStorage = sessionStorage.getItem('profileData');
  const profile = profileDataFromStorage ? JSON.parse(profileDataFromStorage) : {};
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState<IUserProfileResponse>({
    createdAt: '',
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isActive: false,
    isEditable: false,
    status: '',
    roles: [],
    isAllClinicsAccess: false,
    clinics: [],
    profileImageDetails: { expiresInSeconds: 0, isSignedURL: false, location: '', s3Key: '' },
  });
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleSettings = () => {
    setOpen(false);
    navigate('/settings');
  };

  const logout = async () => {
    try {
      sessionStorage.clear();
      navigate('/login');
      await logoutUser();
    } catch (error) {
      console.log(error);
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (userData?.firstName) {
      setProfileData(userData);
    } else {
      setProfileData(profile);
    }
  }, [userData]);

  return profileData.firstName ? (
    <Box sx={{ flexGrow: 1, zIndex: 100 }} className='box'>
      <AppBar className='app-bar' sx={{ backgroundColor: zeplinColor.Background }}>
        <Toolbar className='toolbar'>
          <img className='logo' src={Logo} />
          <div className='rightContainer'>
            <Tooltip title={'Contact Support'}>
              <HelpOutlineIcon
                sx={{ color: zeplinColor.Background50 }}
                className='help-icon'
                onClick={() => window.open(`mailto:${Constants.MAIL_TO.ID}`)}
              />
            </Tooltip>
            <div className='profile-container'>
              <img
                className='profile-img'
                src={profileData?.profileImageDetails?.location || Constants.FALL_BACK_IMAGE}
                onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
              ></img>
              <Link
                ref={anchorRef}
                className='profile-link'
                style={{
                  color: zeplinColor.Background0,
                  textDecoration: 'none',
                }}
                id='composition-button'
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
              >
                <Typography style={{ minWidth: 'fit-content', width: 'auto' }} variant='subtitle1'>
                  {profileData.firstName}
                </Typography>
                <Typography variant='subtitle1'>{profileData.lastName}</Typography>
              </Link>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id='composition-menu'
                          aria-labelledby='composition-button'
                          onKeyDown={handleListKeyDown}
                          className='menu-list'
                          style={{
                            marginTop: '14px',
                            padding: '8px 0',
                          }}
                        >
                          <MenuItem onClick={handleSettings}>
                            <SettingsIcon
                              className='menu-item-setting'
                              style={{ color: zeplinColor.Primary }}
                            />
                            Settings
                          </MenuItem>
                          <MenuItem onClick={logout}>
                            <LogoutIcon
                              className='menu-item-logout'
                              style={{ color: zeplinColor.Primary }}
                            />
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <></>
  );
};
export default Header;
