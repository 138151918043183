/*
 * File: ViewOtherLinks.tsx
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 10:46:58 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 3:37:45 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
//
import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ILinkDetailResponse } from '../../../../../../model';
import { zeplinColor } from '../../../../../../theme';
import { Constants } from '../../../../../../utilities/constants';
import InfoTextBox from '../../../../../shared/InfoText/InfoText';
import './ListOtherLinks.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
interface IProps {
  otherLinkList: ILinkDetailResponse[];
  onReordering: (status: boolean, value: ILinkDetailResponse[]) => void;
}
interface OtherLinkListColumn {
  id: 'serial-number' | 'icon-and-title' | 'url';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
const otherLinkListColumn: OtherLinkListColumn[] = [
  { id: 'serial-number', label: 'S.No', minWidth: 100 },
  { id: 'icon-and-title', label: 'Icon and Title', minWidth: 200 },
  { id: 'url', label: 'URL', minWidth: 350 },
];
const useStyles = makeStyles(() => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));
const ListOtherLinks: FC<IProps> = ({ otherLinkList, onReordering }) => {

  const classes = useStyles();
  const [otherLinks, setOtherLinkList] = useState<ILinkDetailResponse[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (otherLinkList?.length > 0) {
      setOtherLinkList(otherLinkList);
    }
  }, [otherLinkList.length]);

  const reorder = (list: ILinkDetailResponse[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? zeplinColor.Primary10 : 'white',
    ...draggableStyle,
  });

  const onDragEnd = (result: any) => {
    if (otherLinks) {
      if (!result.destination) {
        return;
      }
      const movedItems = reorder(otherLinks, result.source.index, result.destination.index);
      setOtherLinkList(movedItems);
      onReordering(true, movedItems);
    }
  };
  return (
    <>
      <div style={{ margin: '8px 0px 0px -10px' }}>
        <InfoTextBox
          content={Constants.INFO_TEXTS.OTHER_LINK_INFO_TEXT}
          show={true}
          width={'76.5vw'}
        />
      </div>
      {otherLinks && otherLinks.length > 0 && (
        <div>
          <Paper
            className='containerOtherLinkdiv'
            sx={{ borderRadius: '16px', overflowX: 'hidden' }}
          >
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {otherLinkListColumn.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 0, minWidth: column.minWidth }}
                      >
                        <Typography
                          style={{ height: '22px', color: zeplinColor.Background50 }}
                          variant='subtitle1'
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided: any) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {otherLinks?.map((obj: ILinkDetailResponse, index: number) => {
                          return (
                            <Draggable key={obj.id} draggableId={'q-' + obj.id} index={index}>
                              {(provided: any, snapshot: any) => (
                                <TableRow
                                  hover
                                  classes={{ hover: classes.tableRow }}
                                  onClick={() =>
                                    navigate({
                                      pathname: `/manage/other-link/${obj.id}`,
                                    })
                                  }
                                  key={obj.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <TableCell
                                    style={{
                                      verticalAlign: 'top',
                                      maxWidth: '2rem',
                                    }}
                                    className={snapshot.isDragging && 'drag-row1'}
                                  >
                                    <Typography>{index + 1}.</Typography>
                                  </TableCell>
                                  <TableCell
                                    className={snapshot.isDragging && 'drag-row2'}
                                    style={{
                                      verticalAlign: 'top',
                                      // maxWidth: '12rem',
                                    }}
                                  >
                                    <Typography className='otherLinkTitle' variant='subtitle1'>
                                      <img
                                        className='otherLinkAvatar'
                                        src={
                                          obj?.imageDetails?.location || Constants.FALL_BACK_IMAGE
                                        }
                                        onError={(e: any) =>
                                          (e.target.src = Constants.FALL_BACK_IMAGE)
                                        }
                                      ></img>
                                      {obj?.title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      verticalAlign: 'top',
                                      maxWidth: '21rem',
                                    }}
                                  >
                                    <Typography className={snapshot.isDragging && 'drag-row3'}>
                                      {obj.url}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};
export default ListOtherLinks;
