import './ListDraftClinic.css';

import { IClinicList, IClinics } from '../../../../model';
import { Pagination, Paper } from '@mui/material';
import React, { FC, useState } from 'react';
/*
 * File: ListDraftClinic.tsx
 * Project: mint-portal
 * File Created: Tuesday, 23rd August 2022 4:05:51 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 1st November 2022 7:00:20 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { Constants } from '../../../../utilities/constants';
import CustomToolTip from '../../../shared/CustomToolTip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';

interface IProps {
  clinicList: IClinicList;
  onPageChangeHandler: any;
  currentPage: number;
}

interface ClinicListColumn {
  id: 'clinic_name' | 'last_updated';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const clinicListColumns: ClinicListColumn[] = [
  { id: 'clinic_name', label: 'Clinic Name', minWidth: 350 },
  { id: 'last_updated', label: 'Last Updated on', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListDraftClinic: FC<IProps> = ({ clinicList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();

  const navigate = useNavigate();
  const onDataPageChange = (event: any, page: number) => {
    setDataPage(page);
    // triggers function in the insurance component to get next set of insurance users
    onPageChangeHandler(page);
  };

  return (
    <div>
      <Paper className='containerDraftdiv' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {clinicListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clinicList.total &&
                clinicList.clinics.length &&
                clinicList.clinics.map((clinicObj: IClinics) => {
                  return (
                    <TableRow
                      hover
                      classes={{ hover: classes.tableRow }}
                      key={clinicObj.id}
                      onClick={() =>
                        navigate({
                          pathname: `/clinics/add/clinic-details?id=${clinicObj.id}`,
                        })
                      }
                    >
                      <TableCell key={clinicObj.id}>
                        <CustomToolTip title={clinicObj.name} width={'350px'} />
                      </TableCell>
                      <TableCell>
                        <Typography>{moment(clinicObj.updatedAt).format('LLL')}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={
          clinicList.total <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(clinicList.total / Constants.PAGINATION_OPTIONS.limit)
        }
        onChange={onDataPageChange}
        page={dataPage}
        variant='outlined'
        shape='rounded'
        className='pagination'
        classes={{ outlined: classes.ul }}
      />
    </div>
  );
};

export default ListDraftClinic;
