/*
 * File: Buttons.tsx
 * Project: mint-portal
 * File Created: Thursday, 16th June 2022 5:51:06 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Friday, 28th July 2023 12:34:38 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { ReactNode } from 'react';
import { zeplinColor } from '../../theme';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const CustomButton = styled(Button)<ButtonProps>(() => ({
  height: '34px',
  backgroundColor: zeplinColor.Primary,
  '&:hover': {
    backgroundColor: zeplinColor.Primary,
  },
}));

export default function Buttons(props: {
  text: string;
  width: string;
  disable: boolean;
  onClick: () => void;
  bgWhite?: boolean;
  classname?: string;
  startIcon?: ReactNode;
  disableBorder?: boolean;
}) {
  return (
    <CustomButton
      variant='contained'
      onClick={props.onClick}
      sx={{
        backgroundColor: props?.bgWhite ? zeplinColor.Surface : zeplinColor.Primary,
        border: props?.bgWhite && !props.disableBorder ? `solid 1px ${zeplinColor.Primary}` : '',
        color: props?.bgWhite ? zeplinColor?.Primary : zeplinColor?.Surface,
        boxShadow: props?.bgWhite ? 'none' : '',
        ':hover': { backgroundColor: props?.bgWhite ? zeplinColor.Surface : zeplinColor.Primary },
      }}
      disabled={props.disable}
      style={{ width: props.width }}
      disableRipple
      className={props.classname}
      startIcon={props.startIcon}
    >
      {props.text}
    </CustomButton>
  );
}

Buttons.defaultProps = {
  text: 'Default',
  width: '161px',
  disabled: false,
};
