/*
 * File: ListIconLibrary.tsx
 * Project: mint-portal
 * File Created: Tuesday, 16th May 2023 9:53:50 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 10th July 2023 8:34:09 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Button, Paper } from '@mui/material';
import { IIconDetailsResponse, IIconListRO } from '../../../../model';
import zeplinColor from '../../../../theme/zeplinColor';
import './ListIconLibrary.css';
import { Constants } from '../../../../utilities/constants';
import DeletePopup from '../../../shared/DeletePopup';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { FileService } from '../../../../service/file.service';
import Loader from '../../../shared/Loader/Loader';

interface IIconProps {
  iconsList: IIconListRO;
  getIconList: (e?: string) => Promise<void>;
}

interface IconListColumn {
  id: 'icon_image' | 'icon_name' | 'action_edit' | 'action_delete';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const iconListColumns: IconListColumn[] = [
  { id: 'icon_image', label: 'Icon' },
  { id: 'icon_name', label: 'File Name' },
  { id: 'action_edit', label: 'Actions' },
  { id: 'action_delete', label: '' },
];

const ListIconLibrary: FC<IIconProps> = ({ iconsList, getIconList }) => {
  const inputRef = useRef<HTMLInputElement[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [iconDelete, setIconDelete] = useState<IIconDetailsResponse>({
    createdAt: '',
    updatedAt: '',
    id: 0,
    iconName: '',
    iconDetails: { expiresInSeconds: 0, isSignedURL: false, location: '', s3Key: '' },
  });
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);

  const updateIcon = async (e: any, id: number) => {
    setShowLoader(true);
    try {
      const imageUploadRes = await FileService.getSignedUrlToUploadFile(
        Constants.FILE_UPLOAD_CATEGORY.ICON_LIBRARY,
        e.target.files[0],
        { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
      );
      const s3Key = imageUploadRes?.signedUrl?.data?.data?.s3Key;
      const fileName = e.target.files[0].name;
      const fileExtension = fileName.split('.').pop();
      const fileNameWithoutExtension = fileName.replace('.' + fileExtension, '');
      await ManageService.updateIconById(id, {
        iconName: fileNameWithoutExtension,
        iconS3Key: s3Key,
      });

      getIconList('updated');
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
      e.target.file = null;
    }
  };
  const handleDeletePopupOpen = (obj: IIconDetailsResponse) => {
    setOpenDeletePopup(true);
    setIconDelete(obj);
  };

  const handleDeleteIcon = async () => {
    try {
      if (iconsList) {
        await ManageService.deleteIconById(iconDelete.id);
        setOpenDeletePopup(false);
        getIconList('deleted');
        setShowSnackbar(true);
        setShowSnackbarMessage(`Icon deleted`);
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <Paper className='iconContainer' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {iconListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {iconsList?.data?.length &&
                iconsList?.data?.map((obj: IIconDetailsResponse, ind: number) => {
                  return (
                    <TableRow key={obj.id}>
                      <TableCell style={{ width: '240px' }}>
                        <img
                          src={
                            obj.iconDetails?.location
                              ? obj.iconDetails?.location
                              : Constants.SERVICE_FALL_BACK_ICON
                          }
                          onError={(e: any) => (e.target.src = Constants.SERVICE_FALL_BACK_ICON)}
                          height={'48px'}
                          width={'48px'}
                          style={{ objectFit: 'contain', borderRadius: '8px' }}
                        />
                      </TableCell>
                      <TableCell style={{ width: '300px' }}>
                        <Typography>{obj.iconName} </Typography>
                      </TableCell>
                      <TableCell style={{ width: '260px' }}>
                        <div className='updateButtonContainer'>
                          <input
                            type='file'
                            accept='image/*'
                            onChange={(e) => updateIcon(e, obj.id)}
                            title=''
                            ref={(e) => {
                              if (e) {
                                inputRef.current[ind] = e;
                              }
                            }}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              opacity: 0,
                              pointerEvents: 'auto',
                              width: '20px',
                            }}
                          />
                          {/* <Typography variant='subtitle1' className='actionText' color='primary'>
                            Update
                          </Typography> */}
                          <Button
                            variant='outlined'
                            style={{
                              // width: '6em',
                              // height: '34px',
                              backgroundColor: `${zeplinColor.Surface}`,
                              border: `0px solid ${zeplinColor.Background70}`,
                              color: `${zeplinColor.Primary}`,
                              paddingLeft: 0,
                              marginBottom: 5,
                              textTransform: 'none',
                              display: 'inline-block',
                              cursor: 'pointer',
                              pointerEvents: 'auto',
                            }}
                            disableRipple={true}
                            onClick={() => {
                              inputRef && inputRef?.current[ind]?.click();
                            }}
                          >
                            {' '}
                            <Typography variant='subtitle1' color='primary'>
                              Update
                            </Typography>
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography
                          onClick={() => handleDeletePopupOpen(obj)}
                          variant='subtitle1'
                          className='actionText'
                          color='error'
                          style={{
                            marginBottom: '10px',
                            display: 'inline-block',
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                          }}
                        >
                          Delete
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {iconsList?.data && (
        <DeletePopup
          title={'Delete Icon'}
          description={`${iconDelete.iconName}`}
          subDescription={''}
          open={openDeletePopup}
          buttonText={`Delete`}
          setOpen={setOpenDeletePopup}
          onDeleteClick={handleDeleteIcon}
        />
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ListIconLibrary;
