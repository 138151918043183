/*
 * File: EmptyState.tsx
 * Project: mint-portal
 * File Created: Monday, 4th July 2022 3:18:53 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 19th June 2023 6:43:06 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './EmptyState.css';

import { Button, Link, Typography } from '@material-ui/core';

import BrokenTooth from '../../../assests/broken-tooth.svg';
import React from 'react';
import Warning from '../../../assests/Warning.svg';
import { zeplinColor } from '../../../theme';

type emptyStateProps = {
  text: string;
  subText: string;
  path: string;
  buttonText: string;
  showAddButton: boolean;
  image: boolean;
  removeImage?: boolean;
  forbiddenAccess?: boolean;
  serviceIconLibrary?: boolean;
  onChangeIcon?: React.ChangeEventHandler<HTMLInputElement>;
  inputRef?: any;
};

const EmptyState = ({
  text,
  subText,
  path,
  buttonText,
  showAddButton,
  image,
  removeImage = false,
  forbiddenAccess = false,
  serviceIconLibrary,
  onChangeIcon,
  inputRef
}: emptyStateProps) => {

  return (
    <div
      className={forbiddenAccess ? 'isForbidden' : 'emptyState'}
      style={{
        height: '100%',
        flexDirection: removeImage ? 'row' : 'column',
        gap: removeImage ? 0 : 24,
      }}
    >
      {removeImage ? (
        <div></div>
      ) : (
        <img src={image ? BrokenTooth : Warning} alt='' className='image' />
      )}

      {removeImage ? (
        <div></div>
      ) : (
        <Typography className='text' variant='h5'>
          {text}
        </Typography>
      )}
      <Typography className='subText' style={{ color: zeplinColor.Background50 }}>
        {subText}
      </Typography>
      {showAddButton && !serviceIconLibrary ? (
        <div className='buttonContainer'>
          <Link href={path} id='addButtonLink'>
            <Typography variant='subtitle1' className='buttonText' color='primary'>
              {buttonText}
            </Typography>
          </Link>
        </div>
      ) : (
        <div className='addContainer' style={{ display: 'flex', marginRight: '-3.75%'}}>
          <input className='inputButton' type='file' accept='image/*' onChange={onChangeIcon} title='' ref={inputRef} />
          <Button
            variant='outlined'
            style={{
              backgroundColor: `${zeplinColor.Surface}`,
              border: `0px solid ${zeplinColor.Background70}`,
              paddingLeft: 0,
              marginBottom: 5,
              textTransform: 'none',
            }}
            disableRipple={true}
            onClick={() => {
              inputRef && inputRef?.current?.click();
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {buttonText}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
