/*
 * File: Faq.tsx
 * Project: mint-portal
 * File Created: Friday, 9th December 2022 6:36:44 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:26 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import { Typography, Toolbar, Button } from '@material-ui/core';
import Buttons from '../../../shared/Buttons';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import { Constants } from '../../../../utilities/constants';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { useNavigate } from 'react-router-dom';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import ListFaq from './ListFaq/ListFaq';
import Loader from '../../../shared/Loader/Loader';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import './Faq.css';
import { IListFaqRO, IFaqDetailResponse } from '../../../../model';
import getPermissionForModule from '../../../../utilities/getPermission';
import { FAQ } from '../../../../pages/Manage/ManageCardConstants';
import { zeplinColor } from '../../../../theme';

const Faq = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const navigate = useNavigate();
  const addedFaq = sessionStorage.getItem('faqData');
  const deletedFaq = sessionStorage.getItem('deletedFaq');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [faqList, setFaqList] = useState<IListFaqRO>({ data: [], message: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [reorderedFaq, setReorderedFaq] = useState<any>([]);

  useEffect(() => {
    setShowLoader(true);
    if (addedFaq) {
      setShowSnackBar(true);
      setShowSnackbarMessage('New Question Added');
      sessionStorage.removeItem('faqData');
    }
    if (deletedFaq) {
      setShowSnackBar(true);
      setShowSnackbarMessage('Question Deleted');
      sessionStorage.removeItem('deletedFaq');
    }
    getFaqList();
  }, []);

  const onReorderFaq = (status: boolean, movedItems: IFaqDetailResponse[]) => {
    setIsReordering(status);
    setReorderedFaq(movedItems);
  };

  const onCancelReorder = () => {
    setShowLoader(true);
    getFaqList();
    setIsReordering(false);
  };

  const getFaqList = async () => {
    try {
      const response: IListFaqRO = await ManageService.getFaqList();
      setFaqList(response);
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const onSaveChange = async () => {
    try {
      setShowLoader(true);
      const faqIds = reorderedFaq.map((obj: IFaqDetailResponse) => obj.id);
      const res = await ManageService.updateFaqOrder({ faqIds: faqIds });
      setFaqList(res);
      setShowSnackBar(true);
      setShowSnackbarMessage('Order of FAQs Updated');
      setIsReordering(false);
    } catch (err) {
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackBar(true);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {(isForbiddenAccess || !FAQ.roleAccess.includes(roleId)) && !showLoader && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {!isForbiddenAccess && FAQ.roleAccess.includes(roleId) && (
        <div className='faqContainer'>
          <div className='faqHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='faqTitle' color='inherit'>
                Frequently Asked Questions
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Frequently Asked Questions'} />
              </Typography>
              {FAQ.roleAccess.includes(roleId) && (
                <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                  {!isReordering ? (
                    <Buttons
                      text='ADD NEW QUESTION'
                      width='15em'
                      disable={faqList?.data?.length >= Constants.FAQ_LIMIT ? true : false}
                      onClick={() => navigate('/manage/faq/add')}
                    />
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        style={{
                          width: '8em',
                          height: '34px',
                          backgroundColor: `${zeplinColor.Background97}`,
                          border: `1px solid ${zeplinColor.Background70}`,
                          color: `${zeplinColor.Background70}`,
                        }}
                        onClick={() => onCancelReorder()}
                      >
                        Cancel
                      </Button>
                      <Buttons
                        text='Save Changes'
                        width='11em'
                        disable={false}
                        onClick={() => onSaveChange()}
                      />
                    </>
                  )}
                </div>
              )}
            </Toolbar>
            <div style={{ margin: '33px 0px 0px 20px' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.FAQ_INFO_TEXT}
                show={true}
                width={'76.5vw'}
              />
            </div>
          </div>
          {faqList?.data.length === 0 && !showLoader ? (
            <div style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/manage/faq/add'}
                text={'No questions to show'}
                subText={'Get started by clicking on Add New Question'}
                buttonText={'ADD NEW QUESTION'}
                showAddButton={true}
                image={true}
              />
            </div>
          ) : (
            !showLoader && <ListFaq faqList={faqList?.data} onReordering={onReorderFaq} />
          )}
        </div>
      )}
      {showSnackBar && (
        <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
      )}
    </>
  );
};

export default Faq;
