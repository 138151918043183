/*
 * File: ListInsurance.tsx
 * Project: mint-portal
 * File Created: Tuesday, 26th July 2022 4:01:19 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 7th November 2022 5:29:38 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../../theme';
import React, { FC, useState } from 'react';
import './ListInsurance.css';
import { Constants } from '../../../../utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import { InsuranceListRO, InsuranceList } from '../../../../model';
import { useNavigate } from 'react-router-dom';

interface IProps {
  insuranceList: InsuranceListRO;
  onPageChangeHandler: any;
  currentPage: number;
}

interface InsuranceListColumn {
  id: 'insurance_name';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const staffListColumns: InsuranceListColumn[] = [{ id: 'insurance_name', label: 'Insurance Name' }];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListInsurance: FC<IProps> = ({ insuranceList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();

  const navigate = useNavigate();
  const onDataPageChange = (event: any, page: number) => {
    setDataPage(page);
    // triggers function in the insurance component to get next set of insurance users
    onPageChangeHandler(page);
  };

  return (
    <div>
      <Paper className='containerdiv' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {staffListColumns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {insuranceList.total &&
                insuranceList.insurances.length &&
                insuranceList.insurances.map((insuranceObj: InsuranceList) => {
                  return (
                    <TableRow
                      hover
                      classes={{ hover: classes.tableRow }}
                      key={insuranceObj.id}
                      onClick={() =>
                        navigate({
                          pathname: `/insurances/detail/${insuranceObj.id}`,
                        })
                      }
                    >
                      <TableCell key={insuranceObj.id}>
                        <Typography style={{ width: '72vw',overflowWrap: 'break-word' }}>{insuranceObj.insuranceName}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={
          insuranceList.total <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(insuranceList.total / Constants.PAGINATION_OPTIONS.limit)
        }
        onChange={onDataPageChange}
        page={dataPage}
        variant='outlined'
        shape='rounded'
        className='pagination'
        classes={{ outlined: classes.ul }}
      />
    </div>
  );
};

export default ListInsurance;
