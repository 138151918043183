/*
 * File: AddNotifications.tsx
 * Project: mint-portal
 * File Created: Tuesday, 14th February 2023 2:59:56 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 28th February 2023 9:36:58 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Box, MenuItem, Typography, makeStyles } from '@material-ui/core';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatTime, getTime } from '../../../../utilities/getTime';
import { useLocation, useNavigate } from 'react-router-dom';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { Constants } from '../../../../utilities/constants';
import CustomNumericInput from '../../../shared/CustomNumericField';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { handleKeyPressForEscapeSymbols } from '../../../../utilities/validate';
import moment from 'moment';
import { zeplinColor } from '../../../../theme';

const useStyles = makeStyles(() => ({
  inputLabelRoot: {
    color: `${zeplinColor.Primary}`,
    '&.MuiFormLabel-root': {
      color: `${zeplinColor.Primary}`, // or black
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${zeplinColor.Primary}`,
      },
    },
  },
  selectBorder: {
    '&.MuiInputBase-colorPrimary': {
      border: `0px solid ${zeplinColor.Background} !important`,
      borderBottom: `1px solid ${zeplinColor.Background0} !important`,
      borderRadius: '0px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0px solid ${zeplinColor.Background0} !important`,
      // borderBottom: `1px solid ${zeplinColor.Background0} !important`,
      borderRadius: '0px',
    },
    '&.Mui-focused': {
      borderWidth: '0px',
      borderColor: zeplinColor.Primary,
      borderBottom: `1px solid ${zeplinColor.Primary} !important`,
      borderRadius: '0px',
    },
  },
}));

const AddNotifications = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const classes = useStyles();
  const time = getTime();
  const [daysLeft, setDaysLeft] = useState('');
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState({ id: 1, name: '1' });
  const [scheduledTime, setScheduledTime] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const prevlink = [
    { path: '/manage', label: 'Manage' },
    { path: '/manage/appointment-notifications', label: 'Appointment Notifications' },
  ];
  useEffect(() => {
    if (route.pathname.includes('edit')) {
      setShowLoader(true);
      getNotificationById();
    }
  }, [route]);
  const getNotificationById = async () => {
    try {
      const res = await ManageService.getAppointmentNotificationById(
        parseInt(route.search.split('=')[1]),
      );
      setDaysLeft(res.data.hours > 2 ? 'more' : 'less');
      res.data.hours > 2
        ? setDays(res.data.hours / 24)
        : setHours({ id: res.data.hours, name: res.data.hours.toString() });
      setScheduledTime(moment(res.data.scheduledTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };
  
  const handleDaysLeft = (e: any) => {
    setDaysLeft(e.target.value);
  };
  const handleDaysChange = (e: any) => {
    setDays(e.target.value);
  };
  const handleHours = (e: any) => {
    setHours(e);
  };
  const onCancel = () => {
    navigate('/manage/appointment-notifications');
  };
  const addNotification = async () => {
    try {
      const hoursPayload = daysLeft === 'less' ? hours?.id : days * 24;

      !route.pathname.includes('edit')
        ? await ManageService.addAppointmentNotifications({
          hours: hoursPayload,
          ...(daysLeft === 'more' && {
            scheduledTime: moment(
              scheduledTime.split(' ')[0],
              Constants.MOMENT_FORMATS.HH_MM_SS,
            ).format(Constants.MOMENT_FORMATS.HH_MM_SS),
          }),
        })
        : await ManageService.editAppointmentNotification(
          {
            hours: hoursPayload,
            ...(daysLeft === 'more' && {
              scheduledTime: moment(
                scheduledTime.split(' ')[0],
                Constants.MOMENT_FORMATS.HH_MM_SS,
              ).format(Constants.MOMENT_FORMATS.HH_MM_SS),
            }),
          },
          parseInt(route.search.split('=')[1]),
        );

      route.pathname.includes('edit')? sessionStorage.setItem('notification','updated') 
        : sessionStorage.setItem('notification', 'added');
  
      onCancel();
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  const handleScheduled = (e: any) => {
    setScheduledTime(moment(e.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));
    // console.log(e);
  };

  return (
    <Box>
      {showLoader && <Loader />}
      <Box style={{ margin: '32px -24px 0px 0px' }}>
        <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
          <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
            {!route.pathname.includes('edit') ? 'Add Schedule' : 'Edit Schedule'}
            <br></br>
            <BreadCrumbs
              prevLink={prevlink}
              activeLink={!route.pathname.includes('edit') ? 'Add Schedule' : 'Edit Schedule'}
            />
          </Typography>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button
              variant='outlined'
              style={{
                width: '8em',
                height: '34px',
                backgroundColor: `${zeplinColor.Background97}`,
                border: `1px solid ${zeplinColor.Background70}`,
                color: `${zeplinColor.Background70}`,
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Buttons
              text={!route.pathname.includes('edit') ? 'ADD SCHEDULE' : 'SAVE CHANGES'}
              width='100%'
              disable={
                (daysLeft === 'more' && scheduledTime && days) || (daysLeft === 'less' && hours)
                  ? false
                  : true
              }
              onClick={addNotification}
            />
          </div>
        </Toolbar>
      </Box>
      <Box>
        <Paper
          elevation={0}
          sx={{
            borderRadius: '16px',
            border: `1px solid ${zeplinColor.Background90}`,
            paddingBottom: '3rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            margin: '32px 0px 32px 24px',
            padding: '32px',
            width: ' 76.5vw',
          }}
        >
          <Box>
            <Typography variant='subtitle1'>
              When should the user be notified of the appointment?
            </Typography>
            <FormControl style={{ marginTop: 20, paddingLeft: 10 }}>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={daysLeft}
                onChange={handleDaysLeft}
              >
                <FormControlLabel
                  value={'less'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: `${zeplinColor.Primary}`,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant='body1'>Less than a day before the appointment</Typography>
                  }
                />
                <FormControlLabel
                  value={'more'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: `${zeplinColor.Primary}`,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant='body1'>More than a day before the appointment</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            {daysLeft === 'less' ? (
              <Box>
                <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
                  How many hours before the appointment?
                </Typography>
                <SearchDropdown
                  searchList={Constants.hourOptions}
                  width={'200px'}
                  onSelectHandler={handleHours}
                  onSearchHandler={() => {
                    return;
                  }}
                  multiple={false}
                  value={hours}
                  staticValue={true}
                  label={''}
                  placeHolderText={'Select Hours'}
                  fixed={true}
                />
              </Box>
            ) : (
              daysLeft === 'more' && (
                <Box style={{ display: 'flex', flexDirection: 'column', gap: 45 }}>
                  <Box>
                    <Typography variant='subtitle1' style={{ marginBottom: 0 }}>
                      How many days before the appointment?
                    </Typography>
                    <Box style={{ position: 'relative', left: '-9px' }}>
                      <CustomNumericInput
                        name='days'
                        width={'240px'}
                        label=''
                        placeholder='Enter the no. of days here'
                        value={days}
                        error={false}
                        disabled={false}
                        onKeyPress={handleKeyPressForEscapeSymbols}
                        onChange={handleDaysChange}
                        icon={false}
                      />
                    </Box>
                  </Box>
                  <Box>
                    {' '}
                    <Typography variant='subtitle1' style={{ marginBottom: 0 }}>
                      Choose the time when the notification will be sent
                    </Typography>
                    <Box style={{ position: 'relative' }}>
                      <Select
                        className={classes.selectBorder}
                        labelId='open-time-picker-label'
                        id='open-time-picker'
                        value={formatTime(scheduledTime)}
                        onChange={(event) => handleScheduled(event)}
                        label='Open'
                        MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                        sx={{ width: 240 }}
                      >
                        {time.map((i) => {
                          return (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Paper>
      </Box>
      {showSnackBar && (
        <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
      )}
    </Box>
  );
};

export default AddNotifications;
