/*
 * File: DeletePopup.tsx
 * Project: mint-portal
 * File Created: Monday, 8th August 2022 10:39:00 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 20th March 2023 4:05:44 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Dispatch } from 'react';
import { zeplinColor } from '../../theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { IListRewardResponse, RewardType } from '../../model';

type deleteModalProps = {
  title: string;
  description?: string;
  subDescription: string;
  buttonText: string;
  open: boolean;
  setOpen: Dispatch<boolean>;
  onDeleteClick: Dispatch<boolean>;
  customDescription?: any;
  customButtonColor?: boolean;
  setRewardPoints?: React.Dispatch<React.SetStateAction<IListRewardResponse>>;
  setShortCut?: React.Dispatch<React.SetStateAction<number>>;
};

const DeletePopup = ({
  title,
  description = '',
  subDescription,
  open,
  buttonText,
  setOpen,
  onDeleteClick,
  customDescription = '',
  customButtonColor = false,
  setRewardPoints,
  setShortCut,
}: deleteModalProps) => {
  const handleClose = () => {
    setOpen(false);
    setShortCut && setShortCut(0);
    setRewardPoints &&
      setRewardPoints({
        name: '',
        rewardType: RewardType.PointsCredit,
        id: 0,
        pointsValue: 0,
        inventoryCount: 0,
      });
  };

  const onDelete = () => {
    onDeleteClick(true);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{
              marginTop: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {customDescription === '' ? (
              <Typography
                component={'span'}
                variant='body1'
                style={{ color: `${zeplinColor.Background0}`, wordBreak: 'break-word' }}
              >
                Are you sure you want to delete <b>{description}</b> ? You can’t undo this action.
              </Typography>
            ) : (
              <Typography
                component={'span'}
                variant='body1'
                style={{ color: `${zeplinColor.Background0}` }}
              >
                {customDescription}
              </Typography>
            )}
            <Typography
              component={'span'}
              variant='body2'
              style={
                !customButtonColor
                  ? { color: `${zeplinColor.Error}` }
                  : { color: `${zeplinColor.Background0}` }
              }
            >
              {subDescription}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: `${zeplinColor.Background70}`,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            onClick={onDelete}
            style={
              !customButtonColor
                ? { color: `${zeplinColor.Error}` }
                : { color: `${zeplinColor.Primary}` }
            }
            autoFocus
            disableRipple
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DeletePopup;
