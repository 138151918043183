/*
 * File: Providers.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 6:46:53 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 12th December 2023 9:36:29 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useEffect, useState } from 'react';
import './Providers.css';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import {
  IProviderByClinicIdQueryParamOptions,
  IProviderByClinicIdRO,
  IProviderGetResponse,
  IProviderSavePayload,
  IClinicProviderDetailRO,
  IViewClinicDetailsData,
  IOpenDentalSyncStatusRO,
} from '../../../../../model';
import { ClinicService } from '../../../../../service/clinic.service';
import { Box, Typography } from '@material-ui/core';
import Buttons from '../../../../shared/Buttons';
import { Constants } from '../../../../../utilities/constants';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { useLocation } from 'react-router-dom';
import { Utilities } from '../../../../../utilities/utilities';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import InfiniteDropDown from '../../../../shared/InfiniteDropDown/InfiniteDropDown';

type providersProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const Providers = ({ updatedClinicDetails, handleCreateClinicBtn }: providersProps) => {
  const route = useLocation();

  const [providerList, setProvidersList] = useState<IProviderGetResponse[]>([]);
  const defaultProvider = {
    providerNumber: +'',
    providerCode: '',
    providerLastName: '',
    providerFirstName: '',
  };
  const [primaryProviderList, setPrimaryProviderList] = useState<IProviderGetResponse[]>([]);
  const [selectProvider, setSelectProvider] = useState<IProviderGetResponse>(
    updatedClinicDetails?.id ? updatedClinicDetails?.provider : defaultProvider,
  );
  const [selectPrimaryProvider, setSelectPrimaryProvider] = useState<IProviderGetResponse>(
    updatedClinicDetails?.id ? updatedClinicDetails?.primaryProvider : defaultProvider,
  );
  const [searchProvider, setSearchProvider] = useState('');
  const [searchPrimaryProvider, setSearchPrimaryProvider] = useState('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [openDentalSyncStatus, setOpenDentalSyncStatus] = useState<boolean>(false);
  const [providerListOpen, setProviderListOpen] = useState(false);
  const [primaryProviderListOpen, setPrimaryProviderListOpen] = useState(false);
  const [providerCounter, setProviderCounter] = useState(0);
  const [primaryProviderCounter, setPrimaryProviderCounter] = useState(0);
  const [providerTotal, setProviderTotal] = useState(0);
  const [primaryProviderTotal, setPrimaryProviderTotal] = useState(0);

  const getOpenDentalSyncStatus = async () => {
    setShowLoader(true);
    try {
      let openDentalSyncStatusRO: IOpenDentalSyncStatusRO = {
        openDentalSync: false, // TODO: not checking this key because we have no server agent ready yet.
        operatories: false,
        procedureCodes: false,
        providers: false,
      };
      if (updatedClinicDetails?.id) {
        openDentalSyncStatusRO = await ClinicService.getOpenDentalSyncStatus(
          updatedClinicDetails.id,
        );
      }

      const isOpenDentalSyncDone = !!openDentalSyncStatusRO?.providers;
      setOpenDentalSyncStatus(isOpenDentalSyncDone);
      if (isOpenDentalSyncDone) {
        await getProviders(); // it is required, because we need to fetch the latest providers after sync done
        await getPrimaryProviderList();
      }
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any)?.response?.data?.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (openDentalSyncStatus) {
      // if open dental not in sync, then no need to call provider apis
      getProviders();
    }
  }, [searchProvider]);

  useEffect(() => {
    if (updatedClinicDetails?.id) {
      getPrimaryProviderList();
    }
  }, [searchPrimaryProvider]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      updatedClinicDetails?.id &&
      updatedClinicDetails?.isDraft &&
      updatedClinicDetails?.openDentalConfig?.id
    ) {
      // GET OPEN DENTAL STATUS ONLY IF CLINIC IS IN DRAFT AND OPEN DENTAL CONFIG SET
      getOpenDentalSyncStatus();
    }
    if (updatedClinicDetails?.isDraft === false) {
      // if clinic is not in draft state, then no need to check open dental sync status
      setOpenDentalSyncStatus(true);
      getProviders();
      getPrimaryProviderList();
    }
    if (openDentalSyncStatus) {
      // if open dental not in sync, then no need to call provider apis
      getProviders();
      if (updatedClinicDetails?.provider && updatedClinicDetails?.primaryProvider) {
        setSelectProvider(
          updatedClinicDetails?.id ? updatedClinicDetails?.provider : defaultProvider,
        );
        setSelectPrimaryProvider(
          updatedClinicDetails?.id ? updatedClinicDetails?.primaryProvider : defaultProvider,
        );
      }
    }
  }, [updatedClinicDetails?.id, openDentalSyncStatus]);

  const onSelectProvider = (inputValue: IProviderGetResponse) => {
    setSelectProvider(inputValue);
    handleProviderListOpen();
  };

  const onSearchProvider = (searchVal: any) => {
    setSearchProvider(searchVal?.target.value);
  };

  const onSearchPrimaryProvider = async (searchVal: any) => {
    setSearchPrimaryProvider(searchVal?.target.value);
  };


  const onSelectPrimaryProvider = (inputValue: IProviderGetResponse) => {
    setSelectPrimaryProvider(inputValue);
  };

  const handleProviderListOpen = () => {
    setSearchProvider('');
    setProviderListOpen(!providerListOpen);
  };

  const handlePrimaryProviderListOpen = () => {
    setSearchProvider('');
    setPrimaryProviderListOpen(!primaryProviderListOpen);
  };
  
  const getPrimaryProviderList = async () => {
    if (updatedClinicDetails?.id && selectProvider) {
      try {
        setPrimaryProviderCounter(primaryProviderCounter + 4);
        const queryParam: IProviderByClinicIdQueryParamOptions =
          searchPrimaryProvider && searchPrimaryProvider.length > 2
            ? { searchString: searchPrimaryProvider }
            : { limit: 4, skip: searchPrimaryProvider?.length ? 0 : primaryProviderCounter };
        const response: IProviderByClinicIdRO = await ClinicService.getProviderListByClinicId(
          queryParam,
          updatedClinicDetails?.id,
        );
  
        setPrimaryProviderTotal(response.data.total);
        setPrimaryProviderList((prevPrimaryProviderList) => {
          const filteredList = response.data.providers.filter((item) => {
            return item.providerNumber !== selectProvider.providerNumber;
          });
  
          return primaryProviderCounter && !searchPrimaryProvider.length && primaryProviderCounter > 0
            ? [...prevPrimaryProviderList, ...filteredList]
            : [...filteredList];
        });
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  

  const getProviders = async () => {
    if (updatedClinicDetails?.id) {
      try {
        setProviderCounter(providerCounter + 4);
        setPrimaryProviderCounter(primaryProviderCounter + 4);
        const queryParam: IProviderByClinicIdQueryParamOptions =
          searchProvider && searchProvider.length > 2
            ? { searchString: searchProvider }
            : { limit: 4, skip: searchProvider.length ? 0 : providerCounter };
        const response: IProviderByClinicIdRO = await ClinicService.getProviderListByClinicId(
          queryParam,
          updatedClinicDetails?.id,
        );
  
        setProviderTotal(response.data.total);
        setProvidersList(
          providerCounter && !searchProvider.length && providerCounter > 0
            ? [...providerList, ...response.data.providers]
            : [...response.data.providers],
        );

        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };

  const saveProviders = async () => {
    if (selectProvider && selectPrimaryProvider && updatedClinicDetails?.id) {
      setShowLoader(true);
      try {
        const providerPayload: IProviderSavePayload = {
          provider: selectProvider.providerNumber.toString(),
          primaryProvider: selectPrimaryProvider.providerNumber.toString(),
        };
        const saveProvidersRes: IClinicProviderDetailRO = await ClinicService.saveClinicProvider(
          updatedClinicDetails?.id,
          providerPayload,
        );
        if (saveProvidersRes.data && !saveProvidersRes.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Providers Saved');
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData) {
      if (selectProvider?.providerNumber && selectPrimaryProvider?.providerNumber) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    }
  }, [selectProvider, selectPrimaryProvider]);


  return (
    <div>
      {!updatedClinicDetails?.id ||
      !updatedClinicDetails?.openDentalConfig?.id ||
      !openDentalSyncStatus ? (
          <section className='provider-empty-state'>
            <EmptyState
              path={'/'}
              text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.text}
              subText={
                Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails, true)?.subText
              }
              buttonText={''}
              showAddButton={false}
              image={false}
            />
            {updatedClinicDetails?.openDentalConfig?.id && !openDentalSyncStatus && (
              <div style={{ position: 'absolute', top: '48.5rem' }}>
                <Buttons
                  text='refresh status'
                  width='170px'
                  disable={false}
                  onClick={getOpenDentalSyncStatus}
                />
              </div>
            )}
          </section>
        ) : (
          <div className='provider-container'>
            {showLoader && <Loader margin={true} />}
            <Typography variant='h6' style={{ marginBottom: 36 }}>
            Providers
            </Typography>
            <Box sx={{ margin: '32px auto' }}>
              <InfoTextBox
                content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
                width={'100%'}
                show={true}
              />
            </Box>
            <section style={{ display: 'flex', flexDirection: 'column', gap: 36, flex: 1 }}>
              <section>
                <div className=''>
                  <InfiniteDropDown
                    handleOpen={handleProviderListOpen}
                    open={providerListOpen}
                    searchList={providerList}
                    name={'provider'}
                    zIndex={'1'}
                    onSelectHandler={onSelectProvider}
                    setSelected={setSelectProvider}
                    onSearchHandler={onSearchProvider}
                    search={searchProvider}
                    value={selectProvider}
                    label={'Provider'}
                    disable={route?.pathname?.split('/')[2] === 'view'}
                    infinite={'provider'}
                    dataLength={providerList?.length}
                    hasMore={providerList?.length < providerTotal}
                    next={getProviders}
                    clearSearch={false}
                    multiple={false}
                    counter={providerCounter}
                  />
                </div>
              </section>
              {selectProvider && (
                <section>
                  <div className=''>
                    <InfiniteDropDown
                      handleOpen={handlePrimaryProviderListOpen}
                      open={primaryProviderListOpen}
                      searchList={primaryProviderList}
                      name={'provider'}
                      zIndex={'1'}
                      onSelectHandler={onSelectPrimaryProvider}
                      onSearchHandler={onSearchPrimaryProvider}
                      setSelected={setSelectPrimaryProvider}
                      search={searchPrimaryProvider}
                      value={selectPrimaryProvider}
                      label={'Primary Provider'}
                      disable={
                        route?.pathname?.split('/')[2] === 'view'
                          ? true
                          : selectProvider
                            ? false
                            : true
                      }
                      infinite={'provider'}
                      dataLength={primaryProviderList?.length}
                      hasMore={primaryProviderList?.length < primaryProviderTotal}
                      next={getPrimaryProviderList}
                      clearSearch={false}
                      multiple={false}
                      counter={primaryProviderCounter}
                    />
                  </div>
                </section>
              )}
              {route?.pathname?.split('/')[2] === 'view' ? (
                <></>
              ) : (
                <section
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 'auto',
                  }}
                >
                  <Buttons
                    text='SAVE DETAILS'
                    width='160px'
                    disable={
                      selectProvider?.providerNumber && selectPrimaryProvider?.providerNumber
                        ? false
                        : true
                    }
                    onClick={saveProviders}
                  />
                </section>
              )}
            </section>
          </div>
        )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default Providers;
