/*
 * File: sideBar.tsx
 * Project: mint-portal
 * File Created: Thursday, 16th June 2022 12:22:35 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 2nd November 2022 9:38:04 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import './SideBar.css';
import { NavLink } from 'react-router-dom';
import { SidebarData } from './SideBarData';
import { Typography } from '@material-ui/core';
import { zeplinColor } from '../../../theme';
import { IUserProfileResponse } from '../../../model';

const sideBar = () => {
  const navLinkStyles = ({ isActive }: { isActive: boolean }): React.CSSProperties => ({
    color: isActive ? zeplinColor.Primary : zeplinColor.Background70,
    borderRight: isActive && zeplinColor.Primary ? '3px solid' : '',
  });

  const loggedInUserData = sessionStorage.getItem('profileData');
  const loggedInUserRoles: IUserProfileResponse = loggedInUserData
    ? JSON.parse(loggedInUserData)
    : [];

  return (
    <div className='SideBar' style={{ background: zeplinColor.Surface }}>
      <div className='side' style={{ borderColor: zeplinColor.Background90 }}>
        <ul className='sideBarList' style={{ paddingLeft: '18px' }}>
          {SidebarData.map(
            (item, index: number) =>
              loggedInUserRoles?.roles &&
              loggedInUserRoles?.roles?.map((role) =>
                item.roleAccess.includes(role.id) ? (
                  <li key={index} className='sideRow'>
                    <div key={role.name} className='listContainer'>
                      <NavLink key={index + 1} to={item.path} className='row' style={navLinkStyles}>
                        <div key={index + 2} className='icon'>
                          {item.icon}
                        </div>
                        <Typography
                          key={index + 3}
                          component='span'
                          variant='subtitle1'
                          className='title'
                        >
                          {item.title}
                        </Typography>
                      </NavLink>
                    </div>
                  </li>
                ) : (
                  <div key={index}></div>
                ),
              ),
          )}
        </ul>
      </div>
    </div>
  );
};

export default sideBar;
