/*
 * File: ViewFaq.tsx
 * Project: mint-portal
 * File Created: Monday, 12th December 2022 12:55:58 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 10th February 2023 3:34:28 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../shared/Buttons';
import { CustomCard } from '../../../../shared/CustomCard/CustomCard';
import { ManageService } from '../../../../../service/manage.service';
import { IFaqDetailResponse } from '../../../../../model';
import CustomTextField from '../../../../shared/CustomTextField';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { zeplinColor } from '../../../../../theme';
import DeletePopup from '../../../../shared/DeletePopup';
import getPermissionForModule from '../../../../../utilities/getPermission';
import { FAQ } from '../../../../../pages/Manage/ManageCardConstants';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  box: {
    maxWidth: '72vw',
    wordWrap: 'break-word',
  },
}));

const ViewFaq = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const navigate = useNavigate();
  const styles = useStyles();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: `/manage/faq`, label: 'Frequently Asked Questions' },
  ];
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [payload, setPayload] = useState({ question: '', answer: '' });
  const [faq, setFaq] = useState<IFaqDetailResponse>({
    createdAt: '',
    updatedAt: '',
    id: 0,
    question: '',
    answer: '',
    displayOrder: 0,
  });
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getFaqDetailById();
  }, []);

  const handleText = (e: { target: { name: string; value: string } }) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onCancelEdit = () => {
    setEdit(false);
    setPayload({ question: faq.question, answer: faq.answer });
  };

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  const getFaqDetailById = async () => {
    setShowLoader(true);
    try {
      if (id) {
        const res = await ManageService.getFaqById(parseInt(id));
        setFaq(res.data);
        setPayload({ question: res.data.question, answer: res.data.answer });
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };
  const handleUpdate = async () => {
    setShowLoader(true);
    try {
      if (id) {
        await ManageService.editFaq(+id, payload);
        await getFaqDetailById();
        setEdit(false);
        setShowSnackBar(true);
        setShowSnackbarMessage('Question Updated');
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteFaq = async () => {
    if (id) {
      setShowLoader(true);
      try {
        await ManageService.deleteFaqById(+id);
        sessionStorage.setItem('deletedFaq', 'deleted');
        navigate(prevLink[1].path);
      } catch (err) {
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        setShowSnackBar(true);
      } finally {
        setShowLoader(false);
      }
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      {!FAQ.roleAccess.includes(roleId) && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {FAQ.roleAccess.includes(roleId) && (
        <Box sx={{ width: '76.5vw', margin: '32px 0px 32px 24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            <Box>
              <Typography variant='h5'>FAQ</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'FAQ'} />
            </Box>
            <Box>
              {!edit ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    className='deleteButton'
                    onClick={handleDeletePopupOpen}
                  >
                    <DeleteOutlineSharpIcon style={{ width: '24px' }} />
                  </Button>
                  <Buttons
                    text='EDIT'
                    width='79px'
                    disable={false}
                    onClick={() => setEdit(!edit)}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    disabled={showLoader ? true : false}
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={onCancelEdit}
                  >
                    Cancel
                  </Button>
                  <Buttons
                    text='SAVE CHANGES'
                    width='162px'
                    disable={Object.values(payload).some((d) => !d) || showLoader ? true : false}
                    onClick={handleUpdate}
                  />
                </div>
              )}
            </Box>
          </Box>
          {!showLoader && (
            <Box sx={{ marginTop: '28px' }}>
              {!edit ? (
                <CustomCard>
                  <Box style={{ padding: '32px 32px 47px 32px' }}>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1,1fr)',
                        gridRowGap: '32px',
                      }}
                    >
                      <Box className={styles.box}>
                        <Typography variant='subtitle1'>Question</Typography>
                        <Typography variant='body1'>{faq.question}</Typography>
                      </Box>
                      <Box className={styles.box}>
                        <Typography variant='subtitle1'>Answer</Typography>
                        <Typography variant='body1'>{faq.answer}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </CustomCard>
              ) : (
                <CustomCard>
                  <Box style={{ padding: '32px 32px 47px 32px' }}>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1,1fr)',
                        gridRowGap: '32px',
                      }}
                    >
                      <Box>
                        <CustomTextField
                          name='question'
                          label='Question*'
                          multiline={true}
                          value={payload.question}
                          width={'584px'}
                          onChange={handleText}
                        />
                      </Box>
                      <Box>
                        <CustomTextField
                          name='answer'
                          label='Answer*'
                          multiline={true}
                          value={payload.answer}
                          width={'584px'}
                          onChange={handleText}
                        />
                      </Box>
                    </Box>
                  </Box>
                </CustomCard>
              )}
            </Box>
          )}
          {faq && (
            <DeletePopup
              title={'Delete Question'}
              customDescription={
                'Are you sure you want to delete the question? This action can’t be undone.'
              }
              subDescription={''}
              open={openDeletePopup}
              buttonText={`Delete`}
              setOpen={setOpenDeletePopup}
              onDeleteClick={handleDeleteFaq}
            />
          )}
          {showSnackBar && (
            <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
          )}
        </Box>
      )}
    </>
  );
};

export default ViewFaq;
