/*
 * File: Links.tsx
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 6:04:40 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Monday, 12th June 2023 6:32:15 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Links.css';

import React, { useEffect, useState } from 'react';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import Loader from '../../../shared/Loader/Loader';
import { Button, Toolbar, Typography } from '@material-ui/core';
import getPermissionForModule from '../../../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { ManageService } from '../../../../service/manage.service';
import { Constants } from '../../../../utilities/constants';
import CustomTab from '../../../shared/CustomTab';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import Buttons from '../../../shared/Buttons';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { LINK_TYPE } from '../../../../model';
import { ILinkListRO, ILinkDetailResponse } from '../../../../model/manage.model';
import { zeplinColor } from '../../../../theme';
import ListOtherLinks from './OtherLinks/ListOtherLinks/ListOtherLinks';
import ListSocialLinks from './SocialLinks/ListSocialLinks/ListSocialLinks';

export enum LinksTabs {
  SOCIAL_MEDIA = 0,
  OTHER_LINKS ,
}

const Links = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Manage;
  const navigate = useNavigate();
  const prevLink = [{ path: '/manage', label: 'Manage' }];

  const [socialLinkList, setSocialLinkList] = useState<ILinkListRO>({ data: [], message: '' });
  const [otherLinks, setOtherLinks] = useState<ILinkListRO>({ data: [], message: '' });

  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMsg, setSnackbarMsg] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [reorderedList, setReorderedList] = useState<ILinkDetailResponse[]>([]);
  const addedLink = sessionStorage.getItem('addedLink');
  const deletedLink = sessionStorage.getItem('deletedLink');
  const updatedSocialLink = sessionStorage.getItem('updatedSocialLink');
  const [defaultTab, setDefaultTab] = useState(0);

  const onTabChange = (index: number) => {
    setDefaultTab(index);
    if(index === 0){
      getSocialLinkList();
    }
    else{
      getOtherLinkList();
    }
  };

  const getSocialLinkList = async () => {
    try {
      const socialList: ILinkListRO = await ManageService.getLinkList(LINK_TYPE.SOCIAL_MEDIA);
      setSocialLinkList(socialList);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const getOtherLinkList = async () => {
    setShowLoader(true);
    try {
      const otherList: ILinkListRO = await ManageService.getLinkList(LINK_TYPE.OTHERS);
      setOtherLinks(otherList);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const onReorderList = (status: boolean, movedItems: ILinkDetailResponse[]) => {
    setIsReordering(status);
    setReorderedList(movedItems);
  };

  const onCancelReorder = () => {
    setShowLoader(true);
    getOtherLinkList();
    setIsReordering(false);
  };

  const tabs = [
    {
      label: 'SOCIAL MEDIA',
      isHidden: false,
      id: 1,
      Component:
        permission.read && socialLinkList?.data.length && !showLoader ? (
          <ListSocialLinks socialList={socialLinkList?.data} />
        ) : (
          ''
        ),
    },
    {
      label: 'OTHER LINKS',
      isHidden: false,
      id: 2,
      Component:
        permission.read && otherLinks?.data.length && !showLoader ? (
          <ListOtherLinks otherLinkList={otherLinks?.data} onReordering={onReorderList} />
        ) : (
          otherLinks?.data.length === 0 &&
          !showLoader && (
            <section style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/manage/other-link/add'}
                text={'No links to show'}
                subText={permission.create ? 'Get started by clicking on Add New Link' : ''}
                buttonText={permission.create ? 'ADD NEW LINK' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </section>
          )
        ),
    },
  ];

  useEffect(() => {
    setShowLoader(true);
    getSocialLinkList();
    if (updatedSocialLink) {
      setShowSnackbar(true);
      setSnackbarMsg('Link Updated');
      sessionStorage.removeItem('updatedSocialLink');
    }
  }, []);

  useEffect(() => {
    setShowLoader(true);
    getOtherLinkList();
    if (addedLink) {
      onTabChange(1);
      setShowSnackbar(true);
      setSnackbarMsg('New Link Added');
      sessionStorage.removeItem('addedLink');
    }
    if (deletedLink) {
      onTabChange(1);
      setShowSnackbar(true);
      setSnackbarMsg('Link Deleted');
      sessionStorage.removeItem('deletedLink');
    }
  }, []);

  const onSaveChange = async () => {
    try {
      setShowLoader(true);
      const linkIds = reorderedList.map((obj: ILinkDetailResponse) => obj.id);
      const res = await ManageService.updateOtherLinkListOrder({ linkIds: linkIds });
      setOtherLinks(res);
      setShowSnackbar(true);
      setSnackbarMsg('Order of Links Updated');
      setIsReordering(false);
    } catch (err) {
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
      setShowSnackbar(true);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='linkContainer'>
          <div className='linkHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='staffTitle' color='inherit'>
                Links
                <br></br>
                <BreadCrumbs
                  prevLink={prevLink}
                  activeLink={defaultTab === LinksTabs.SOCIAL_MEDIA ? 'Social Media' : 'Other Links'}
                />
              </Typography>

              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                {permission.create &&
                  defaultTab === LinksTabs.OTHER_LINKS &&
                  (!isReordering ? (
                    <Buttons
                      text={'Add New Link'}
                      width='15em'
                      disable={
                        otherLinks?.data.length === Constants.OTHER_LINK_LIMIT ? true : false
                      }
                      onClick={() => navigate('/manage/other-link/add')}
                    />
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        style={{
                          width: '8em',
                          height: '34px',
                          backgroundColor: `${zeplinColor.Background97}`,
                          border: `1px solid ${zeplinColor.Background70}`,
                          color: `${zeplinColor.Background70}`,
                        }}
                        onClick={() => onCancelReorder()}
                      >
                        Cancel
                      </Button>
                      <Buttons
                        text='Save Changes'
                        width='11em'
                        disable={false}
                        onClick={() => onSaveChange()}
                      />
                    </>
                  ))}
              </div>
            </Toolbar>
            <div style={{ marginTop: '16px' }}>
              <CustomTab tabs={tabs} onTabChange={onTabChange} defaultTab={defaultTab} />
            </div>
          </div>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default Links;
