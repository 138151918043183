/*
 * File: DetailAutoRewards.tsx
 * Project: mint-portal
 * File Created: Monday, 12th June 2023 09:01:48 pm
 * Author: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Last Modified: Monday, 12th June 2023 08:50:57 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './DetailAutoRewards.css';

import { Divider, Paper } from '@mui/material';
import { IAutoRewardRO, IAutoRewardResponse } from '../../../../../model';
import React, { useEffect, useState } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Loader from '../../../../shared/Loader/Loader';
import { RewardService } from '../../../../../service/reward.service';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { useParams } from 'react-router-dom';

const DetailRedeemReward = () => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [autoRewardsDetail, setautoRewardsDetail] = useState<IAutoRewardResponse>();
  const { id } = useParams();
  const [showLoader, setShowLoader] = useState(false);

  const prevLink = [{ path: '/rewards', label: 'Auto-assign Points' }];

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      getAutoRewardById(+id);
    }
  }, [id]);

  const getAutoRewardById = async (id: number) => {
    try {
      const autoRewardsDetails: IAutoRewardRO = await RewardService.getAutoRewardById(id);
      setautoRewardsDetail(autoRewardsDetails?.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='detailAutoRewardsContainer'>
        <div className='detailAutoRewardsHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              {autoRewardsDetail?.name}
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={autoRewardsDetail?.name} />
            </Typography>
          </Toolbar>
        </div>
        {autoRewardsDetail && (
          <Paper className='detailAutoRewardsBox' sx={{ borderRadius: '16px' }}>
            <div className='detailAutoRewards'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Title</Typography>
                <Typography variant='body1'>{autoRewardsDetail?.name}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Description</Typography>
                <Typography variant='body1'>{autoRewardsDetail?.description}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Points earned by Primary Patient</Typography>
                <Typography variant='body1'>
                  {autoRewardsDetail?.primaryPatientPointsValue}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Points earned by Family Member</Typography>
                <Typography variant='body1'>
                  {autoRewardsDetail?.familyPatientPointsValue}
                </Typography>
              </div>
              <Divider />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>List of Procedure Codes</Typography>
                <Typography variant='body1'>{autoRewardsDetail?.procedureCodes.join(',') || autoRewardsDetail?.procedureCodes}</Typography>
              </div>
            </div>
          </Paper>
        )}
        {showSnackBar && (
          <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};

export default DetailRedeemReward;
