/*
 * File: App.tsx
 * Project: mint-portal
 * File Created: Thursday, 26th May 2022 12:22:53 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Sunday, 26th June 2022 9:28:05 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC } from 'react';
import './App.css';
import { appTheme, withTheme } from './theme';
import Routes from './routes/Routes';

const App: FC = () => {
  return (
    <div className=''>
      <Routes />
    </div>
  );
};

export default withTheme(appTheme, App, true);
