/*
 * File: OpeningHours.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 8:25:05 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 28th February 2023 9:22:39 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Switch, Typography, makeStyles } from '@material-ui/core';
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { IAvailabilityWeek, IViewClinicDetailsData, availabilityDay } from '../../../../../model';
import React, { useEffect, useState } from 'react';
import { formatTime, getTime } from '../../../../../utilities/getTime';

import Buttons from '../../../../shared/Buttons';
import { ClinicService } from '../../../../../service/clinic.service';
import { Constants } from '../../../../../utilities/constants';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import InfoTextBox from '../../../../shared/InfoText/InfoText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import { Utilities } from '../../../../../utilities/utilities';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { zeplinColor } from '../../../../../theme';

type hourProps = {
  updatedClinicDetails: IViewClinicDetailsData | undefined;
  handleCreateClinicBtn: (value: boolean) => void;
};

const useStyles = makeStyles(() => ({
  inputLabelRoot: {
    color: `${zeplinColor.Primary}`,
    '&.MuiFormLabel-root': {
      color: `${zeplinColor.Primary}`, // or black
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${zeplinColor.Primary}`,
      },
    },
  },
  selectBorder: {
    '&:before': {
      // normal
      borderBottom: `1.5px solid ${zeplinColor.Primary} !important`,
    },
    '&:after': {
      // focused
      borderBottom: `1.5px solid ${zeplinColor.Primary} !important`,
    },
  },
}));

const OpeningHours = ({ updatedClinicDetails, handleCreateClinicBtn }: hourProps) => {
  const openingHours: IAvailabilityWeek = {
    Monday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Tuesday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Wednesday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Thursday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Friday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Saturday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
    Sunday: {
      isOpen: false,
      startTime: null || '',
      closeTime: null || '',
    },
  };
  const route = useLocation();
  const classes = useStyles();
  const time = getTime();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [availabilityHours, setAvailabilityHours] = useState<IAvailabilityWeek>(openingHours);
  const [saveBtn, setSaveBtn] = useState<boolean>(true);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');

  useEffect(() => {
    if (updatedClinicDetails?.availability) {
      setAvailabilityHours(updatedClinicDetails?.availability || openingHours);
    }
  }, [updatedClinicDetails?.id]);

  useEffect(() => {
    if (!updatedClinicDetails?.isPartialData && updatedClinicDetails) {
      if (Object.values(availabilityHours).length > 0) {
        handleCreateClinicBtn(false);
      } else {
        handleCreateClinicBtn(true);
      }
    } else {
      handleCreateClinicBtn(true);
    }
  }, [saveBtn, availabilityHours]);

  useEffect(() => {
    validateOpeningHours();
  }, [availabilityHours && Object.values(availabilityHours)]);

  const handleIsOpenToggle = (event: React.ChangeEvent<HTMLInputElement>, availability: string) => {
    const stateCopy = Object.assign({}, availabilityHours);
    stateCopy[availability].isOpen = event.target.checked;
    stateCopy[availability].startTime = '';
    stateCopy[availability].closeTime = '';
    setAvailabilityHours(stateCopy);
  };

  const handleTimeChange = (event: any, availability: string, label: string) => {
    const stateCopy = Object.assign({}, availabilityHours);
    if (label === 'start') {
      stateCopy[availability].startTime = moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM);
    } else {
      stateCopy[availability].closeTime = moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM);
    }
    setAvailabilityHours(stateCopy);
  };

  const saveOpeningHours = async () => {
    setShowLoader(true);
    // const payload = { ...availabilityHours };

    // Object.values(payload).map((i) => {
    //   i.startTime = i.startTime.substr(0, 5); // removes AM/PM
    //   i.closeTime = i.closeTime.substr(0, 5);
    // });
    if (updatedClinicDetails?.id && availabilityHours) {
      try {
        const res = await ClinicService.saveClinicAvailability(
          updatedClinicDetails?.id,
          availabilityHours,
        );
        if (res.data && !res.data.isPartialData) {
          handleCreateClinicBtn(false);
        }
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage('Open Hours Saved');
      } catch (err) {
        setShowLoader(false);
        setShowSnackbar(true);
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
      }
    }
  };
  const validateOpeningHours = () => {
    const validationObj =
      availabilityHours &&
      Object.values(availabilityHours).map((i) => {
        if (!i.isOpen) {
          return true;
        } else if (
          i.startTime !== '' &&
          i.startTime !== null &&
          i.closeTime !== '' &&
          i.closeTime !== null
        ) {
          return true;
        } else {
          return false;
        }
      });
    availabilityHours && validationObj?.includes(false) ? setSaveBtn(true) : setSaveBtn(false);
  };

  return (
    <div>
      {!updatedClinicDetails?.id ? (
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '712px',
          }}
        >
          <EmptyState
            path={'/'}
            text={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.text}
            subText={Utilities.getTextSubTextForClinicEmptyState(updatedClinicDetails)?.subText}
            buttonText={''}
            showAddButton={false}
            image={false}
          />
        </section>
      ) : (
        <div className='map-operatories-details-container'>
          {showLoader && <Loader margin={true} />}
          <section>
            <Typography variant='h6'>Open Hours</Typography>
          </section>
          <Box sx={{ margin: '32px auto' }}>
            <InfoTextBox
              content={Constants.INFO_TEXTS.WARNING_SAVE_CLINIC}
              width={'100%'}
              show={true}
            />
          </Box>
          <section>
            <List>
              {availabilityHours &&
                Object.keys(openingHours).map((availability: any, index: number) => (
                  <>
                    <ListItem
                      key={index}
                      style={{ display: 'flex', flexDirection: 'row', gap: 50 }}
                    >
                      <span style={{ display: 'flex', flexDirection: 'row', gap: 70 }}>
                        <ListItemText key={index} sx={{ width: 80 }}>
                          <Typography variant='h6'>{availability}</Typography>
                        </ListItemText>
                        <FormControlLabel
                          labelPlacement='end'
                          disabled={route?.pathname?.split('/')[2] === 'view'}
                          control={
                            <Switch
                              color='primary'
                              onChange={(e) => handleIsOpenToggle(e, availability)}
                              checked={
                                availabilityHours[availability as keyof availabilityDay]?.isOpen
                              }
                            />
                          }
                          label={
                            availabilityHours[availability]?.isOpen ? (
                              <Typography>Open</Typography>
                            ) : (
                              <Typography>Closed</Typography>
                            )
                          }
                        />
                      </span>
                      {time && availabilityHours[availability]?.isOpen && (
                        <>
                          <FormControl variant='standard' sx={{ m: 1, minWidth: 100 }}>
                            <InputLabel
                              id='open-time-picker-label'
                              className={
                                route?.pathname?.split('/')[2] === 'view'
                                  ? ''
                                  : classes.inputLabelRoot
                              }
                            >
                              Open
                            </InputLabel>
                            <Select
                              className={
                                route?.pathname?.split('/')[2] === 'view'
                                  ? ''
                                  : classes.selectBorder
                              }
                              labelId='open-time-picker-label'
                              id='open-time-picker'
                              value={ formatTime(availabilityHours[availability].startTime)}
                              onChange={(event) => handleTimeChange(event, availability, 'start')}
                              label='Open'
                              MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                              disabled={route?.pathname?.split('/')[2] === 'view'}
                            >
                              {time.map((i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Typography variant='body2' style={{ marginTop: '1.45em' }}>
                            TO
                          </Typography>
                          <FormControl variant='standard' sx={{ m: 1, minWidth: 100 }}>
                            <InputLabel
                              id='close-time-picker-label'
                              classes={{
                                root:
                                  route?.pathname?.split('/')[2] === 'view'
                                    ? ''
                                    : classes.inputLabelRoot,
                              }}
                            >
                              Close
                            </InputLabel>
                            <Select
                              color='primary'
                              labelId='close-time-picker-label'
                              id='close-time-picker'
                              value={formatTime(availabilityHours[availability].closeTime)}
                              onChange={(event) => handleTimeChange(event, availability, 'close')}
                              label='Close'
                              className={
                                route?.pathname?.split('/')[2] === 'view'
                                  ? ''
                                  : classes.selectBorder
                              }
                              MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                              disabled={route?.pathname?.split('/')[2] === 'view'}
                            >
                              {time.map((i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </ListItem>
                    {availabilityHours[availability]?.isOpen && <Divider />}
                  </>
                ))}
            </List>
          </section>
          {route?.pathname?.split('/')[2] === 'view' ? (
            <></>
          ) : (
            <section style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 28 }}>
              <Buttons
                text='SAVE DETAILS'
                width='160px'
                disable={saveBtn}
                onClick={saveOpeningHours}
              />
            </section>
          )}
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </div>
  );
};

export default OpeningHours;
