/*
 * File: listStaff.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 8:30:20 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 21st March 2023 2:54:17 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../../theme';
import React, { FC, useState } from 'react';
import './ListStaff.css';
import { Constants } from '../../../../utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import { IStaffList } from '../../../../model';
import { useNavigate } from 'react-router-dom';
import CustomToolTip from '../../../shared/CustomToolTip';

interface IProps {
  staffList: IStaffList;
  onPageChangeHandler: any;
  currentPage: number;
}

interface StaffListColumn {
  id: 'name' | 'clinic' | 'role';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const staffListColumns: StaffListColumn[] = [
  { id: 'name', label: 'Name', minWidth: 300 },
  { id: 'clinic', label: 'Clinic', minWidth: 300 },
  {
    id: 'role',
    label: 'Role',
    minWidth: 195,
  },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListStaff: FC<IProps> = ({ staffList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();

  const onDataPageChange = (event: any, page: number) => {
    setDataPage(page);
    // triggers function in the staff component to get next set of staff users
    onPageChangeHandler(page);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper className='containerdiv' sx={{ borderRadius: '16px' }}>
        <TableContainer>
          <Table aria-label='caption table'>
            <TableHead>
              <TableRow>
                {staffListColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 0, minWidth: column.minWidth }}
                  >
                    <Typography
                      style={{ height: '22px', color: zeplinColor.Background50 }}
                      variant='subtitle1'
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {staffList.total &&
                staffList.staff.length &&
                staffList.staff.map((staffObj) => {
                  return (
                    <TableRow
                      hover
                      classes={{ hover: classes.tableRow }}
                      key={staffObj.id}
                      onClick={() =>
                        navigate({
                          pathname: `/staff/${staffObj.id}`,
                        })
                      }
                    >
                      <TableCell>
                        <Typography className='userName'>
                          <img
                            className='avatar'
                            style={{
                              border:
                                staffObj.status === 'CONFIRMED'
                                  ? 'none'
                                  : `2px solid ${zeplinColor.LightOrange}`,
                            }}
                            src={
                              staffObj?.profileImageDetails?.location || Constants.FALL_BACK_IMAGE
                            }
                            onError={(e: any) => (e.target.src = Constants.FALL_BACK_IMAGE)}
                          ></img>

                          <CustomToolTip
                            title={staffObj.firstName + ' ' + staffObj.lastName}
                            width={'275px'}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {staffObj.isAllClinicsAccess ? (
                          <Typography>All Clinics</Typography>
                        ) : staffObj?.clinics.length > 1 ? (
                          <Typography>
                            {staffObj.clinics[0]?.name} + {staffObj.clinics.length - 1}
                          </Typography>
                        ) : staffObj?.clinics.length === 0 ? (
                          <Typography>-</Typography>
                        ) : (
                          <Typography>{staffObj.clinics[0]?.name}</Typography>
                        )}
                      </TableCell>
                      {staffObj.roles &&
                        staffObj.roles.map((role) => {
                          return (
                            <TableCell key={role.id}>
                              <Typography>{role.name}</Typography>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={
          staffList.total <= Constants.PAGINATION_OPTIONS.limit
            ? 1
            : Math.ceil(staffList.total / Constants.PAGINATION_OPTIONS.limit)
        }
        onChange={onDataPageChange}
        page={dataPage}
        variant='outlined'
        shape='rounded'
        className='pagination'
        classes={{ outlined: classes.ul }}
      />
    </div>
  );
};

export default ListStaff;
