/*
 * File: SideDrawer.tsx
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 12:11:46 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 31st March 2023 9:24:08 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { Dispatch } from 'react';
import './SideDrawer.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import SearchBar from '../SearchBar';
import { zeplinColor } from '../../../theme';
import Chip from '@mui/material/Chip';
import { FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import Buttons from '../Buttons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Constants } from '../../../utilities/constants';

type sideDrawerProps = {
  sideDrawerOpen: boolean;
  clinicListData: any;
  onSearch: any;
  onSelectClinics: any;
  selectCustomClinic: Array<any>;
  disableSave: boolean;
  setSideDrawerOpen: Dispatch<boolean>;
  setChipData: any;
  moreClinics: any;
  getClinics: any;
  setClinicID?: any;
  getSearchedClinics: any;
  searchLength: number;
};

const SideDrawer = ({
  sideDrawerOpen,
  setSideDrawerOpen,
  clinicListData,
  onSearch,
  selectCustomClinic,
  onSelectClinics,
  disableSave,
  setChipData,
  moreClinics,
  getClinics,
  setClinicID,
  getSearchedClinics,
  searchLength
}: sideDrawerProps) => {
  const handleChange = (e: any) => {
    const selectedClinicArray = selectCustomClinic.includes(e.target.value)
      ? selectCustomClinic.filter((r: any) => r != e.target.value)
      : [...selectCustomClinic, e.target.value];
    onSelectClinics(selectedClinicArray);
    setClinicID(selectedClinicArray);
  };

  const clearAllChip = () => {
    onSelectClinics(() => []);
  };

  const handleDrawerSave = () => {
    setChipData(selectCustomClinic.length);
    setSideDrawerOpen(false);
  };

  return (
    <div>
      <React.Fragment>
        <Drawer anchor='right' open={sideDrawerOpen}>
          <Box sx={{ width: 480 }} role='presentation'>
            <Typography variant='h5' className='selectClinics'>
              Select Clinics
            </Typography>
            <div className='list-box'>
              {' '}
              <div className='drawerSearch'>
                {' '}
                <SearchBar onSearchHandler={onSearch} />
              </div>
              <div
                className='chip'
                style={{ display: selectCustomClinic.length === 0 ? 'none' : 'inline-block' }}
              >
                <Chip
                  label={`${selectCustomClinic.length < 10 ? '0' : ''}${
                    selectCustomClinic.length
                  } Clinics Selected`}
                  onDelete={clearAllChip}
                />
              </div>
              {moreClinics.length === 0 && (
                <div className='noResultsText'>
                  <Typography>{Constants.NO_SEARCH_RESULTS}</Typography>
                </div>
              )}
              <InfiniteScroll
                dataLength={clinicListData.total}
                next={searchLength >= 3 ? getSearchedClinics : getClinics}
                hasMore={clinicListData.total !== moreClinics.length}
                loader={<h4></h4>}
                height={480}
              >
                {moreClinics &&
                  moreClinics.map((clinics: any, index: number) => (
                    <FormGroup key={index} style={{ paddingLeft: '20px' }}>
                      <ListItem style={{ padding: '0px 0px 0px 20px' }}>
                        <ListItemButton disableRipple={true} style={{ overflowWrap: 'anywhere' }}>
                          <FormControlLabel
                            style={{ alignItems: 'normal' }}
                            control={
                              <Checkbox
                                value={clinics.id}
                                sx={{
                                  '&.Mui-checked': {
                                    color: `${zeplinColor.Primary}`,
                                  },
                                }}
                                style={{
                                  height: '30px',
                                  width: '20px',
                                  paddingRight: '20px',
                                  paddingTop: '5px',
                                }}
                                checked={selectCustomClinic.includes(clinics.id)}
                                onChange={handleChange}
                              />
                            }
                            label={clinics.name}
                          />
                        </ListItemButton>
                      </ListItem>
                    </FormGroup>
                  ))}
              </InfiniteScroll>
            </div>
            <div className='footer'>
              <div className='footerButtons'>
                <Buttons
                  text='Update'
                  width='76px'
                  disable={disableSave}
                  onClick={handleDrawerSave}
                />
              </div>
            </div>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};
export default SideDrawer;
