/*
 * File: DashBoard.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 5:31:00 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 25th September 2023 7:21:47 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Dashboard.css';
import { Badge, Box, Button, Chip, Toolbar, Typography } from '@material-ui/core';
import {
  FilterArray,
  FilterParams,
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
  IClinics,
} from '../../model';
import {
  IAppointmentAnalyticsInsuranceRO,
  IAppointmentAnalyticsResponse,
  IAppointmentDetails,
  IAppointmentDetailsListRO,
  IAppointmentDetailsQueryParams,
  IAppointmentDetailsResponse,
  IAppointmentPatientGenderResponse,
  IDashBoardServiceRO,
  IDashboardAgeType,
  IDashboardPatientType,
  IDashboardQueryParams,
  IDashboardServices,
} from '../../model/analytics.model';
import React, { useEffect, useState } from 'react';
import { dashboardColorTheme, zeplinColor } from '../../theme';
import { AnalyticsService } from '../../service/analytics.service';
import BarChart from '../../components/shared/Chart/VerticalBarChart/BarChart';
import Buttons from '../../components/shared/Buttons';
import { ClinicService } from '../../service/clinic.service';
import { Constants } from '../../utilities/constants';
import CustomBars from '../../components/shared/Chart/ProgressBar/CustomProgressBar';
import { CustomCard } from '../../components/shared/CustomCard/CustomCard';
import CustomDatePicker from '../../components/shared/CustomDatePicker';
import DoughnutChart from '../../components/shared/Chart/DoughnutChart/DoughnutChart';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAppointment from '../../components/feature/dashboard/FilterAppointment';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListAppointmentDetails from '../../components/feature/dashboard/ListAppointmentDetails';
import Loader from '../../components/shared/Loader/Loader';
import SearchBar from '../../components/shared/SearchBar';
import SearchDropdown from '../../components/shared/SearchDropdown/SearchDropdown';
import SideDrawer from '../../components/shared/SideDrawer/SideDrawer';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import { Utilities } from '../../utilities/utilities';
import moment from 'moment';

const DashBoard = () => {
  const [clinicFilter, setClinicFilter] = useState({ id: 'all clinics', name: 'All Clinics' });
  const [dateRangeType, setDateRangeType] = useState({ id: 'yesterday', name: 'Yesterday' });
  const [servicesLoader, setServicesLoader] = useState(false);
  const [servicesData, setServicesData] = useState<IDashboardServices[]>([
    { id: '', serviceName: '', totalAppointments: 0, percentageOfTotalAppointments: 0 },
  ]);
  const [servicesValue, setServicesValue] = useState([0]);
  const [servicesName, setServicesName] = useState(['']);
  const [servicesPercentage, setServicesPercentage] = useState([0]);

  const [insuranceValue, setInsuranceValue] = useState([0]);
  const [insuranceName, setInsuranceName] = useState(['']);
  const [insurancePercentage, setInsurancePercentage] = useState([0]);
  const [insuranceLoader, setInsuranceLoader] = useState(false);

  const [ageValue, setAgeValue] = useState<any[]>([]);
  const [ageData, setAgeData] = useState<IDashboardAgeType[]>([
    { percentageOfTotalAppointments: 100, type: 'adult' },
  ]);
  const [patientValue, setPatientValue] = useState<any[]>([]);
  const [patientData, setPatientData] = useState<IDashboardPatientType[]>([
    { percentageOfTotalAppointments: 100, type: 'new' },
  ]);
  const { startDate, endDate } = Utilities.getDateFilter(dateRangeType?.id);
  const [dateRange, setDateRange] = useState({
    startDate: moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    endDate: moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
  });
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [insuranceAnalytics, setInsuranceAnalytics] = useState<IAppointmentAnalyticsInsuranceRO>({
    data: [],
    message: '',
  });
  const [appointmentAnalytics, setAppointmentAnalytics] = useState<IAppointmentAnalyticsResponse>();
  const [genderAnalytics, setGenderAnalytics] = useState<IAppointmentPatientGenderResponse>();
  const [genderLoader, setGenderLoader] = useState(false);
  const [appointmentAnalyticLoader, setAppointmentAnalyticsLoader] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [selectCustomClinic, setSelectCustomClinic] = useState(['']);
  const [chipData, setChipData] = useState();
  const [moreClinics, setMoreClinics] = useState<any>(clinicList.clinics);
  const [counter, setCounter] = useState(0);
  const [searchCustomClinic, setSearchCustomClinic] = useState('');

  // infinite-scroll-table
  const [appointmentList, setAppointmentList] = useState<IAppointmentDetailsResponse>({
    total: 0,
    appointments: [],
  });
  const [searchValue, setSearchValue] = useState('');
  const [moreAppointments, setMoreAppoinments] = useState<IAppointmentDetails[]>([]);
  const [tableCounter, setTableCounter] = useState(0);

  const [selectClinics, setSelectClinics] = useState<IClinics[]>([]);
  // const [searchClinic, setSearchClinic] = useState('');
  const [selectStatus, setSelectStatus] = useState<any>([]);
  // const [searchStatus, setSearchStatus] = useState('');
  const [selectInsurance, setSelectInsurance] = useState<FilterArray[] | null>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterOptions, setFilterOption] = useState<FilterParams | any>();
  const [apptClinics, setApptClinics] = useState<IClinics[]>([]);
  const [clinicID, setClinicID] = useState([]);
  const [totalClinicList, setTotalClinicList] = useState<any>(clinicList.clinics);
  const [appointmentLoader, setAppointmentLoader] = useState(false);
  const [apptClinicsStore, setApptClinicsStore] = useState<IClinics[]>([]);

  const handleStartDateChange = (newValue: string | Date | null) => {
    setDateRange({
      ...dateRange,
      startDate: moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    });
  };

  const handleEndDateChange = (newValue: string | Date | null) => {
    setDateRange({
      ...dateRange,
      endDate: moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    });
  };

  const handleRangeOptions = (e: any) => {
    setDateRangeType(e);
  };

  // side drawer
  const handleDrawerOpen = () => {
    setSearchCustomClinic('');
    setSideDrawerOpen(true);
  };
  const onSearchClinic = (inputValue: string) => {
    setSearchCustomClinic(inputValue);
  };
  const onSelectClinics = (inputValue: any) => {
    setSelectCustomClinic(inputValue);
  };

  const handleClinicOptions = (e: any) => {
    setClinicFilter(e);
    // if (e.name === 'Select Clinics') {
    //   handleDrawerOpen();
    // }
  };

  // appointment table
  const getClinicUserList = async (skipArg?: number, searchClinic?: string) => {
    try {
      if (searchClinic && clinicFilter?.id !== 'all clinics') {
        const filtered = apptClinics.filter((v: any) => v.name.match(searchClinic));
        setApptClinics(filtered);
      } else if (
        clinicFilter?.id !== 'all clinics' &&
        !searchClinic &&
        selectCustomClinic?.length
      ) {
        setApptClinics(apptClinicsStore);
      } else {
        const queryParam: IClinicQueryParamOptions =
          searchClinic && searchClinic?.length > 2
            ? { searchString: searchClinic }
            : { limit: 4, skip: skipArg ? skipArg : 0 };
        const response: IClinicListResponse = await ClinicService.getClinicList(queryParam);
        setClinicList({
          total: response.data.total,
          clinics:
            skipArg && !searchClinic
              ? [...clinicList.clinics, ...response.data.clinics]
              : [...response.data.clinics],
        });
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
  };
  const closeFilters = () => {
    setShowFilter(false);
  };
  // const onSearchStatus = (searchStatus: any) => {
  //   setSearchStatus(searchStatus);
  // };
  // const onSearchClinics = (searchVal: string) => {
  //   setSearchClinic(searchVal);
  // };
  const clearAllFilter = (clearClinicList?: boolean) => {
    setSelectStatus([]);
    setSelectClinics([]);
    setSelectInsurance(undefined);
    setFilterOption({ yes: false, no: false });
    // getAppointmentList();
    getAppointmentFilter(false, false, true, true);
    clearClinicList && setClinicID([]);
  };

  const handleDeleteChip = (values: IClinics | any, type: string) => () => {
    if (type === 'status') {
      const newarray = selectStatus.filter((i: any) => {
        return i.id !== values.id;
      });
      setSelectStatus(newarray);
    }
    if (type !== 'status') {
      const newarray = selectClinics.filter((i: IClinics) => {
        return i.id !== values.id;
      });
      setSelectClinics(newarray);
    }
    if (selectStatus?.length === 1 || selectClinics?.length === 1) {
      getAppointmentFilter(
        false,
        false,
        type !== 'status' ? true : false,
        type === 'status' ? true : false,
      );
    }
  };

  const filterData = (status: any, clinicList: IClinics[], inputValue: any) => {
    setSelectClinics(clinicList);
    setSelectStatus(status);
    const arrayForChips = Object.keys(inputValue).map((key: string, index: number) => ({
      id: index,
      label: key,
      value: inputValue[key],
      type: (key === 'yes' || key === 'no') && 'isInsuranceUsed',
    }));
    const selectedOptions = arrayForChips.filter((i: FilterArray) => i.value === true);
    selectedOptions.length ? setSelectInsurance(selectedOptions) : setSelectInsurance(undefined);
    setShowFilter(false);
  };
  const handleDelete = (chipToDelete: FilterArray) => () => {
    if (selectInsurance?.filter((chip: any) => chip.id !== chipToDelete.id).length === 0) {
      setSelectInsurance(undefined);
    } else {
      const chip = selectInsurance?.filter((chip: any) => chip.id !== chipToDelete.id);
      setSelectInsurance(chip);
    }
  };

  const handleFilterPopupOpen = () => {
    setShowFilter(true);
    if (selectInsurance && selectInsurance.length > 0) {
      const newObj = Object.assign(
        { yes: false, no: false },
        ...selectInsurance.map((item) => ({ [item.label]: item.value })),
      );
      setFilterOption(newObj);
    }
  };

  const getClinic = async () => {
    if (clinicList.total > 0 && clinicList?.total !== moreClinics?.length) {
      setCounter((prev) => prev + 13);
    } else if (clinicList.total > 0 && clinicList?.total === moreClinics?.length) {
      return;
    }
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(clinicList.total > 0 &&
          clinicList?.total !== moreClinics?.length && {
          skip: counter,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      if (moreClinics.length === 0) {
        setMoreClinics(() => listClinic.data?.clinics);
      } else {
        const len = moreClinics.filter((o1: any) => {
          return listClinic.data.clinics.some((o2: any) => {
            return o1.id === o2.id; // return the ones with equal id
          });
        }).length;
        if (len === 0) {
          const newArray = moreClinics.map((a: any) => ({ ...a }));
          setMoreClinics(() => newArray.concat(listClinic.data?.clinics));
          setTotalClinicList((e: any) => [
            ...e,
            ...listClinic.data?.clinics.filter(
              (val: any) => !e.filter((val1: any) => val1.id === val.id).length,
            ),
          ]);
        } else {
          setMoreClinics(() => listClinic.data?.clinics);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getSearchedClinics = async () => {
    try {
      const limit = 13;
      const skip = 0;
      const queryParam: IClinicQueryParamOptions = {
        limit,
        skip,
        ...(searchCustomClinic &&
          searchCustomClinic.length >= 3 && {
          searchString: searchCustomClinic,
        }),
      };
      const listClinic: IClinicListResponse = await ClinicService.getClinicList(queryParam);
      setClinicList(() => listClinic.data);
      setMoreClinics(() => listClinic.data?.clinics);
      setTotalClinicList((e: any) => [
        ...e,
        ...listClinic.data?.clinics.filter(
          (val: any) => !e.filter((val1: any) => val1.id === val.id).length,
        ),
      ]);
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getAppointmentAnalytics = async (reset = false) => {
    setAppointmentAnalyticsLoader(true);
    try {
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const param = {
        startDate: reset
          ? moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.startDate,
        endDate: reset
          ? moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.endDate,
        ...(clinicFilter !== undefined &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && { clinicIds: selectCustomClinic }),
        limit: Constants.DASHBOARD_LIMIT,
      };
      const res = await AnalyticsService.getAppointmentAnalytics(param);
      setAppointmentAnalytics(res?.data);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setAppointmentAnalyticsLoader(false);
    }
  };

  const getGenderStatistics = async (reset = false) => {
    try {
      setGenderLoader(true);
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const param = {
        startDate: reset
          ? moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.startDate,
        endDate: reset
          ? moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.endDate,
        ...(clinicFilter !== undefined &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && { clinicIds: selectCustomClinic }),
        limit: Constants.DASHBOARD_LIMIT,
      };
      const res = await AnalyticsService.getAppointmentPatientGenderAnalytics(param);
      setGenderAnalytics(res?.data?.gender);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setGenderLoader(false);
    }
  };

  const getDashboardServices = async (reset = false) => {
    setServicesLoader(true);
    try {
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const param = {
        startDate: reset
          ? moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.startDate,
        endDate: reset
          ? moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD)
          : dateRange.endDate,
        ...(clinicFilter !== undefined &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && { clinicIds: selectCustomClinic }),
        limit: Constants.DASHBOARD_LIMIT,
      };
      const res: IDashBoardServiceRO = await AnalyticsService.getServiceBreakDown(param);
      setServicesData(res.data.services);
      setAgeData(res.data.ageTypes);
      setPatientData(res.data.patientTypes);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setServicesLoader(false);
    }
  };

  const getInsuranceBreakdownAnalytics = async (reset = false) => {
    setInsuranceLoader(true);
    try {
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const queryParam: IDashboardQueryParams = {
        startDate: reset ? moment(startDate?.toString()).format('YYYY-MM-DD') : dateRange.startDate,
        endDate: reset ? moment(endDate?.toString()).format('YYYY-MM-DD') : dateRange.endDate,
        ...(clinicFilter !== undefined &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && { clinicIds: selectCustomClinic }),
        limit: Constants.DOUGHNUT_CHART_LIMIT,
      };
      const response = await AnalyticsService.getInsuranceAnalytics(queryParam);
      setInsuranceAnalytics(response);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setInsuranceLoader(false);
    }
  };

  const getAppointmentList = async (reset = false, update = false) => {
    moreAppointments.length && appointmentList.total > moreAppointments.length
      ? setAppointmentLoader(true)
      : setShowLoader(true);
    try {
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const limit = Constants.DASHBOARD_LIMIT;
      const skip = 0;
      const listQuery: IAppointmentDetailsQueryParams = {
        limit,
        skip,
        ...(appointmentList.total > 0 &&
          appointmentList?.total > moreAppointments?.length && {
          skip: update ? 0 : tableCounter,
        }),
        ...(clinicID.length &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && {
          clinicIds: clinicID,
        }),
        startDate: reset ? moment(startDate?.toString()).format('YYYY-MM-DD') : dateRange.startDate,
        endDate: reset ? moment(endDate?.toString()).format('YYYY-MM-DD') : dateRange.endDate,
      };
      const response: IAppointmentDetailsListRO = await AnalyticsService.getAppintmentDetailsList(
        listQuery,
      );
      setAppointmentList(() => response?.data);
      if (update || reset) {
        setMoreAppoinments(() => response.data?.appointments);
      } else {
        setMoreAppoinments(() => [...moreAppointments, ...response.data?.appointments]);
      }

      appointmentList.total > moreAppointments?.length
        ? setTableCounter(tableCounter + Constants.DASHBOARD_LIMIT)
        : update
          ? setTableCounter(Constants.DASHBOARD_LIMIT)
          : setTableCounter(0);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      moreAppointments.length && appointmentList.total > moreAppointments.length
        ? setAppointmentLoader(false)
        : setShowLoader(false);
    }
  };

  const getAppointmentFilter = async (
    reset = false,
    clear = false,
    selectClinicList = false,
    statusList = false,
  ) => {
    if (searchValue && searchValue.length < 3) {
      setShowLoader(false);
    }
    // if (appointmentList.total > 0 && appointmentList.total > moreAppointments?.length) {
    //   reset ? 0 : setTableCounter((prev) => prev + 20);
    // } else if (appointmentList.total > 0 && appointmentList?.total === moreAppointments?.length) {
    //   return;
    // }
    setShowLoader(true);
    try {
      const { startDate, endDate } = Utilities.getDateFilter('yesterday');
      const limit = Constants.DASHBOARD_LIMIT;
      const skip = 0;
      const isInsuranceUsed =
        selectInsurance &&
        selectInsurance.length > 0 &&
        selectInsurance.filter((i: any) => i.type === 'isInsuranceUsed');
      const listQuery: IAppointmentDetailsQueryParams = {
        limit,
        skip,
        startDate: reset ? moment(startDate?.toString()).format('YYYY-MM-DD') : dateRange.startDate,
        endDate: reset ? moment(endDate?.toString()).format('YYYY-MM-DD') : dateRange.endDate,
        ...(searchValue &&
          !clear &&
          searchValue.length >= 3 && {
          searchString: searchValue,
        }),
        ...(clinicID.length &&
          !reset &&
          clinicFilter?.id !== 'all clinics' && {
          clinicIds: clinicID,
        }),
        ...(selectClinicList
          ? {}
          : selectClinics.length > 0 &&
            !reset && {
            clinicIds: selectClinics.map((clinic: any) => clinic?.id),
          }),
        ...(statusList
          ? {}
          : selectStatus.length &&
            !reset && {
            appointmentStatus: selectStatus.map((obj: any) => obj?.id),
          }),
        ...(isInsuranceUsed &&
          isInsuranceUsed.length && {
          isInsuranceUsed:
              isInsuranceUsed && isInsuranceUsed.map((i) => (i.label === 'yes' ? true : false)),
        }),
      };
      const response: IAppointmentDetailsListRO = await AnalyticsService.getAppintmentDetailsList(
        listQuery,
      );
      setAppointmentList(() => response?.data);
      setMoreAppoinments(() => response.data.appointments);
    } catch (error) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    } finally {
      setShowLoader(false);
    }
  };

  const handleReset = () => {
    const { startDate, endDate } = Utilities.getDateFilter('yesterday');
    setDateRange({
      startDate: moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
      endDate: moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    });
    setClinicFilter({ id: 'all clinics', name: 'All Clinics' });
    setDateRangeType({ id: 'yesterday', name: 'Yesterday' });
    getDashboardServices(true);
    getInsuranceBreakdownAnalytics(true);
    getGenderStatistics(true);
    getAppointmentAnalytics(true);
    getAppointmentList(true);
    getSearchedClinics();
    appointmentClinicList(true);
    setSelectCustomClinic([]);
    setClinicID([]);
    setApptClinics([]);
    setApptClinicsStore([]);
    setTableCounter(0);
    setMoreAppoinments([]);
    setSearchValue('');
  };

  const handleUpdate = () => {
    getDashboardServices();
    getInsuranceBreakdownAnalytics();
    getAppointmentAnalytics();
    getAppointmentList(false, true);
    appointmentClinicList();
    getGenderStatistics();
    clearAllFilter(false);
    setSearchValue('');
  };

  const appointmentClinicList = (reset = false) => {
    const options = totalClinicList.filter((d: IClinics) => selectCustomClinic.includes(d.id));
    const prevOptions = apptClinics.filter(
      (d: IClinics) =>
        selectCustomClinic.includes(d.id) &&
        !options.filter((opt: IClinics) => opt.id === d.id).length,
    );
    !reset && setApptClinics([...prevOptions, ...options]);
    !reset && setApptClinicsStore([...prevOptions, ...options]);
  };
  useEffect(() => {
    if (dateRangeType?.id !== 'custom') {
      const { startDate, endDate } = Utilities.getDateFilter(dateRangeType?.id);
      setDateRange({
        startDate: moment(startDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
        endDate: moment(endDate?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
      });
    }
  }, [dateRangeType?.id]);

  useEffect(() => {
    getInsuranceBreakdownAnalytics();
  }, []);

  // useEffect(() => {
  //   if (clinicFilter?.id === 'all clinics') {
  //     getClinicUserList();
  //   }
  // }, [showFilter]);

  useEffect(() => {
    if (moreClinics.length > 0 && !searchCustomClinic) {
      getClinic();
    }
  }, [moreClinics.length]);

  useEffect(() => {
    if (clinicFilter?.id === 'all clinics') {
      setApptClinics(moreClinics);
      setApptClinicsStore(moreClinics);
    }
  }, [moreClinics]);

  useEffect(() => {
    if (clinicFilter && clinicFilter?.name === 'Select Clinics') {
      handleDrawerOpen();
    } else {
      setSelectCustomClinic([]);
    }
  }, [clinicFilter]);

  useEffect(() => {
    if (
      searchValue?.length >= 3 ||
      searchValue?.length === 0 ||
      selectClinics?.length ||
      selectStatus?.length ||
      selectInsurance?.length
    )
      getAppointmentFilter();
  }, [
    searchValue,
    selectClinics,
    selectStatus,
    selectInsurance,
    selectInsurance?.length && JSON.stringify(selectInsurance),
  ]);

  useEffect(() => {
    getDashboardServices();
    getAppointmentAnalytics();
    getGenderStatistics();
  }, []);

  useEffect(() => {
    setServicesValue(servicesData.map((val: any) => val?.totalAppointments));
    setServicesName(servicesData.map((val: any) => val?.serviceName));
    setServicesPercentage(servicesData.map((val: any) => val?.percentageOfTotalAppointments));

    setInsuranceValue(insuranceAnalytics.data.map((val: any) => val?.totalAppointments));
    setInsuranceName(insuranceAnalytics.data.map((val: any) => val?.insuranceName));
    setInsurancePercentage(
      insuranceAnalytics.data.map((val: any) => val?.percentageOfTotalAppointments),
    );

    setAgeValue(ageData.map((val: any) => val?.percentageOfTotalAppointments));
    setPatientValue(patientData.map((val: any) => val?.percentageOfTotalAppointments));
  }, [servicesData, insuranceAnalytics]);

  useEffect(() => {
    getSearchedClinics();
    setCounter(0);
    if (searchCustomClinic.trim().length < 3 && moreClinics.length !== clinicList.total) {
      getClinic();
    }
  }, [searchCustomClinic.trim()]);

  return (
    <>
      {appointmentLoader && <Loader />}

      <InfiniteScroll
        dataLength={moreAppointments.length}
        next={getAppointmentList}
        hasMore={appointmentList.total > moreAppointments.length}
        loader={<h4></h4>}
        height={1000}
        className='scroller'
      >
        <Box
          sx={{
            margin: '44px 0px 32px 24px',
            display: 'flex',
            flexDirection: 'column',
            width: '76.5vw',
          }}
          style={{ gap: 40 }}
        >
          <Typography variant='h5' style={{ color: zeplinColor.Background0 }}>
            Dashboard
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{ gap: 28 }}>
            <CustomCard>
              <Box
                sx={{
                  padding: 24,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }} style={{ gap: 28 }}>
                  <SearchDropdown
                    searchList={Constants.clinicOptions}
                    width={'200px'}
                    onSelectHandler={handleClinicOptions}
                    onSearchHandler={() => {
                      return;
                    }}
                    multiple={false}
                    value={clinicFilter}
                    staticValue={true}
                    handleDrawerOpen={handleDrawerOpen}
                    label={'Select Clinics'}
                    fixed={true}
                  />

                  <SearchDropdown
                    searchList={Constants.rangeOptions}
                    width={'200px'}
                    label={'Select Date Range'}
                    onSelectHandler={handleRangeOptions}
                    onSearchHandler={() => {
                      return;
                    }}
                    multiple={false}
                    value={dateRangeType}
                    staticValue={true}
                    fixed={true}
                  />
                  {dateRangeType?.id === 'custom' ? (
                    <>
                      <CustomDatePicker
                        value={dateRange?.startDate}
                        handleDateChange={handleStartDateChange}
                        disablePast={false}
                        width={160}
                        label='Start Date (mm/dd/yyyy)'
                      />
                      <CustomDatePicker
                        value={dateRange?.endDate}
                        handleDateChange={handleEndDateChange}
                        disablePast={false}
                        fromFormatted={moment(dateRange?.startDate).format('YYYY-MM-DD')}
                        width={160}
                        label='End Date (mm/dd/yyyy)'
                      />
                    </>
                  ) : (
                    ''
                  )}
                </Box>
                <Box sx={{ display: 'flex' }} style={{ gap: 28 }}>
                  <Buttons
                    text={'UPDATE'}
                    width='105px'
                    disable={
                      (dateRangeType?.id === 'custom' &&
                        Object.values(dateRange).some((val) => val === '')) ||
                      dateRange?.startDate > dateRange?.endDate ||
                      (clinicFilter &&
                        clinicFilter?.name === 'Select Clinics' &&
                        selectCustomClinic.length === 0)
                        ? true
                        : false
                    }
                    onClick={handleUpdate}
                    bgWhite={false}
                  />
                  <Buttons
                    text={'RESET'}
                    width='94px'
                    disable={false}
                    onClick={handleReset}
                    bgWhite={false}
                  />
                </Box>
              </Box>
              {chipData && selectCustomClinic.length && clinicFilter.name !== 'All Clinics' ? (
                <Typography
                  style={{ marginLeft: '32px', position: 'relative', bottom: '12px' }}
                  color={chipData ? 'primary' : 'textSecondary'}
                  variant='caption'
                >
                  {selectCustomClinic.length < 10 ? '0' : ''}
                  {chipData} Clinics Selected
                </Typography>
              ) : null}
            </CustomCard>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gridColumnGap: 24 }}>
              <CustomCard>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 141,
                    position: 'relative',
                  }}
                >
                  {appointmentAnalyticLoader && <Loader small />}

                  <Box sx={{ minHeight: 100 }}>
                    <Typography style={{ padding: 24 }} variant='subtitle1'>
                      Total Appointments
                    </Typography>
                    {!appointmentAnalyticLoader && (
                      <Typography style={{ paddingLeft: 24, paddingBottom: 24 }} variant='h4'>
                        {appointmentAnalytics?.totalAppointments}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </CustomCard>
              <CustomCard>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 141,
                    position: 'relative',
                  }}
                >
                  {appointmentAnalyticLoader && <Loader small />}
                  <Box sx={{ minHeight: 100 }}>
                    <Typography style={{ padding: 24, paddingBottom: 8 }} variant='subtitle1'>
                      Appointments Breakdown
                    </Typography>
                    {!appointmentAnalyticLoader && (
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>
                          <Typography
                            color='textSecondary'
                            style={{ paddingLeft: 24 }}
                            variant='body2'
                          >
                            Booked
                          </Typography>
                          <Typography
                            color='primary'
                            style={{ paddingLeft: 24, paddingBottom: 24 }}
                            variant='h4'
                          >
                            {appointmentAnalytics?.appointmentsStatusCount?.complete}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            color='textSecondary'
                            style={{ paddingLeft: 24 }}
                            variant='body2'
                          >
                            Failed
                          </Typography>
                          <Typography
                            color='error'
                            style={{ paddingLeft: 24, paddingBottom: 24 }}
                            variant='h4'
                          >
                            {appointmentAnalytics?.appointmentsStatusCount?.broken}
                          </Typography>
                        </Box>
                      </span>
                    )}
                  </Box>
                </Box>
              </CustomCard>
              <CustomCard>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 141,
                    position: 'relative',
                  }}
                >
                  {appointmentAnalyticLoader && <Loader small />}

                  <Box sx={{ minHeight: 100 }}>
                    <Typography style={{ padding: 24 }} variant='subtitle1'>
                      Average Lead Time
                    </Typography>
                    {!appointmentAnalyticLoader && (
                      <Typography style={{ paddingLeft: 24, paddingBottom: 24 }} variant='h4'>
                        {appointmentAnalytics?.averageLeadTimeInDays === 0
                          ? '-'
                          : appointmentAnalytics?.averageLeadTimeInDays + ' days'}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </CustomCard>
            </Box>
            <CustomCard>
              <Box
                sx={{
                  padding: '32px 24px',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 572,
                  position: 'relative',
                }}
                style={{ gap: !servicesValue ? 0 : 32 }}
              >
                {servicesLoader && <Loader small />}
                <Typography variant='h6'>Services Breakdown</Typography>

                {!servicesValue?.length && !servicesLoader ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EmptyState
                      path={``}
                      text={''}
                      subText={'No data to display'}
                      buttonText={''}
                      showAddButton={false}
                      image={false}
                      removeImage={true}
                    />
                  </Box>
                ) : !servicesLoader ? (
                  <BarChart
                    value={[...servicesValue]}
                    name={[...servicesName]}
                    percentage={[...servicesPercentage]}
                  />
                ) : (
                  ''
                )}
              </Box>
            </CustomCard>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2,1fr)',
                gridTemplateRows: 'repeat(2,1fr)',
                gridGap: 24,
              }}
            >
              <CustomCard>
                <Box
                  sx={{
                    minHeight: 141,
                    position: 'relative',
                  }}
                  style={{ gap: !ageValue ? 0 : 24 }}
                >
                  {servicesLoader && <Loader small />}
                  <Typography style={{ padding: '24px 24px 0px 24px' }} variant='subtitle1'>
                    Age Group
                  </Typography>
                  {!ageValue.length && !servicesLoader ? (
                    <EmptyState
                      path={``}
                      text={''}
                      subText={'No data to display'}
                      buttonText={''}
                      showAddButton={false}
                      image={false}
                      removeImage={true}
                    />
                  ) : (
                    ageValue &&
                    !servicesLoader && (
                      <span style={{ paddingLeft: 24, paddingBottom: 24 }}>
                        <CustomBars
                          value={ageValue[0]}
                          label1={'Adult'}
                          label2={'Child'}
                          color1={dashboardColorTheme[11].color}
                          color2={dashboardColorTheme[3].color}
                          percentage1={ageValue[0]}
                          percentage2={ageValue[1]}
                        />
                      </span>
                    )
                  )}
                </Box>
              </CustomCard>
              <CustomCard>
                <Box
                  sx={{
                    minHeight: 141,
                    position: 'relative',
                  }}
                  style={{ gap: !patientValue ? 0 : 24 }}
                >
                  {servicesLoader && <Loader small />}
                  <Typography style={{ padding: '24px 24px 0px 24px' }} variant='subtitle1'>
                    Patient Type
                  </Typography>

                  {!patientValue.length && !servicesLoader ? (
                    <EmptyState
                      path={``}
                      text={''}
                      subText={'No data to display'}
                      buttonText={''}
                      showAddButton={false}
                      image={false}
                      removeImage={true}
                    />
                  ) : (
                    patientValue &&
                    !servicesLoader && (
                      <span style={{ paddingLeft: 24, paddingBottom: 24 }}>
                        <CustomBars
                          value={patientValue[0]}
                          label1={'Existing'}
                          label2={'New'}
                          color1={dashboardColorTheme[0].color}
                          color2={dashboardColorTheme[2].color}
                          percentage1={patientValue[0]}
                          percentage2={patientValue[1]}
                        />
                      </span>
                    )
                  )}
                </Box>
              </CustomCard>
              <CustomCard>
                <Box
                  sx={{
                    minHeight: 141,
                    position: 'relative',
                  }}
                >
                  {appointmentAnalyticLoader && <Loader small />}
                  <Typography style={{ padding: '24px 24px 0px 24px' }} variant='subtitle1'>
                    Appointment Status
                  </Typography>
                  {!appointmentAnalytics?.appointmentStatusInPercentage?.complete &&
                  !appointmentAnalytics?.appointmentStatusInPercentage?.broken ? (
                      <EmptyState
                        path={``}
                        text={''}
                        subText={'No data to display'}
                        buttonText={''}
                        showAddButton={false}
                        image={false}
                        removeImage={true}
                      />
                    ) : (
                      <span style={{ paddingLeft: 24, paddingBottom: 24 }}>
                        <CustomBars
                          value={appointmentAnalytics?.appointmentStatusInPercentage?.complete}
                          label1={'Success'}
                          label2={'Failed'}
                          color1={dashboardColorTheme[18].color}
                          color2={dashboardColorTheme[2].color}
                          percentage1={appointmentAnalytics?.appointmentStatusInPercentage?.complete}
                          percentage2={appointmentAnalytics?.appointmentStatusInPercentage?.broken}
                        />
                      </span>
                    )}
                </Box>
              </CustomCard>
              <CustomCard>
                <Box
                  sx={{
                    minHeight: 141,
                    position: 'relative',
                  }}
                >
                  {genderLoader && <Loader small />}
                  <Typography style={{ padding: '24px 24px 0px 24px' }} variant='subtitle1'>
                    Gender
                  </Typography>
                  {genderAnalytics?.male === 0 &&
                  genderAnalytics?.female === 0 &&
                  genderAnalytics?.other === 0 ? (
                      <EmptyState
                        path={``}
                        text={''}
                        subText={'No data to display'}
                        buttonText={''}
                        showAddButton={false}
                        image={false}
                        removeImage={true}
                      />
                    ) : (
                      <span style={{ paddingLeft: 24, paddingBottom: 24 }}>
                        {genderAnalytics && (
                          <CustomBars
                            value={genderAnalytics?.male}
                            percentage1={genderAnalytics?.male}
                            percentage2={genderAnalytics?.female}
                            percentage3={genderAnalytics?.other}
                            color1={dashboardColorTheme[20].color}
                            color2={dashboardColorTheme[21].color}
                            color3={dashboardColorTheme[22].color}
                            label1={'Male'}
                            label2={'Female'}
                            label3={'Others'}
                          />
                        )}
                      </span>
                    )}
                </Box>
              </CustomCard>
            </Box>
            <Box>
              <CustomCard>
                <Box
                  sx={{
                    padding: '32px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 472,
                    position: 'relative',
                  }}
                  style={{ gap: !insuranceValue ? 0 : 32 }}
                >
                  <Typography variant='h6'>Insurance Providers Breakdown</Typography>
                  {insuranceLoader && <Loader small forTable />}
                  {!insuranceValue?.length && !insuranceLoader ? (
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EmptyState
                        path={``}
                        text={''}
                        subText={'No data to display'}
                        buttonText={''}
                        showAddButton={false}
                        image={false}
                        removeImage={true}
                      />
                    </Box>
                  ) : (
                    <DoughnutChart
                      value={[...insuranceValue]}
                      name={[...insuranceName]}
                      percentage={[...insurancePercentage]}
                    />
                  )}{' '}
                </Box>
              </CustomCard>
            </Box>
          </Box>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <div style={{ margin: '0px 0px 0px 0px' }}>
              <Toolbar
                style={{ color: 'primary', padding: '0 0 0 0', position: 'inherit', width: '100%' }}
              >
                <Typography variant='h6' component='div' className='staffTitle' color='inherit'>
                  Appointment Details
                </Typography>
                <div style={{ display: 'flex', gap: 16 }}>
                  <SearchBar
                    onSearchHandler={onSearch}
                    searchValue={searchValue}
                    clear={getAppointmentFilter}
                    placeholderText={'Search by Patient Name'}
                    setTableCounter={setTableCounter}
                  />
                  <div style={{ border: `1px solid ${zeplinColor.Primary}` }} className='filterDiv'>
                    {showLoader && <Loader small forTable />}
                    <Badge
                      overlap='rectangular'
                      color='error'
                      variant='dot'
                      invisible={
                        selectClinics &&
                        selectClinics.length === 0 &&
                        selectStatus &&
                        selectStatus.length === 0 &&
                        !selectInsurance
                      }
                    >
                      <FilterAltOutlinedIcon
                        onClick={handleFilterPopupOpen}
                        sx={{
                          color: zeplinColor.Primary,
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          margin: '8px 8px 0px 8px',
                        }}
                      ></FilterAltOutlinedIcon>
                    </Badge>
                    {showFilter && (
                      <FilterAppointment
                        open={showFilter}
                        onClick={closeFilters}
                        onFilterClick={filterData}
                        status={Constants.APPOINTMENT_STATUSES}
                        selectedStatus={selectStatus}
                        selectInsurance={filterOptions}
                        // searchStatus={onSearchStatus}
                        clinicLists={
                          clinicFilter?.id === 'all clinics' || !selectCustomClinic?.length
                            ? clinicList.clinics
                            : apptClinics
                        }
                        total={
                          clinicFilter?.id === 'all clinics' || !selectCustomClinic?.length
                            ? clinicList.total
                            : apptClinics.length
                        }
                        next={getClinicUserList}
                        // searchClinic={onSearchClinics}
                        // clinicFilter={clinicFilter}
                        selectedClinics={selectClinics}
                      />
                    )}
                  </div>
                </div>
              </Toolbar>
            </div>
            <div style={{ marginLeft: '28px', width: '76.5vw' }}>
              {!!selectStatus &&
                selectStatus?.map((i: any) => {
                  return (
                    <Chip
                      style={{ textTransform: 'capitalize', marginRight: 8, marginTop: 2 }}
                      key={i.id}
                      label={i.name}
                      onDelete={handleDeleteChip(i, 'status')}
                    />
                  );
                })}
              {!!selectClinics &&
                selectClinics?.map((i: any) => {
                  return (
                    <Chip
                      style={{ textTransform: 'capitalize', marginRight: 8, marginTop: 2 }}
                      key={i.id}
                      label={i.name}
                      onDelete={handleDeleteChip(i, 'clinic')}
                    />
                  );
                })}
              {selectInsurance &&
                selectInsurance?.map((data: FilterArray) => {
                  return (
                    <Chip
                      style={{ textTransform: 'capitalize', marginRight: 8, marginTop: 2 }}
                      key={data.id}
                      label={data.label === 'yes' ? 'yes' : data.label}
                      onDelete={handleDelete(data)}
                    />
                  );
                })}
              {(selectClinics?.length > 0 ||
                selectStatus?.length > 0 ||
                selectInsurance?.length) && (
                <Button
                  onClick={() => {
                    clearAllFilter(true);
                  }}
                  variant='text'
                  style={{ color: zeplinColor.Primary }}
                >
                  Clear all
                </Button>
              )}
            </div>
            <ListAppointmentDetails appointmentList={moreAppointments} showLoader={showLoader} />
            {
              <SideDrawer
                setSideDrawerOpen={setSideDrawerOpen}
                sideDrawerOpen={sideDrawerOpen}
                clinicListData={clinicList}
                onSearch={onSearchClinic}
                selectCustomClinic={selectCustomClinic}
                onSelectClinics={onSelectClinics}
                disableSave={false}
                setChipData={setChipData}
                moreClinics={moreClinics}
                getClinics={getClinic}
                setClinicID={setClinicID}
                getSearchedClinics={getSearchedClinics}
                searchLength={searchCustomClinic.length}
              />
            }
            {showSnackbar && (
              <SnackBar
                message={showSnackbarMessage}
                show={showSnackbar}
                setShow={setShowSnackbar}
              />
            )}
          </Box>
        </Box>
      </InfiniteScroll>
    </>
  );
};

export default DashBoard;
