/*
 * File: FormCard.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 1:12:08 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 18th August 2022 8:29:18 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
// import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { zeplinColor } from '../../../../../theme';
import './FormCard.css';
type formProps = {
  children: React.ReactNode;
};
function FormCard({ children }: formProps) {
  return (
    <div
      className='rectangle'
      style={{ borderColor: zeplinColor.Background90, backgroundColor: zeplinColor.Surface }}
    >
      {children}
    </div>
  );
}

export default FormCard;
