/*
 * File: MapOperatoriesCard.tsx
 * Project: mint-portal
 * File Created: Monday, 29th August 2022 9:29:51 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 11th October 2022 8:44:00 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { zeplinColor } from '../../../../../theme';
import './MapOperatoriesCard.css';
import { Typography, makeStyles } from '@material-ui/core';
import { Box, Tooltip } from '@mui/material';
import {
  IServiceOperatoriesDetails,
  ISaveOperatoriesPayload,
  IOperatoriesDetails,
} from '../../../../../model';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

type CardProps = {
  serviceDetails: IServiceOperatoriesDetails;
  selectedOperatories: ISaveOperatoriesPayload[];
  onSelectOperatories: React.Dispatch<ISaveOperatoriesPayload[]>;
  onTrigger: React.Dispatch<number>;
  isViewMode: boolean;
};
const useStyles = makeStyles(() => ({
  box: {
    width: 161,
    height: 66,
    border: `1px Solid ${zeplinColor.Background90}`,
    borderRadius: 6,
    padding: 14,
  },
  selected: {
    width: 161,
    height: 66,
    borderRadius: 6,
    padding: 14,
    border: `1px Solid ${zeplinColor.Primary}`,
    backgroundColor: zeplinColor.Primary50,
  },
  cardContentSection: {
    display: 'flex',
    gap: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ellipsesText: {
    width: 129,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  disableState: {
    width: 161,
    height: 66,
    borderRadius: 6,
    padding: 14,
    border: `1px Solid ${zeplinColor.Background97}`,
    backgroundColor: zeplinColor.Background90,
  },
}));

const MapOperatoriesCard = ({
  serviceDetails,
  selectedOperatories,
  onSelectOperatories,
  onTrigger,
  isViewMode = false,
}: CardProps) => {
  const classes = useStyles();
  const [selectedVals, setVals] = React.useState<IServiceOperatoriesDetails>();

  React.useEffect(() => {
    if (selectedOperatories && selectedOperatories.length > 0) {
      selectedOperatories?.map((selectedOps: ISaveOperatoriesPayload) => {
        if (selectedOps.serviceId === serviceDetails.id) {
          serviceDetails.operatories.map((serviceOps: IOperatoriesDetails) => {
            if (selectedOps.operatoryNumbers.includes(serviceOps.operatoryNumber)) {
              serviceOps.isMapped = true;
            } else {
              serviceOps.isMapped = false;
            }
          });
        }
      });
      setVals(serviceDetails);
    }
  }, []);

  React.useEffect(() => {
    mapServiceObjtoSelectOper();
  }, []);

  const mapServiceObjtoSelectOper = () => {
    if (
      selectedOperatories.findIndex(
        (i: ISaveOperatoriesPayload) => i.serviceId === serviceDetails.id,
      ) === -1
    ) {
      const filteredOperatories = serviceDetails.operatories
        .filter((j: IOperatoriesDetails) => j.isMapped)
        .map((i: IOperatoriesDetails) => i.operatoryNumber);
      selectedOperatories.push({
        serviceId: serviceDetails.id,
        operatoryNumbers: filteredOperatories,
      });
    }
  };

  const handleClick = (e: any, selected: any) => {
    selected.isMapped ? (selected.isMapped = false) : (selected.isMapped = true);
    if (
      selectedOperatories.filter(
        (selectedObj: ISaveOperatoriesPayload) => selectedObj.serviceId === serviceDetails.id,
      ).length === 0
    ) {
      onSelectOperatories([
        ...selectedOperatories,
        {
          serviceId: serviceDetails.id,
          operatoryNumbers: [selected.operatoryNumber],
        },
      ]);
    } else {
      selectedOperatories.map((operatoryObj: ISaveOperatoriesPayload) => {
        if (operatoryObj.serviceId === serviceDetails.id) {
          operatoryObj.operatoryNumbers = !operatoryObj.operatoryNumbers.includes(
            selected.operatoryNumber,
          )
            ? [...operatoryObj.operatoryNumbers, selected.operatoryNumber]
            : operatoryObj.operatoryNumbers.filter((opNum) => opNum != selected.operatoryNumber);
          onTrigger(operatoryObj.operatoryNumbers.length);
        }
      });
      onSelectOperatories(selectedOperatories);
    }
  };

  return (
    <>
      <section>
        <Card
          sx={{
            border: `1px Solid ${zeplinColor.Background90}`,
            borderRadius: 8,
          }}
          elevation={0}
        >
          <CardContent className='Card-header'>
            <Typography variant='h6'>{serviceDetails?.name}</Typography>
            <section style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <section style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                <PersonOutlineOutlinedIcon
                  fontSize='small'
                  style={{ color: zeplinColor.Background60 }}
                />
                <Typography
                  variant='body2'
                  style={{ color: zeplinColor.Background60, textTransform: 'capitalize' }}
                >
                  {serviceDetails.ageType}
                </Typography>
              </section>
              <section style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                <AssignmentTurnedInOutlinedIcon
                  fontSize='small'
                  style={{ color: zeplinColor.Background60 }}
                />
                <Typography
                  variant='body2'
                  style={{ color: zeplinColor.Background60, textTransform: 'capitalize' }}
                >
                  {serviceDetails.patientType}
                </Typography>
              </section>
              <section style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                <AccessTimeOutlinedIcon
                  fontSize='small'
                  style={{ color: zeplinColor.Background60 }}
                />
                <Typography
                  variant='body2'
                  style={{ color: zeplinColor.Background60, textTransform: 'capitalize' }}
                >
                  {serviceDetails.duration} min
                </Typography>
              </section>
            </section>
          </CardContent>
          <CardContent className='CardContent' id={serviceDetails?.id}>
            <section className={classes.cardContentSection}>
              {selectedVals && selectedVals?.operatories
                ? selectedVals?.operatories.map((operatories: any) => {
                  return (
                    <Box
                      key={operatories.operatoryNumber}
                      className={
                        isViewMode && operatories.isMapped
                          ? classes.disableState
                          : operatories.isMapped
                            ? classes.selected
                            : classes.box
                      }
                      onClick={(e) => (isViewMode ? '' : handleClick(e, operatories))}
                    >
                      <Tooltip title={operatories.operatoryName} placement='bottom-end'>
                        <Typography variant='body2' className={classes.ellipsesText}>
                          {operatories.operatoryName}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={operatories.operatoryCode} placement='bottom-end'>
                        <Typography variant='body2' className={classes.ellipsesText}>
                          {operatories.operatoryCode}
                        </Typography>
                      </Tooltip>
                    </Box>
                  );
                })
                : serviceDetails?.operatories.map((operatories: any) => {
                  return (
                    <Box
                      key={operatories.operatoryNumber}
                      className={
                        isViewMode && operatories.isMapped
                          ? classes.disableState
                          : operatories.isMapped
                            ? classes.selected
                            : classes.box
                      }
                      onClick={(e) => (isViewMode ? '' : handleClick(e, operatories))}
                    >
                      <Tooltip title={operatories.operatoryName} placement='bottom-end'>
                        <Typography variant='body2' className={classes.ellipsesText}>
                          {operatories.operatoryName}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={operatories.operatoryCode} placement='bottom-end'>
                        <Typography variant='body2' className={classes.ellipsesText}>
                          {operatories.operatoryCode}
                        </Typography>
                      </Tooltip>
                    </Box>
                  );
                })}
            </section>
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default MapOperatoriesCard;
