/*
 * File: zeplinColor.tsx
 * Project: mint-portal
 * File Created: Tuesday, 31st May 2022 12:30:07 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 16th August 2023 12:07:54 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
enum zeplinColor {
  Primary = '#26a626',
  Primary50 = '#e7f6e6',
  Primary10 = '#26a6261a',
  Error = '#cc0d00',
  Error50 = '#fcefee',
  Background = '#fcfdfc',
  Surface = '#FFFFFF',
  Background50 = '#798679',
  Background0 = '#000000',
  Background05 = '#00000080',
  Background60 = '#949e94',
  Background90 = '#e5e7e5',
  Background97 = '#f9f9f9',
  Background70 = '#afb7af',
  Background80 = '#c9cfc9',
  Background30 = ' #495049',
  Background95 = '#F2F3F2',
  LightOrange = '#f7aa54',
  Blue = '#359dd9',
  Blue10 = '#359dd91a',
  Blue01 = '#3882ed1a',
  BlueLink = '#3882ed',
  Response = '#f7aa54',
  Response10 = '#f7aa541a',
}

export default zeplinColor;

export const dashboardColorTheme = [
  { id: 1, color: '#192b5b' },
  { id: 2, color: '#763773' },
  { id: 3, color: '#c64b66' },
  { id: 4, color: '#ef8346' },
  { id: 5, color: '#f2a93c' },
  { id: 6, color: '#ffebc6' },
  { id: 7, color: '#c9f299' },
  { id: 8, color: '#8ab26e' },
  { id: 9, color: '#408c81' },
  { id: 10, color: '#355f71' },
  { id: 11, color: '#274392' },
  { id: 12, color: '#a450a0' },
  { id: 13, color: '#ff5e81' },
  { id: 14, color: '#ffa36f' },
  { id: 15, color: '#b69d78' },
  { id: 16, color: '#dbc8a6' },
  { id: 17, color: '#92cc4e' },
  { id: 18, color: '#2f7200' },
  { id: 19, color: '#5fcdbd' },
  { id: 20, color: '#64a8c5' },
  { id: 21, color: '#453c67' },
  { id: 22, color: '#6d67e4' },
  { id: 23, color: '#46c2cb' },
];
