import './OngoingBlockout.css';

import { Constants } from '../../../../utilities/constants';
import CustomCard from '../../../shared/CustomCard';
import { IOngoingBlockoutDetails } from '../../../../model';
/*
 * File: OngoingBlockout.tsx
 * Project: mint-portal
 * File Created: Wednesday, 14th September 2022 3:09:58 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th September 2022 1:00:51 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

type props = {
  isOngoingBlockout: IOngoingBlockoutDetails;
};
const OnGoingBlockout = ({ isOngoingBlockout }: props) => {
  return (
    <CustomCard width={'76.5vw'} margin={'0px 0px 0px 28px'}>
      <section className='ongoing' style={{ margin: '8px 16px' }}>
        <Typography variant='h6'>Ongoing Blockout</Typography>
        <section className='ongoing' style={{ marginTop: 32 }}>
          <Typography  data-testid='block-title' variant='body1' style={{ textTransform: 'capitalize' }}>
            {isOngoingBlockout?.title} | {isOngoingBlockout?.type}
          </Typography>
          <Typography variant='body1'>
            {moment(isOngoingBlockout?.startDate).format(Constants.MOMENT_FORMATS.DATE) +
              ' ' +
              moment(isOngoingBlockout?.startTime, 'HH:mm:ss').format(
                Constants.MOMENT_FORMATS.TIME,
              )}{' '}
            TO
            {' ' +
              moment(isOngoingBlockout?.endDate).format(Constants.MOMENT_FORMATS.DATE) +
              ' ' +
              moment(isOngoingBlockout?.endTime, 'HH:mm:ss').format(Constants.MOMENT_FORMATS.TIME)}
          </Typography>
        </section>
      </section>
    </CustomCard>
  );
};
export default OnGoingBlockout;
