/*
 * File: SideBarData.tsx
 * Project: mint-portal
 * File Created: Monday, 20th June 2022 12:44:10 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 9th June 2023 7:16:44 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { RoleIds } from '../../../utilities/roles';

interface ISidebarData {
  title: string;
  path: string;
  icon: JSX.Element;
  roleAccess: RoleIds[];
}
export const SidebarData: ISidebarData[] = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <HomeOutlinedIcon />,
    roleAccess: [
      RoleIds.DSO_ADMIN,
      RoleIds.CLINIC_ADMIN,
      RoleIds.FRONT_OFFICE_MANAGER,
      RoleIds.MARKETING_ADMIN,
    ],
  },
  {
    title: 'Clinics',
    path: '/clinics',
    icon: <BusinessOutlinedIcon />,
    roleAccess: [
      RoleIds.DSO_ADMIN,
      RoleIds.CLINIC_ADMIN,
      RoleIds.FRONT_OFFICE_MANAGER,
      RoleIds.MARKETING_ADMIN,
    ],
  },
  {
    title: 'Services',
    path: '/services',
    icon: <MedicalServicesOutlinedIcon />,
    roleAccess: [
      RoleIds.DSO_ADMIN,
      RoleIds.CLINIC_ADMIN,
      RoleIds.FRONT_OFFICE_MANAGER,
      RoleIds.MARKETING_ADMIN,
    ],
  },
  {
    title: 'Staff',
    path: '/staff',
    icon: <GroupAddOutlinedIcon />,
    roleAccess: [RoleIds.DSO_ADMIN, RoleIds.CLINIC_ADMIN, RoleIds.FRONT_OFFICE_MANAGER],
  },
  {
    title: 'Patients',
    path: '/patient',
    icon: <GroupsOutlinedIcon />,
    roleAccess: [RoleIds.DSO_ADMIN, RoleIds.CLINIC_ADMIN, RoleIds.FRONT_OFFICE_MANAGER],
  },
  {
    title: 'Loyalty Program',
    path: '/rewards',
    icon: <RedeemOutlinedIcon />,
    roleAccess: [
      RoleIds.DSO_ADMIN,
      RoleIds.CLINIC_ADMIN,
      RoleIds.MARKETING_ADMIN,
      RoleIds.FRONT_OFFICE_MANAGER,
    ],
  },
  {
    title: 'Insurances',
    path: '/insurances',
    icon: <FeaturedPlayListOutlinedIcon />,
    roleAccess: [
      RoleIds.DSO_ADMIN,
      RoleIds.CLINIC_ADMIN,
      RoleIds.MARKETING_ADMIN,
      RoleIds.FRONT_OFFICE_MANAGER,
    ],
  },
  {
    title: 'Manage',
    path: '/manage',
    icon: <SettingsOutlinedIcon />,
    roleAccess: [RoleIds.DSO_ADMIN, RoleIds.MARKETING_ADMIN, RoleIds.CLINIC_ADMIN],
  },
];

export default SidebarData;
