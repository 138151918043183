/*
 * File: PasswordField.tsx
 * Project: mint-portal
 * File Created: Thursday, 23rd June 2022 5:33:47 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 8th February 2023 9:22:57 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { zeplinColor } from '../../theme';

interface State {
  password: string;
  showPassword: boolean;
}

const PasswordField = styled(TextField)({
  '& .MuiFormLabel-root': {
    padding: '0px 0px 0px 20px',
  },

  '& .MuiTextField-root': {
    '& label': {
      color: zeplinColor.Primary,
    },
  },
  '& .MuiInputBase-input': {
    padding: '0px 0px 16px 10px',
  },
  '& label.Mui-focused': {
    color: zeplinColor.Primary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Primary,
  },
  '& .Mui-error.MuiInput-underline:after': {
    borderBottomColor: zeplinColor.Error,
  },
  '& label.Mui-focused.Mui-error': {
    color: zeplinColor.Error,
  },
  '& .MuiInputAdornment-root': {
    paddingBottom: '12px',
  },'.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root':{
    paddingLeft:'6px',
  },
  '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated':{
    paddingLeft: '6px',
    marginLeft: '5px'
  }

});

export default function Password(props: {
  label: string;
  value: string;
  name: string;
  iconChanged: any;
  error: boolean;
  width?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => Promise<void>;
  inputValue?:  {  email: string; password: string;};
}) {
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter'&&  (props.inputValue?.email !== '' && props.inputValue?.password !== '')) {
      event.preventDefault();
      props.onEnter &&  props.onEnter()
    }
  };
  return (
    <PasswordField
      label={props.label}
      sx={{ m: 1, width: props.width, backgroundColor: zeplinColor.Background }}
      type={values.showPassword ? 'text' : 'password'}
      value={props.value}
      name={props.name}
      error={props.error}
      onChange={props.onChange}
      onKeyDown={keyDownHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {props.iconChanged ? (
                <WarningAmberIcon sx={{ color: zeplinColor.Error }} />
              ) : values.showPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant='standard'
    />
  );
}


