/*
 * File: AddDsoBlockout.tsx
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 9:10:51 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 14th March 2023 7:39:36 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { useState, useEffect, FC } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Toolbar,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { Typography, Switch, makeStyles } from '@material-ui/core';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import { zeplinColor } from '../../../../../theme';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../shared/Buttons';
import './AddDsoBlockout.css';
import Field from '../../../../shared/CustomTextField';
import { getTime, formatTime } from '../../../../../utilities/getTime';
import CustomDatePicker from '../../../../shared/CustomDatePicker';
import moment, { Moment } from 'moment';
import { Constants } from '../../../../../utilities/constants';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import Loader from '../../../../shared/Loader/Loader';
import { IOngoingBlockoutDetails, IDSOBlockout } from '../../../../../model';
import { ManageService } from '../../../../../service/manage.service';
import { DSOCard } from '../../../../../pages/Manage/ManageCardConstants';
import getPermissionForModule from '../../../../../utilities/getPermission';
import EmptyState from '../../../../shared/EmptyState/EmptyState';

type editProps = {
  edit?: boolean;
  dsoBlockoutDetail?: IOngoingBlockoutDetails;
  setUpdatePayload?: React.Dispatch<React.SetStateAction<IDSOBlockout | undefined>>;
  updateLoader?: boolean;
  setEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(() => ({
  inputLabelRoot: {
    '&.MuiFormLabel-root': {
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${zeplinColor.Primary}`,
      },
    },
  },
  selectBorder: {
    '&:after': {
      borderBottom: `1.5px solid ${zeplinColor.Primary} !important`,
    },
  },
}));

const AddDsoBlockout: FC<editProps> = ({
  edit,
  dsoBlockoutDetail,
  setUpdatePayload,
  updateLoader,
  setEditBtn,
}) => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const classes = useStyles();
  const time = getTime();
  const navigate = useNavigate();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: '/manage/dso-blockouts', label: 'DSO Blockouts' },
  ];
  const [blockoutTitle, setBlockoutTitle] = useState<string>(dsoBlockoutDetail?.title || '');

  const [isAllDay, setIsAllDay] = useState<boolean>(dsoBlockoutDetail?.allDay || false);
  const [startDate, setStartDate] = React.useState<Moment | null | string>(
    (dsoBlockoutDetail &&
      dsoBlockoutDetail.startDate &&
      moment(dsoBlockoutDetail.startDate, Constants.MOMENT_FORMATS.YYYY_MM_DD).format(
        Constants.MOMENT_FORMATS.DATE,
      )) ||
      null,
  );
  const [startTime, setStartTime] = React.useState<string>(
    dsoBlockoutDetail?.startTime
      ? (moment(dsoBlockoutDetail.startTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM))
      : '',
  );
  const [endDate, setEndDate] = React.useState<Moment | null | string>(
    (dsoBlockoutDetail &&
      dsoBlockoutDetail.endDate &&
      moment(dsoBlockoutDetail.endDate, Constants.MOMENT_FORMATS.YYYY_MM_DD).format(
        Constants.MOMENT_FORMATS.DATE,
      )) ||
      null,
  );
  const [endTime, setEndTime] = React.useState<string>(
    dsoBlockoutDetail?.endTime
      ? dsoBlockoutDetail?.allDay
        ? '00:00'
        :(moment(dsoBlockoutDetail.endTime, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM))
      : '',
  );

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState<string>('');
  const [enableCreateBtn, setEnableCreateBtn] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [checkCurrentEndDate, setCurrentEndDateChecker] = useState(false);
  const [checkCurrentStartDate, setCurrentStartDateChecker] = useState(false);

  const handleBlockoutTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlockoutTitle(event.target.value);
  };

  const handleIsAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(() => event.target.checked);
  };
  const handleStartDateChange = (newValue: any) => {
    setStartDate(newValue);
    setCurrentStartDateChecker(
      currentDate === moment(newValue).format(Constants.MOMENT_FORMATS.YYYY_MM_DD) ? true : false,
    );
  };
  const handleStartTimeChange = (event: SelectChangeEvent<string>) => {
    setStartTime(moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));
  };
  const handleEndDateChange = (newValue: any) => {
    setEndDate(newValue);
    setCurrentEndDateChecker(
      currentDate === moment(newValue).format(Constants.MOMENT_FORMATS.YYYY_MM_DD) ? true : false,
    );
  };
  const handleEndTimeChange = (event: SelectChangeEvent<string>) => {
    setEndTime(moment(event.target.value, [Constants.MOMENT_FORMATS.TIME]).format(Constants.MOMENT_FORMATS.HH_MM));

  };

  useEffect(() => {
    getCurrentTime();
  }, []);

  useEffect(() => {
    getCurrentTime();
    validateBlockoutPayload();
    if (checkCurrentStartDate && startTime < currentTime) {
      setStartTime('');
    }
    if (checkCurrentEndDate && endTime < currentTime) {
      setEndTime('');
    }
    if (edit) {
      setUpdatePayload && setUpdatePayload(createBlockoutPayload);
    }
  }, [blockoutTitle, isAllDay, startDate, endDate, startTime, endTime]);

  const getCurrentTime = () => {
    const date = new Date();
    setCurrentDate(moment(date).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    const showTime = date.getHours() + ':' + date.getMinutes();
    setCurrentTime(moment(showTime, 'H:mm').format(Constants.MOMENT_FORMATS.HH_MM));
  };

  const createBlockoutPayload: IDSOBlockout = {
    title: blockoutTitle,
    allDay: isAllDay,
    startDate: moment(startDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    startTime: startTime && moment(startTime, Constants.MOMENT_FORMATS.TIME).format(Constants.MOMENT_FORMATS.HH_MM_SS),
    endDate: moment(endDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    endTime:
      endTime && isAllDay
        ? '00:00'
        : moment(endTime, Constants.MOMENT_FORMATS.TIME).format(Constants.MOMENT_FORMATS.HH_MM_SS),
  };

  const validateBlockoutPayload = async () => {
    if (
      !isAllDay &&
      (startDate === null || startTime === '' || endTime === '' || endDate === null)
    ) {
      setEnableCreateBtn(true);
      setEditBtn && setEditBtn(true);
    } else if ((isAllDay && startDate === null) || blockoutTitle === '') {
      setEnableCreateBtn(true);
      setEditBtn && setEditBtn(true);
    } else {
      setEnableCreateBtn(false);
      setEditBtn && setEditBtn(false);
    }
  };

  const createDsoBlockout = async () => {
    setShowLoader(true);
    const dsoBlockoutPayload: IDSOBlockout = {
      title: blockoutTitle,
      allDay: isAllDay,
      startDate: moment(startDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
      startTime:
        startTime && moment(startTime, Constants.MOMENT_FORMATS.TIME).format(Constants.MOMENT_FORMATS.HH_MM_SS),
      endDate: moment(endDate).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
      endTime: endTime && moment(endTime, Constants.MOMENT_FORMATS.TIME).format(Constants.MOMENT_FORMATS.HH_MM_SS),
    };
    try {
      const createRes = await ManageService.addDsoBlockoutDetail(dsoBlockoutPayload);
      sessionStorage.setItem('addedDsoBlockout', createRes?.data?.title);
      setShowLoader(false);
      navigate(prevLink[1].path);
    } catch (err) {
      setShowLoader(false);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackbar(true);
    }
  };

  return (
    <>
      {!DSOCard.roleAccess.includes(roleId) && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {(showLoader || updateLoader) && <Loader />}
      {DSOCard.roleAccess.includes(roleId) && (
        <div className='dsoBlockoutAddContainer'>
          {edit ? (
            ''
          ) : (
            <div className='dsoBlockoutAddHeader'>
              <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
                <Typography
                  variant='h5'
                  component='div'
                  className='dsoBlockoutTitle'
                  color='inherit'
                >
                  Add DSO Blockout
                  <br></br>
                  <BreadCrumbs prevLink={prevLink} activeLink={'Add DSO Blockout'} />
                </Typography>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='outlined'
                    style={{
                      width: '8em',
                      height: '34px',
                      backgroundColor: `${zeplinColor.Background97}`,
                      border: `1px solid ${zeplinColor.Background70}`,
                      color: `${zeplinColor.Background70}`,
                    }}
                    onClick={() => navigate(prevLink[1].path)}
                  >
                    Cancel
                  </Button>
                  <Buttons
                    text='Add Blockout'
                    width='100%'
                    disable={enableCreateBtn}
                    onClick={createDsoBlockout}
                  />
                </div>
              </Toolbar>
            </div>
          )}
          <Paper className='dsoBlockoutAddBox' sx={{ borderRadius: '16px', paddingBottom: '3rem' }}>
            <div className='dsoBlockoutAdd'>
              <section className='dsoBlockoutSection'>
                <section>
                  <Typography variant='h6'>Blockout Title*</Typography>
                  <Typography variant='body2'>Add title for the blockout</Typography>
                </section>
                <Field
                  name='blockoutTitle'
                  label='Title*'
                  placeholder='Title'
                  showIcon={false}
                  style={{ width: '520px', marginLeft: '0px' }}
                  value={blockoutTitle}
                  onChange={handleBlockoutTitleChange}
                />
              </section>
              <section className='dsoBlockoutSection'>
                <section>
                  <Typography variant='h6'> Duration*</Typography>
                  <Typography variant='body2'>
                    Choose start and end duration for the blockout
                  </Typography>
                </section>
                <section style={{ display: 'flex', gap: 32, flexDirection: 'column' }}>
                  <FormControlLabel
                    style={{ marginLeft: '-0.75em', flexDirection: 'row', width: 'fit-content' }}
                    labelPlacement='start'
                    control={
                      <Switch
                        color='primary'
                        onChange={(e) => handleIsAllDay(e)}
                        checked={isAllDay}
                      />
                    }
                    label={<Typography variant='body1'>All Day</Typography>}
                  />
                  <section style={{ display: 'flex', gap: 32, flexDirection: 'row' }}>
                    <Typography style={{ width: 32 }} variant='subtitle1'>
                      Start
                    </Typography>
                    <span style={{ marginTop: '-1.5em' }}>
                      <CustomDatePicker
                        value={startDate}
                        handleDateChange={handleStartDateChange}
                        label='Date (mm/dd/yyyy)'
                      />
                    </span>
                    {!isAllDay && (
                      <FormControl
                        variant='standard'
                        sx={{ m: 1, minWidth: 200, margin: 0, marginTop: '-1.5em' }}
                      >
                        <InputLabel className={classes.inputLabelRoot} id='open-time-picker-label'>
                          Time
                        </InputLabel>
                        <Select
                          className={classes.selectBorder}
                          labelId='open-time-picker-label'
                          id='open-time-picker'
                          value={formatTime(startTime)}
                          onChange={(event) => handleStartTimeChange(event)}
                          label='Time'
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 180,
                              },
                            },
                          }}
                        >
                          {time.map((i: any) => (
                            <MenuItem
                              key={i}
                              value={i}
                              disabled={checkCurrentStartDate && currentTime > i ? true : false}
                            >
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </section>
                  {!isAllDay && (
                    <section style={{ display: 'flex', gap: 32, flexDirection: 'row' }}>
                      <Typography style={{ width: 32 }} variant='subtitle1'>
                        End
                      </Typography>
                      <span style={{ marginTop: '-1.5em' }}>
                        <CustomDatePicker
                          value={endDate}
                          handleDateChange={handleEndDateChange}
                          label='Date (mm/dd/yyyy)'
                        />
                      </span>
                      <FormControl
                        variant='standard'
                        sx={{ m: 1, minWidth: 200, margin: 0, marginTop: '-1.5em' }}
                      >
                        <InputLabel className={classes.inputLabelRoot} id='open-time-picker-label'>
                          Time
                        </InputLabel>
                        <Select
                          className={classes.selectBorder}
                          labelId='open-time-picker-label'
                          id='open-time-picker'
                          value={formatTime(endTime)}
                          onChange={(event) => handleEndTimeChange(event)}
                          label='Time'
                          MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                        >
                          {time.map((i: any) => (
                            <MenuItem
                              key={i}
                              value={i}
                              disabled={checkCurrentEndDate && currentTime > i ? true : false}
                            >
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </section>
                  )}
                </section>
              </section>
            </div>
          </Paper>
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </div>
      )}
    </>
  );
};

export default AddDsoBlockout;
