/*
 * File: AddFaq.tsx
 * Project: mint-portal
 * File Created: Sunday, 11th December 2022 5:45:37 pm
 * Author: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 13th December 2022 10:52:11 am
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { Toolbar, Typography } from '@material-ui/core';
import { Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFaqAddPayload } from '../../../../../model';
import { ManageService } from '../../../../../service/manage.service';
import { zeplinColor } from '../../../../../theme';
import BreadCrumbs from '../../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../../shared/Buttons';
import CustomTextField from '../../../../shared/CustomTextField';
import Loader from '../../../../shared/Loader/Loader';
import SnackBar from '../../../../shared/Snack-Bar/snackBar';
import './AddFaq.css';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import getPermissionForModule from '../../../../../utilities/getPermission';
import { FAQ } from '../../../../../pages/Manage/ManageCardConstants';

const AddFaq = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: '/manage/faq', label: 'Frequently Asked Questions' },
  ];
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [enableActiveButton, setEnableActiveButton] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackMessage, setSnackMessage] = useState('');

  const handleQuestion = (e: { target: { value: React.SetStateAction<string> } }) => {
    setQuestion(e.target.value);
  };

  const handleAnswer = (e: { target: { value: React.SetStateAction<string> } }) => {
    setAnswer(e.target.value);
  };

  const handleAddQuestion = async () => {
    setShowLoader(true);
    const faqAddPayLoad: IFaqAddPayload = {
      question: question,
      answer: answer,
    };
    try {
      await ManageService.addFaq(faqAddPayLoad);
      sessionStorage.setItem('faqData', question);
      setShowSnackBar(false);
      navigate(prevLink[1].path);
    } catch (err) {
      setShowSnackBar(true);
      setSnackMessage(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (question && answer && question.trim() && answer.trim() !== '') {
      setEnableActiveButton(false);
    } else {
      setEnableActiveButton(true);
    }
  }, [question, answer]);

  return (
    <>
      {showLoader && <Loader />}
      {!FAQ.roleAccess.includes(roleId) && !showLoader && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {FAQ.roleAccess.includes(roleId) && (
        <>
          <div className='faqHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='faqTitle' color='inherit'>
                Add New Question
                <br></br>
                <BreadCrumbs prevLink={prevLink} activeLink={'Add New Question'} />
              </Typography>
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <Button
                  variant='outlined'
                  style={{
                    width: '8em',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={() => navigate(prevLink[1].path)}
                >
                  Cancel
                </Button>
                <Buttons
                  text='ADD QUESTION'
                  width='15em'
                  disable={enableActiveButton}
                  onClick={handleAddQuestion}
                />
              </div>
            </Toolbar>
          </div>
          <Paper className='faqCard' sx={{ borderRadius: '16px' }}>
            <div className='faqDiv'>
              <CustomTextField
                name='Question'
                label='Question*'
                multiline={true}
                value={question}
                width={'584px'}
                onChange={handleQuestion}
              />
              <CustomTextField
                name='Answer'
                label='Answer*'
                multiline={true}
                value={answer}
                width={'584px'}
                onChange={handleAnswer}
              />
            </div>
          </Paper>
        </>
      )}
      {showSnackBar && (
        <SnackBar message={showSnackMessage} show={showSnackBar} setShow={setShowSnackBar} />
      )}
    </>
  );
};

export default AddFaq;
