/*
 * File: InfoText.tsx
 * Project: mint-portal
 * File Created: Thursday, 28th July 2022 4:55:50 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 17th August 2023 1:01:50 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { zeplinColor } from '../../../theme';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ReactHtmlParser from 'react-html-parser';

type infoProps = {
  content: string;
  show: boolean;
  width: string;
  authenticated?: boolean;
  staff?: React.Dispatch<React.SetStateAction<boolean>>;
  currentStaff?: React.Dispatch<React.SetStateAction<string>>;
};

const InfoTextBox = ({ content, show, width, authenticated, staff, currentStaff }: infoProps) => {
  return (
    <Card
      elevation={0}
      style={{
        borderRadius: '16px',
        width: width,
        border: show ? `1px solid ${zeplinColor.Background90}` : '0',
        backgroundColor: show ? zeplinColor.Surface : zeplinColor.Background97,
      }}
    >
      {' '}
      <Box>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '16px',
          }}
        >
          <div style={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {' '}
            {authenticated ? (
              <CheckIcon
                style={{
                  width: '26.7px',
                  height: '26.7px',
                  marginTop: '0.5px',
                  color: zeplinColor.Background70,
                }}
              />
            ) : staff ? (
              <SupervisorAccountOutlinedIcon
                style={{
                  width: '26.7px',
                  height: '26.7px',
                  marginTop: '0.5px',
                  color: zeplinColor.Background70,
                }}
              />
            ) : (
              <InfoOutlinedIcon
                className='infoIcon'
                style={{
                  width: '26.7px',
                  height: '26.7px',
                  marginTop: '0.5px',
                  color: zeplinColor.Background70,
                }}
              />
            )}
            {staff ? (
              <Typography
                variant='subtitle1'
                style={{
                  marginLeft: '8px',
                  marginTop: '1.5px',
                  lineHeight: '1.2',
                  whiteSpace: 'pre-line',
                }}
              >
                Please note,{' '}
                <span
                  style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                  onClick={() => {
                    staff(true);
                    currentStaff && currentStaff('DSO');
                  }}
                >
                  DSO Admins
                </span>{' '}
                and{' '}
                <span
                  style={{ color: zeplinColor.Primary, cursor: 'pointer' }}
                  onClick={() => {
                    staff(true);
                    currentStaff && currentStaff('marketing');
                  }}
                >
                  Marketing Managers
                </span>{' '}
                will be automatically assigned to this clinic as per assigned roles and permissions.
              </Typography>
            ) : (
              <Typography
                variant='subtitle1'
                style={{
                  marginLeft: '8px',
                  marginTop: '1.5px',
                  lineHeight: '1.2',
                  whiteSpace: 'pre-line',
                }}
              >
                {ReactHtmlParser(content)}
              </Typography>
            )}
          </div>
        </CardContent>
      </Box>
    </Card>
  );
};

export default InfoTextBox;
