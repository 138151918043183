/*
 * File: AppointmentNotifications.tsx
 * Project: mint-portal
 * File Created: Tuesday, 14th February 2023 12:36:49 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:13:41 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import './AppointmentNotifications.css';

import { IListNotificationCadenceRO, INotificationCadenceDetailResponse } from '../../../../model';
import React, { useEffect, useState } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import { AppointmentNotifications } from '../../../../pages/Manage/ManageCardConstants';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import { Constants } from '../../../../utilities/constants';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import ListNotifications from './ListNotifications/ListNotifications';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';

const AppointmentNotification = () => {
  const [notificationsList, setNotificationsList] = useState<IListNotificationCadenceRO>({
    data: [],
    message: '',
  });
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const navigate = useNavigate();
  // delete popup
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [scheduleDelete, setScheduleDelete] = useState<INotificationCadenceDetailResponse>({
    createdAt: '',
    updatedAt: '',
    id: 0,
    hours: 0,
    scheduledTime: '',
  });

  const notification = sessionStorage.getItem('notification');

  const getNotificationsList = async () => {
    setShowLoader(true);
    try {
      const response: IListNotificationCadenceRO =
        await ManageService.getAppointmentNotificationsList();
      setNotificationsList(response);
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeletePopupOpen = (obj: INotificationCadenceDetailResponse) => {
    setOpenDeletePopup(true);
    setScheduleDelete(obj);
  };

  const handleDeleteNotification = async () => {
    try {
      if (notificationsList) {
        await ManageService.deleteNotificationById(scheduleDelete.id);
        sessionStorage.setItem('deletedNotification', 'deleted');
        setOpenDeletePopup(false);
        setShowSnackBar(true);
        setShowSnackbarMessage(`Notification Schedule deleted`);
        getNotificationsList();
      }
    } catch (err) {
      setShowSnackBar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  useEffect(() => {
    getNotificationsList();
  }, []);

  useEffect(() => {
    setShowLoader(true);
    getNotificationsList();
    if (notification == 'added') {
      setShowSnackBar(true);
      setShowSnackbarMessage('Notification Schedule added');
      sessionStorage.removeItem('notification');
    }
    else if(notification == 'updated'){
      setShowSnackBar(true);
      setShowSnackbarMessage('Notification Schedule updated');
      // sessionStorage.removeItem('notification');
    }
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      {(isForbiddenAccess || !AppointmentNotifications.roleAccess.includes(roleId)) &&
        !showLoader && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      <div className='notificationScheduleContainer'>
        <div className='notificationScheduleHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' className='notificationScheduleTitle' color='inherit'>
              Appointment Notifications
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Appointment Notifications'} />
            </Typography>
            {AppointmentNotifications.roleAccess.includes(roleId) && (
              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                <Buttons
                  text='ADD SCHEDULE'
                  width='15em'
                  disable={notificationsList.data.length === 10 ? true : false}
                  onClick={() => navigate('/manage/appointment-notifications/add')}
                />
              </div>
            )}
          </Toolbar>
          <div style={{ margin: '33px 0px 0px 20px' }}>
            <InfoTextBox
              content={Constants.INFO_TEXTS.APPOINTMENT_NOTIFICATION_INFO_TEXT}
              show={true}
              width={'76.5vw'}
            />
          </div>
        </div>
        {notificationsList?.data.length === 0 && !showLoader ? (
          <div style={{ marginTop: '15%' }}>
            <EmptyState
              path={'/manage/appointment-notifications/add'}
              text={'No schedules added'}
              subText={'Get started by clicking on Add Schedule'}
              buttonText={'ADD SCHEDULE'}
              showAddButton={true}
              image={true}
            />
          </div>
        ) : (
          !showLoader && (
            <ListNotifications
              notificationsList={notificationsList}
              handleDeletePopupOpen={handleDeletePopupOpen}
              openDeletePopup={openDeletePopup}
              handleDeleteNotification={handleDeleteNotification}
              setOpenDeletePopup={setOpenDeletePopup}
              scheduleDelete={scheduleDelete}
            />
          )
        )}
      </div>
      {showSnackBar && (
        <SnackBar message={showSnackbarMessage} show={true} setShow={setShowSnackBar} />
      )}
    </>
  );
};

export default AppointmentNotification;
