/*
 * File: LI
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 8:51:13 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 3rd November 2022 12:42:33 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { zeplinColor } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { IClinicODResponse, IListClinicODPatientResponse } from '../../../model';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EmptyState from '../../shared/EmptyState/EmptyState';
import CustomToolTip from '../../shared/CustomToolTip';
import { Constants } from '../../../utilities/constants';
import CustomNumericInput from '../../shared/CustomNumericField';
import { PatientService } from '../../../service/patient.service';
import { useLocation } from 'react-router-dom';
import SnackBar from '../../shared/Snack-Bar/snackBar';
import { styled } from '@mui/material/styles';
import DeletePopup from '../../shared/DeletePopup';
import Loader from '../../shared/Loader/Loader';
import getPermissionForModule from '../../../utilities/getPermission';
import { handleKeyPressForEscapeSymbols } from '../../../utilities/validate';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3, 1, 3),
    maxHeight: '26.5rem',
    minWidth: '35rem',
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogBoxTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1 0 1 0' }} {...other}>
      {children}
    </DialogTitle>
  );
};

interface IClinicProps {
  odClinicList: IListClinicODPatientResponse;
  onPageChangeHandler: any;
  currentPage: number;
  getODClinicList: () => Promise<void>;
}

interface ClinicListColumn {
  id: 'clinic_name' | 'od_patient_number' | 'pending_balance' | 'clinic_connection';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const clinicListColumns: ClinicListColumn[] = [
  { id: 'clinic_name', label: 'Clinic Name', minWidth: 300 },
  { id: 'od_patient_number', label: 'OpenDental Patient Number', minWidth: 150 },
  { id: 'pending_balance', label: 'Pending Balance', minWidth: 150 },
  { id: 'clinic_connection', label: 'Clinic Connection', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));
const ListODClinics: FC<IClinicProps> = ({
  odClinicList,
  onPageChangeHandler,
  currentPage,
  getODClinicList,
}) => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Patient;
  const [openRemovePopup, setOpenRemovePopup] = useState(false);
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const [ODPopUp, setODPopUp] = useState(false);
  const [patientType, setPatientType] = useState<'new' | 'existing'>('new');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMessage, setSnackbarMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [selectedOD, setSelectedOD] = useState<IClinicODResponse>({
    openDentalPatientNumber: '',
    clinicId: '',
    clinicName: '',
    estBalance: 0,
  });

  const [patientNumber, setPatientNumber] = useState(+selectedOD.openDentalPatientNumber || 0);

  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const classes = useStyles();
  const route = useLocation();

  const handleRemovePopupOpen = () => {
    setODPopUp(false);
    setOpenRemovePopup(true);
  };

  const handleODPopUp = (clinicObj: IClinicODResponse) => {
    setSelectedOD(clinicObj);
    setODPopUp(true);
    setPatientNumber(+clinicObj?.openDentalPatientNumber || 0);
    setPatientType('new');
  };

  const onCancel = () => {
    setODPopUp(false);
  };

  const handleODUpdate = async () => {
    setODPopUp(false);
    setShowLoader(true);
    const patientId = route.pathname.split('/')[4] || route.pathname.split('/')[2];
    try {
      const payload = {
        patientId,
        clinicId: selectedOD.clinicId,
        ...((patientType !== 'new' || selectedOD.openDentalPatientNumber) && {
          openDentalPatientNumber: patientNumber,
        }),
      };

      const res = await PatientService.updateOD(patientId, payload);
      if (res) {
        setShowSnackbar(true);
        setSnackbarMessage(`Clinic connection updated`);
        getODClinicList();
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const handleODRemove = async () => {
    setOpenRemovePopup(false);
    setShowLoader(true);
    const patientId = route.pathname.split('/')[4] || route.pathname.split('/')[2];
    try {
      const res = await PatientService.removeOD(patientId, selectedOD.clinicId);
      if (res) {
        setShowSnackbar(true);
        setSnackbarMessage(`Clinic connection removed`);
        getODClinicList();
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const handlePatientType = (e: any) => {
    setPatientType(e.target.value);
  };

  const handlePatientNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientNumber(+event.target.value);
  };

  const onPaste = (e: any) => {
    e.preventDefault();
    return false;
  };
  return (
    <>
      {showLoader && <Loader />}
      {odClinicList.total && odClinicList.openDentalConnections.length > 0 ? (
        <div>
          <Paper
            className='containerdiv'
            sx={{ borderRadius: '16px', margin: '8px 0px 24px -16px' }}
          >
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {clinicListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {odClinicList.total &&
                    odClinicList.openDentalConnections.length &&
                    odClinicList.openDentalConnections.map((clinicObj: IClinicODResponse) => {
                      return (
                        <TableRow key={clinicObj.clinicId}>
                          <TableCell>
                            <CustomToolTip title={clinicObj.clinicName} width={'250px'} />
                          </TableCell>
                          <TableCell>
                            <Typography>{clinicObj.openDentalPatientNumber || '-'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant='subtitle1'
                              style={{
                                color:
                                  clinicObj.estBalance && clinicObj.estBalance < 0
                                    ? zeplinColor.Error
                                    : '',
                              }}
                            >
                              {!clinicObj.estBalance && clinicObj.estBalance !== 0
                                ? '-'
                                : '$' + clinicObj.estBalance.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Button
                              style={
                                permission.update
                                  ? { color: zeplinColor.Primary }
                                  : { color: zeplinColor.Background70 }
                              }
                              onClick={() => handleODPopUp(clinicObj)}
                              disabled={permission.update ? false : true}
                            >
                              Update
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              odClinicList.total <= Constants.LIMIT
                ? 1
                : Math.ceil(odClinicList.total / Constants.LIMIT)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </div>
      ) : (
        odClinicList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={''}
              text={'No clinics to show'}
              subText={''}
              buttonText={''}
              showAddButton={false}
              image={true}
            />
          </section>
        )
      )}
      {ODPopUp && (
        <DialogBox onClose={onCancel} aria-labelledby='customized-dialog-title' open={ODPopUp}>
          <div style={{ padding: '8px 0rem 16px 0px' }}>
            <DialogBoxTitle id='customized-dialog-title' onClose={onCancel}>
              <Typography variant='h5'>Update Clinic Connection</Typography>
            </DialogBoxTitle>
            <DialogContent id='scroll'>
              <div
                style={{
                  marginTop: '16px',
                  borderBottom: `1px solid ${zeplinColor.Background90}`,
                  paddingBottom: '24px',
                }}
              >
                <Typography variant='body2' style={{ color: zeplinColor.Background50 }}>
                  Clinic Name
                </Typography>
                <Typography variant='subtitle1'>{selectedOD.clinicName}</Typography>
              </div>
              {!selectedOD.openDentalPatientNumber ? (
                <>
                  <Typography variant='subtitle1' style={{ marginTop: '24px' }}>
                    Select Patient Type on OpenDental*
                  </Typography>

                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    defaultValue={'new'}
                    onChange={handlePatientType}
                  >
                    <FormControlLabel
                      value='new'
                      control={
                        <Radio
                          sx={{
                            color: zeplinColor.Background60,
                            '&.Mui-checked': {
                              color: zeplinColor.Primary,
                            },
                          }}
                        />
                      }
                      label='New Patient'
                    />
                    <FormControlLabel
                      value='existing'
                      control={
                        <Radio
                          sx={{
                            color: zeplinColor.Background60,
                            '&.Mui-checked': {
                              color: zeplinColor.Primary,
                            },
                          }}
                        />
                      }
                      label='Existing Patient'
                    />
                  </RadioGroup>
                </>
              ) : (
                ''
              )}
              {(selectedOD.openDentalPatientNumber || patientType === 'existing') && (
                <div style={{ marginLeft: '-16px' }}>
                  <CustomNumericInput
                    name='patient number'
                    label='OpenDental Patient Number'
                    placeholder='OpenDental Patient Number'
                    width='98%'
                    value={patientNumber}
                    error={false}
                    icon={false}
                    onChange={handlePatientNumberChange}
                    min={0}
                    onKeyPress={handleKeyPressForEscapeSymbols}
                    onPaste={onPaste}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: 'flex',
                justifyContent: !selectedOD.openDentalPatientNumber ? 'flex-end' : 'space-between',
                marginTop: '40px',
                width: '100%',
                padding: '0px 24px 0px 24px',
              }}
            >
              {selectedOD.openDentalPatientNumber ? (
                <Button style={{ color: zeplinColor.Error }} onClick={handleRemovePopupOpen}>
                  REMOVE CONNECTION
                </Button>
              ) : (
                ''
              )}
              <div style={{ display: 'flex', gap: 20, justifyContent: 'flex-end' }}>
                <Button style={{ color: zeplinColor.Background70 }} onClick={onCancel}>
                  Cancel
                </Button>
                <Button style={{ color: zeplinColor.Primary }} onClick={handleODUpdate}>
                  UPDATE
                </Button>
              </div>
            </DialogActions>
          </div>
        </DialogBox>
      )}
      <DeletePopup
        title={'Remove Clinic Connection'}
        customDescription={
          <>
            Are you sure you want to remove the patient&apos;s connection to the clinic &#34;
            <b>{selectedOD?.clinicName}</b>&#34;? You can&apos;t undo this action.
          </>
        }
        subDescription={''}
        open={openRemovePopup}
        buttonText={`Remove`}
        setOpen={setOpenRemovePopup}
        onDeleteClick={handleODRemove}
      />
      {showSnackbar && (
        <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default ListODClinics;
