/*
 * File: ListConfigureRewards.tsx
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:01:48 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 10th November 2022 4:34:40 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React, { FC, useState } from 'react';
import './ListConfigureRewards.css';
import {
  IListRewardPaginationResponse,
  IListRewardResponse,
  RewardType,
  FilterArray,
} from '../../../../../model';
import { zeplinColor } from '../../../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper, Chip, Button } from '@mui/material';
import { Constants } from '../../../../../utilities/constants';
import { useNavigate } from 'react-router-dom';
import getPermissionForModule from '../../../../../utilities/getPermission';
import EmptyState from '../../../../shared/EmptyState/EmptyState';
import CustomToolTip from '../../../../shared/CustomToolTip';
interface IProps {
  rewardList: IListRewardPaginationResponse;
  onPageChangeHandler: any;
  currentPage: number;
  onFilterClear: any;
  onFilterOptionDel: any;
  selectedChipOption: FilterArray[] | null | undefined;
}

interface ConfigureRewardListColumn {
  id: 'reward_name' | 'reward_type' | 'points_value' | 'inventory_count';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const ConfigureRewardListColumns: ConfigureRewardListColumn[] = [
  { id: 'reward_name', label: 'Reward Name', minWidth: 300 },
  { id: 'reward_type', label: 'Reward Type', minWidth: 150 },
  { id: 'points_value', label: 'Points Value', minWidth: 150 },
  { id: 'inventory_count', label: 'Inventory Count', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));
const ListConfigureRewards: FC<IProps> = ({
  rewardList,
  onPageChangeHandler,
  currentPage,
  onFilterClear,
  onFilterOptionDel,
  selectedChipOption,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Rewards;
  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };

  const [selectedChips, setSelectedChips] = useState<FilterArray[] | null | undefined>(
    selectedChipOption,
  );

  const clearAllFilter = () => {
    setSelectedChips(null);
    onFilterClear({
      gift: false,
      pointCredits: false,
    });
  };

  const onFilterOptionChange = (chipToDelete: any) => {
    if (selectedChips?.filter((chip: any) => chip.id !== chipToDelete.id).length === 0) {
      setSelectedChips(null);
      onFilterClear({
        gift: false,
        pointCredits: false,
      });
    } else {
      setSelectedChips((chips: any) => chips?.filter((chip: any) => chip.id !== chipToDelete.id));
      onFilterOptionDel(chipToDelete);
    }
  };

  return (
    <>
      <div style={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {!!selectedChips &&
          selectedChips?.map((data: FilterArray) => {
            return (
              <Chip
                style={{ textTransform: 'capitalize' }}
                key={data.id}
                label={data.label === 'gift' ? 'Gift' : 'Points Credit'}
                onDelete={() => onFilterOptionChange(data)}
              />
            );
          })}
        {selectedChips?.length && (
          <Button onClick={clearAllFilter} variant='text' style={{ color: zeplinColor.Primary }}>
            Clear all
          </Button>
        )}
      </div>
      {rewardList.total && rewardList.rewards.length > 0 ? (
        <>
          <Paper className='configureRewardsContainer' sx={{ borderRadius: '16px' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {ConfigureRewardListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rewardList.total &&
                    rewardList.rewards.length &&
                    rewardList.rewards.map((rewardObj: IListRewardResponse) => {
                      return (
                        <TableRow
                          hover
                          classes={{ hover: classes.tableRow }}
                          key={rewardObj.id}
                          onClick={() =>
                            navigate({
                              pathname: `/rewards/detail/${rewardObj.id}`,
                            })
                          }
                        >
                          <TableCell>
                            <CustomToolTip title={rewardObj.name} width={'275px'} />
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {rewardObj.rewardType === RewardType.PointsCredit
                                ? 'Points Credit'
                                : 'Gift'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{rewardObj.pointsValue}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {rewardObj?.inventoryCount
                                ? rewardObj?.inventoryCount
                                : rewardObj.rewardType === RewardType.PointsCredit
                                  ? '-'
                                  : 0}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              rewardList.total <= Constants.PAGINATION_OPTIONS.limit
                ? 1
                : Math.ceil(rewardList.total / Constants.PAGINATION_OPTIONS.limit)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </>
      ) : (
        rewardList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={permission.create ? '/rewards/add' : ''}
              text={'No rewards to show'}
              subText={permission.create ? 'Start by adding a new reward.' : ''}
              buttonText={permission.create ? 'ADD NEW REWARD' : ''}
              showAddButton={permission.create ? true : false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListConfigureRewards;
