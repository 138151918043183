/*
 * File: axios.tsx
 * Project: mint-portal
 * File Created: Monday, 27th June 2022 8:59:18 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 12th July 2022 7:30:40 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  headers: { 'Content-Type': 'application/json' },
});

export default instance;
