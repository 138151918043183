/*
 * File: VideosList.tsx
 * Project: mint-portal
 * File Created: Friday, 26th May 2023 8:00:45 pm
 * Author: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Last Modified: Wed, 31st May 2023 01:16:44 am
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

import './VideosList.css';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { IVideosDetailResponse } from '../../../../../model';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../../theme';

interface IProps {
  videoData: IVideosDetailResponse[];
  onVideosReordering: (status: boolean, value: IVideosDetailResponse[]) => void;
}
interface VideosListColumn {
  id: 'title' | 'url' | 'serial-number';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const VideosList: FC<IProps> = ({ videoData, onVideosReordering }) => {
  const [videosData, setvideoData] = useState<IVideosDetailResponse[]>();
  const videosListColumns: VideosListColumn[] = [
    { id: 'serial-number', label: 'S.No', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 300 },
    { id: 'url', label: 'URL', minWidth: 300 },
  ];
  const classes = useStyles();
  const navigate = useNavigate();

  const reorderVideos = (list: IVideosDetailResponse[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    if (videoData?.length > 0) {
      setvideoData(videoData);
    }
  }, [videoData.length]);

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? zeplinColor.Primary10 : 'white',
    ...draggableStyle,
  });

  const onDragEnd = (result: any) => {
    if (videosData) {
      if (!result.destination) {
        return;
      }
      const movedItems = reorderVideos(videosData, result.source.index, result.destination.index);
      setvideoData(movedItems);
      onVideosReordering(true, movedItems);
    }
  };

  return (
    <>
      {videoData && videoData.length > 0 && (
        <div>
          <Paper className='containerdivvideos' sx={{ borderRadius: '16px', overflowX: 'hidden' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {videosListColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 0, minWidth: column.minWidth }}
                      >
                        <Typography
                          style={{ height: '22px', color: zeplinColor.Background50 }}
                          variant='subtitle1'
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided: any) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {videosData?.map((obj: IVideosDetailResponse, index: number) => {
                          return (
                            <Draggable key={obj.id} draggableId={'q-' + obj.id} index={index}>
                              {(provided: any, snapshot: any) => (
                                <TableRow
                                  hover
                                  classes={{ hover: classes.tableRow }}
                                  onClick={() =>
                                    navigate({
                                      pathname: `/manage/videos/${obj.id}`,
                                    })
                                  }
                                  key={obj.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <TableCell
                                    style={{
                                      verticalAlign: 'top',
                                    }}
                                    className={snapshot.isDragging && 'drag-row1'}
                                  >
                                    <Typography>{index + 1}.</Typography>
                                  </TableCell>
                                  <TableCell
                                    className={snapshot.isDragging && 'drag-row2'}
                                    style={{
                                      verticalAlign: 'top',
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle1'
                                      style={{
                                        fontWeight: 600,
                                        maxWidth: '25rem',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {obj.title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      verticalAlign: 'top',
                                      wordWrap: 'break-word',
                                      maxWidth: '25rem',
                                    }}
                                  >
                                    <Typography className={snapshot.isDragging && 'drag-row3'}>{obj.link}</Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};

export default VideosList;
