/*
 * File: ManageCardConstants.ts
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 6:01:39 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 31st May 2023 2:00:49 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import BlockIcon from '@mui/icons-material/Block';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import LanguageIcon from '@mui/icons-material/Language';
import PolicyIcon from '@mui/icons-material/Policy';
import React from 'react';
import { RoleIds } from '../../utilities/roles';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { zeplinColor } from '../../theme';

export interface ICard {
  name: string;
  icon: JSX.Element;
  routeTo: string;
  roleAccess: RoleIds[];
}

export const DSOCard: ICard = {
  name: 'DSO Blockouts',
  icon: <BlockIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/dso-blockouts',
  roleAccess: [RoleIds.DSO_ADMIN],
};
export const NPPWK: ICard = {
  name: 'NPPWK',
  icon: <TextSnippetIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/dso-nppwrk',
  roleAccess: [RoleIds.DSO_ADMIN],
};
export const LegalDocument: ICard = {
  name: 'Legal Documents',
  icon: <PolicyIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/legal-documents',
  roleAccess: [RoleIds.DSO_ADMIN],
};
export const LandingImage: ICard = {
  name: 'Landing Carousel',
  icon: <CollectionsIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/landing-carousel',
  roleAccess: [RoleIds.DSO_ADMIN, RoleIds.MARKETING_ADMIN, RoleIds.CLINIC_ADMIN],
};

export const Video: ICard = {
  name: 'Videos',
  icon: <PlayCircleFilledIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/videos',
  roleAccess: [RoleIds.DSO_ADMIN, RoleIds.MARKETING_ADMIN],
};

export const ShowPendingBalance: ICard = {
  name: 'Show Pending Balance',
  icon: <AccountBalanceWalletIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/clinics',
  roleAccess: [RoleIds.DSO_ADMIN],
};
export const RewardCategory: ICard = {
  name: 'Reward Categories',
  icon: <CardGiftcardIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/reward-categories',
  roleAccess: [RoleIds.DSO_ADMIN],
};
export const ReferFriend: ICard = {
  name: 'Refer a Friend',
  icon: <GroupsIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/refer-friend',
  roleAccess: [RoleIds.DSO_ADMIN, RoleIds.MARKETING_ADMIN],
};

export const FAQ: ICard = {
  name: 'Frequently Asked Questions',
  icon: <HelpIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/faq',
  roleAccess: [RoleIds.DSO_ADMIN],
};

export const AppointmentNotifications: ICard = {
  name: 'Appointment Notifications',
  icon: <NotificationsIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/appointment-notifications',
  roleAccess: [RoleIds.DSO_ADMIN],
};

export const ServicesIconLibrary: ICard = {
  name: 'Services Icon Library',
  icon: <AppsRoundedIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/services-icon-library',
  roleAccess: [RoleIds.DSO_ADMIN],
};

export const Links: ICard = {
  name: 'Links',
  icon: <LanguageIcon sx={{ color: zeplinColor.Primary, height: 32, width: 32 }} />,
  routeTo: '/manage/links',
  roleAccess: [RoleIds.DSO_ADMIN],
};
