/*
 * File: InsuranceDetail.tsx
 * Project: mint-portal
 * File Created: Thursday, 28th July 2022 1:41:23 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 28th November 2022 3:32:57 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './InsuranceDetail.css';

import { Button, Link, Toolbar, Typography } from '@material-ui/core';
import { IInsuranceDetail, InsuranceDetailRO } from '../../../../model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import CustomToolTip from '../../../shared/CustomToolTip';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import DeletePopup from '../../../shared/DeletePopup';
import { InsuranceService } from '../../../../service/insurance.service';
import ListPopup from '../../../shared/ListPopup';
import Loader from '../../../shared/Loader/Loader';
import { Paper } from '@mui/material';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../../../utilities/getPermission';
import { zeplinColor } from '../../../../theme';

const InsuranceDetail = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Insurances;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [insuranceDetail, SetInsuranceDetail] = useState<IInsuranceDetail>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [openClinicPopup, setOpenClinicPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const prevLink = [{ path: '/insurances', label: 'Insurances' }];
  const handleClinicPopupOpen = () => {
    setOpenClinicPopup(true);
  };

  const handleDeletePopupOpen = () => {
    setOpenDeletePopup(true);
  };

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      getInsuranceDetailsById(id);
    }
  }, [id]);

  const handleDeleteInsurance = async () => {
    setShowLoader(true);
    try {
      if (insuranceDetail) {
        await InsuranceService.deleteInsurance(insuranceDetail?.id);
        sessionStorage.setItem('deletedInsurance', insuranceDetail?.insuranceName);
        setShowLoader(false);
        navigate('/insurances');
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const getInsuranceDetailsById = async (id: string) => {
    try {
      const insuranceDetails: InsuranceDetailRO = await InsuranceService.getInsuranceDetailById(id);
      SetInsuranceDetail(insuranceDetails?.data);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className='insuranceDetailContainer'>
        <div className='insuranceDetailHeader'>
          <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
            <Typography variant='h5' component='div' className='staffTitle' color='inherit'>
              Insurance Details
              <br></br>
              <BreadCrumbs prevLink={prevLink} activeLink={'Insurance Details'} />
            </Typography>
            <div style={{ display: 'flex', gap: '16px' }}>
              {permission.delete && (
                <Button
                  variant='outlined'
                  color='secondary'
                  className='deleteBox'
                  onClick={handleDeletePopupOpen}
                >
                  <DeleteOutlineSharpIcon
                    className='deleteIcon'
                    style={{ color: `${zeplinColor.Error}` }}
                  />
                </Button>
              )}
              {/* {permission.update &&
                <Buttons text='Edit Details' width='12em' disable={false}
                  onClick={() => navigate('/insurances')} />
              } */}
            </div>
          </Toolbar>
        </div>
        {insuranceDetail && (
          <Paper className='insuranceDetailBox' sx={{ borderRadius: '16px' }}>
            <div className='insuranceDetail'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Insurance Name</Typography>
                <CustomToolTip  title={insuranceDetail?.insuranceName} width={'65rem'} />
              </div>
              <div className='clinicBox'>
                <Typography variant='subtitle1'>Selected Clinics</Typography>
                {insuranceDetail?.isAllClinicAccess ? (
                  <Typography variant='body1' style={{ marginBottom: '32px' }}>
                    All Clinics
                  </Typography>
                ) : insuranceDetail?.clinics.length > 5 ? (
                  <>
                    <Typography variant='body1' style={{ marginBottom: '32px' }}>
                      <Link
                        onClick={handleClinicPopupOpen}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {insuranceDetail?.clinics.length} Clinics Selected
                      </Link>
                    </Typography>
                    <ListPopup
                      title={'Selected Clinics'}
                      description={'Below is the list of clinics the insurance will have access to'}
                      open={openClinicPopup}
                      assignedClinic={insuranceDetail?.clinics}
                      setOpen={setOpenClinicPopup}
                    />
                  </>
                ) : (
                  insuranceDetail?.clinics.map((clinic) => (
                    <div key={clinic.id} className='listItems'>
                      <Typography>{clinic.name}</Typography>
                    </div>
                  ))
                )}
              </div>
            </div>
            {insuranceDetail && (
              <DeletePopup
                title={'Delete Insurance'}
                description={`"${insuranceDetail?.insuranceName}"`}
                subDescription={`Upon deletion, this insurance will be removed from (${insuranceDetail?.clinics?.length}) clinic(s).`}
                open={openDeletePopup}
                buttonText={`Delete Insurance`}
                setOpen={setOpenDeletePopup}
                onDeleteClick={handleDeleteInsurance}
              />
            )}
          </Paper>
        )}
        {showSnackBar && (
          <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
        )}
      </div>
    </>
  );
};

export default InsuranceDetail;
