/*
 * File: LandingPage.tsx
 * Project: mint-portal
 * File Created: Wednesday, 8th June 2022 5:10:41 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 12th October 2022 7:16:51 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import React from 'react';
import './LandingPage.css';
import Logo from '../../../assests/mintlogo.svg';
import { Typography } from '@material-ui/core';
import { zeplinColor } from '../../../theme';
import { Box ,Divider} from '@mui/material';

type footerProps = {
  showFooter: boolean;
};

const LandingPage = ({showFooter}: footerProps) => {
  return (
    <div className='landingPage'>
      <header className='header'>
        <div className='headerContent'>
          <img className='logo' src={Logo} />
          <span className='poweredBy'>
            <Typography variant='body2' color='textSecondary'>
              Powered By{' '}
              <Typography component='span' color='primary' className='mint'>
                MINT
              </Typography>
              <Typography component='span' color='primary' className='copyRight'>
                &#174;
              </Typography>
            </Typography>
          </span>
        </div>
      </header>
      <div className='footerContent' style={{position:'absolute' ,display: showFooter ? '' :'none'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: zeplinColor.Background50,
            '& svg': {
              m: 5,
            },
            '& hr': {
              mx: 4,
            },
          }}
          style={{position:'relative', top:'40rem'}}
        >
          <Typography>TERMS OF SERVICE</Typography>
          <Divider orientation='vertical' variant='middle' flexItem sx={{ height: '30px' }} />
          <Typography>PRIVACY POLICY</Typography>
        </Box>
      </div>
    </div>
  );
};

export default LandingPage;
