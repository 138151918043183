/*
 * File: InfiniteDropDown.tsx
 * Project: mint-portal
 * File Created: Thursday, 17th August 2023 1:12:19 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 12th December 2023 9:41:10 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { zeplinColor } from '../../../theme';
import Field from '../CustomTextField';
import { Constants } from '../../../utilities/constants';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

type IProp = {
  infinite: string;
  searchList: any;
  dataLength?: any;
  hasMore: string | boolean;
  next: any;
  value: any;
  handleOpen: any;
  label: any;
  open: boolean;
  disable: boolean;
  name: string;
  zIndex: string;
  onSelectHandler: (e: any, select?: boolean) => void;
  onSearchHandler?: (e: any) => void;
  search?: string;
  clearSearch?: boolean;
  setFiltered?: React.Dispatch<any>;
  counter?: number;
  multiple?: boolean;
  setSelected?: React.Dispatch<any>;
  width?: string;
  setCounter?: React.Dispatch<React.SetStateAction<number>>;
  padding?: boolean;
};
const InfiniteDropDown = ({
  infinite,
  searchList,
  dataLength,
  hasMore,
  next,
  value,
  handleOpen,
  open,
  label,
  disable,
  name,
  zIndex,
  onSelectHandler,
  onSearchHandler,
  search,
  clearSearch,
  setFiltered,
  counter,
  multiple = false,
  setSelected,
  width,
  setCounter,
  padding,
}: // setSelected,
IProp) => {
  const handleChange = (event?: any, value?: any) => {
    if (event.key !== 'Backspace') {
      infinite === 'location' ? onSelectHandler(value, true) : onSelectHandler(value);
    }
  };
  const [profileSuccess, setProfileSuccess] = React.useState(false);

  return (
    <div style={{ position: 'relative', width: '100%' }} id='dropdown'>
      <div style={{ marginBottom: -8 }}>
        <Field
          // width={'100%'}
          name={name}
          id={name}
          label={!infinite && !value?.length ? '' : label}
          placeholder={value?.length ? '' : label}
          showIcon={multiple && value?.length}
          style={{ width: width ? width : '100%', marginLeft: 0 }}
          padding={padding ? true : false}
          infinite={true}
          setSelected={setSelected}
          multiline={multiple}
          value={
            clearSearch
              ? ''
              : search && open
                ? multiple
                  ? value
                  : search
                : value
                  ? infinite === 'location'
                    ? value?.title
                    : infinite === 'provider' && value?.providerFirstName === null
                      ? ' ' + value?.providerLastName
                      : infinite === 'provider' && value?.providerFirstName !== null
                        ? value?.providerFirstName + ' ' + value?.providerLastName
                        : infinite === 'account'
                          ? value?.accountName
                          : infinite === 'insurance'
                            ? value?.insuranceName
                            : infinite === 'operatory'
                              ? value?.operatoryName
                              : infinite === 'patientList'
                                ? value?.firstName + ' ' + value?.lastName
                                : value?.length
                                  ? value
                                  : value?.name
                  : ''
          }
          search={search}
          multiple={multiple}
          onChange={(e: any) => {
            onSearchHandler && onSearchHandler(e);
          }}
          disable={disable}
          handleOpen={handleOpen}
          open={open}
        />
      </div>
      {open ? (
        <Paper
          elevation={8}
          style={{ position: 'absolute', zIndex: zIndex, width: width ? width : '100%' }}
        >
          <InfiniteScroll
            dataLength={dataLength}
            next={() => {
              setCounter && counter ? setCounter(counter + 4) : '';
              infinite === 'location'
                ? next('', false, hasMore)
                : infinite === 'account'
                  ? next(hasMore)
                  : // : infinite === 'insurance'
                  //   ? next('', false, hasMore)
                  next(counter);
            }}
            hasMore={hasMore ? true : false}
            loader={
              <div
                style={{
                  marginTop: '5px',
                  paddingLeft: '1rem',
                  // paddingLeft: '1rem',
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Loading...
              </div>
            }
            height={dataLength > 2 ? 150 : dataLength > 1 ? 100 : 50}
          >
            {dataLength ? (
              searchList?.map((val: any, ind: number) => {
                return (
                  <>
                    <Box
                      key={ind}
                      style={{
                        flex: name === 'clinicStaff' ? 1 : 0,
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                        padding: '0.7rem 0rem 0.7rem 1rem',
                        // width: name === 'clinicService' ? '98%' : '',
                        margin: name === 'clinicService' ? 'auto' : 0,
                        // marginBottom: '0.2rem',
                        // paddingLeft: '1rem',
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundColor:
                          (multiple &&
                            value?.length &&
                            value.map((v: any) => v?.name).includes(val?.name)) ||
                          (value &&
                            infinite !== 'insurance' &&
                            infinite !== 'operatory' &&
                            infinite !== 'provider' &&
                            infinite !== 'clinicStaff' &&
                            infinite !== 'provider' &&
                            (val?.name
                              ? value?.name === val?.name
                              : val?.id
                                ? value?.id === val?.id
                                : value?.firstName === val?.firstName)) ||
                          (infinite === 'provider' &&
                            val.providerNumber === value?.providerNumber) ||
                          (infinite === 'insurance' &&
                            val?.insuranceName === value?.insuranceName) ||
                          (infinite === 'clinicStaff' && val?.firstName === value?.firstName) ||
                          (infinite === 'operatory' && val?.operatoryName === value?.operatoryName) ||
                          (infinite === 'provider' && val?.providerNumber === value?.providerNumber)
                            ? zeplinColor.Blue01
                            : '',
                        zIndex: 10,
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                      onClick={(e: any) => {
                        handleChange(e, val);
                        setFiltered && setFiltered([]);
                      }}
                    >
                      {(infinite === 'clinicStaff' || infinite === 'clinicService') && (
                        <img
                          src={
                            profileSuccess &&
                            (infinite === 'clinicStaff' || infinite === 'clinicService')
                              ? infinite === 'clinicStaff'
                                ? val?.profileImageDetails?.location
                                : val?.serviceImageDetails?.location
                              : Constants.FALL_BACK_IMAGE
                          }
                          style={{
                            width: 32,
                            height: 32,
                            borderRadius: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onError={() => setProfileSuccess(false)}
                        />
                      )}

                      {infinite === 'location' ? (
                        val?.title
                      ) : infinite === 'provider' ? (
                        val?.providerFirstName + ' ' + val?.providerLastName
                      ) : infinite === 'account' ? (
                        val?.accountName
                      ) : infinite === 'patientList' ? (
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                          <Typography style={{ width: 240 }}>
                            {val?.firstName + ' ' + val?.lastName}
                          </Typography>
                          <Typography>{val?.code}</Typography>
                        </Box>
                      ) : infinite === 'insurance' ? (
                        val?.insuranceName
                      ) : infinite === 'operatory' ? (
                        val?.operatoryName
                      ) : infinite === 'clinicStaff' ? (
                        val?.firstName +
                        ' ' +
                        val?.lastName +
                        '  ' +
                        ' | ' +
                        '  ' +
                        val?.roles[0].name
                      ) : infinite === 'clinicService' ? (
                        <>
                          <Typography variant='h6' style={{ color: zeplinColor.Background30 }}>
                            {val?.name}
                          </Typography>
                        </>
                      ) : (
                        val?.name
                      )}
                    </Box>
                    {infinite === 'clinicService' && (
                      <Box
                        style={{
                          display: 'flex',
                          gap: 15,
                          padding: '10px 10px 10px 24px',
                          borderBottom: `1px solid ${zeplinColor.Background90}`,
                          cursor: 'pointer',
                        }}
                        onClick={(e: any) => {
                          handleChange(e, val);
                          setFiltered && setFiltered([]);
                        }}
                      >
                        <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                          <PermIdentityIcon
                            style={{ color: zeplinColor.Background30, width: 16, height: 16 }}
                          />
                          <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
                            {val?.ageType}
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                          <AssignmentTurnedInOutlinedIcon
                            style={{ color: zeplinColor.Background30, width: 16, height: 16 }}
                          />
                          <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
                            {val.patientType}
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                          <AccessTimeIcon
                            style={{ color: zeplinColor.Background30, width: 16, height: 16 }}
                          />
                          <Typography variant='body2' style={{ color: zeplinColor.Background30 }}>
                            {val?.duration} Min
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                );
              })
            ) : (
              <Box
                style={{
                  padding: '0.7rem 0rem 0.7rem 1rem',
                  // paddingLeft: '1rem',
                  cursor: 'pointer',
                  position: 'relative',
                  zIndex: 10,
                  fontSize: 16,
                  fontWeight: 500,
                  color: zeplinColor?.Background05,
                }}
              >
                No options
              </Box>
            )}
          </InfiniteScroll>
        </Paper>
      ) : (
        ''
      )}
    </div>
  );
};

export default InfiniteDropDown;
