/*
 * File: Clients.tsx
 * Project: mint-portal
 * File Created: Wednesday, 15th June 2022 10:42:25 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 1st August 2023 7:34:00 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import './Clinics.css';

import {
  FilterParams,
  IClinicList,
  IClinicListResponse,
  IClinicQueryParamOptions,
} from '../../model';
import React, { FC, useEffect, useState } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import { Badge } from '@mui/material';
import Buttons from '../../components/shared/Buttons';
import { ClinicService } from '../../service/clinic.service';
import { Constants } from '../../utilities/constants';
import CustomTab from '../../components/shared/CustomTab';
import EmptyState from '../../components/shared/EmptyState/EmptyState';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterClinicPopup from '../../components/feature/clinic/FilterClinic/FilterClinicPopup';
import ListClinic from '../../components/feature/clinic/ListClinic/ListClinic';
import ListDraftClinic from '../../components/feature/clinic/ListDraftClinic/ListDraftClinic';
import Loader from '../../components/shared/Loader/Loader';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchBar from '../../components/shared/SearchBar';
import SnackBar from '../../components/shared/Snack-Bar/snackBar';
import getPermissionForModule from '../../utilities/getPermission';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../theme';

interface FilterArray {
  id: number;
  label: string;
  value: boolean;
  type: string;
}

const Clinics: FC = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Clinics;
  const loggedInuserRoleId = getPermission.roleId;
  const clinicAdded = sessionStorage.getItem('clinicAdded');
  const navigate = useNavigate();
  const [clinicList, setClinicList] = useState<IClinicList>({ total: 0, clinics: [] });
  const [dataPage, setDataPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarMsg, setSnackbarMsg] = useState('');
  const [draft, setDraft] = useState<boolean>(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  // Filter popup states
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedChips, setSelectedChips] = useState<FilterArray[] | null>();
  const [filterOptions, setFilterOption] = useState<FilterParams | any>();

  const onTabChange = (index: number) => {
    setSearchValue('');
    index === 0 ? setDraft(false) : setDraft(true);
    setDataPage(1);
  };

  const onDataPageChange = (page: number) => {
    setDataPage(page);
  };

  const onSearch = (inputValue: string) => {
    setSearchValue(inputValue);
    setDataPage(1);
  };

  useEffect(() => {
    setShowLoader(true);
    getClinicList();
    if (clinicAdded) {
      setShowSnackbar(true);
      setSnackbarMsg(`${clinicAdded} is created`);
      sessionStorage.removeItem('clinicAdded');
    }
  }, [
    dataPage,
    searchValue,
    selectedChips && selectedChips.length && JSON.stringify(selectedChips),
    draft,
  ]);

  const handleRefresh = () => {
    setShowLoader(true);
    getClinicList();
  };

  const getClinicList = async () => {
    if (searchValue && searchValue.length < 3) {
      setShowLoader(false);
    }
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const skip = (dataPage - 1) * limit;
      const status =
        selectedChips &&
        selectedChips.length > 0 &&
        selectedChips.filter((i) => i.type === 'appListing');
      const bookingStatus =
        selectedChips &&
        selectedChips.length > 0 &&
        selectedChips.filter((i) => i.type === 'bookingStatus');
      const serverAgentStatus =
        selectedChips &&
        selectedChips.length > 0 &&
        selectedChips.filter((i) => i.type === 'serverAgentStatus');
      const clinicListQueryParams: IClinicQueryParamOptions = {
        limit,
        skip,
        isDraft: draft,
        ...(searchValue &&
          !draft &&
          searchValue.length >= 3 && {
          searchString: searchValue,
          skip: dataPage !== 1 ? skip : 0,
        }),
        ...(!draft &&
          status &&
          status.length && {
          skip: dataPage !== 1 ? skip : 0,
          appListing:
              status &&
              status.map((i) => (i.label === 'active1' && i.value === true ? i.value : false)),
        }),
        ...(!draft &&
          serverAgentStatus &&
          serverAgentStatus.length && {
          skip: dataPage !== 1 ? skip : 0,
          serverAgentStatus:
              serverAgentStatus &&
              serverAgentStatus.map((i) =>
                i.label === 'disconnected' && i.value === true ? false : i.value,
              ),
        }),
        ...(!draft &&
          bookingStatus &&
          bookingStatus.length && {
          skip: dataPage !== 1 ? skip : 0,
          bookingStatus:
              bookingStatus &&
              bookingStatus.map((i) => (i.label === 'notAccepting' ? 'not_accepting' : i.label)),
        }),
      };
      const response: IClinicListResponse = await ClinicService.getClinicList(
        clinicListQueryParams,
      );
      setClinicList(() => response.data);
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMsg(typeof err === 'string' ? err : (err as any).response.data.error.message[0]);
    } finally {
      setShowLoader(false);
    }
  };
  const handleFilterPopupOpen = () => {
    setOpenFilterPopup(true);
    if (selectedChips && selectedChips.length > 0) {
      const newObj = Object.assign(
        {
          active: false,
          offline: false,
          accepting: false,
          notAccepting: false,
          connected: false,
          disconnected: false,
        },
        ...selectedChips.map((item) => ({ [item.label]: item.value })),
      );
      setFilterOption(newObj);
    }
  };
  const handleFilterService = (inputValue: any) => {
    const arrayForChips = Object.keys(inputValue).map((key: string, index: number) => ({
      id: index,
      label: key,
      value: inputValue[key],
      type:
        key === 'active1' || key === 'offline'
          ? 'appListing'
          : key === 'accepting' || key === 'notAccepting'
            ? 'bookingStatus'
            : 'serverAgentStatus',
    }));
    const selectedOptions = arrayForChips.filter((i: FilterArray) => i.value === true);
    selectedOptions.length ? setSelectedChips(selectedOptions) : setSelectedChips(undefined);
    setOpenFilterPopup(false);
    setDataPage(1);
  };
  const clearAllFilter = () => {
    setSelectedChips(undefined);
    setFilterOption({
      active: false,
      offline: false,
      accepting: false,
      notAccepting: false,
      connected: false,
      disconnected: false,
    });
    setDataPage(1);
  };

  const handleDelete = (data: any) => {
    if (selectedChips?.filter((chip: any) => chip.id !== data.id).length === 0) {
      clearAllFilter();
    } else {
      setSelectedChips((chips) => chips?.filter((chip: any) => chip.id !== data.id));
      setDataPage(1);
    }
  };

  const tabs = [
    {
      label: 'MY CLINICS',
      isHidden: false,
      id: 1,
      Component:
        permission.read && clinicList?.total >= 0 && !showLoader ? (
          <ListClinic
            clinicList={clinicList}
            selectedChipOption={selectedChips}
            onPageChangeHandler={onDataPageChange}
            currentPage={dataPage}
            onFilterClear={clearAllFilter}
            onFilterOptionDel={handleDelete}
          />
        ) : (
          clinicList?.total === 0 &&
          !showLoader && (
            <section style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/clinics/add'}
                text={'No clinics to show'}
                subText={permission.create ? 'Start by adding a new clinic.' : ''}
                buttonText={permission.create ? 'ADD NEW CLINIC' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </section>
          )
        ),
    },
    {
      label: 'DRAFTS',
      isHidden: loggedInuserRoleId === 1 ? false : true,
      id: 2,
      Component:
        permission.read && clinicList?.total !== 0 && !showLoader ? (
          <ListDraftClinic
            clinicList={clinicList}
            onPageChangeHandler={onDataPageChange}
            currentPage={dataPage}
          />
        ) : (
          clinicList?.total === 0 &&
          !showLoader && (
            <section style={{ marginTop: '15%' }}>
              <EmptyState
                path={'/clinics/add'}
                text={'No drafts to show'}
                subText={permission.create ? 'Start by adding a new clinic.' : ''}
                buttonText={permission.create ? 'ADD NEW CLINIC' : ''}
                showAddButton={permission.create ? true : false}
                image={true}
              />
            </section>
          )
        ),
    },
  ];
  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div className='clinicContainer'>
          <div className='clinicHeader'>
            <Toolbar style={{ color: 'primary', paddingRight: '0px', position: 'inherit' }}>
              <Typography variant='h5' className='clinicTitle' color='inherit'>
                Clinics
              </Typography>

              <div style={{ display: 'flex', gap: '16px', marginRight: '-3.75%' }}>
                {!draft && (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <SearchBar
                      onSearchHandler={onSearch}
                      placeholderText={'Search by Clinic Name'}
                    />
                    <div
                      style={{
                        border: `1px solid ${zeplinColor.Primary}`,
                      }}
                      className='iconButton'
                    >
                      <Badge
                        overlap='rectangular'
                        color='error'
                        variant='dot'
                        invisible={!selectedChips || selectedChips.length === 0}
                      >
                        <FilterAltOutlinedIcon
                          onClick={handleFilterPopupOpen}
                          sx={{
                            color: zeplinColor.Primary,
                            width: '24px',
                            height: '24px',
                            borderRadius: '4px',
                            margin: '8px 8px 0px 8px',
                          }}
                        ></FilterAltOutlinedIcon>
                      </Badge>
                      <FilterClinicPopup
                        selectedChips={filterOptions}
                        open={openFilterPopup}
                        setOpen={setOpenFilterPopup}
                        onFilterClick={handleFilterService}
                      />
                    </div>
                    <div
                      style={{
                        border: `1px solid ${zeplinColor.Primary}`,
                      }}
                      className='iconButton'
                    >
                      <RefreshIcon
                        onClick={handleRefresh}
                        sx={{
                          color: zeplinColor.Primary,
                          width: '24px',
                          height: '24px',
                          margin: '8px 8px 0px 8px',
                        }}
                      ></RefreshIcon>
                    </div>
                  </div>
                )}
                {permission.create && (
                  <Buttons
                    text='ADD NEW CLINIC'
                    width='15em'
                    disable={false}
                    onClick={() => navigate('/clinics/add/clinic-details')}
                  />
                )}
              </div>
            </Toolbar>

            <div>
              <CustomTab tabs={tabs} onTabChange={onTabChange} />
            </div>
          </div>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={showSnackbarMsg} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default Clinics;
