/*
 * File: ListAddPoints.tsx
 * Project: mint-portal
 * File Created: Tuesday, 7th March 2023 2:27:22 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 12th June 2023 9:15:25 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, Paper } from '@mui/material';
import { zeplinColor } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { Constants } from '../../../utilities/constants';
import {
  IRedeemRewardHistoryPaginationResponse,
  IRewardRedeemHistoryResponse,
  RewardType,
} from '../../../model';
import CustomToolTip from '../../shared/CustomToolTip';
import moment from 'moment';
import EmptyState from '../../shared/EmptyState/EmptyState';

interface IProps {
  pointsHistoryList: IRedeemRewardHistoryPaginationResponse;
  onPageChangeHandler: any;
  currentPage: number;
}

interface AppointmentsListColumn {
  id: 'date' | 'title' | 'type' | 'points';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const appointmentsListColumns: AppointmentsListColumn[] = [
  { id: 'date', label: 'Date', minWidth: 150 },
  { id: 'title', label: 'Title', minWidth: 150 },
  { id: 'type', label: 'Type', minWidth: 150 },
  { id: 'points', label: 'Points', minWidth: 150 },
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
}));

const ListPointsHistory: FC<IProps> = ({ pointsHistoryList, onPageChangeHandler, currentPage }) => {
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const classes = useStyles();
  const getRewardType = (type: RewardType) => {
    switch (type) {
    case RewardType.Gift:
      return Constants.REWARD_TYPES.Gift;
    case RewardType.PointsCredit:
      return Constants.REWARD_TYPES.PointsCredit
    case RewardType.ManualCredit:
      return Constants.REWARD_TYPES.ManualCredit;
    case RewardType.ReferTo:
      return Constants.REWARD_TYPES.ReferTo;
    case RewardType.ReferBy:
      return Constants.REWARD_TYPES.ReferBy
    default:
      return Constants.REWARD_TYPES.AutoAssignedPoints;
    }
  };

  return (
    <>
      {pointsHistoryList.total && pointsHistoryList.rewardRedeemHistory.length > 0 ? (
        <div>
          <Paper
            className='containerdiv'
            sx={{ borderRadius: '16px', margin: '8px 0px 24px -16px' }}
          >
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {appointmentsListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pointsHistoryList?.total &&
                    pointsHistoryList.rewardRedeemHistory.length &&
                    pointsHistoryList.rewardRedeemHistory.map(
                      (obj: IRewardRedeemHistoryResponse) => {
                        return (
                          <TableRow key={obj.id}>
                            <TableCell>
                              <Typography>
                                {moment(obj.createdAt).format(Constants.MOMENT_FORMATS.DATE_FORMAT)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {obj?.rewardName ? (
                                <CustomToolTip title={obj?.rewardName} width={'220px'} />
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography>{getRewardType(obj.type)}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: obj.points > 0 ? zeplinColor.Primary : zeplinColor.Error,
                                }}
                              >
                                {obj.points > 0 ? '+' + obj.points : obj.points}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              pointsHistoryList.total <= Constants.LIMIT
                ? 1
                : Math.ceil(pointsHistoryList.total / Constants.LIMIT)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='pagination'
            classes={{ outlined: classes.ul }}
          />
        </div>
      ) : (
        pointsHistoryList?.total === 0 && (
          <section style={{ marginTop: '15%' }}>
            <EmptyState
              path={''}
              text={'No points history to show'}
              subText={''}
              buttonText={''}
              showAddButton={false}
              image={true}
            />
          </section>
        )
      )}{' '}
    </>
  );
};

export default ListPointsHistory;
