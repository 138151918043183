/*
 * File: AddClinic.tsx
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 12:10:14 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 15th September 2023 7:49:40 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAddClinic, IViewClinicDetails, IViewClinicDetailsData } from '../../../../model';
import { ClinicService } from '../../../../service/clinic.service';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import ClinicStaff from './ClinicStaff/ClinicStaff';
import ClinicDetails from './ClinicDetails/ClinicDetails';
import FormCard from './FormCard/FormCard';
import Location from './Location/Location';
import MapOperatories from './MapOperatories/MapOperatories';
import NavCard from './NavCard/NavCard';
import OpenDentalConfig from './OpenDentalConfig/OpenDentalConfig';
import OpeningHours from './OpeningHours/OpeningHours';
import Providers from './Providers/Providers';
import ClinicServices from './ClinicServices/ClinicServices';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import Loader from '../../../shared/Loader/Loader';
import ClinicNppwk from './ClinicNppwk/ClinicNppwk';
import ClinicInsurance from './ClinicInsurance/ClinicInsurance';
import { Constants } from '../../../../utilities/constants';
import getPermissionForModule from '../../../../utilities/getPermission';
import EmptyState from '../../../shared/EmptyState/EmptyState';

type objStr = {
  [key: string]: any;
};

const AddClinic = () => {
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Clinics;
  const staffPermission = getPermission.permissions.Staff;
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);
  const [createClinicBtn, setCreateClinicBtn] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [addClinic, setAddClinic] = useState<undefined | IAddClinic | IViewClinicDetailsData>();
  const [updatedClinicDetails, setUpdatedClinicDetails] = useState<IViewClinicDetailsData>();
  const [id, setId] = useState('');
  const nav = useNavigate();
  const route = useLocation();
  const prevLink = route?.pathname.includes('add')
    ? [{ path: '/clinics', label: 'Clinics' }]
    : [
      { path: '/clinics', label: 'Clinics' },
      { path: `/clinics/detail/${addClinic?.id}`, label: addClinic?.name },
    ];
  const setData = (res: IViewClinicDetails) => {
    setAddClinic(res?.data);
  };

  const exchangeCode = sessionStorage.getItem('exchangeCode');
  // **** UseEffects ****
  useEffect(() => {
    if (route?.search?.includes('code') && !route?.search?.includes('id=')) {
      const clinicId = sessionStorage.getItem('clinicId') || '';
      setCurrentPath(`/${route?.pathname?.split('/')[3]}`);
      setId(route?.search?.split('&')[0] || clinicId?.split('=')[1]);
      init(true);
      nav(`${route.pathname}?${clinicId}&${route?.search?.split('?')[1]}`);
    } else if (!route?.search?.includes('code') || !route?.search?.includes('id') || exchangeCode) {
      init(exchangeCode ? true : false);
      exchangeCode && setCurrentPath(`/${route?.pathname?.split('/')[3]}`);
      route?.search &&
        sessionStorage.setItem('clinicId', route?.search?.split('?')[1].split('&')[0]);
    }
  }, [route?.search, route?.pathname]);

  useEffect(() => {
    if (updatedClinicDetails && !updatedClinicDetails?.isPartialData) {
      setCreateClinicBtn(false);
    }
  }, [updatedClinicDetails && updatedClinicDetails?.isPartialData]);

  const init = async (hasHash: boolean) => {
    const path = route?.pathname?.split('/');
    const clinicId = sessionStorage.getItem('clinicId') || '';
    setId(route?.search?.split('&')[0] || clinicId);

    if (!hasHash) {
      if (path.length < 4 || (path?.length >= 4 && !path[3])) {
        setCurrentPath(`/clinic-details`);
        nav(`/clinics/${path[2]}/clinic-details${id ? id : route?.search}`, { replace: true });
      } else {
        setCurrentPath(`/${path[3]}`);
        nav(`/clinics/${path[2]}/${path[3]}${id ? id : route?.search}`, { replace: true });
      }
    }
    if (route?.search) {
      getClinicDetails();
    }
  };

  // **** EventHandlers ****

  const handleFill = (e: any) => {
    if (e.target.id !== '') {
      const path = route.pathname.split('/');
      const mapObj: objStr = {
        ' ': '-',
        '*': '',
      };
      const replaced = e.target.id.replace(/ |\*/g, (matched: any) => mapObj[matched as string]);
      setCurrentPath(`/${replaced.toLowerCase()}`);
      nav(`/clinics/${path[2]}/${replaced.toLowerCase()}${id}`);
    }
  };

  const handleCreateClinicBtn = (value: boolean) => {
    setCreateClinicBtn(value);
  };

  // **** API Calls ****
  const getClinicDetails = async () => {
    try {
      setShowLoader(true);
      const path = route.pathname.split('/');
      const clinicId = sessionStorage.getItem('clinicId')?.split('=')[1] || '';

      const id =
        path[2] === 'detail'
          ? path[3]
          : route?.search?.includes('code')
            ? clinicId
            : route?.search?.split('id=')[1]?.split('&')[0];
      if (id) {
        const res = await ClinicService.viewClinicById(id);
        if (res?.data) {
          setUpdatedClinicDetails(res.data);
          setData(res);
        }
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    } finally {
      setShowLoader(false);
    }
  };

  const updateDraftClinicStatus = async () => {
    if (route?.pathname?.includes('view')) {
      nav(`/clinics/edit/${route?.pathname?.split('/')[3]}${route?.search}`);
    } else if (route?.pathname?.includes('edit')) {
      nav(`/clinics/detail/${route?.search?.split('=')[1]}`);
    } else if (updatedClinicDetails?.id) {
      setShowLoader(true);
      try {
        await ClinicService.updateClinicDraftStatus(updatedClinicDetails?.id);
        sessionStorage.setItem('clinicAdded', updatedClinicDetails?.name);
        nav('/clinics');
        setShowLoader(false);
      } catch (err) {
        setShowSnackbarMessage(
          typeof err === 'string' ? err : (err as any).response.data.error.message[0],
        );
        if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
          setForbiddenAccess(true);
        }
        setShowLoader(false);
        setShowSnackbar(true);
      }
    }
  };
  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            margin: '20px 32px',
            width: '100%',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant='h5'>
                {route?.pathname?.includes('add') ? 'Add A New Clinic' : 'Clinic Details'}
              </Typography>
              <BreadCrumbs
                prevLink={prevLink}
                activeLink={
                  addClinic && addClinic?.name && route?.pathname?.includes('add')
                    ? `Add A New Clinic / ${addClinic?.name}`
                    : route?.pathname?.includes('add')
                      ? 'Add A New Clinic'
                      : 'Clinic Details'
                }
              />
            </Box>

            {(permission.update || permission.create) && !route?.pathname?.includes('edit') ? (
              <Buttons
                text={
                  route?.pathname?.includes('add') && permission.create
                    ? 'Create Clinic'
                    : route?.pathname?.includes('edit') && permission.update
                      ? 'Cancel'
                      : 'Edit Details'
                }
                width='160px'
                disable={createClinicBtn}
                onClick={updateDraftClinicStatus}
                bgWhite={route?.pathname?.includes('edit') ? true : false}
              />
            ) : (
              ''
            )}
          </Box>
          <Box>
            <InfoTextBox content={Constants.INFO_TEXTS.CREATE_CLINIC} width={'100%'} show={true} />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '1.5rem' }}>
            <Box style={{ display: 'grid', gridTemplateRows: 'auto', rowGap: '1.5rem' }}>
              <section onClick={handleFill}>
                <NavCard
                  header='Clinic Details'
                  required
                  content='Enter contact information for your new clinic'
                  currentPath={currentPath}
                  path='/clinic-details'
                />
              </section>
              <section onClick={handleFill}>
                <NavCard
                  header='Location'
                  required
                  content='Provide clinic address for location mapping'
                  currentPath={currentPath}
                  path='/location'
                />
              </section>
              <section onClick={handleFill}>
                <NavCard
                  header='Open Hours'
                  required
                  content='Add operating hours for this clinic'
                  currentPath={currentPath}
                  path='/open-hours'
                />
              </section>
              {route?.pathname?.includes('add') ? (
                <></>
              ) : (
                <>
                  <section onClick={handleFill}>
                    <NavCard
                      header='NPPWK'
                      content='New Patient Paperwork Link'
                      currentPath={currentPath}
                      path='/nppwk'
                    />
                  </section>
                </>
              )}
              {staffPermission.read && (
                <section onClick={handleFill}>
                  <NavCard
                    header='Clinic Staff'
                    content='Select the available staff and map them to pre-defined roles'
                    currentPath={currentPath}
                    path='/clinic-staff'
                  />
                </section>
              )}
              {route?.pathname?.includes('add') ? (
                <></>
              ) : (
                <>
                  <section onClick={handleFill}>
                    <NavCard
                      header='Insurances'
                      content='Available insurance providers for the clinic'
                      currentPath={currentPath}
                      path='/insurances'
                    />
                  </section>
                </>
              )}
              <section onClick={handleFill}>
                <NavCard
                  header='Open Dental Configuration'
                  required
                  content='Provide details for the eConnector and open dental integration'
                  currentPath={currentPath}
                  path='/open-dental-configuration'
                />
              </section>
              <section onClick={handleFill}>
                <NavCard
                  header='Services'
                  required
                  content='Select the available services for the clinic'
                  currentPath={currentPath}
                  path='/services'
                />
              </section>
              <section onClick={handleFill}>
                <NavCard
                  header='Map Operatories'
                  required
                  content='Map your clinic’s operatory chairs to services'
                  currentPath={currentPath}
                  path='/map-operatories'
                />
              </section>
              <section onClick={handleFill}>
                <NavCard
                  header='Providers'
                  required
                  content='Select the available providers for the clinic'
                  currentPath={currentPath}
                  path='/providers'
                />
              </section>
            </Box>
            <Box style={{ width: '100%', overflow: 'hidden' }}>
              <FormCard>
                {currentPath === '/clinic-staff' ? (
                  <ClinicStaff
                    updatedClinicDetails={updatedClinicDetails}
                    handleCreateClinicBtn={handleCreateClinicBtn}
                  />
                ) : currentPath === '/clinic-details' ? (
                  <ClinicDetails
                    setAddClinic={setAddClinic}
                    addClinic={addClinic}
                    setUpdatedClinicDetails={setUpdatedClinicDetails}
                    updatedClinicDetails={updatedClinicDetails}
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    getClinicDetails={getClinicDetails}
                  />
                ) : currentPath === '/location' ? (
                  <Location
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                    showNavLoader={showLoader}
                  />
                ) : currentPath === '/open-hours' ? (
                  <OpeningHours
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                  />
                ) : currentPath === '/nppwk' ? (
                  <ClinicNppwk
                    updatedClinicDetails={updatedClinicDetails}
                    handleCreateClinicBtn={handleCreateClinicBtn}
                  />
                ) : currentPath === '/open-dental-configuration' ? (
                  <OpenDentalConfig
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                    setUpdatedClinicDetails={setUpdatedClinicDetails}
                  />
                ) : currentPath === '/services' ? (
                  <ClinicServices
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                  />
                ) : currentPath === '/map-operatories' ? (
                  <MapOperatories
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                  />
                ) : currentPath === '/insurances' ? (
                  <ClinicInsurance
                    updatedClinicDetails={updatedClinicDetails}
                    handleCreateClinicBtn={handleCreateClinicBtn}
                  />
                ) : (
                  <Providers
                    handleCreateClinicBtn={handleCreateClinicBtn}
                    updatedClinicDetails={updatedClinicDetails}
                  />
                )}
              </FormCard>
            </Box>
          </Box>
          {showSnackbar && (
            <SnackBar message={showSnackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
          )}
        </div>
      )}
    </>
  );
};

export default AddClinic;
