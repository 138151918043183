/*
 * File: utilities.ts
 * Project: mint-portal
 * File Created: Tuesday, 1st November 2022 4:31:51 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 2nd March 2023 6:18:36 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { IViewClinicDetailsData } from '../model';

export class Utilities {
  public static getTextSubTextForClinicEmptyState(
    clinicDetailsData: IViewClinicDetailsData | undefined,
    dependOnOpenDentalConfig = false,
    openDentalSyncStatus = false,
  ): {
    text: string;
    subText: string;
  } {
    if (!clinicDetailsData?.id && !dependOnOpenDentalConfig) {
      return {
        text: 'Clinic Details Required',
        subText: 'Please complete Clinic Details\n first to get access to this section.',
      };
    }

    if (!clinicDetailsData?.id && dependOnOpenDentalConfig) {
      return {
        text: 'Clinic Details & Open Dental Configuration Required',
        subText:
          'Please complete Clinic Details & Open Dental Configuration\n first to get access to this section.',
      };
    }

    if (
      clinicDetailsData?.id &&
      dependOnOpenDentalConfig &&
      !clinicDetailsData?.openDentalConfig?.id
    ) {
      return {
        text: 'Open Dental Configuration Required',
        subText: 'Please complete Open Dental Configuration\n first to get access to this section.',
      };
    }

    if (
      clinicDetailsData?.id &&
      dependOnOpenDentalConfig &&
      clinicDetailsData?.openDentalConfig?.id &&
      !openDentalSyncStatus
    ) {
      return {
        text: 'Open Dental sync in progress',
        subText:
          'It will take approximately 15 minutes for Open Dental to sync with our system.\n You can start assigning services after the first sync is complete.',
      };
    }
    return { text: '', subText: '' };
  }

  public static formatPhoneNumber(phone: string) {
    if (phone) return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
  }

  public static usFormatMobile(phone: string) {
    return (
      phone && phone.replace(/\D+/g, '').replace(/(\d{1})([0-9]{3})(\d{3})(\d{4})/, ' ($2) $3-$4')
    );
  }

  public static getDateFilter(type: string) {
    const endDate = new Date();
    const startDate = new Date();
    if (type === 'yesterday') {
      startDate.setDate(startDate.getDate() - 1);
      endDate.setDate(endDate.getDate() - 1);
    } else if (type === 'last week') {
      startDate.setDate(startDate.getDate() - 6);
    } else if (type === 'last month') {
      startDate.setDate(startDate.getDate() - 29);
    } else if (type === 'last 3 months') {
      startDate.setDate(startDate.getDate() - 89);
    }
    return { startDate, endDate };
  }
}
