/*
 * File: LandingImages.tsx
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 11:52:10 am
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * Last Modified: Wednesday, 15th March 2023 1:13:23 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import { Box, Toolbar, Typography, makeStyles, Button } from '@material-ui/core';
import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ManageService } from '../../../../service/manage.service';
import { zeplinColor } from '../../../../theme';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useDropzone } from 'react-dropzone';
import './LandingCarousel.css';
import { FileService } from '../../../../service/file.service';
import { Constants } from '../../../../utilities/constants';
import Loader from '../../../shared/Loader/Loader';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import RGL, { WidthProvider } from 'react-grid-layout';
import InfoTextBox from '../../../shared/InfoText/InfoText';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import getPermissionForModule from '../../../../utilities/getPermission';
import { RoleIds } from '../../../../utilities/roles';

const ReactGridLayout = WidthProvider(RGL);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    '&.MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiToolbar-gutters': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));

const LandingCarousel = () => {
  const getPermission = getPermissionForModule();
  const roleId = getPermission.roleId;
  const [landingCarousel, setlandingCarousel] = useState<any[]>([
    { location: '', s3Key: '', isSignedURL: false, expiresInSeconds: 0 },
  ]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>('');
  const [errorImg, setErrorImg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [imgUpload, setImgUpload] = useState<any[]>([]);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const prevLink = [{ path: '/manage', label: 'Manage' }];
  const [editMode, setEditMode] = useState(false);
  const style = useStyles();
  const ref = useRef<any>();
  const getImages = async () => {
    try {
      setShowLoader(true);
      const res = await ManageService.getLandingImages();
      const s3Keys = res.data.map((d) => d?.s3Key);
      setImgUpload(s3Keys);
      setlandingCarousel(res.data);
      setEditMode(false);
      setTimeout(() => setShowLoader(false), 2500);
    } catch (error) {
      setShowLoader(false);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };
  useEffect(() => {
    getImages();
    setErrorImg('');
  }, []);
  const handleImageErrors = (acceptedFiles: any) => {
    acceptedFiles.map((i: any, file: any) => {
      if (acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_ALL_IMAGES);
      }
      if (acceptedFiles?.length == 1 && acceptedFiles[file].size > 1048576) {
        setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_1MB_SIZE_EXCEED);
      }
    });
    if (acceptedFiles?.length && landingCarousel.length === 5) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_EXCEED);
    }
    if (acceptedFiles.length > 5 || acceptedFiles.length + imgUpload.length > 5) {
      setErrorImg(Constants.ERROR_MESSAGES.FILE.MAX_NUMBER_FILES_5);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    multiple: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp'],
    },
    onDrop: (acceptedFiles) => {
      handleImageErrors(acceptedFiles);
      if (
        acceptedFiles.filter((value) => value.size > 1048576).length === 0 &&
        acceptedFiles.length <= 5 - landingCarousel.length &&
        ref.current >= acceptedFiles.length
      ) {
        setErrorImg('');
        setlandingCarousel((prevFiles: any) => {
          return [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                location: URL.createObjectURL(file),
                data: file,
              }),
            ),
          ];
        });
        setImgUpload((prevFiles: any) => {
          return [...prevFiles, ...acceptedFiles.map((file) => file)];
        });
      }
    },
  });

  useEffect(() => {
    ref.current = 5 - landingCarousel.length;
  }, [landingCarousel]);

  const handleSetEdit = () => {
    setEditMode(true);
  };
  const closeEdit = () => {
    setEditMode(false);
    setErrorImg('');
    getImages();
  };

  const drag = (e: any) => {
    const sorted = e.sort((a: any, b: any) => a.x - b.x);
    setImgUpload(sorted.map((d: any) => (typeof d.i === 'string' ? d?.i.slice(0, -1) : d.i)));
    // setlandingCarousel(sorted.map((d: any) => d?.i));
  };
  const dragStart = (e: any) => {
    e.map((val: any) => val.y > 0);
    return e;
  };
  const updateImages = async (s3Keys: string[]) => {
    try {
      const res = await ManageService.updateLandingImages({ appImages: s3Keys });
      getImages();
      setErrorImg('');
      if (res) {
        setShowSnackbar(true);
        setShowSnackbarMessage('Landing Carousel updated');
      }
    } catch (error) {
      setShowLoader(false);
      if ((error as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackbar(true);
      setShowSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };

  const handleSave = async () => {
    setShowLoader(true);
    try {
      const imageArr =
        (await imgUpload?.length) &&
        imgUpload.map(async (d: any) => {
          return typeof d !== 'string' || (typeof d == 'string' && d?.includes('blob'))
            ? await FileService.getSignedUrlToUploadFile(
              Constants.FILE_UPLOAD_CATEGORY.APP_IMAGES,
              landingCarousel.filter((val: any) =>
                typeof d == 'string' && d?.includes('blob')
                  ? d === val?.location
                  : val.path == d.path,
              )[0],
              { 'Content-Type': Constants.HEADERS_CONTENT_TYPE.imageType },
            )
            : d;
        });

      if (imageArr) {
        Promise.all(imageArr)
          .then(async (val) => {
            const s3Keys = val.map((ele: any) =>
              ele?.signedUrl ? ele?.signedUrl?.data?.data?.s3Key : ele,
            );
            updateImages(s3Keys);
          })
          .catch((err) => {
            setShowLoader(false);
            setShowSnackbar(true);
            setShowSnackbarMessage(
              typeof err === 'string' ? err : (err as any).response.data.error.message[0],
            );
          });
      }
    } catch (err) {
      setShowSnackbar(true);
      setShowLoader(false);
      setShowSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  const handleRemove = (loc: string, s3: string) => {
    setErrorImg('');
    const filteredData = landingCarousel.findIndex((val: any) => val.location === loc);
    const filteredS3 = imgUpload.findIndex((val: any) =>
      typeof val !== 'string' ? val.location === s3 : val === s3,
    );
    setImgUpload(imgUpload.filter((_, ind: number) => ind !== filteredS3));
    setlandingCarousel(landingCarousel.filter((_, ind: number) => ind !== filteredData));
  };

  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {showLoader && <Loader />}
      {!isForbiddenAccess && (
        <Box className='landing-carousel-container'>
          <Toolbar className={style.toolbar}>
            <div>
              <Typography variant='h5'>Landing Carousel</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Landing Carousel'} />
            </div>
            {editMode ? (
              <div style={{ display: 'flex', gap: '1.5rem' }}>
                <Button
                  variant='outlined'
                  disabled={showLoader ? true : false}
                  style={{
                    padding: '0px 3rem',
                    height: '34px',
                    backgroundColor: `${zeplinColor.Background97}`,
                    border: `1px solid ${zeplinColor.Background70}`,
                    color: `${zeplinColor.Background70}`,
                  }}
                  onClick={closeEdit}
                >
                  Cancel
                </Button>
                <Buttons
                  text='SAVE CHANGES'
                  width='12rem'
                  disable={!landingCarousel?.length || showLoader ? true : false}
                  onClick={handleSave}
                />
              </div>
            ) : (
              (roleId === RoleIds.DSO_ADMIN || roleId === RoleIds.MARKETING_ADMIN) && (
                <Buttons text='Edit' width='6em' disable={false} onClick={handleSetEdit} />
              )
            )}
          </Toolbar>
          {editMode && (
            <Box>
              <InfoTextBox
                content={Constants.INFO_TEXTS.LANDING_CAROUSEL_DRAG_AND_DROP}
                width={'100%'}
                show={true}
              />
            </Box>
          )}
          {!showLoader && (
            <Paper
              className='landing-carousel-card'
              elevation={0}
              sx={{
                borderRadius: `16px`,
                border: `1px solid ${zeplinColor.Background90}`,
                padding: '32px',
              }}
              style={{ height: editMode || landingCarousel.length ? '480px' : '94px' }}
            >
              {!landingCarousel.length && !editMode && (
                <Typography color='textSecondary' variant='subtitle1'>
                  There are no carousel images added at the moment. To add one, click ‘Edit’.
                </Typography>
              )}
              {editMode ? (
                <div style={{ overflow: 'hidden' }}>
                  <Typography
                    variant='body1'
                    style={{ color: zeplinColor.Background50, marginBottom: 10 }}
                  >
                    {' '}
                    You can upload upto 5 images
                  </Typography>
                  <div
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: '0.2rem',
                      borderColor: zeplinColor.Primary,
                      backgroundColor: zeplinColor.Background97,
                    }}
                    // className='upload-btn'
                    {...getRootProps({ className: 'dropzone upload-btn' })}
                  >
                    <input {...getInputProps()} />
                    {/* <img src='/UploadSimple.svg' alt='upload' /> */}
                    <SaveAltIcon />
                    <Typography variant='body1'>Drag & drop or click to upload</Typography>
                    <Typography variant='caption' style={{ width: '30%', textAlign: 'center' }}>
                      JPG or PNG, Image dimensions must be exactly 375 by 460 pixels and max size of
                      1 MB
                    </Typography>
                  </div>
                  {errorImg && (
                    <Typography variant='body1' style={{ color: zeplinColor.Error }}>
                      {errorImg}
                    </Typography>
                  )}
                  <div>
                    <ReactGridLayout
                      className='layout'
                      isBounded={true}
                      margin={[0, 0]}
                      cols={10}
                      width={1000}
                      maxRows={1}
                      preventCollision={false}
                      compactType='horizontal'
                      onDragStart={dragStart}
                      onDragStop={drag}
                    >
                      {landingCarousel?.map((data, index) => {
                        return (
                          <div
                            className='landing-carousel-img'
                            style={{
                              backgroundColor: zeplinColor.Background97,
                              marginTop: '24px',
                              position: 'relative',
                              cursor: 'pointer',
                              height: '100%',
                            }}
                            key={data.s3Key ? `${data.s3Key}${index}` : `${data?.location}${index}`}
                            data-grid={{ x: index * 2, y: 0 * index, w: 2, h: 1 }}
                          >
                            <img
                              src={data?.location ? data.location : (data as string)}
                              height={'100%'}
                              width={'100%'}
                              style={{ objectFit: 'contain' }}
                            />
                            <img
                              src={'/close-selected.svg'}
                              className='landing-remove-btn'
                              onMouseDown={() => {
                                handleRemove(
                                  data?.location,
                                  data.s3Key ? data.s3Key : data?.location,
                                );
                              }}
                              alt='close-button'
                            />
                          </div>
                        );
                      })}
                    </ReactGridLayout>
                  </div>
                </div>
              ) : (
                <div className='landing-images-grid'>
                  {landingCarousel?.map((d) =>
                    d?.location ? (
                      <div
                        className='landing-carousel-img'
                        style={{ backgroundColor: zeplinColor.Background97 }}
                      >
                        <img
                          src={d?.location}
                          height={'100%'}
                          width={'100%'}
                          style={{ objectFit: 'contain' }}
                        />
                      </div>
                    ) : (
                      ''
                    ),
                  )}
                </div>
              )}
              {showSnackbar && (
                <SnackBar
                  message={showSnackbarMessage}
                  show={showSnackbar}
                  setShow={setShowSnackbar}
                />
              )}
            </Paper>
          )}
        </Box>
      )}
    </>
  );
};

export default LandingCarousel;
