/*
 * File: ToolTip.tsx
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 5:56:11 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 17th August 2023 10:53:17 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { zeplinColor } from '../../theme';

type tooltipProps = {
  title: string | undefined | string[];
  width?: string;
  variant?: string | any;
  size?: number | undefined;
  procedureCodes?: boolean;
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`&.${tooltipClasses.popper}`]: {
    zIndex: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: zeplinColor.Background0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: zeplinColor.Background0,
  },
}));

const useStyles = makeStyles(() => ({
  ellipsesText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipsesCode: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, // Display up to two lines
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
  }
}));

const CustomToolTip: FC<tooltipProps> = ({ title, width, size, variant, procedureCodes }) => {
  const classes = useStyles();
  return (
    <div>
      <BootstrapTooltip title={title ? title : ''}>
        <Typography
          className={procedureCodes ? classes.ellipsesCode : classes.ellipsesText}
          style={{
            maxWidth: width,
            color: size === 12 ? zeplinColor.Background50 : zeplinColor.Background0,
            fontSize: size,
          }}
          variant={variant}
        >
          {title}
        </Typography>
      </BootstrapTooltip>
    </div>
  );
};

export default CustomToolTip;
