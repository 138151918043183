/*
 * File: FilterConfigureRewards.tsx
 * Project: mint-portal
 * File Created: Sunday, 16th October 2022 6:46:43 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 3rd March 2023 12:58:34 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import * as React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Dispatch, useState } from 'react';
import { zeplinColor } from '../../../../../theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';

interface FilterParams {
  gift: boolean;
  pointCredits: boolean;
}

type filterModalProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  onFilterClick: Dispatch<boolean>;
  selectedChips: object;
};

const useStyles = makeStyles(() => ({
  paper: { minWidth: '600px' },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const ToggleButton = styled(MuiToggleButton)({
  height: '34px',
  color: zeplinColor.Background50,
  borderRadius: '4px',
  border: `1px solid ${zeplinColor.Background50}`,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: `${zeplinColor.Primary}`,
    border: `1px solid ${zeplinColor.Primary}`,
    backgroundColor: zeplinColor.Surface,
  },
});

const FilterConfigureRewardPopup = ({
  open,
  setOpen,
  onFilterClick,
  selectedChips,
}: filterModalProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<FilterParams | any>(
    selectedChips || { gift: false, pointCredits: false },
  );
  const { gift, pointCredits } = selected;

  React.useEffect(() => {
    if (selectedChips) {
      setSelected(selectedChips);
    }
  }, [selectedChips]);

  const handleFilterOptions = (e: any) => {
    if (e.target.name !== undefined) {
      const name = e.target.name;
      setSelected((prev: FilterParams) => ({
        ...prev,
        [name]: !selected[name],
      }));
    }
  };

  const handleClose = () => {
    setSelected({ gift: false, pointCredits: false });
    setOpen(false);
  };

  const onFilter = () => {
    onFilterClick(selected);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        // onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>
          <Typography variant='h5' component={'span'}>
            Filter Options
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.displayColumn}
            style={{ gap: '16px' }}
          >
            <div style={{ gap: '24px' }} className={classes.displayColumn}>
              <div className={classes.displayColumn}>
                <Typography component={'span'} variant='h6'>
                  Reward Type
                </Typography>
                <Typography component={'span'} variant='body2'>
                  Filter rewards based on its type
                </Typography>
              </div>
              <div style={{ gap: '16px' }} className={classes.displayRow}>
                <ToggleButton
                  disableRipple
                  selected={gift}
                  name='gift'
                  onChange={handleFilterOptions}
                  value='checked'
                  style={{ width: '115px' }}
                >
                  {gift && <CheckIcon />}Gift
                </ToggleButton>
                <ToggleButton
                  disableRipple
                  selected={pointCredits}
                  name='pointCredits'
                  onChange={handleFilterOptions}
                  value='checked'
                  style={{ width: '180px' }}
                >
                  {pointCredits && <CheckIcon />}Points Credit
                </ToggleButton>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: `${zeplinColor.Primary}`,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            onClick={onFilter}
            style={{
              color: `${zeplinColor.Primary}`,
            }}
          >
            <Typography>Filter Results</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterConfigureRewardPopup;
