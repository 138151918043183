/*
 * File: RewardDetails.tsx
 * Project: mint-portal
 * File Created: Tuesday, 1st November 2022 12:13:26 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th March 2023 1:12:54 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './RewardDetails.css';

import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import { Constants } from '../../../../utilities/constants';
import CustomCard from '../../../shared/CustomCard';
import CustomTextField from '../../../shared/CustomTextField';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import DeletePopup from '../../../shared/DeletePopup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import { ICategoryDetail } from '../../../../model';
import Loader from '../../../shared/Loader/Loader';
import { ManageService } from '../../../../service/manage.service';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import { isTextOnly } from '../../../../utilities/validate';
import { styled } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import { zeplinColor } from '../../../../theme';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogBox = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2, 3, 1, 3),
    maxHeight: '26.5rem',
    minWidth: '35rem',
  },
}));

const DialogBoxTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1 0 1 0' }} {...other}>
      {children}
    </DialogTitle>
  );
};

const RewardDetails = () => {
  const [reward, setReward] = useState<ICategoryDetail>({ id: '', name: '' });
  const [rewardCategoryTitle, setRewardCategoryTitle] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState('');
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [warning, setWarning] = useState('');
  const [error, setError] = useState(false);
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const rewardCategorySelected = sessionStorage.getItem('reward-category');
  const navigate = useNavigate();
  const prevLink = [
    { path: '/manage', label: 'Manage' },
    { path: '/manage/reward-categories', label: 'Reward Categories' },
  ];
  const onCancel = () => {
    setUpdatePopUp(false);
    setShowLoader(false);
  };
  const handleEditModal = () => {
    setUpdatePopUp(true);
  };
  const handleEdit = async () => {
    setShowLoader(true);
    try {
      if (rewardCategoryTitle && isTextOnly(rewardCategoryTitle)) {
        await ManageService.updateRewardCategoryById(
          { name: rewardCategoryTitle },
          parseInt(reward.id),
        );
        setShowSnackBar(true);
        setShowSnackBarMessage(`Reward Category Updated`);
        setRewardCategoryTitle('');
        onCancel();
        getRewardById();
      } else {
        setError(true);
        setShowLoader(false);
      }
    } catch (err) {
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowLoader(false);
      setShowSnackBar(true);
    }
  };

  const getRewardById = async () => {
    setShowLoader(true);
    try {
      if (rewardCategorySelected) {
        const category = JSON.parse(rewardCategorySelected);
        const res = await ManageService.getRewardCategoryById(category.id);
        setReward(res);
        sessionStorage.setItem('reward-category', JSON.stringify(res));
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setShowSnackBarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };
  const handleChangeText = (e: any) => {
    setRewardCategoryTitle(e.target.value);
  };
  const handleDeletePopupOpen = () => {
    setDeletePopUp(true);
  };
  const handleDeleteCategory = async () => {
    try {
      const res = await ManageService.deleteRewardCategoryById(parseInt(reward?.id));
      console.log(res);
      rewardCategorySelected && sessionStorage.setItem('deleted-category', rewardCategorySelected);
      sessionStorage.removeItem('reward-category');
      setDeletePopUp(false);
      navigate('/manage/reward-categories');
    } catch (err) {
      setShowLoader(false);
      setShowSnackBar(
        (err as any).response.data.error.message[0]?.includes('existing') ? false : true,
      );
      setDeletePopUp(false);
      setWarning(
        typeof err === 'string'
          ? ''
          : (err as any).response.data.error.message[0]?.includes('existing')
            ? (err as any).response.data.error.message[0]
            : '',
      );
      setShowSnackBarMessage(
        typeof err === 'string'
          ? err
          : (err as any).response.data.error.message[0]?.includes('existing')
            ? ''
            : (err as any).response.data.error.message[0],
      );
    }
  };
  const handleWarning = () => {
    setWarning('');
  };
  useEffect(() => {
    getRewardById();
  }, []);
  useEffect(() => {
    if (rewardCategorySelected) {
      const data = JSON.parse(rewardCategorySelected);
      setRewardCategoryTitle(data.name);
    }
  }, [updatePopUp]);
  useEffect(() => {
    if (rewardCategoryTitle) {
      setError(false);
    }
  }, [rewardCategoryTitle]);
  return (
    <>
      {isForbiddenAccess && (
        <EmptyState
          path={``}
          text={'Sorry, you don’t have access to this section'}
          subText={''}
          buttonText={''}
          showAddButton={false}
          image={true}
          removeImage={false}
          forbiddenAccess={true}
        />
      )}
      {!isForbiddenAccess && (
        <Box
          sx={{ width: '97%', margin: '32px auto', display: 'flex', flexDirection: 'column' }}
          style={{ gap: 32 }}
        >
          {showLoader && <Loader />}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography>Reward Category Details</Typography>
              <BreadCrumbs prevLink={prevLink} activeLink={'Reward Category Details'} />
            </Box>
            <Box style={{ display: 'flex', gap: 20 }}>
              <Button
                variant='outlined'
                color='secondary'
                className='deleteBox'
                onClick={handleDeletePopupOpen}
                disabled={false}
                disableRipple
              >
                <DeleteOutlineSharpIcon
                  className='deleteIcon'
                  style={{ color: `${zeplinColor.Error}` }}
                />
              </Button>
              <Button
                variant='contained'
                color='primary'
                className='edit-reward-details'
                onClick={handleEditModal}
                disableRipple
              >
                EDIT DETAILS
              </Button>
            </Box>
          </Box>
          <Box>
            <CustomCard>
              <Box sx={{ padding: 16 }}>
                <Typography variant='subtitle1'>Reward Category</Typography>
                <Typography variant='body1'>{reward?.name}</Typography>
              </Box>
            </CustomCard>
          </Box>
          {showSnackBar && (
            <SnackBar message={showSnackBarMessage} show={showSnackBar} setShow={setShowSnackBar} />
          )}
          {!showLoader && (
            <DialogBox
              onClose={onCancel}
              aria-labelledby='customized-dialog-title'
              open={updatePopUp || warning ? true : false}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{ gap: 10 }}>
                <DialogBoxTitle id='customized-dialog-title' onClose={onCancel}>
                  <Typography variant='h5'>
                    {warning ? 'Delete Category Failed' : 'Edit Details'}
                  </Typography>{' '}
                </DialogBoxTitle>
                <DialogContent id='scroll'>
                  <Box>
                    {warning ? (
                      <Typography variant='body1'>
                        Sorry, we couldn’t delete the reward category as it is associated with gifts
                        available in the system. Please remove all the gifts under this category and
                        try again.
                      </Typography>
                    ) : (
                      <Box>
                        <CustomTextField
                          width='100%'
                          name='rewardCategoryTitle'
                          value={rewardCategoryTitle}
                          onChange={handleChangeText}
                          label='Reward Category Title*'
                          error={!isTextOnly(rewardCategoryTitle)}
                        ></CustomTextField>
                        {error && (
                          <Typography
                            className='profileDescription'
                            variant='body1'
                            style={{ color: zeplinColor.Error }}
                          >
                            {rewardCategoryTitle
                              ? Constants.ERROR_MESSAGES.TEXT_VALIDATION.ALLOW_ALPHABET_MESSAGE
                              : 'This field is required'}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                  <DialogActions>
                    {warning ? (
                      <Button>
                        <Typography
                          style={{
                            color: zeplinColor.Primary,
                            cursor: 'pointer',
                          }}
                          onClick={handleWarning}
                        >
                          OKAY
                        </Typography>
                      </Button>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          gap: 20,
                          justifyContent: 'flex-end',
                          marginTop: 25,
                        }}
                      >
                        <Button style={{ color: zeplinColor.Background50 }} onClick={onCancel}>
                          CANCEL
                        </Button>
                        <Button
                          onClick={handleEdit}
                          style={{
                            color: zeplinColor.Primary,
                          }}
                        >
                          UPDATE
                        </Button>
                      </div>
                    )}
                  </DialogActions>
                </DialogContent>
              </Box>
            </DialogBox>
          )}
          <DeletePopup
            title={'Delete Category'}
            description={`"${reward?.name}"`}
            subDescription={''}
            open={deletePopUp}
            buttonText={`Delete`}
            setOpen={setDeletePopUp}
            onDeleteClick={handleDeleteCategory}
          />
        </Box>
      )}
    </>
  );
};

export default RewardDetails;
