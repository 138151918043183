/*
 * File: ListBlockouts.tsx
 * Project: mint-portal
 * File Created: Wednesday, 14th September 2022 1:53:06 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 27th February 2023 2:54:33 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
import './ListBlockouts.css';

import {
  BlockOutType,
  IClinicBlockoutDetailResponse,
  IClinicBlockoutListResponse,
} from '../../../../model';
import { Pagination, Paper } from '@mui/material';
import React, { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { Constants } from '../../../../utilities/constants';
import CustomToolTip from '../../../shared/CustomToolTip';
import EmptyState from '../../../shared/EmptyState/EmptyState';
import getPermissionForModule from '../../../../utilities/getPermission';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { zeplinColor } from '../../../../theme';

interface IProps {
  blockoutList: IClinicBlockoutListResponse;
  onPageChangeHandler: any;
  currentPage: number;
}
interface BlockoutListColumn {
  id: 'title' | 'type' | 'blockout_for' | 'start' | 'end';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const BlockoutListColumns: BlockoutListColumn[] = [
  { id: 'title', label: 'Title', minWidth: 300 },
  { id: 'type', label: 'Type', minWidth: 150 },
  { id: 'blockout_for', label: 'Blockout For', minWidth: 150 },
  { id: 'start', label: 'Start', minWidth: 150 },
  { id: 'end', label: 'End', minWidth: 150 },
];
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: zeplinColor.Background70,
      fontWeight: 'bold',
      '&.Mui-disabled': {
        background: zeplinColor.Background90,
      },
      '&.Mui-selected': {
        color: zeplinColor.Primary,
        border: `1px solid ${zeplinColor.Primary}`,
        background: zeplinColor.Background,
        fontWeight: 'bold',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${zeplinColor.Primary50} !important`,
      cursor: 'pointer',
    },
  },
}));

const ListBlockouts: FC<IProps> = ({ blockoutList, onPageChangeHandler, currentPage }) => {
  const search = useLocation().search;
  const clinicId = new URLSearchParams(search).get('id');
  const [dataPage, setDataPage] = useState<number>(currentPage);
  const classes = useStyles();
  const onDataPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setDataPage(page);
    onPageChangeHandler(page);
  };
  const getPermission = getPermissionForModule();
  const permission = getPermission.permissions.Booking;
  const navigate = useNavigate();
  return (
    <>
      {blockoutList.total && blockoutList.blockouts.length > 0 ? (
        <>
          <Paper className='blockoutContainer' sx={{ borderRadius: '16px' }}>
            <TableContainer>
              <Table aria-label='caption table'>
                <TableHead>
                  <TableRow>
                    {BlockoutListColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Typography style={{ color: zeplinColor.Background50 }} variant='subtitle1'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {blockoutList.total &&
                    blockoutList.blockouts.length &&
                    blockoutList.blockouts.map((blockoutObj: IClinicBlockoutDetailResponse) => {
                      return (
                        <TableRow
                          hover
                          classes={{ hover: classes.tableRow }}
                          key={blockoutObj.id}
                          onClick={() =>
                            navigate(
                              blockoutObj?.type === BlockOutType.DSO
                                ? {
                                  pathname: `/clinics/view/booking/blockout?id=${clinicId}&dso-blockout=${blockoutObj.id}`,
                                }
                                : {
                                  pathname: `/clinics/view/booking/blockout?id=${clinicId}&blockout=${blockoutObj.id}`,
                                },
                            )
                          }
                        >
                          <TableCell>
                            <CustomToolTip title={blockoutObj.title} width={'180px'} />
                          </TableCell>
                          <TableCell>
                            {blockoutObj.type === BlockOutType.DSO ? (
                              <Typography
                                className='dsoStatus'
                                style={{
                                  color: zeplinColor.Blue,
                                  backgroundColor: zeplinColor.Blue10,
                                }}
                              >
                                {blockoutObj.type}
                              </Typography>
                            ) : (
                              <Typography
                                className='customStatus'
                                style={{
                                  color: zeplinColor.Primary,
                                  backgroundColor: zeplinColor.Primary50,
                                }}
                              >
                                {blockoutObj.type}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {blockoutObj.type === BlockOutType.OPERATORY
                                ? blockoutObj?.operatoryName
                                : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {!blockoutObj.allDay
                                ? moment(blockoutObj.startDate).format(
                                  Constants.MOMENT_FORMATS.DATE,
                                ) +
                                  ' ' +
                                  moment(blockoutObj.startTime, 'hh:mm:ss').format(
                                    Constants.MOMENT_FORMATS.TIME,
                                  )
                                : moment(blockoutObj.startDate).format(
                                  Constants.MOMENT_FORMATS.DATE,
                                ) + ' All Day'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {!blockoutObj.allDay
                                ? moment(blockoutObj.endDate).format(
                                  Constants.MOMENT_FORMATS.DATE,
                                ) +
                                  ' ' +
                                  moment(blockoutObj.endTime, 'hh:mm:ss').format(
                                    Constants.MOMENT_FORMATS.TIME,
                                  )
                                : '-'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={
              blockoutList.total <= Constants.LIMIT
                ? 1
                : Math.ceil(blockoutList.total / Constants.LIMIT)
            }
            onChange={onDataPageChange}
            page={dataPage}
            variant='outlined'
            shape='rounded'
            className='blockoutListPagination'
            classes={{ outlined: classes.ul }}
          />
        </>
      ) : (
        blockoutList?.total === 0 && (
          <section style={{ margin: '15% auto' }}>
            <EmptyState
              path={`/clinics/add/booking?id=${clinicId}`}
              text={'No blockouts to show'}
              subText={'Start by adding a new blockout.'}
              buttonText={'Add Blockout'}
              showAddButton={permission.create ? true : false}
              image={true}
            />
          </section>
        )
      )}
    </>
  );
};

export default ListBlockouts;
