/*
 * File: file.service.tsx
 * Project: mint-portal
 * File Created: Tuesday, 12th July 2022 6:36:09 pm
 * Author: priyagupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 31st October 2022 6:10:26 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { ApiConstants } from './api-constants';
import axios from '../core/axios';
import { IUploadFilePayload, IUploadFileRO } from '../model/file.model';
import { AxiosRequestHeaders } from 'axios';

export class FileService {
  static uploadFile = async (filePayload: IUploadFilePayload): Promise<IUploadFileRO> => {
    return await (
      await axios.post(ApiConstants.FILE_API.UPLOAD_FILE.URL(), filePayload)
    ).data;
  };

  static getSignedUrlToUploadFile = async (
    category: number,
    file: any,
    header: AxiosRequestHeaders,
  ) => {
    const signedUrl = await axios.get(
      ApiConstants.FILE_API.GET_SIGNED_URL_TO_UPLOAD_FILE.URL(category),
    );
    const fileUploaded = await axios.put(signedUrl.data?.data?.location, file, {
      headers: header,
    });
    return { signedUrl, fileUploaded };
  };
}
