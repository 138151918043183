/*
 * File: dashboard
 * Project: mint-portal
 * File Created: Monday, 14th November 2022 7:22:47 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 16th August 2023 11:50:05 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

export interface IAppointmentDetailsListRO {
  data: IAppointmentDetailsResponse;
  message: string;
}

export interface IAppointmentDetailsResponse {
  appointments: IAppointmentDetails[];
  total: number;
}

export interface IAppointmentDetails {
  appointmentNumber: number;
  clinic: IAppointmentDetailsListClinicRO;
  patient: IAppointmentDetailsListPatientRO;
  service: IAppointmentDetailsListServiceRO;
  status: Status;
  createdAt: string;
  appointmentDateTime: string;
  isInsuranceUsed: boolean;
  note: string;
  operatory: IAppointmentDetailsListOperatoryRO;
}

export enum Status {
  SCHEDULED = 1,
  COMPLETE,
  UNSCHEDULEDLIST,
  ASAP,
  BROKEN,
  PLANNED,
  PTNOTE,
  PTNOTECOMPLETED,
}

export interface IAppointmentDetailsListClinicRO {
  id: string;
  name: string;
}

export interface IAppointmentDetailsListPatientRO {
  patientNumber: number;
  firstName: string;
  lastName: string;
}

export interface IAppointmentDetailsListServiceRO {
  id: string;
  name: string;
}

export interface IAppointmentDetailsListOperatoryRO {
  operatoryName: string;
  operatoryNumber: number;
}

export interface IAppointmentDetailsQueryParams {
  limit?: number;
  skip?: number;
  clinicIds?: string[];
  searchString?: string;
  appointmentStatus?: number;
  isInsuranceUsed?: boolean[];
  startDate?: string;
  endDate?: string;
}

export interface IAppointmentAnalyticsInsuranceRO {
  data: IAppointmentAnalyticsInsuranceResponse[];
  message: string;
}

export interface IAppointmentAnalyticsInsuranceResponse {
  id: number;
  insuranceName: string;
  totalAppointments: number;
  percentageOfTotalAppointments: number;
}

export interface IAppointmentInsuranceQueryParams {
  limit?: number;
  skip?: number;
  clinicIds?: string[];
  searchString?: string;
  appointmentStatus?: number;
  isInsuranceUsed?: boolean;
}

export interface IDashboardQueryParams {
  limit?: number;
  clinicIds?: string[];
  startDate: string;
  endDate: string;
}
export interface IDashBoardServiceRO {
  data: IDashboardServiceDataRO;
  message: string;
}

export interface IDashboardServiceDataRO {
  services: IDashboardServices[];
  ageTypes: IDashboardAgeType[];
  patientTypes: IDashboardPatientType[];
}

export interface IDashboardServices {
  id: string;
  serviceName: string;
  totalAppointments: number;
  percentageOfTotalAppointments: number;
}

export interface IDashboardAgeType {
  type: 'adult' | 'child';
  percentageOfTotalAppointments: number;
}

export interface IDashboardPatientType {
  type: 'existing' | 'new';
  percentageOfTotalAppointments: number;
}

export interface IAppointmentPatientGenderAnalyticsRO {
  data: IAppointmentPatientGenderAnalyticsResponse;
  message: string;
}

export interface IAppointmentPatientGenderAnalyticsResponse {
  gender: IAppointmentPatientGenderResponse;
}

export interface IAppointmentPatientGenderResponse {
  male: number;
  female: number;
  other: number;
}

export interface IAppointmentAnalyticsRO {
  data: IAppointmentAnalyticsResponse;
  message: string;
}

export interface IAppointmentAnalyticsResponse {
  totalAppointments: number;
  averageLeadTimeInDays: number;
  appointmentsStatusCount: IAppointmentAnalyticsStatus;
  appointmentStatusInPercentage: IAppointmentAnalyticsStatus;
}

export interface IAppointmentAnalyticsStatus {
  complete: number;
  broken: number;
}

export interface IAppointmentQueryParams {
  clinicIds?: string[];
  startDate: string;
  endDate: string;
}
